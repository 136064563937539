import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface Commune {
  zipcode: string;
  name: string;
}

interface CommuneState {
  saved: boolean;
  loading: boolean;
  lastCreatedId?: string;
  error: any;
  communes: Commune[];
}

const initialState: CommuneState = {
  saved: false,
  loading: false,
  error: null,
  communes: [],
};

export const getCommunes = createAsyncThunk('getCommunes', async () => {
  const response = await fetch('/data/communes.json');
  const data = (await response.json()) as Commune[];
  return data.filter(function (item, pos) {
    return data.indexOf(item) == pos;
  });
});

const communesSlice = createSlice({
  name: 'communes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommunes.pending, (state: CommuneState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(getCommunes.fulfilled, (state: CommuneState, { payload }) => {
        state.saved = true;
        state.loading = false;
        state.communes = payload;
      })
      .addCase(getCommunes.rejected, (state: CommuneState, { payload }) => {
        state.saved = false;
        state.loading = false;
        state.error = payload;
      });
  },
});

export default communesSlice.reducer;

import React, { useEffect, useState } from 'react';
import { List, Modal, Space, Tag, Typography } from 'antd';
import { createUseStyles } from 'react-jss';
import { Place } from '../slices/classification';
const { Text } = Typography;

const useStyles = createUseStyles({
  title: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    alignSelf: 'center',
    lineHeight: '1 !important',
  },
  subtitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: '400',
    fontSize: '1.5vw !important',
    alignSelf: 'center',
    lineHeight: '1 !important',
  },
  textHelper: {
    fontFamily: "'Baloo 2'",
    fontWeight: '400',
    fontSize: '1.2vw !important',
    alignSelf: 'center',
    lineHeight: '1 !important',
    // red
    color: '#f5222d',
  },
  header: {
    fontWeight: 'bold',
  },
  tag: {
    whiteSpace: 'normal',
    color: 'black',
    cursor: 'pointer',
    fontSize: '13px',
    padding: '2px 5px',
    margin: '5px 0',
  },
  limitPlacesText: {
    textAlign: 'right',
    margin: '8px 0',
  },
  space: {
    width: '100%',
  },
});

interface PlacesModalProps {
  places: Place[];
  ngoPlaces: string[];
  requestClose: () => void;
  isVisible: boolean;
}

export const PlacesModal: React.VFC<PlacesModalProps> = ({
  ngoPlaces,
  places,
  isVisible,
  requestClose,
}) => {
  const classes = useStyles();

  const [countries, setCountries] = useState<Place[]>([]);
  const [france, setFrance] = useState<Place[]>([]);

  useEffect(() => {
    if (places) {
      setCountries(
        places
          .filter((place) => place.countries && place.countries.length)
          .sort((a, b) => a.name.localeCompare(b.name)) as Place[],
      );
      setFrance(
        places
          .filter((place) => place.departments && place.departments.length)
          .sort((a, b) =>
            a.name.includes('DROM') ||
            a.name.includes("Collectivités d'Outre-Mer")
              ? 1
              : a.name.localeCompare(b.name),
          ) as Place[],
      );
    }
  }, [places]);

  function renderFrance(depIds: string[]): JSX.Element[] {
    if (!depIds || depIds.length === 0) return [];
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    return (
      isFranceComplete && [
        <Tag key={'france'} className={classes.tag}>
          France
        </Tag>,
      ]
    );
  }

  function renderDepartments(depIds: string[]): JSX.Element[] {
    if (!depIds || depIds.length === 0) return [];
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return results.length > 0
      ? results.map((department, index) => (
          <Tag key={index} className={classes.tag}>
            {department}
          </Tag>
        ))
      : [];
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return [];
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return results.length > 0
      ? results.map((currentCountry, index) => (
          <Tag key={index} className={classes.tag}>
            {currentCountry}
          </Tag>
        ))
      : [];
  }

  function renderPlaces(placeIds: string[]): JSX.Element[] {
    if (!placeIds || placeIds.length === 0) return [];
    let results = [] as JSX.Element[];
    if (renderFrance(placeIds)) {
      results = results.concat(renderFrance(placeIds));
    } else {
      if (renderDepartments(placeIds)) {
        results = results.concat(renderDepartments(placeIds));
      }
    }
    if (renderCountries(placeIds)) {
      results = results.concat(renderCountries(placeIds));
    }
    return results;
  }

  const ngoCharacteristics = [
    // {
    //   title: (
    //     <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
    //   ),
    //   description: (
    //     <PlaceListForDonorAndNgoPages
    //       elementIds={ngoPlaces ? ngoPlaces : []}
    //       collection={places}
    //       color="default"
    //       collectionName="places"
    //     />
    //   ),
    //   valid: ngoPlaces || [],
    // },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: <>{renderPlaces(ngoPlaces)}</>,
      valid: ngoPlaces || [],
    },
  ];

  return (
    <Modal
      open={isVisible}
      closable={false}
      onCancel={() => {
        requestClose();
      }}
      onOk={() => {
        requestClose();
      }}
      width={'100%'}
      style={{ top: '5px', padding: 0 }}
      okText="Valider"
      cancelText="Annuler"
    >
      <Space direction={'vertical'} size={'large'} className={classes.space}>
        <List
          grid={{ gutter: 0, column: 1 }}
          dataSource={ngoCharacteristics}
          className={classes.tag}
          split={false}
          renderItem={(item) =>
            (Array.isArray(item.valid)
              ? item.valid?.length !== 0
              : !!item.valid) && (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )
          }
        />
      </Space>
    </Modal>
  );
};

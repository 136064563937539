import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Typography } from 'antd';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';
import { NewCallForTenderEditForm } from '../containers/NewCallForTenderEditForm';
const { Text } = Typography;

import { useAppSelector, useAppDispatch } from '../hooks/store';
import { CloseOutlined } from '@ant-design/icons';
import { DonorEditTenderForm } from 'src/containers/DonorEditTenderForm';
import {
  getCallForTender,
  removeCallForTender,
  updateCallForTender,
} from 'src/slices/callForTender';
import { FullscreenSpin } from 'src/components/FullscreenSpin';
import moment from 'moment';
import { Offer } from '../slices/financingNeed';
import { LoggedIntercom } from '../utils/loggedIntercom';

const useStyles = createUseStyles({
  container: {
    padding: '42px',
  },
  view: {
    height: '100vh',
    width: '100vw',
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  deleteButton: {
    // margin: '0 0 0 1.87%',
    color: 'red',
    textAlign: 'center',
  },
  cancelButton: {
    color: 'red',
    marginRight: '32px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props {
  setIsNavBarHidden: (isNavBarHidden: boolean) => void;
}

export const EditTenderDonor: React.VFC<Props> = ({
  setIsNavBarHidden,
}: Props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { callForTenderId } = useParams<{ callForTenderId: string }>();
  const callForTender = useAppSelector(
    (state) => state.callForTender.callForTenders.byId[callForTenderId],
  );

  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    if (user?.role === 'admin') {
      setIsNavBarHidden(false);
    } else {
      setIsNavBarHidden(true);
    }
  }, [user]);

  const history = useHistory();
  const classes = useStyles();

  const renderConfirmDelete = () => (
    <Modal
      onOk={handleDeleteCallOfTender}
      okText={'Supprimer'}
      cancelText={'Annuler'}
      visible={showDeleteModal}
      onCancel={() => setShowDeleteModal(false)}
      okButtonProps={{
        danger: true,
      }}
    >
      <Text>
        {'Êtes-vous sûr de vouloir supprimer ce programme de soutien ?'}
      </Text>
    </Modal>
  );
  useEffect(() => {
    dispatch(getCallForTender(callForTenderId));
  }, []);

  const handleDeleteCallOfTender = () => {
    if (callForTender?.adminDonors && callForTender.adminDonors.length > 0) {
      const donorId = callForTender?.adminDonors[0]._id || '';
      dispatch(removeCallForTender({ callForTenderId, body: { donorId } }));
      history.push('/callForTenders');
    }
  };

  useEffect(() => {
    if (callForTender) {
      form.setFieldsValue({
        ...callForTender,
        openingDate: callForTender?.openingDate
          ? moment(callForTender?.openingDate)
          : null,
        closingDate: callForTender?.closingDate
          ? moment(callForTender?.closingDate)
          : null,
      });
    }
  }, [callForTender]);

  if (!callForTender) return <FullscreenSpin />;

  const handleSave = async (values: any) => {
    if (values.openingDate) {
      const newOpeningDate = values.openingDate[0]
        ? values.openingDate[0].toDate()
        : null;
      const newClosingDate = values.openingDate[1]
        ? values.openingDate[1].toDate()
        : null;
      values.openingDate = newOpeningDate;
      values.closingDate = newClosingDate;
    } else {
      values.openingDate = null;
      values.closingDate = null;
    }
    dispatch(
      updateCallForTender({
        id: callForTenderId,
        ...values,
      }),
    );
    history.goBack();
  };

  const renderActionButtons = () => (
    <div className={classes.buttonContainer}>
      <Button
        type="dashed"
        onClick={() => history.goBack()}
        danger
        icon={<CloseOutlined />}
        className={classes.cancelButton}
      >
        Annuler
      </Button>
      <Button
        type="primary"
        onClick={async () => {
          const values = await form.validateFields();

          handleSave(values);
        }}
      >
        Sauvegarder
      </Button>
    </div>
  );

  function renderDeleteButton() {
    return (
      <>
        {(callForTender.offers &&
          callForTender.offers.find((o) => o.offer !== Offer.visibility) && (
            <></>
          )) || (
          <Button
            className={classes.deleteButton}
            type="primary"
            danger
            onClick={() => setShowDeleteModal(true)}
            size={'small'}
          >
            Supprimer
          </Button>
        )}
      </>
    );
  }

  const handleSaveNewForm = async (values: any) => {
    await dispatch(
      updateCallForTender({
        id: callForTenderId,
        ...values,
      }),
    );
    LoggedIntercom(
      user?.firstName + ' ' + user?.lastName,
      user?.email,
      user?._id,
      'EditedCallForTender',
    );
  };

  async function submitForm() {
    const fieldNames = [
      'name',
      'description',
      'donationTypes',
      'modusOperandi',
      'targetPopulations',
      'tags',
      'places',
      'roadAndNumber',
      'uniqueZipcode',
      'zipcode',
      'city',
      'openingDate',
      'closingDate',
      'url',
      'comments',
    ];

    const allFieldValues = fieldNames.reduce((acc: any, fieldName: string) => {
      acc[fieldName] = form.getFieldValue(fieldName);
      return acc;
    }, {});
    handleSaveNewForm(allFieldValues);
    const donorId = callForTender?.adminDonors[0]._id || '';
    history.push(`/donor/${donorId}/callForTender/${callForTenderId}`);
  }

  return (
    <div className={classes.view}>
      {user?.role === 'admin' && (
        <div className={classes.container}>
          {renderActionButtons()}
          <DonorEditTenderForm form={form} isNewCallForTender={false} />
          {renderActionButtons()}
          {renderDeleteButton()}
          {renderConfirmDelete()}
        </div>
      )}
      {user?.role !== 'admin' && (
        <div className={classes.view}>
          <NewCallForTenderEditForm
            form={form}
            submitForm={submitForm}
            isNewCallForTender={false}
            onCancel={() => {
              setIsNavBarHidden(false);
              history.goBack();
            }}
          />
        </div>
      )}
    </div>
  );
};

import React, { useState } from 'react';
import { Form, Input, Typography, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { theme } from '../constants/theme';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  incrementUserNbConnections,
  signIn,
  signInUpGoogle,
} from '../slices/auth';
import { useFormRules } from '../hooks/useFormRules';
import { FormItem } from 'src/components/FormItem';

const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    alignSelf: 'center',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  signUp: {
    left: 0,
  },
  stepContainer: {
    marginBottom: '1rem',
    minHeight: '30vw',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  stepColLeft: {
    backgroundImage: `url("./images/signInStep.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  stepColRight: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '5vw',
    marginLeft: '50vw',
    position: 'relative',
  },
  stepImage: {
    width: '100%',
  },
  colLeftPadding: {
    marginLeft: '1rem',
    marginRight: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.8vw',
  },
  customLabel: {
    position: 'relative',
    fontSize: '1vw',
    '&::after': {
      content: '"*"',
      color: '#ff4d4f',
      fontSize: '1.2vw',
      marginLeft: '0.2vw',
    },
  },
  customLabelOptional: {
    position: 'relative',
    fontSize: '1vw',
  },
  buttonPrevious: {
    height: '3vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: '70%',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputHeight: {
    height: '3vw',
    fontSize: '1vw',
  },
});

export const SignIn: React.VFC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const rules = useFormRules();
  const history = useHistory();

  const { loading } = useAppSelector(({ auth: { loading } }) => ({
    loading,
  }));

  const [error, setError] = useState<string | undefined>(undefined);

  const responseGoogle = async (response: any) => {
    if (error) {
      setError(undefined);
    }

    if (response.credential) {
      const resultAction = await dispatch(
        signInUpGoogle({ googleAccessToken: response.credential }),
      );
      if (signInUpGoogle.rejected.match(resultAction)) {
        setError(resultAction.error.message);
      } else {
        const nbConnections = await dispatch(
          incrementUserNbConnections({ _id: resultAction.payload._id }),
        );
        console.log(nbConnections);
      }
    }
  };

  const onFinish = async (values: any) => {
    if (error) {
      setError(undefined);
    }

    const resultAction = await dispatch(
      signIn({ email: values.email, password: values.password }),
    );

    if (signIn.rejected.match(resultAction)) {
      setError(resultAction.error.message);
    } else {
      const nbConnections = await dispatch(
        incrementUserNbConnections({ _id: resultAction.payload._id }),
      );
      console.log(nbConnections);
      //window.location.reload();
    }
  };

  return (
    <div className={classes.container}>
      <Form name="normal_login" className="login-form" onFinish={onFinish}>
        <div className={classes.stepContainer}>
          <div className={classes.stepColLeft}></div>
          <div className={classes.stepColRight}>
            <div>
              <div className={classes.colLeftPadding}>
                <div>
                  <Button
                    type="primary"
                    onClick={() => window.location.replace('https://sonar.so/')}
                    className={classes.buttonPrevious}
                    style={{ width: '8vw' }}
                  >
                    Retour
                  </Button>
                </div>
                <div>
                  <Text style={{ fontSize: '2vw' }}>
                    Connectez-vous à Sonar
                  </Text>
                </div>
                <div>
                  <Text style={{ fontSize: '1vw' }}>
                    Profitez à 100% de votre expérience Sonar en vous connectant
                  </Text>
                </div>
                <div className={classes.fieldsContainer}>
                  <div className={classes.field}>
                    <FormItem
                      name="email"
                      rules={[rules.required(), rules.email()]}
                      customLabelClassName={classes.customLabel}
                    >
                      <Input
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        type="email"
                        placeholder={t('common.email')}
                        className={classes.inputHeight}
                      />
                    </FormItem>
                  </div>
                  <div className={classes.field}>
                    <FormItem
                      name="password"
                      rules={[rules.required()]}
                      customLabelClassName={classes.customLabel}
                    >
                      <Input
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder={t('common.password')}
                        className={classes.inputHeight}
                      />
                    </FormItem>
                  </div>
                </div>
                <div className={classes.flexBetween}>
                  {/* <Link to="/signUp">{t('pages.SignIn.createAccount')}</Link> */}
                  <Link to="/lostPassword" style={{ fontSize: '1vw' }}>
                    {t('pages.SignIn.lostPassword')}
                  </Link>
                </div>
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ width: '16vw' }}
                    className={classes.buttonPrevious}
                  >
                    {t('common.logIn')}
                  </Button>
                </div>
                <div>
                  <Text type="danger">
                    {!!error &&
                      t(
                        `pages.SignIn.errors.${error}`,
                        t('common.errorGeneric'),
                      )}
                    &nbsp;
                  </Text>
                </div>
                <div>
                  <FormItem>
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        responseGoogle(credentialResponse);
                        console.log(credentialResponse);
                      }}
                      onError={() => {
                        responseGoogle({ error: 'error' });
                        console.log('Login Failed');
                      }}
                      text="signin_with"
                      locale="fr"
                      size="large"
                      width={'16vw'}
                    />
                  </FormItem>
                </div>
                <div>
                  <Text style={{ fontSize: '1.1vw' }}>
                    Vous n’avez pas encore de compte ?
                  </Text>
                </div>
                <div>
                  <Button
                    style={{ width: '16vw' }}
                    onClick={() => {
                      history.push(`/signup`);
                    }}
                    className={classes.buttonPrevious}
                    type="primary"
                  >
                    {'Inscription'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

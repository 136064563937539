import React, { useMemo, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import {
  AutoComplete,
  Badge,
  Button,
  Dropdown,
  Input,
  Select,
  Tag,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/lib/select';
import { ClassificationsFilter, DonorName } from 'src/slices/donor';
import { Departments } from '../constants/departments';
import { CallForTender } from '../slices/callForTender';
import {
  Classification,
  ClassificationElement,
} from '../slices/classification';
import { FinancingNeed } from 'src/slices/financingNeed';

const { Search } = Input;
const { Option } = Select;

export type SearchFiltersParameters = {
  name?: string;
  tags?: string[];
  statuses?: string[];
  targetPersons?: string[];
  activityDomains?: string[];
  activityZones?: string[];
  sustainableDevelopmentGoals?: string[];
  targetPopulations?: string[];
  donationTypes?: string[];
  source?: string;
  department?: string[];
  region?: string[];
};

interface Props {
  isAutoComplete: boolean;
  classification: Classification;
  suggestions: DonorName[] | null;
  searchParameters: SearchFiltersParameters;
  onChangeName?: (value: string) => void;
  suggestionsLoading: boolean;
  setSearchFilters: React.Dispatch<
    React.SetStateAction<SearchFiltersParameters>
  >;
  classificationsFilter?: ClassificationsFilter;
  isFromProject: boolean;
  financingNeed?: FinancingNeed;
  callForTender?: CallForTender;
  dropdownFilterVisible?: boolean;
  setDropdownFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isNgo?: boolean;
}

const useStyles = createUseStyles({
  container: {
    marginLeft: '5%',
    paddingTop: '2%',
    width: '35vw',
  },
  grid: {
    display: 'grid',
    width: '28vw',
    gridTemplateColumns: '1fr',
    gridGap: '0.625vw',
    margin: '0 auto',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '2.32%',
    border: 'solid 1px',
    borderColor: theme.palette.divider,
  },
  gridCell: {
    width: '100%',
    display: 'block',
    objectFit: 'cover',
    '& .ant-select .ant-select-selector': {
      width: '100%',
      height: '100%',
      background: '#f9f9f9',
      border: 'none',
      padding: '2.35%',
      '& .ant-select-selection-item': {
        background: '#e0f5ff',
      },
    },
  },
  searchBar: {
    width: '35vw',
    display: 'flex',
    '& .ant-select .ant-select-selector': {
      height: '2.5vw',
      webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      borderRadius: '0.25vw',
    },
    '& .ant-badge': {
      marginLeft: '3.35%',
      width: '30%',
      webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      borderRadius: '0.25vw',
      '& .ant-btn': {
        borderRadius: '0.25vw',
        width: '100%',
        height: '2.5vw',
        padding: '2% 2%',
      },
    },
    '& .ant-input-affix-wrapper': {
      height: '2.5vw',
      padding: '1.14% 3.13%',
      borderRadius: '0.25vw',
      '& .ant-input': {
        fontSize: '0.875vw',
      },
    },
    '& .anticon-search': {
      fontSize: '1.75vw',
      color: '#ccc9e6',
    },
    '& .ant-input-group-addon': {
      verticalAlign: 'top',
      backgroundColor: 'transparent',
      fontSize: '0.875vw',
      lineHeight: 1,
      '& .ant-btn': {
        borderLeft: 'none',
        height: '2.5vw',
        width: '2.5vw',
        borderTopRightRadius: '0.25vw !important',
        borderBottomRightRadius: '0.25vw !important',
      },
    },
    '& .ant-btn': {
      lineHeight: '1',
    },
  },
  dropdown: {
    '& .ant-dropdown-trigger': {
      width: '35vw',
    },
  },
  filters: {
    width: '28vw',
    maxWidth: '50%',
  },
  filterColored: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5%',
    marginTop: '1%',
    maxWidth: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '1.5%',
    // justifyContent: 'center',
    '& > *': {
      margin: '5px 0',
    },
  },
  filterIcon: {
    color: '#ccc9e6',
    fontSize: '1.75vw',
    '& svg': { verticalAlign: 'middle' },
  },
  // totalActivatedFilters: {
  //   '&.ant-badge-count': {
  //     background: theme.palette.primary,
  //   },
  // },
});

export const DatabaseSearchBar: React.VFC<Props> = ({
  isAutoComplete,
  classification,
  suggestions,
  searchParameters,
  onChangeName,
  setSearchFilters,
  classificationsFilter,
  isFromProject,
  financingNeed,
  callForTender,
  dropdownFilterVisible,
  setDropdownFilterVisible,
  isNgo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getFieldOptions = (
    field:
      | 'tags'
      | 'statuses'
      | 'targetPersons'
      | 'activityDomains'
      | 'activityZones'
      | 'sustainableDevelopmentGoals'
      | 'targetPopulations'
      | 'donationTypes',
  ) =>
    classification
      ? [
          ...(classificationsFilter
            ? classification[field].filter((entry: ClassificationElement) =>
                classificationsFilter[field]?.includes(entry._id),
              )
            : classification[field]),
        ]
          .sort((a, b) => {
            const first = `${
              field === 'sustainableDevelopmentGoals'
                ? classification?.sustainableDevelopmentGoals.find(
                    (v) => v._id === a._id,
                  )?.number
                : ''
            } ${t(`${field}:${a.name}`)}`;
            const second = `${
              field === 'sustainableDevelopmentGoals'
                ? classification?.sustainableDevelopmentGoals.find(
                    (v) => v._id === b._id,
                  )?.number
                : ''
            } ${t(`${field}:${b.name}`)}`;

            if (field === 'sustainableDevelopmentGoals') {
              if (first && second) {
                if (first.match(/^\d*/) && second.match(/^\d*/)) {
                  const firstNumber = first.match(/^\d*/)?.pop();
                  const secondNumber = second.match(/^\d*/)?.pop();
                  if (firstNumber && secondNumber) {
                    const result =
                      parseInt(firstNumber, 10) - parseInt(secondNumber, 10);
                    return result;
                  }
                }
              }
            }
            return first.localeCompare(second);
          })
          .map((entry: ClassificationElement) => (
            <Option
              value={entry._id}
              key={entry._id}
              label={`${
                field === 'sustainableDevelopmentGoals'
                  ? classification?.sustainableDevelopmentGoals.find(
                      (v) => v._id === entry._id,
                    )?.number
                  : ''
              } ${t(`${field}:${entry.name}`)}`}
            >
              {`${
                field === 'sustainableDevelopmentGoals'
                  ? classification?.sustainableDevelopmentGoals.find(
                      (v) => v._id === entry._id,
                    )?.number
                  : ''
              } ${t(`${field}:${entry.name}`)}`}
            </Option>
          ))
      : [];

  // const getDonationTypesOptions = () =>
  //   classification
  //     ? classification['donationTypes'].map((entry: ClassificationElement) => (
  //         <Option
  //           value={entry._id}
  //           key={entry._id}
  //           label={t(`${'donationTypes'}:${entry.name}`)}
  //         >
  //           {t(`${'donationTypes'}:${entry.name}`)}
  //         </Option>
  //       ))
  //     : [];

  const getTagsOptions = () =>
    classification
      ? (classificationsFilter
          ? classification['tags'].filter((entry: ClassificationElement) =>
              classificationsFilter['tags'].includes(entry._id),
            )
          : classification['tags']
        )
          .filter((elem: ClassificationElement) => {
            if (financingNeed) {
              return financingNeed?.tags.includes(elem._id);
            }
            if (callForTender) {
              return callForTender?.tags.includes(elem._id);
            }
            return false;
          })
          .map((entry: ClassificationElement) => (
            <Option
              value={entry._id}
              key={entry._id}
              label={t(`${'tags'}:${entry.name}`)}
            >
              {t(`${'tags'}:${entry.name}`)}
            </Option>
          ))
      : [];

  const tagOptions = !isFromProject
    ? useMemo(
        () => getFieldOptions('tags'),
        [classification, classificationsFilter],
      )
    : useMemo(() => getTagsOptions(), [classification, classificationsFilter]);

  function getDepOptions() {
    const resultArray = Departments.map((entry) => (
      <Option
        value={entry.numDep.toString()}
        key={entry.numDep}
        label={entry.depName}
      >
        {entry.depName}
      </Option>
    ));
    return resultArray;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getRegionOptions() {
    const resultArray = Departments.reduce((previousValue, currentDep) => {
      previousValue.push(currentDep.regionName);
      return previousValue;
    }, [] as string[])
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((entry) => (
        <Option value={entry} key={entry} label={entry}>
          {entry}
        </Option>
      ));
    return resultArray;
  }

  // const statusOptions = useMemo(() => getFieldOptions('statuses'), [
  //   classification,
  //   classificationsFilter,
  // ]);

  const activityDomainsOptions = useMemo(
    () => getFieldOptions('activityDomains'),
    [classification, classificationsFilter],
  );

  const activityZonesOptions = useMemo(
    () => getFieldOptions('activityZones'),
    [classification],
  );

  const targetPopulationsOptions = useMemo(
    () => getFieldOptions('targetPopulations'),
    [classification, classificationsFilter],
  );

  const sustainableDevelopmentGoalsOptions = useMemo(
    () => getFieldOptions('sustainableDevelopmentGoals'),
    [classification, classificationsFilter],
  );

  // const donationTypesOptions = useMemo(() => getDonationTypesOptions(), [
  //   classification,
  // ]);

  const totalActivatedFilters = useMemo(
    () =>
      Object.values(
        Object.assign({}, { ...searchParameters }, { name: undefined }),
      ).reduce((total: number, current: string | string[] | undefined) => {
        console.log('searchParameters', searchParameters);
        console.log('current', current);
        console.log('total', total);
        return current
          ? Array.isArray(current)
            ? current.length + total
            : total + 1
          : total;
      }, 0),
    [searchParameters],
  );

  const menu = useMemo<any[]>(() => {
    return suggestions
      ? suggestions.map((value) => {
          return {
            value: value.name,
          };
        })
      : [];
  }, [suggestions]);

  const onChangeField = (field: string, value: string | SelectValue) => {
    setSearchFilters((previous) => {
      return { ...previous, [field]: value };
    });
  };

  const handleReset = () => {
    const resetValues = {
      name: '',
      tags: [],
      region: [],
      department: [],
      statuses: [],
      targetPersons: [],
      activityDomains: [],
      activityZones: [],
      sustainableDevelopmentGoals: [],
      targetPopulations: [],
      donationTypes: [],
      source: undefined,
    };
    onChangeName && onChangeName('');
    setSearchFilters(resetValues);
  };

  const renderFilters = () => (
    <div
      className={classes.grid}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {
        <div className={classes.gridCell}>
          <Select
            placeholder={'Filtrer par département'}
            onChange={(value) => onChangeField('department', value)}
            style={{ width: '100%' }}
            value={searchParameters.department}
            // optionFilterProp="label"
            showSearch
            mode={'tags'}
            filterOption={(input, option) =>
              (option?.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            optionFilterProp="children"
          >
            {getDepOptions()}
          </Select>
        </div>
      }

      <div className={classes.gridCell}>
        <Select
          placeholder={'Filtrer par régions'}
          onChange={(value) => onChangeField('region', value)}
          style={{ width: '100%' }}
          value={searchParameters.region}
          // optionFilterProp="label"
          showSearch
          mode={'tags'}
          filterOption={(input, option) =>
            (option?.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          optionFilterProp="children"
        >
          {getRegionOptions()}
        </Select>
      </div>

      <div className={classes.gridCell}>
        <Select
          placeholder={'Filtrer par mots clés'}
          mode="tags"
          onChange={(value) => onChangeField('tags', value)}
          style={{ width: '100%' }}
          value={searchParameters.tags}
          optionFilterProp="label"
        >
          {tagOptions}
        </Select>
      </div>
      {/*<div className={classes.gridCell}>*/}
      {/*  <Select*/}
      {/*    mode="tags"*/}
      {/*    onChange={(value) => onChangeField('statuses', value)}*/}
      {/*    style={{ width: '100%' }}*/}
      {/*    placeholder={'Filtrer par statut'}*/}
      {/*    value={searchParameters.statuses}*/}
      {/*    optionFilterProp="label"*/}
      {/*  >*/}
      {/*    {statusOptions}*/}
      {/*  </Select>*/}
      {/*</div>*/}
      <div className={classes.gridCell}>
        <Select
          mode="tags"
          onChange={(value) => onChangeField('activityDomains', value)}
          style={{ width: '100%' }}
          placeholder={"Filtrer par domaine d'activité"}
          value={searchParameters.activityDomains}
          optionFilterProp="label"
        >
          {activityDomainsOptions}
        </Select>
      </div>
      {isNgo && (
        <div className={classes.gridCell}>
          <Select
            mode="tags"
            onChange={(value) => onChangeField('activityZones', value)}
            style={{ width: '100%' }}
            placeholder={"Filtrer par zone d'activité"}
            value={searchParameters.activityZones}
            optionFilterProp="label"
          >
            {activityZonesOptions}
          </Select>
        </div>
      )}
      <div className={classes.gridCell}>
        <Select
          mode="tags"
          onChange={(value) =>
            onChangeField('sustainableDevelopmentGoals', value)
          }
          style={{ width: '100%' }}
          placeholder={'Filtrer par objectif de développement durable'}
          value={searchParameters.sustainableDevelopmentGoals}
          optionFilterProp="label"
        >
          {sustainableDevelopmentGoalsOptions}
        </Select>
      </div>
      <div className={classes.gridCell}>
        <Select
          mode="tags"
          onChange={(value) => onChangeField('targetPopulations', value)}
          style={{ width: '100%' }}
          placeholder={'Filtrer par public cible'}
          value={searchParameters.targetPopulations}
          optionFilterProp="label"
        >
          {targetPopulationsOptions}
        </Select>
      </div>
      {/*<div className={classes.gridCell}>*/}
      {/*  <Select*/}
      {/*    mode="tags"*/}
      {/*    onChange={(value) => onChangeField('donationTypes', value)}*/}
      {/*    style={{ width: '100%' }}*/}
      {/*    placeholder={'Filtrer par type de mécénat'}*/}
      {/*    value={searchParameters.donationTypes}*/}
      {/*    optionFilterProp="label"*/}
      {/*  >*/}
      {/*    {donationTypesOptions}*/}
      {/*  </Select>*/}
      {/*</div>*/}
      <div className={classes.gridCell}>
        <a onClick={() => handleReset()}>Réinitialiser tous les filtres</a>
      </div>
    </div>
  );

  const renderSearchBar = () => (
    <div className={classes.searchBar}>
      {isAutoComplete ? (
        <AutoComplete
          options={menu}
          style={{ width: '70%' }}
          onSelect={(value: any) => onChangeField('name', value)}
          notFoundContent="Aucun résultat correspondant"
          value={searchParameters.name}
          onSearch={(value) => onChangeField('name', value)}
        >
          <Search
            allowClear
            onChange={(e) => onChangeName && onChangeName(e.target.value)}
            onSearch={(value) => onChangeField('name', value)}
          />
        </AutoComplete>
      ) : (
        <AutoComplete
          style={{ width: '70%' }}
          onSelect={(value: any) => onChangeField('name', value)}
          value={searchParameters.name}
          onSearch={(value) => onChangeField('name', value)}
        >
          <Search
            allowClear
            onChange={(e) => onChangeName && onChangeName(e.target.value)}
            onSearch={(value) => onChangeField('name', value)}
          />
        </AutoComplete>
      )}
      <Badge
        count={totalActivatedFilters}
        style={{
          background: theme.palette.orange[0],
          height: '1.25vw',
          //minWidth: '1.25vw',
          width: 'auto',
          fontFamily: "'Baloo 2'",
          fontSize: '1.25vw',
          lineHeight: '1',
          padding: '0',
          color: theme.palette.common.white,
          borderColor: theme.palette.orange[0],
        }}
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setDropdownFilterVisible(!dropdownFilterVisible);
          }}
          style={{
            fontSize: '0.8vw',
          }}
        >
          Ajouter des filtres
        </Button>
      </Badge>
    </div>
  );

  const ref = useRef(null);

  function showFilters(searchFilters: SearchFiltersParameters) {
    const result = [];
    if (searchFilters.department?.length) {
      result.push(
        searchFilters.department.map((departmentNumber) => (
          <Tag
            closable={
              searchFilters?.department && searchFilters.department?.length > 1
            }
            onClose={() => {
              setSearchFilters((previous) => {
                const newSearchFilters = { ...previous };
                newSearchFilters.department =
                  newSearchFilters.department?.filter(
                    (department) => department !== departmentNumber,
                  );
                return newSearchFilters;
              });
            }}
            key={departmentNumber}
            color="blue"
          >
            {
              Departments.find((d) => d.numDep.toString() === departmentNumber)
                ?.depName
            }
          </Tag>
        )),
      );
    }
    if (searchFilters.region?.length) {
      result.push(
        searchFilters.region.map((region) => (
          <Tag
            closable
            onClose={() => {
              setSearchFilters((previous) => {
                const newSearchFilters = { ...previous };
                newSearchFilters.region = newSearchFilters.region?.filter(
                  (r) => r !== region,
                );
                return newSearchFilters;
              });
            }}
            key={region}
            color="green"
          >
            {region}
          </Tag>
        )),
      );
    }
    if (searchFilters.tags?.length) {
      result.push(
        searchFilters.tags
          ?.map((tagId) => classification.tags.find((t) => t._id === tagId))
          .filter((tag) => tag !== undefined)
          .map((tag) => (
            <Tag
              closable
              onClose={() => {
                setSearchFilters((previous) => {
                  const newSearchFilters = { ...previous };
                  newSearchFilters.tags = newSearchFilters.tags?.filter(
                    (tagId) => tagId !== tag?._id,
                  );
                  return newSearchFilters;
                });
              }}
              color={'magenta'}
              key={tag?._id}
            >
              {t(`${'tags'}:${tag?.name}`)}
            </Tag>
          )),
      );
    }
    if (searchFilters.statuses?.length) {
      result.push(
        searchFilters.statuses
          ?.map((statusId) =>
            classification.statuses.find((s) => s._id === statusId),
          )
          .map((status) => (
            <Tag
              closable
              onClose={() => {
                setSearchFilters((previous) => {
                  const newSearchFilters = { ...previous };
                  newSearchFilters.statuses = newSearchFilters.statuses?.filter(
                    (statusId) => statusId !== status?._id,
                  );
                  return newSearchFilters;
                });
              }}
              color={'red'}
              key={status?._id}
            >
              {t(`${'statuses'}:${status?.name}`)}
            </Tag>
          )),
      );
    }
    if (searchFilters.targetPersons?.length) {
      result.push(
        searchFilters.targetPersons
          ?.map((targetPersonId) =>
            classification.targetPersons.find((t) => t._id === targetPersonId),
          )
          .map((targetPerson) => (
            <Tag
              closable
              onClose={() => {
                setSearchFilters((previous) => {
                  const newSearchFilters = { ...previous };
                  newSearchFilters.targetPersons =
                    newSearchFilters.targetPersons?.filter(
                      (targetPersonId) => targetPersonId !== targetPerson?._id,
                    );
                  return newSearchFilters;
                });
              }}
              color={'red'}
              key={targetPerson?._id}
            >
              {t(`${'targetPersons'}:${targetPerson?.name}`)}
            </Tag>
          )),
      );
    }
    if (searchFilters.activityDomains?.length) {
      result.push(
        searchFilters.activityDomains
          ?.map((activityDomainId) =>
            classification.activityDomains.find(
              (a) => a._id === activityDomainId,
            ),
          )
          .map((activityDomain) => (
            <Tag
              closable
              onClose={() => {
                setSearchFilters((previous) => {
                  const newSearchFilters = { ...previous };
                  newSearchFilters.activityDomains =
                    newSearchFilters.activityDomains?.filter(
                      (activityDomainId) =>
                        activityDomainId !== activityDomain?._id,
                    );
                  return newSearchFilters;
                });
              }}
              color={'orange'}
              key={activityDomain?._id}
            >
              {/*{activityDomain?.name}*/}
              {t(`${'activityDomains'}:${activityDomain?.name}`)}
            </Tag>
          )),
      );
    }
    if (searchFilters.activityZones?.length) {
      result.push(
        searchFilters.activityZones
          ?.map((activityZoneId) =>
            classification.activityZones.find((a) => a._id === activityZoneId),
          )
          .map((activityZone) => (
            <Tag
              closable
              onClose={() => {
                setSearchFilters((previous) => {
                  const newSearchFilters = { ...previous };
                  newSearchFilters.activityZones =
                    newSearchFilters.activityZones?.filter(
                      (activityZonesId) =>
                        activityZonesId !== activityZone?._id,
                    );
                  return newSearchFilters;
                });
              }}
              color={'cyan'}
              key={activityZone?._id}
            >
              {t(`${'activityZones'}:${activityZone?.name}`)}
              {/*{activityZone?.name}*/}
            </Tag>
          )),
      );
    }
    if (searchFilters.sustainableDevelopmentGoals?.length) {
      result.push(
        searchFilters.sustainableDevelopmentGoals
          ?.map((sustainableDevelopmentGoalId) =>
            classification.sustainableDevelopmentGoals.find(
              (s) => s._id === sustainableDevelopmentGoalId,
            ),
          )
          .map((sustainableDevelopmentGoal) => (
            <Tag
              closable
              onClose={() => {
                setSearchFilters((previous) => {
                  const newSearchFilters = { ...previous };
                  newSearchFilters.sustainableDevelopmentGoals =
                    newSearchFilters.sustainableDevelopmentGoals?.filter(
                      (sustainableDevelopmentGoalId) =>
                        sustainableDevelopmentGoalId !==
                        sustainableDevelopmentGoal?._id,
                    );
                  return newSearchFilters;
                });
              }}
              color={'purple'}
              key={sustainableDevelopmentGoal?._id}
            >
              {`${sustainableDevelopmentGoal?.number} ${t(
                `${'sustainableDevelopmentGoals'}:${
                  sustainableDevelopmentGoal?.name
                }`,
              )}`}
              {/*{sustainableDevelopmentGoal?.name}*/}
            </Tag>
          )),
      );
    }
    if (searchFilters.targetPopulations?.length) {
      result.push(
        searchFilters.targetPopulations
          ?.map((targetPopulationId) =>
            classification.targetPopulations.find(
              (t) => t._id === targetPopulationId,
            ),
          )
          .map((targetPopulation) => (
            <Tag
              closable
              onClose={() => {
                setSearchFilters((previous) => {
                  const newSearchFilters = { ...previous };
                  newSearchFilters.targetPopulations =
                    newSearchFilters.targetPopulations?.filter(
                      (targetPopulaitonId) =>
                        targetPopulaitonId !== targetPopulation?._id,
                    );
                  return newSearchFilters;
                });
              }}
              color={'gold'}
              key={targetPopulation?._id}
            >
              {/*{targetPopulation?.name}*/}
              {t(`${'targetPopulations'}:${targetPopulation?.name}`)}
            </Tag>
          )),
      );
    }
    return result;
  }

  return (
    <div>
      <div className={classes.container} ref={ref}>
        <Dropdown visible={dropdownFilterVisible} overlay={renderFilters()}>
          {renderSearchBar()}
        </Dropdown>
      </div>
      <div className={classes.filterColored}>
        {showFilters(searchParameters)}
      </div>
    </div>
  );
};

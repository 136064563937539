import React from 'react';
import { Col, Form, FormInstance, Row, Switch } from 'antd';
import { createUseStyles } from 'react-jss';
import { FormItem } from '../components/FormItem';
import { NotificationSettings } from 'src/slices/auth';

interface Props {
  form: FormInstance;
  notificationSettings: NotificationSettings | undefined;
  isAdmin: boolean;
}

const layout = {
  labelCol: { span: 10 },
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '5%',
    paddingBottom: '32%',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
  },
  modal: {
    '&.ant-modal': {
      maxWidth: '1000px',
      height: 900,
      zIndex: 2000,
    },
    '&.ant-modal-body': {
      height: 900,
    },
  },
  modalWrap: {
    zIndex: 2000,
  },
  formItem: {
    marginTop: '10%',
  },
});

export const MailSettingsForm: React.VFC<Props> = ({
  form,
  notificationSettings,
  isAdmin,
}) => {
  const classes = useStyles();

  const renderSwitchFormItem = ({
    name,
    label,
    value,
  }: {
    name: string;
    label: string;
    value: boolean | undefined;
  }) => (
    <FormItem label={label} name={name} className={classes.formItem}>
      <Switch defaultChecked={value !== undefined ? value : true} />
    </FormItem>
  );

  return (
    <div className={classes.container}>
      <Row>
        <Col span={5} />
        <Col span={14}>
          <Form {...layout} form={form}>
            {renderSwitchFormItem({
              name: 'emailNewDonorMatchProject',
              label: 'Nouveau(x) Mécène(s)',
              value: notificationSettings?.emailNewDonorMatchProject,
            })}
            {renderSwitchFormItem({
              name: 'emailCallForTendersAddedFromProject',
              label:
                'Nouveau(x) appel(s) à candidatures sur les mécènes de vos projets',
              value: notificationSettings?.emailCallForTendersAddedFromProject,
            })}
            {isAdmin &&
              renderSwitchFormItem({
                name: 'emailAdminSummary',
                label: 'Rapport des emails envoyés',
                value: notificationSettings?.emailAdminSummary,
              })}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

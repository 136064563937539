import React from 'react';
import { createUseStyles } from 'react-jss';
import { Typography } from 'antd';

const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    padding: '3.98% 4.59%',
    flexDirection: 'column',
    textAlign: 'center',
  },
  error: {
    fontSize: '1.500vw',
    margin: 'auto',
  },
});

export const NoNgoError: React.VFC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Text className={classes.error}>
        Vous ne disposez pas d&apos;un abonnement valide. <br />
        Si vous pensez qu&apos;il s&apos;agit d&apos;une erreur, merci de nous
        contacter à l&apos;adresse contact@sonar.so.
      </Text>
    </div>
  );
};

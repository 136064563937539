import React, { useEffect } from 'react';
import { Button, Form, Row, Col, Divider, message } from 'antd';
import { createUseStyles } from 'react-jss';

import { useAppSelector, useAppDispatch } from '../hooks/store';
import { theme } from 'src/constants/theme';
import { FullscreenSpin } from 'src/components/FullscreenSpin';
import { MailSettingsForm } from 'src/containers/MailSettingsForm';
import { fetchCurrentUser, updateUserSettings } from 'src/slices/auth';

const useStyles = createUseStyles({
  container: {
    backgroundColor: 'white',
    padding: '16px',
    paddingBottom: '12%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  cancelButton: {
    color: 'red',
  },
  saveButton: {
    backgroundColor: theme.palette.success,
    borderColor: theme.palette.success,
    '&:hover': {
      backgroundColor: theme.palette.green[4],
      borderColor: theme.palette.green[4],
    },
  },
});

export const MailSettings: React.VFC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const classes = useStyles();

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  if (!user) return <FullscreenSpin />;

  const handleSave = async (values: any) => {
    const user = dispatch(updateUserSettings(values));

    if (user !== undefined) {
      fetchCurrentUser();
      message.success('Modifications effectuées avec succès !');
    }
  };

  const renderActionButtons = () => (
    <Row align="top" style={{ margin: '30px' }} justify="space-between">
      <Col span={20} />
      <Col span={4}>
        <Button
          type="primary"
          onClick={async () => {
            const values = await form.validateFields();

            handleSave(values);
          }}
          className={classes.saveButton}
        >
          Sauvegarder
        </Button>
      </Col>
    </Row>
  );

  return (
    <div className={classes.container}>
      {renderActionButtons()}
      <Divider>Réglages notifications</Divider>
      <MailSettingsForm
        form={form}
        notificationSettings={user?.notificationSettings}
        isAdmin={user?.role === 'admin'}
      />
      <div className={classes.buttonContainer} />
    </div>
  );
};

import { ListItemProps } from 'antd/es/list';
import classNames from 'classnames';
import React from 'react';
import { List } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    transition: '0.3s',
  },
  isSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
});

interface Props {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  isSelected?: boolean;
  selectedColor?: string;
  className?: string;
  children: React.ReactNode;
}

export const ListItemClickable: React.VFC<Props & ListItemProps> = ({
  onClick,
  isSelected,
  selectedColor,
  className,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <List.Item
      {...rest}
      className={classNames({
        [classes.container]: true,
        [classes.isSelected]: isSelected,
        [className || '']: !!className,
      })}
      style={
        selectedColor && isSelected ? { backgroundColor: selectedColor } : {}
      }
      onClick={onClick}
    >
      {children}
    </List.Item>
  );
};

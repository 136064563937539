import {
  createAsyncThunk,
  createSlice,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { Offer } from './financingNeed';

export const CALL_FOR_TENDERS_MAX_RESULTS = 10;

type SearchResults = {
  items: CallForTender[];
  totalItems: number;
};

export type CFTOffer = {
  offer: Offer;
  expirationDate?: Date;
  subscriptionId?: string;
};

export type CallForTender = {
  _id: string;
  name: string;
  description?: string;
  openingDate?: Date;
  closingDate?: Date;
  status?: 'open' | 'closed' | 'permanent' | 'coming';
  url?: string;
  comments?: string;
  activityDomains?: string[];
  tags: string[];
  targetPopulations?: string[];
  places: string[];
  city?: string;
  roadAndNumber?: string;
  zipcode?: string;
  uniqueZipcode?: string;
  donationTypes?: string[];
  fundedNgos?: string[];
  modusOperandi?: string[];
  offers: CFTOffer[];
  expirationDate: Date;
  ngos: string[];
  dealFlow: string;
  published: boolean;
  sustainableDevelopmentGoals?: [string];
  adminDonors: { _id: string; name: string }[];
};
enum CallForTenderAction {
  GET_FINANCING_NEEDS = 'getFinancingNeeds',
  GET_FINANCING_NEED = 'getFinancingNeed',
  SAVE_FINANCING_NEED = 'saveFinancingNeed',
  ADD_DONOR = 'addDonor',
  REMOVE_DONOR = 'removeDonor',
}
interface CallForTenderEvent {
  action: CallForTenderAction;
  message?: string;
  error?: any;
}

export type SearchCFTParametersAdmin = {
  id?: string;
  name?: string;
  tags?: string[];
  adminDonor?: string;
  places?: string[];
  zipcode?: string;
  city?: string;
  activityDomains?: string[];
  targetPopulations?: string[];
  offers?: CFTOffer[];
  published?: boolean;
  status?: 'open' | 'closed' | 'permanent' | 'coming';
  donationTypes?: string[];
  fundedNgos?: string[];
  ngos?: string[];
  dealFlow?: string;
  openingDateStart?: Date;
  openingDateEnd?: Date;
  closingDateStart?: Date;
  closingDateEnd?: Date;
  createdDateStart?: Date;
  createdDateEnd?: Date;
  updatedDateStart?: Date;
  updatedDateEnd?: Date;
  offset?: number;
};

export type LoadingStatus = 'idle' | 'pending' | 'succeeded' | 'failed';

interface CallForTenderState {
  saved: boolean;
  loading: boolean;
  callForTendersCounts: { [key: string]: number };
  loadingCallForTendersCounts: LoadingStatus;
  callForTenderCount: number | null;
  loadingCallForTendersCount: LoadingStatus;
  uniqueAdminDonorsCount: number | null;
  uniqueAdminDonorsPaidCount: number | null;
  loadingUniqueAdminDonorsCount: LoadingStatus;
  callForTenderPaidCount: number | null;
  loadingCallForTenderPaidCount: LoadingStatus;
  lastCreatedId?: string;
  event: CallForTenderEvent | null;
  error: any;
  addNgoLoading: boolean;
  removeNgoLoading: boolean;
  addNgoError: any;
  removeNgoError: any;
  offset: number;

  callForTenders: {
    byId: { [id: string]: CallForTender };
    allIds: string[];
    assignedToDonor: CallForTender[];
  };
  searchResult: {
    resultsIds: string[] | null;
    resultsCount: number;
  } | null;
}

const initialState: CallForTenderState = {
  saved: false,
  loading: false,
  callForTendersCounts: {},
  loadingCallForTendersCounts: 'idle',
  callForTenderCount: 0,
  loadingCallForTendersCount: 'idle',
  uniqueAdminDonorsCount: 0,
  uniqueAdminDonorsPaidCount: 0,
  loadingUniqueAdminDonorsCount: 'idle',
  callForTenderPaidCount: 0,
  loadingCallForTenderPaidCount: 'idle',
  error: null,
  event: null,
  addNgoLoading: false,
  removeNgoLoading: false,
  addNgoError: null,
  removeNgoError: null,
  callForTenders: {
    byId: {},
    allIds: [],
    assignedToDonor: [],
  },
  offset: 0,
  searchResult: null,
};

export const fetchCallForTendersCount = createAsyncThunk(
  'fetchCallForTendersCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/call-for-tenders/count',
      );
      dispatch(
        callForTenderSlice.actions.setCallForTendersCount(Number(result)),
      );
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchCallForTendersCountForDonor = createAsyncThunk(
  'fetchCallForTendersCountForDonor',
  async (donorIds: string[], { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ [key: string]: number }>(
        'GET',
        `/call-for-tenders/callForTendersCountForDonor?donorIds=${donorIds.join(
          ',',
        )}`,
      );
      dispatch(callForTenderSlice.actions.setCallForTendersCounts(result));
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchUniqueAdminDonorsCount = createAsyncThunk(
  'fetchUniqueAdminDonorsCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/call-for-tenders/uniqueAdminDonorsCount',
      );
      dispatch(
        callForTenderSlice.actions.setUniqueAdminDonorsCount(Number(result)),
      );
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchUniqueAdminDonorsPaidCount = createAsyncThunk(
  'fetchUniqueAdminDonorsPaidCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/call-for-tenders/uniqueAdminDonorsPaidCount',
      );
      dispatch(
        callForTenderSlice.actions.setUniqueAdminDonorsPaidCount(
          Number(result),
        ),
      );
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchCallForTenderPaidCount = createAsyncThunk(
  'fetchCallForTenderPaidCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/call-for-tenders/callForTenderPaidCount',
      );
      dispatch(
        callForTenderSlice.actions.setCallForTenderPaidCount(Number(result)),
      );
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const getCallForTender = createAsyncThunk(
  'getCallForTender',
  async (callForTenderId: string | undefined) => {
    return await apiRequest<CallForTender>(
      'GET',
      `/call-for-tenders/${callForTenderId}`,
    );
  },
);

export const updateCallForTender = createAsyncThunk(
  'updateCallForTenders',
  async ({ id, ...newValues }: any) => {
    newValues['activityDomains'] = newValues['activityDomains'] || [];
    newValues['tags'] = newValues['tags'] || [];
    newValues['uniqueZipcode'] = newValues['uniqueZipcode'] || '';
    newValues['city'] = newValues['city'] || '';
    newValues['roadAndNumber'] = newValues['roadAndNumber'] || '';
    newValues['zipcode'] = newValues['zipcode'] || '';
    newValues['targetPopulations'] = newValues['targetPopulations'] || [];

    return await apiRequest<CallForTender>(
      'PATCH',
      `/call-for-tenders/${id}`,
      undefined,
      newValues,
    );
  },
);

export const searchAllCFT = createAsyncThunk(
  'searchAllCFT',
  async (payload: SearchCFTParametersAdmin) => {
    const params: any = {
      ...payload,
      limit: CALL_FOR_TENDERS_MAX_RESULTS,
    };
    return {
      ...(await apiRequest<SearchResults>(
        'GET',
        '/call-for-tenders/searchAll',
        params,
      )),
      offset: payload.offset,
    };
  },
);

export const updateCFTOffer = createAsyncThunk(
  'updateCFTOffer',
  async (payload: { cftId: string; offer: Offer; type: string }) => {
    const { cftId, offer, type } = payload;
    return await apiRequest<CallForTender>(
      'PATCH',
      `/call-for-tenders/updateOffer/${cftId}`,
      undefined,
      {
        offer: offer,
        type: type,
      },
    );
  },
);

export const updateCFTPublished = createAsyncThunk(
  'updateCFTPublished',
  async (payload: { cftId: string; published: boolean }) => {
    const { cftId, published } = payload;
    return await apiRequest<CallForTender>(
      'PATCH',
      `/call-for-tenders/updatePublished/${cftId}`,
      undefined,
      {
        published: published,
      },
    );
  },
);

export const saveCallForTender = createAsyncThunk(
  'saveCallForTenders',
  async ({ donorId, ...values }: any) => {
    values['activityDomains'] = values['activityDomains'] || [];
    values['tags'] = values['tags'] || [];
    values['uniqueZipcode'] = values['uniqueZipcode'] || '';
    values['city'] = values['city'] || '';
    values['roadAndNumber'] = values['roadAndNumber'] || '';
    values['zipcode'] = values['zipcode'] || '';
    values['targetPopulations'] = values['targetPopulations'] || [];
    values['donationTypes'] = values['donationTypes'] || [];
    values['fundedNgos'] = values['fundedNgos'] || [];
    values['offers'] = [{ offer: Offer.visibility }];
    values['published'] = true;
    return await apiRequest<CallForTender>(
      'POST',
      `/call-for-tenders/${donorId}`,
      undefined,
      values,
    );
  },
);

export const getCallForTenderFromDonorId = createAsyncThunk(
  'getCallForTenderFromDonorId',
  async (donorId: string | undefined) => {
    return await apiRequest<CallForTender[]>(
      'GET',
      `/donor/${donorId}/callForTenders`,
    );
  },
);

export const removeCallForTender = createAsyncThunk(
  'removeCallForTender',
  async (payload: { callForTenderId: string; body: { donorId?: string } }) => {
    return await apiRequest(
      'DELETE',
      `/call-for-tenders/${payload.callForTenderId}`,
      undefined,
      payload.body,
    );
  },
);

export const invalidateCallForTenders = createAsyncThunk(
  'invalidateCallForTenders',
  () => {
    return Promise.resolve();
  },
);

export const addNgo = createAsyncThunk(
  'addNgo',
  async (
    payload: { callForTenderId: string; body: { ngoId: string } },
    { dispatch },
  ) => {
    const result = await apiRequest<{ status: boolean }>(
      'POST',
      `/call-for-tenders/${payload.callForTenderId}/ngo`,
      undefined,
      payload.body,
    );
    if (result) dispatch(getCallForTender(payload.callForTenderId));
    return result;
  },
);

export const removeNgo = createAsyncThunk(
  'removeNgo',

  async (
    payload: { callForTenderId: string; body: { ngoId: string } },
    { dispatch },
  ) => {
    const result = await apiRequest<{ status: boolean }>(
      'DELETE',
      `/call-for-tenders/${payload.callForTenderId}/ngo`,
      undefined,
      payload.body,
    );
    if (result) dispatch(getCallForTender(payload.callForTenderId));
    return result;
  },
);

function normalizeCallForTenders(list: CallForTender[]) {
  return list.reduce(
    (accumulator, callForTender: CallForTender) => ({
      ...accumulator,
      [callForTender._id]: callForTender,
    }),
    {},
  );
}

export const reportCFTProblem = createAsyncThunk(
  'ReportCFTProblem',
  async (payload: {
    _id: string;
    name: string;
    problemDescription: string;
  }) => {
    await apiRequest(
      'POST',
      `/call-for-tenders/${payload._id}/problem`,
      undefined,
      payload,
    );
    return payload;
  },
);

const receiveCallForTenderReducer = (
  state: CallForTenderState,
  { payload }: { payload: any },
) => {
  state.loading = false;
  if (payload) {
    const callForTendersIds = payload.items.map((item: any) => item._id);
    const callForTendersById = normalizeCallForTenders(payload.items);
    state.callForTenders = {
      allIds: callForTendersIds,
      byId: callForTendersById,
      assignedToDonor: state.callForTenders.assignedToDonor,
    };
    state.offset = payload.offset;
    state.searchResult = {
      resultsCount: payload.totalItems,
      resultsIds: callForTendersIds,
    };
  }
};

const pendingReducer = (state: CallForTenderState) => {
  state.loading = true;
  state.error = null;
};

const fulfilledReducer = (state: CallForTenderState) => {
  state.loading = false;
};

const rejectedReducer = (
  state: CallForTenderState,
  { error }: { error: SerializedError },
) => {
  state.loading = false;
  state.error = error.message || 'error';
};

const callForTenderSlice = createSlice({
  name: 'callForTender',
  initialState,
  reducers: {
    resetCallForTenderEvent: (state: CallForTenderState) => {
      state.event = null;
    },
    setCallForTendersCount: (state, action: PayloadAction<number>) => {
      state.callForTenderCount = action.payload;
    },
    setCallForTendersCounts(
      state,
      action: PayloadAction<{ [key: string]: number }>,
    ) {
      state.callForTendersCounts = action.payload;
    },
    setUniqueAdminDonorsCount: (state, action: PayloadAction<number>) => {
      state.uniqueAdminDonorsCount = action.payload;
    },
    setUniqueAdminDonorsPaidCount: (state, action: PayloadAction<number>) => {
      state.uniqueAdminDonorsPaidCount = action.payload;
    },
    setCallForTenderPaidCount: (state, action: PayloadAction<number>) => {
      state.callForTenderPaidCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCallForTender.pending, (state: CallForTenderState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(reportCFTProblem.pending, pendingReducer)
      .addCase(reportCFTProblem.rejected, rejectedReducer)
      .addCase(reportCFTProblem.fulfilled, (state: CallForTenderState) => {
        state.loading = false;
      })
      .addCase(
        saveCallForTender.fulfilled,
        (state: CallForTenderState, { payload }) => {
          state.saved = true;
          state.loading = false;
          state.lastCreatedId = payload._id;
        },
      )
      .addCase(
        saveCallForTender.rejected,
        (state: CallForTenderState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(getCallForTender.pending, (state: CallForTenderState) => {
        state.loading = true;
      })
      .addCase(
        getCallForTender.fulfilled,
        (state: CallForTenderState, { payload }) => {
          state.loading = false;
          state.callForTenders.byId[payload._id] = payload;
          if (!state.callForTenders.allIds.includes(payload._id))
            state.callForTenders.allIds.push(payload._id);
        },
      )
      .addCase(
        getCallForTender.rejected,
        (state: CallForTenderState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(updateCallForTender.pending, (state: CallForTenderState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateCallForTender.fulfilled,
        (state: CallForTenderState, { payload }) => {
          state.loading = false;
          if (payload) {
            state.callForTenders.byId[payload._id] = payload;
          }
        },
      )
      .addCase(
        updateCallForTender.rejected,
        (state: CallForTenderState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(
        getCallForTenderFromDonorId.pending,
        (state: CallForTenderState) => {
          state.loading = true;
        },
      )
      .addCase(
        getCallForTenderFromDonorId.fulfilled,
        (state: CallForTenderState, { payload }) => {
          state.loading = false;
          const callForTendersIds =
            payload.length > 0
              ? payload.map((callForTender) => callForTender?._id)
              : [];
          const callForTendersById = payload.reduce(
            (accumulator, donor: CallForTender) => ({
              ...accumulator,
              ...(donor?._id ? { [donor._id]: donor } : {}),
            }),
            {},
          );
          state.callForTenders = {
            ...state.callForTenders,
            byId: { ...state.callForTenders.byId, ...callForTendersById },
            allIds: [...state.callForTenders.allIds, ...callForTendersIds],
            assignedToDonor: [
              ...state.callForTenders.assignedToDonor,
              ...payload,
            ],
          };
        },
      )
      .addCase(
        getCallForTenderFromDonorId.rejected,
        (state: CallForTenderState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(removeCallForTender.pending, (state: CallForTenderState) => {
        state.loading = true;
      })
      .addCase(removeCallForTender.fulfilled, (state: CallForTenderState) => {
        state.loading = false;
      })
      .addCase(
        removeCallForTender.rejected,
        (state: CallForTenderState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(
        invalidateCallForTenders.fulfilled,
        (state: CallForTenderState) => {
          state.callForTenders = initialState.callForTenders;
        },
      )
      .addCase(addNgo.pending, (state: CallForTenderState) => {
        state.addNgoLoading = true;
      })
      .addCase(addNgo.fulfilled, (state: CallForTenderState) => {
        state.event = { action: CallForTenderAction.ADD_DONOR };
        state.addNgoLoading = false;
      })
      .addCase(addNgo.rejected, (state: CallForTenderState, { payload }) => {
        state.event = {
          action: CallForTenderAction.ADD_DONOR,
          error: payload,
        };
        state.addNgoLoading = false;
        state.addNgoError = payload;
      })
      .addCase(removeNgo.pending, (state: CallForTenderState) => {
        state.removeNgoLoading = true;
      })
      .addCase(removeNgo.fulfilled, (state: CallForTenderState) => {
        state.event = { action: CallForTenderAction.REMOVE_DONOR };
        state.removeNgoLoading = false;
      })
      .addCase(removeNgo.rejected, (state: CallForTenderState, { payload }) => {
        state.event = {
          action: CallForTenderAction.REMOVE_DONOR,
          error: payload,
        };
        state.removeNgoLoading = false;
        state.removeNgoError = payload;
      })
      .addCase(searchAllCFT.pending, (state: CallForTenderState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchAllCFT.fulfilled, receiveCallForTenderReducer)
      .addCase(searchAllCFT.rejected, rejectedReducer)
      .addCase(updateCFTOffer.pending, (state: CallForTenderState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(
        updateCFTOffer.fulfilled,
        (state: CallForTenderState, { payload }) => {
          state.loading = false;
          state.saved = true;
          if (payload) {
            state.callForTenders.byId[payload._id] = payload;
          }
        },
      )
      .addCase(
        updateCFTOffer.rejected,
        (state: CallForTenderState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(updateCFTPublished.pending, (state: CallForTenderState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(
        updateCFTPublished.fulfilled,
        (state: CallForTenderState, { payload }) => {
          state.loading = false;
          state.saved = true;
          if (payload) {
            state.callForTenders.byId[payload._id] = payload;
          }
        },
      )
      .addCase(
        updateCFTPublished.rejected,
        (state: CallForTenderState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(fetchCallForTendersCount.pending, pendingReducer)
      .addCase(fetchCallForTendersCount.rejected, rejectedReducer)
      .addCase(fetchCallForTendersCount.fulfilled, fulfilledReducer)
      .addCase(fetchCallForTendersCountForDonor.pending, pendingReducer)
      .addCase(fetchCallForTendersCountForDonor.rejected, rejectedReducer)
      .addCase(fetchCallForTendersCountForDonor.fulfilled, fulfilledReducer)
      .addCase(fetchUniqueAdminDonorsCount.pending, pendingReducer)
      .addCase(fetchUniqueAdminDonorsCount.rejected, rejectedReducer)
      .addCase(fetchUniqueAdminDonorsCount.fulfilled, fulfilledReducer)
      .addCase(fetchUniqueAdminDonorsPaidCount.pending, pendingReducer)
      .addCase(fetchUniqueAdminDonorsPaidCount.rejected, rejectedReducer)
      .addCase(fetchUniqueAdminDonorsPaidCount.fulfilled, fulfilledReducer)
      .addCase(fetchCallForTenderPaidCount.pending, pendingReducer)
      .addCase(fetchCallForTenderPaidCount.rejected, rejectedReducer)
      .addCase(fetchCallForTenderPaidCount.fulfilled, fulfilledReducer);
  },
});
export const { resetCallForTenderEvent } = callForTenderSlice.actions;
export default callForTenderSlice.reducer;

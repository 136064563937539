import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { DealFlowCard, FullDealFlowCard } from './dealFlowCard';
import { DealFlowColumn, FullDealFlowColumn } from './dealFlowColumn';

export type AddRemoveDealFlowDto = {
  cards?: string[];
  columns?: string[];
};

export type FullDealFlow = {
  _id: string;
  cards: FullDealFlowCard[];
  columns: FullDealFlowColumn[];
  donors: string[];
  financingNeed: string;
};

export type DealFlow = {
  _id: string;
  cards: string[];
  columns: string[];
  donors: string[];
  financingNeed: string;
};

export type createDealFlowDto = {
  financingNeed: string;
};

export type updateDealFlowDto = {
  _id?: string;
  cards?: DealFlowCard[];
  columns?: DealFlowColumn[];
  financingNeed?: string;
  add: AddRemoveDealFlowDto;
  remove: AddRemoveDealFlowDto;
};

interface DealFlowState {
  saved: boolean;
  loading: boolean;
  error: any;
  dealFlow: DealFlow | null;
  fullDealFlow: FullDealFlow | null;
}

const initialState: DealFlowState = {
  saved: false,
  loading: false,
  error: null,
  dealFlow: null,
  fullDealFlow: null,
};

export const getDealFlow = createAsyncThunk(
  'getDealFlow',
  async (dealFlowId: string | undefined) => {
    // return dealflow;
    return await apiRequest<DealFlow>('GET', `/dealFlow/${dealFlowId}`);
  },
);

export const getFullDealFlow = createAsyncThunk(
  'getFullDealFlow',
  async (dealFlowId: string | undefined) => {
    // return dealflow;
    return await apiRequest<FullDealFlow>(
      'GET',
      `/dealFlow/${dealFlowId}/full`,
    );
  },
);

export const updateDealFlow = createAsyncThunk(
  'updateDealFlow',

  async (payload: updateDealFlowDto, { dispatch }) => {
    const body = payload;
    const response = await apiRequest<{ status: boolean }>(
      'PATCH',
      `/dealFlow/${payload._id}`,
      undefined,
      body,
    );
    if (response) dispatch(getDealFlow());
    return response;
  },
);

const dealFlowSlice = createSlice({
  name: 'dealFlow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateDealFlow.pending, (state: DealFlowState) => {
        state.loading = true;
      })
      .addCase(updateDealFlow.fulfilled, (state: DealFlowState) => {
        state.loading = false;
      })
      .addCase(updateDealFlow.rejected, (state: DealFlowState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getDealFlow.pending, (state: DealFlowState) => {
        state.loading = true;
      })
      .addCase(getDealFlow.fulfilled, (state: DealFlowState, { payload }) => {
        state.loading = false;
        state.dealFlow = payload;
      })
      .addCase(getDealFlow.rejected, (state: DealFlowState, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.dealFlow = null;
      })
      .addCase(getFullDealFlow.pending, (state: DealFlowState) => {
        state.loading = true;
      })
      .addCase(
        getFullDealFlow.fulfilled,
        (state: DealFlowState, { payload }) => {
          state.loading = false;
          state.fullDealFlow = payload;
        },
      )
      .addCase(
        getFullDealFlow.rejected,
        (state: DealFlowState, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.dealFlow = null;
        },
      );
  },
});
// export const { addColumnCard, removeColumnCard } = dealFlowSlice.actions;

export default dealFlowSlice.reducer;

import React, { useState } from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { parse } from 'query-string';

import { useFormRules } from '../hooks/useFormRules';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { sendLostPassword, useLostPasswordToken } from '../slices/auth';
import { Box } from '../components/Box';

const { Text } = Typography;

const useStyles = createUseStyles({
  box: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boxWide: {
    minWidth: '550px',
  },
  form: {
    width: '100%',
  },
});

export const LostPassword: React.VFC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();
  const rules = useFormRules();

  const { loading } = useAppSelector(({ auth: { loading } }) => ({
    loading,
  }));

  const [error, setError] = useState(false);

  const { token }: any = parse(search);

  const onFinish = async (values: any) => {
    if (error) {
      setError(false);
    }

    const resultAction = await (token
      ? dispatch(useLostPasswordToken({ token, password: values.password }))
      : dispatch(sendLostPassword({ email: values.email })));

    if (
      (token ? useLostPasswordToken : sendLostPassword).rejected.match(
        resultAction,
      )
    ) {
      setError(true);
    } else {
      if (token) {
        message.success(t('common.passwordChanged'));
      } else {
        message.info(t('pages.LostPassword.emailSent'));
      }
      history.push('/');
    }
  };

  const renderSendLostPasswordFormItems = () => (
    <Form.Item name="email" rules={[rules.required(), rules.email()]}>
      <Input
        prefix={<UserOutlined className="site-form-item-icon" />}
        type="email"
        placeholder={t('common.email')}
      />
    </Form.Item>
  );

  const renderUseLostPasswordTokenFormItems = () => (
    <>
      <Form.Item
        name="password"
        label={t('common.password')}
        rules={[rules.required(), rules.min(10)]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label={t('forms.confirmPassword')}
        dependencies={['password']}
        hasFeedback
        rules={[
          rules.required(),
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t('forms.errorConfirmPasswordNotMatch')),
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
    </>
  );

  return (
    <Box className={classNames(classes.box, { [classes.boxWide]: !!token })}>
      <Form
        layout={'vertical'}
        name="normal_login"
        className={classes.form}
        onFinish={onFinish}
      >
        {token
          ? renderUseLostPasswordTokenFormItems()
          : renderSendLostPasswordFormItems()}
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{ width: '100%' }}
        >
          {t('common.submit')}
        </Button>
        <div>
          <Text type="danger">
            {error && t(token ? 'forms.errorBadToken' : 'common.errorGeneric')}
            &nbsp;
          </Text>
        </div>
        <div>
          <Link to="/signIn">{t('pages.SignUp.backToSignIn')}</Link>
        </div>
      </Form>
    </Box>
  );
};

import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Menu,
  Dropdown,
  Typography,
  Divider,
  Button,
  Tag,
  Image,
  message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { debounce } from 'lodash';
import { PlacesSelectorModal } from '../components/PlacesSelectorModal';
import { TagsSelectorModal } from '../components/TagsSelectorModal';
import { IUploadProps, Upload } from '../components/Upload';

import { useAppDispatch, useAppSelector } from '../hooks/store';
import { useSortedClassification } from '../hooks/useSortedClassification';
import { getClassification, Place } from '../slices/classification';
import { getCommunes } from '../slices/communes';

import { reset, searchRna } from '../slices/rna';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { placesValidator, tagsValidator } from '../helpers/validators';
import { useFormRules } from '../hooks/useFormRules';
import { FormItem } from 'src/components/FormItem';
import {
  getDonor,
  getDonorsFromNgoId,
  saveContributorDonor,
  SaveContributorDonorDto,
  searchDonorsAndGetSuggestions,
} from 'src/slices/donor';
import { ContributorAddDonorForm } from './ContributorAddDonorForm';

const { Option } = Select;
const { Text, Paragraph, Link } = Typography;

const layout = {
  labelCol: { span: 6 },
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '1vh',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    '& .ant-form-item, .ant-form-item-label > label,\
      .ant-input, .ant-input-number': {
      fontSize: '0.875vw',
    },
    '& .ant-col': {
      maxWidth: '100%',
    },
  },
  textArea: {
    height: 200,
    marginBottom: 50,
  },
  tagCities: {
    fontSize: '14px',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
  },
  menu: {
    width: 800,
    whiteSpace: 'normal',
    height: '60vh',
    overflow: 'auto',
  },
  menuItem: {
    position: 'relative',
    width: '100%',
    wordWrap: 'break-word',
    marginBottom: 20,
  },
  name: {
    width: '100%',
    fontWeight: 'bold',
  },
  description: {
    width: '100%',
  },
  tag: {
    fontSize: '14px',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  button: {
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  search: {
    '& .ant-input-group-addon': {
      backgroundColor: 'white',
      fontSize: '0.875vw',
      lineHeight: '1.5715',
      height: '100%',
      '& .ant-btn': {
        padding: '4px 0 !important',
        height: 'unset',
        width: '2vw',
        fontSize: '0.875vw !important',
        boxShadow: 'none',
      },
    },
    '& .ant-btn-icon-only > *': {
      fontSize: '1vw',
    },
  },
  trashIcon: {
    color: '#002766',
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  headquarterCityFormItem: {
    marginTop: '1vh',
    '& .ant-input-affix-wrapper-disabled': {
      backgroundColor: 'white',
    },
    '& .ant-input[disabled]': {
      color: 'black',
    },
  },
  donorsFormItem: {
    marginTop: '1vh',
    '& .ant-form-item-tooltip': {
      color: '#5050F9 !important',
    },
  },
  modal: {
    '&.ant-modal': {
      maxWidth: '1000px',
      height: 900,
      zIndex: 2000,
    },
    '&.ant-modal-body': {
      height: 900,
    },
  },
  modalWrap: {
    zIndex: 2000,
  },
});

interface INgoEditForm {
  form: FormInstance;
  ngoId?: string;
}

const debouncedSearchCall = debounce((searchFunction: any, value: string) => {
  searchFunction(value);
}, 500);

export const NgoEditForm: React.VFC<INgoEditForm> = ({ form, ngoId }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const rules = useFormRules();
  const [menu, setMenu] = useState<null | React.ReactElement>(null);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string | undefined | null>(undefined);

  const [cityState, setCityState] = useState<string>('');
  const [citiesState, setCitiesState] = useState<string[]>([]);

  const [addDonorModalOpen, setAddDonorModalOpen] = useState(false);
  const [addDonorForm] = Form.useForm<SaveContributorDonorDto>();

  const classification = useSortedClassification();
  const { results, loading, userRole, suggestionsDonors, donors, communes } =
    useAppSelector(
      ({
        rna: { loading, results },
        auth,
        donor: { suggestionsDonors, donors },
        communes: { communes },
      }) => ({
        loading,
        results,
        userRole: auth.user?.role,
        suggestionsDonors,
        donors,
        communes,
      }),
    );

  useEffect(() => {
    dispatch(getClassification());

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (ngoId) {
      dispatch(getDonorsFromNgoId(ngoId));
    }
    if (communes.length === 0) {
      dispatch(getCommunes());
    }
  }, [ngoId, communes]);

  useEffect(() => {
    if (results && results.length > 0) {
      setMenu(
        <Menu className={classes.menu}>
          {results.map(
            ({
              idRna,
              object,
              name,
              rnaNumber,
              siret,
              headquarterAddress,
              websiteUrl,
              creationYear,
              isPublicInterestNgo,
            }) => (
              <Menu.Item
                key={rnaNumber}
                className={classes.menuItem}
                onClick={() => {
                  form.setFieldsValue({
                    name,
                    idRna,
                    object: (object?.length || 0) > 1 ? object : null,
                    siret,
                    headquarterAddress,
                    websiteUrl,
                    creationYear,
                    isPublicInterestNgo,
                  });
                  dispatch(reset());
                }}
              >
                <div className={classes.name}>{name}</div>
                {(object?.length || 0) > 1 && (
                  <Paragraph
                    className={classes.description}
                    type="secondary"
                    ellipsis={{ rows: 2 }}
                  >
                    {object}
                  </Paragraph>
                )}
                <Text type="secondary">RNA : {idRna}</Text>
                {headquarterAddress && (
                  <>
                    <br />
                    <Text type="secondary">Adresse : {headquarterAddress}</Text>
                  </>
                )}
              </Menu.Item>
            ),
          )}
        </Menu>,
      );
    } else {
      setMenu(null);
    }
  }, [results]);

  const optionsDonors = useMemo(
    () =>
      (suggestionsDonors || [])
        .concat(
          Object.entries(donors.byId || {})
            .map(([id, donor]) => ({
              _id: id,
              name: donor.name,
            }))
            .filter(
              (donor) =>
                (suggestionsDonors || []).findIndex(
                  (searchDonor) => searchDonor._id === donor._id,
                ) === -1,
            ),
        )
        .filter(
          (donor) => !(form.getFieldValue('donors') || []).includes(donor._id),
        )
        .map((donor) => ({ label: donor.name, value: donor._id })),
    [donors.byId, suggestionsDonors],
  );

  useEffect(() => {
    form.setFieldsValue({ logoUrl });
  }, [logoUrl]);

  useEffect(() => {
    if (form.getFieldValue('logoUrl')) {
      setLogoUrl(form.getFieldValue('logoUrl'));
    }
  }, [form.getFieldValue('logoUrl')]);

  if (!classification) return <FullscreenSpin />;

  const handleSearch = (value: string) => {
    if (value !== '' && value !== undefined) {
      dispatch(searchDonorsAndGetSuggestions({ name: value, offset: 0 }));
    }
  };

  const { tags, places, activityDomains, targetPopulations } = classification;

  let lastSearchNamePromise: any;
  const searchName = (name: string) => {
    if (name.length >= 3) {
      lastSearchNamePromise?.abort();
      lastSearchNamePromise = dispatch(searchRna({ text: name }));
    }
  };

  const renderDonorFormItem = () => (
    <>
      <FormItem
        label="Liste des mécènes qui soutiennent ou ont soutenu votre organisation"
        name="unverifiedDonors"
        tooltip={"À remplir afin d'augmenter la qualité des mécènes proposés"}
        className={classes.donorsFormItem}
      >
        <Select
          allowClear
          style={{ width: '100%' }}
          mode="multiple"
          onSearch={handleSearch}
          filterOption={(input, option) => {
            const label = (option?.label as string) || '';

            return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          options={optionsDonors}
          placeholder={'Rechercher  un mécène'}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  padding: 8,
                }}
              >
                <a
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setAddDonorModalOpen(true);
                  }}
                >
                  {"Un Mécène n'apparait pas ? Ajoutez-le "}
                  <PlusOutlined />
                </a>
              </div>
            </div>
          )}
        />
      </FormItem>
    </>
  );

  /* Logo */
  const renderLogo = () => {
    return (
      logoUrl && (
        <div>
          <Row align="middle">
            <Col xs={6} />
            <Col xs={5} xxl={3}>
              <div
                style={{ display: 'flex', float: 'left', marginTop: '10px' }}
              >
                <Image
                  src={logoUrl}
                  width="90px"
                  height="90px"
                  fallback="error"
                  alt="Logo"
                />
              </div>
            </Col>
            <Col xs={9} xxl={10}>
              <Link onClick={() => setLogoUrl(null)} style={{ float: 'left' }}>
                <DeleteOutlined className={classes.trashIcon} />
              </Link>
            </Col>
          </Row>
        </div>
      )
    );
  };

  const onSuccessLogoUpload = (info: any) => {
    if (info.file.type === 'image/png' || info.file.type === 'image/jpeg') {
      message.success('Logo ajouté avec succès !');
      setLogoUrl(info.file.response);
    } else {
      message.error('Seul les formats jpeg et png sont acceptés');
    }
  };

  function updateCitiesState(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const cities = communes
      .filter((c) => {
        if (c.zipcode) {
          const zipcode = c.zipcode.toString();
          return zipcode === value.toString();
        }
        return false;
      })
      .map((c) => c.name);
    setCitiesState(cities);
  }

  const uploadLogoProps: IUploadProps = {
    name: 'file',
    endpoint: '/file/upload',
    onSuccess: onSuccessLogoUpload,
    text: 'Importer un logo',
    children: <Button icon={<UploadOutlined />}>Importer un logo</Button>,
    uploadProps: {
      showUploadList: false,
    },
  };

  const renderUploadLogo = () => {
    return (
      <div style={{ marginBottom: '25px' }}>
        <Row align="middle" style={{ paddingBottom: '8px' }}>
          <Text
            style={{
              textAlign: 'left',
              width: '100%',
              marginRight: '8px',
              fontSize: '0.875vw',
            }}
          >
            Logo :
          </Text>
        </Row>
        <Row align="middle" style={{ paddingBottom: '8px' }}>
          <Upload {...uploadLogoProps} />
        </Row>
        {renderLogo()}
      </div>
    );
  };

  const france = places
    .filter((place) => place.departments && place.departments.length)
    .sort((a, b) =>
      a.name.includes('DROM') || a.name.includes("Collectivités d'Outre-Mer")
        ? 1
        : a.name.localeCompare(b.name),
    ) as Place[];

  const countries = places
    .filter((place) => place.countries && place.countries.length)
    .sort((a, b) => a.name.localeCompare(b.name)) as Place[];

  function renderFrance(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    return isFranceComplete && <Tag className={classes.tag}>France</Tag>;
  }

  function renderDepartments(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return (
      results.length > 0 &&
      results.map((department, index) => (
        <Tag key={index} className={classes.tag}>
          {department}
        </Tag>
      ))
    );
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return;
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return (
      results.length > 0 &&
      results.map((currentCountry, index) => (
        <Tag key={index} className={classes.tag}>
          {currentCountry}
        </Tag>
      ))
    );
  }

  function renderRnaNumber() {
    return (
      <FormItem
        label="Numéro RNA"
        name="rnaNumber"
        normalize={(value) => (value === '' ? null : value)}
        rules={[rules.required()]}
      >
        <Input />
      </FormItem>
    );
  }

  function renderLieuxIntervention() {
    return (
      <FormItem
        label="Lieu(x) d’intervention"
        name="places"
        rules={
          userRole !== 'admin' &&
          !form.getFieldValue('city') &&
          !form.getFieldValue('zipcode') &&
          !form.getFieldValue('roadAndNumber') &&
          !form.getFieldValue('uniqueZipcode')
            ? [
                {
                  required: true,
                  type: 'array',
                  validator: placesValidator,
                },
              ]
            : undefined
        }
      >
        <div>
          <div style={{ marginBottom: '20px' }}>
            {!!form.getFieldValue('roadAndNumber') &&
              !!form.getFieldValue('city') &&
              !!form.getFieldValue('zipcode') && (
                <div>
                  <Tag
                    key={'uniqueAddressWithInformations'}
                    className={classes.tag}
                  >
                    {form.getFieldValue('roadAndNumber')}
                    {', '}
                    {form.getFieldValue('city')}
                    {', '}
                    {form.getFieldValue('zipcode')}
                  </Tag>
                </div>
              )}
            {!!form.getFieldValue('uniqueZipcode') &&
              !!form.getFieldValue('city') && (
                <div>
                  <Tag key={'uniqueZipcode'} className={classes.tag}>
                    {form.getFieldValue('uniqueZipcode')}
                    {', '}
                    {form.getFieldValue('city')}
                  </Tag>
                </div>
              )}
            {!!form.getFieldValue('places')?.length &&
              (renderFrance(form.getFieldValue('places')) ||
                renderDepartments(form.getFieldValue('places')))}
            {!!form.getFieldValue('places')?.length &&
              renderCountries(form.getFieldValue('places'))}
          </div>
          <Button
            onClick={() => setShowPlacesModal(true)}
            className={classes.button}
          >
            Choisir les lieu(x) d’intervention
          </Button>
        </div>
      </FormItem>
    );
  }

  function renderBeneficiaresCibles() {
    return (
      <FormItem
        label="Bénéficiaires cibles"
        name="targetPopulations"
        rules={
          userRole !== 'admin' ? [rules.required({ type: 'array' })] : undefined
        }
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          optionFilterProp="label"
        >
          {targetPopulations.map((targetPopulation) => {
            return (
              <Option
                value={targetPopulation._id}
                key={targetPopulation._id}
                label={targetPopulation.translatedName}
              >
                {targetPopulation.translatedName}
              </Option>
            );
          })}
        </Select>
      </FormItem>
    );
  }

  useEffect(() => {
    if (form.getFieldValue('tags')?.length > 0 && classification.tags?.length) {
      form.setFieldsValue({
        activityDomains: form
          .getFieldValue('tags')
          .reduce((previousValue: string[], tagId: string) => {
            const activityDomains = classification.tags.find(
              (t) => t._id === tagId,
            )?.associatedDomains;
            if (activityDomains) {
              return previousValue.concat(activityDomains);
            }
            return previousValue;
          }, [])
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index,
          ),
      });
    } else {
      form.setFieldsValue({
        activityDomains: [],
      });
    }
  }, [form.getFieldValue('tags')]);

  const onAddDonor = async () => {
    const valuesNewDonor = await addDonorForm.validateFields();
    setAddDonorModalOpen(false);
    const resultAction = await dispatch(saveContributorDonor(valuesNewDonor));

    if (saveContributorDonor.fulfilled.match(resultAction)) {
      dispatch(getDonor(resultAction.payload._id));
      message.success('Mécène ajouté avec succès !');
    } else {
      message.error("Erreur lors de l'ajout du Mécène");
    }
  };

  const renderAddDonorModal = () => (
    <Modal
      className={classes.modal}
      maskStyle={{ zIndex: 1500 }}
      width={1000}
      wrapClassName={classes.modalWrap}
      title={'Ajouter un Mécène'}
      onOk={onAddDonor}
      onCancel={() => setAddDonorModalOpen(false)}
      visible={addDonorModalOpen}
      okText={'Confirmer'}
      cancelText={'Annuler'}
      confirmLoading={loading}
    >
      {<ContributorAddDonorForm form={addDonorForm} />}
    </Modal>
  );

  return (
    <div
      className={classes.container}
      onClick={() => {
        if (menu) {
          dispatch(reset());
        }
      }}
    >
      <Row>
        <Col span={5} />
        <Col span={14}>
          <Form
            {...layout}
            form={form}
            layout={'vertical'}
            initialValues={{ remember: true }}
          >
            {userRole === 'admin' && (
              <>
                <Divider style={{ fontSize: '1vw' }} orientation={'left'}>
                  Champs Admin
                </Divider>
                <FormItem label="Mécènes vérifiés" name="donors">
                  {form.getFieldValue('donors')?.map((donorId: string) => (
                    <Tag key={donorId} className={classes.tag}>
                      {donors.byId[donorId]?.name}
                    </Tag>
                  ))}
                </FormItem>
                <Divider style={{ fontSize: '1vw' }} orientation={'left'}>
                  Champs Non-Admin
                </Divider>
              </>
            )}
            <Form.Item name="idRna" hidden />
            <Dropdown overlay={(menu as any) || <></>} visible={!!menu}>
              <FormItem
                label="Nom de l'organisation"
                name="name"
                rules={[rules.required()]}
                labelCol={{ span: 24 }}
              >
                <Input.Search
                  placeholder="Tout commence avec un nom..."
                  loading={loading}
                  className={classes.search}
                  onSearch={searchName}
                  onChange={(event) =>
                    debouncedSearchCall(searchName, event.target.value)
                  }
                />
              </FormItem>
            </Dropdown>

            <FormItem
              label="Statut"
              name="status"
              initialValue="Association"
              rules={userRole !== 'admin' ? [rules.required()] : undefined}
            >
              <Select allowClear style={{ width: '100%' }}>
                <Option value="Association">Association</Option>
                <Option value="Autre">Autre</Option>
              </Select>
            </FormItem>

            <FormItem
              label="Année de création"
              name="creationYear"
              normalize={(value) => (value === '' ? null : value)}
              rules={userRole !== 'admin' ? [rules.creationYear()] : undefined}
            >
              <InputNumber />
            </FormItem>
            {renderRnaNumber()}
            <FormItem
              label="Description"
              name="description"
              rules={
                userRole !== 'admin'
                  ? [rules.required(), rules.min(200), rules.max(2000)]
                  : undefined
              }
            >
              <TextArea
                placeholder="Veuillez décrire votre organisation et ses activités"
                autoSize={{ minRows: 4, maxRows: 10 }}
                minLength={userRole !== 'admin' ? 200 : 0}
                maxLength={2000}
                showCount={true}
              />
            </FormItem>
            <FormItem
              label="Site"
              name="websiteUrl"
              rules={[rules.url()]}
              normalize={(value) => (value === '' ? null : value)}
            >
              <Input type="url" placeholder={'https://'} />
            </FormItem>
            <FormItem
              label="URL de la page Linkedin"
              name="linkedinUrl"
              rules={[rules.url()]}
              normalize={(value) => (value === '' ? null : value)}
            >
              <Input type="url" placeholder={'https://'} />
            </FormItem>

            {renderUploadLogo()}
            <Form.Item name="logoUrl" noStyle>
              <Input type="hidden" />
            </Form.Item>
            {/* <FormItem label="Objet" name="object">
              <TextArea autoSize={{ minRows: 2, maxRows: 8 }} />
            </FormItem> */}
            <FormItem
              label="Mots clés"
              name="tags"
              rules={
                userRole !== 'admin'
                  ? [
                      {
                        required: true,
                        type: 'array',
                        validator: tagsValidator,
                      },
                    ]
                  : undefined
              }
            >
              <div>
                {!!form.getFieldValue('tags')?.length && (
                  <div style={{ marginBottom: '20px' }}>
                    {form.getFieldValue('tags')?.map((tagId: string) => (
                      <Tag key={tagId} className={classes.tag}>
                        {tags.find((tag) => tag._id === tagId)?.translatedName}
                      </Tag>
                    ))}
                  </div>
                )}
                <Button
                  onClick={() => setShowTagsModal(true)}
                  className={classes.button}
                >
                  Choisir les mots clés
                </Button>
              </div>
            </FormItem>
            {renderLieuxIntervention()}
            {renderBeneficiaresCibles()}
            {renderDonorFormItem()}

            <FormItem
              label="Domaines d'intervention"
              name="activityDomains"
              hidden
              rules={
                userRole !== 'admin'
                  ? [rules.required({ type: 'array' })]
                  : undefined
              }
              labelCol={{ span: 24 }}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="label"
              >
                {activityDomains.map((activityDomain) => {
                  return (
                    <Option
                      value={activityDomain._id}
                      key={activityDomain._id}
                      label={activityDomain.translatedName}
                    >
                      {activityDomain.translatedName}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
            {/*<FormItem label="Zones d'intervention" name="activityZone">*/}
            {/*  <Select*/}
            {/*    mode="multiple"*/}
            {/*    allowClear*/}
            {/*    style={{ width: '100%' }}*/}
            {/*    optionFilterProp="label"*/}
            {/*  >*/}
            {/*    {activityZones.map((activityZone) => {*/}
            {/*      return (*/}
            {/*        <Option*/}
            {/*          value={activityZone._id}*/}
            {/*          key={activityZone._id}*/}
            {/*          label={activityZone.translatedName}*/}
            {/*        >*/}
            {/*          {activityZone.translatedName}*/}
            {/*        </Option>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*  </Select>*/}
            {/*</FormItem>*/}

            <FormItem
              label="Mail de contact"
              name="contactMail"
              rules={
                userRole === 'admin'
                  ? [rules.email()]
                  : [rules.required(), rules.email()]
              }
              normalize={(value) => (value === '' ? null : value)}
            >
              <Input type="email" />
            </FormItem>
            <FormItem
              label="Numéro de téléphone"
              name="phoneNumber"
              rules={[rules.phone(), rules.required()]}
              normalize={(value) => (value === '' ? null : value)}
            >
              <Input type="tel" />
            </FormItem>

            <FormItem
              label="Code postal du siège social"
              name="headquarterZipcode"
              wrapperCol={{ span: 2 }}
              rules={[
                {
                  pattern: new RegExp(/^[0-9]{4,5}$/),
                  message: 'Un code postal complet est requis.',
                },
                {
                  required: true,
                  message: 'Un code postal valide est requis.',
                },
              ]}
            >
              <Input
                allowClear
                placeholder={'00000'}
                onChange={(event) => {
                  updateCitiesState(event);
                }}
              />
            </FormItem>
            {citiesState.length > 0 && (
              <>
                <Text style={{ fontSize: '0.875vw' }}>
                  Choisissez la ville :
                </Text>
                <br />
                {Array.from(new Set(citiesState)).map((city: string, index) => (
                  <Tag
                    key={index}
                    onClick={() => {
                      setCityState(city);
                      form.setFieldsValue({ headquarterCity: city });
                    }}
                    className={classes.tagCities}
                    style={
                      city === cityState
                        ? { backgroundColor: '#1890ff', color: 'white' }
                        : {}
                    }
                  >
                    {city}
                  </Tag>
                ))}
                <FormItem
                  label="Ville du siège social : "
                  name="headquarterCity"
                  wrapperCol={{ span: 4 }}
                  key={1}
                  className={classes.headquarterCityFormItem}
                  rules={[
                    {
                      required: true,
                      message: 'Cliquez sur une ville proposée.',
                    },
                  ]}
                >
                  <Input
                    allowClear
                    value={cityState}
                    disabled
                    placeholder={''}
                  />
                </FormItem>
              </>
            )}
            <FormItem
              label="Numéro et adresse du siège social"
              name="headquarterAddress"
              wrapperCol={{ span: 8 }}
            >
              <Input allowClear placeholder={''} />
            </FormItem>

            <FormItem
              label="URL de la page Instagram"
              name="instagramUrl"
              rules={[rules.url()]}
              normalize={(value) => (value === '' ? null : value)}
            >
              <Input type="url" placeholder={'https://'} />
            </FormItem>
            <FormItem
              label="URL de la page Facebook"
              name="facebookUrl"
              rules={[rules.url()]}
              normalize={(value) => (value === '' ? null : value)}
            >
              <Input type="url" placeholder={'https://'} />
            </FormItem>
            <FormItem
              label="URL de la page Twitter"
              name="twitterUrl"
              rules={[rules.url()]}
              normalize={(value) => (value === '' ? null : value)}
            >
              <Input type="url" placeholder={'https://'} />
            </FormItem>
          </Form>
        </Col>
      </Row>
      {renderAddDonorModal()}
      <TagsSelectorModal
        activityDomains={activityDomains}
        tags={tags}
        isVisible={showTagsModal}
        requestClose={() => setShowTagsModal(false)}
        value={form.getFieldValue('tags')}
        onChange={(value) => form.setFieldsValue({ tags: value })}
      />
      <PlacesSelectorModal
        form={form}
        uniqueZipcode={form.getFieldValue('uniqueZipcode')}
        zipcode={form.getFieldValue('zipcode')}
        roadAndNumber={form.getFieldValue('roadAndNumber')}
        city={form.getFieldValue('city')}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
        value={form.getFieldValue('places')}
        onChange={(value) => {
          form.setFieldsValue({ places: value });
        }}
        communes={communes}
      />
    </div>
  );
};

import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  textStyle: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
});

interface Props {
  value: string;
}

export const BenefitTitle = ({ value }: Props) => {
  const classes = useStyles();
  return <div className={classes.textStyle}>{value}</div>;
};

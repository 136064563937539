import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { Project } from 'src/pages/Project';
import { LoggedLayout } from '../containers/LoggedLayout';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { CallForTenderNgos } from '../pages/CallForTenderNgos';
import { CallForTenders } from '../pages/CallForTenders';
import { CallForTendersAdmin } from '../pages/CallForTendersAdmin';
import { ChooseNgoOrDonor } from '../pages/ChooseNgoOrDonor';
import { CreateFirstProject } from '../pages/CreateFirstProject';
import { Database } from '../pages/Database';
import { DatabaseFromCallForTender } from '../pages/DatabaseFromCallForTender';
import { DatabaseFromFreeProject } from '../pages/DatabaseFromFreeProject';
import { DatabaseFromProject } from '../pages/DatabaseFromProject';
import { Donor } from '../pages/Donor';
import { DonorFirstSteps } from '../pages/DonorFirstSteps';
import { DonorsMergeDuplicates } from '../pages/DonorsMergeDuplicates';
import { DonorsNotRelevant } from '../pages/DonorsNotRelevant';
import { EditDonor } from '../pages/EditDonor';
import { EditNgo } from '../pages/EditNgo';
import { FinancingNeeds } from '../pages/FinancingNeeds';
import { FollowedDonors } from '../pages/FollowedDonors';
import { NewProject } from '../pages/NewProject';
import { Ngo } from '../pages/Ngo';
import { PremiumNgo } from '../pages/PremiumNgo';
import { Projects } from '../pages/Projects';
import { PaymentPage } from '../pages/Stripe/PaymentPage';
import { Subscription } from '../pages/Subscription';
import { getClassification } from '../slices/classification';
import { Services } from 'src/pages/Services';
import { AdminNeedToQualify } from 'src/pages/AdminNeedToQualify';
import { AdminNeedToValidate } from 'src/pages/AdminNeedToValidate';
import { Contributors } from 'src/pages/Contributors';
import { AdminAssigned } from 'src/pages/AdminAssigned';
import { SaveTenderDonor } from 'src/pages/SaveTenderDonor';
import { EditTenderDonor } from 'src/pages/EditTenderDonor';
import { CallForTender } from 'src/pages/CallForTender';
import { AdminUsers } from 'src/pages/AdminUsers';
import { AdminNgos } from 'src/pages/AdminNgos';
import { NgosMergeDuplicates } from 'src/pages/NgosMergeDuplicates';
import { Donors } from 'src/pages/Donors';
import { CreateDonor } from 'src/pages/CreateDonor';
import { MailSettings } from 'src/pages/MailSettings';
import { EditAdminUser } from 'src/pages/EditAdminUser';
import { EditFinancingNeed } from 'src/pages/EditFinancingNeed';
import { NoNgoError } from 'src/pages/NoNgoError';
import { ProjectDonors } from 'src/pages/ProjectDonors';
import { DonorAccessInConstruction } from 'src/pages/DonorAccessInConstruction';
import { NgoFirstSteps } from 'src/pages/NgoFirstSteps';
import { Wachete } from 'src/pages/Wachete';
import { LoggedIntercom } from '../utils/loggedIntercom';
import { Statistics } from 'src/pages/Statistics';

export const LoggedSwitch: React.VFC = () => {
  const dispatch = useAppDispatch();
  const [isNavBarHidden, setIsNavBarHidden] = useState<boolean>(false);
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  useEffect(() => {
    dispatch(getClassification());
  }, []);

  const displayAdminRoute = (
    path: string,
    component:
      | React.ComponentType<any>
      | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>,
  ) =>
    user?.role === 'admin' && <Route exact path={path} component={component} />;
  LoggedIntercom(
    user?.firstName + ' ' + user?.lastName,
    user?.email,
    user?._id,
  );

  return (
    <LoggedLayout isNavBarHidden={isNavBarHidden}>
      <Switch>
        <Route exact path="/error" component={NoNgoError} />
        <Route
          exact
          path="/donorAccessInConstruction"
          component={DonorAccessInConstruction}
        />
        <Route
          exact
          path="/NgoFirstSteps"
          render={(props) => (
            <NgoFirstSteps {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/DonorFirstSteps"
          render={(props) => (
            <DonorFirstSteps {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/ngo/premium"
          render={(props) => (
            <PremiumNgo {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/projects"
          render={(props) => (
            <Projects {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/callForTenders"
          render={(props) => (
            <CallForTenders {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/newProject"
          render={(props) => (
            <NewProject {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/createFirstProject"
          render={(props) => (
            <CreateFirstProject
              {...props}
              setIsNavBarHidden={setIsNavBarHidden}
            />
          )}
        />
        <Route
          exact
          path="/projects/:projectId"
          render={(props) => (
            <Project {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/projects/:projectId/donors"
          component={ProjectDonors}
        />
        <Route
          exact
          path="/callForTender/:callForTenderId/ngos"
          component={CallForTenderNgos}
        />
        <Route
          exact
          path="/projects/:projectId/database"
          component={DatabaseFromProject}
        />
        <Route
          exact
          path="/freemium-projects/:projectId/database/:offerCompleteExists"
          component={DatabaseFromFreeProject}
        />
        <Route exact path="/ngo/followedDonors" component={FollowedDonors} />
        <Route
          exact
          path="/callForTenders/:callForTenderId/database"
          component={DatabaseFromCallForTender}
        />
        <Route
          exact
          path="/ngo/:ngoId"
          render={(props) => (
            <Ngo {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/ngo/:ngoId/bypass"
          render={(props) => (
            <Ngo {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/ngo/:ngoId/edit"
          render={(props) => (
            <EditNgo {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route exact path="/services" component={Services} />
        <Route
          exact
          path="/donor/:donorId"
          render={(props) => (
            <Donor {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/donor/:donorId/bypass"
          render={(props) => (
            <Donor {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/donor/:id/edit"
          render={(props) => (
            <EditDonor {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/donor/:donorId/callForTender/:callForTenderId"
          render={(props) => (
            <CallForTender {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/financing-need/:id/edit"
          render={(props) => (
            <EditFinancingNeed
              {...props}
              setIsNavBarHidden={setIsNavBarHidden}
            />
          )}
        />
        <Route exact path="/settings" component={MailSettings} />
        {displayAdminRoute('/create-donor', CreateDonor)}
        <Route
          exact
          path="/donor/:donorId/saveTenderDonor"
          render={(props) => (
            <SaveTenderDonor {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/donor/:callForTenderId/editTenderDonor"
          render={(props) => (
            <EditTenderDonor {...props} setIsNavBarHidden={setIsNavBarHidden} />
          )}
        />
        <Route
          exact
          path="/subscription/:id/:offerCompleteExists"
          component={Subscription}
        />
        <Route
          exact
          path="/checkout/:projectId/:pricing/:type/:offer/:isMonthly"
          component={PaymentPage}
        />
        <Route
          exact
          path="/chooseNgoOrDonor"
          render={(props) => (
            <ChooseNgoOrDonor
              {...props}
              setIsNavBarHidden={setIsNavBarHidden}
            />
          )}
        />

        {displayAdminRoute('/database', Database)}
        {displayAdminRoute('/wachete', Wachete)}
        {displayAdminRoute('/donors', Donors)}
        {displayAdminRoute('/statistics/:organisationType', Statistics)}
        {displayAdminRoute('/financing-needs', FinancingNeeds)}
        {displayAdminRoute('/callForTendersAdmin', CallForTendersAdmin)}
        {displayAdminRoute('/assignedDonors', AdminAssigned)}
        {displayAdminRoute('/donorsToQualify', AdminNeedToQualify)}
        {displayAdminRoute('/donorsToValidate', AdminNeedToValidate)}
        {displayAdminRoute('/contributors', Contributors)}
        {displayAdminRoute('/donorsNotRelevant', DonorsNotRelevant)}
        {displayAdminRoute('/donors-merge-duplicates', DonorsMergeDuplicates)}
        {displayAdminRoute('/ngos-merge-duplicates', NgosMergeDuplicates)}
        {displayAdminRoute('/users/:organisationType', AdminUsers)}
        {displayAdminRoute('/adminNgos', AdminNgos)}
        {displayAdminRoute('/editUser/:userId', EditAdminUser)}
        {user?.role === 'admin' && <Redirect to={`/database`} />}
        {user?.organisationType == 'MECENE' && !user?.donorId && (
          <Redirect to={`/DonorFirstSteps`} />
        )}
        {user?.organisationType == 'PROJECT_HOLDER' && !user?.ngoId && (
          <Redirect to={`/NgoFirstSteps`} />
        )}
        {user?.ngoId || user?.donorId ? (
          <Redirect
            to={user?.ngoId ? `/ngo/${user.ngoId}` : `/donor/${user.donorId}`}
          />
        ) : (
          <Redirect to="/chooseNgoOrDonor" />
        )}
      </Switch>
    </LoggedLayout>
  );
};

import React from 'react';
import { createUseStyles } from 'react-jss';

import { theme } from 'src/constants/theme';
import { Card } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { Link } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    bottom: '0',
    right: '0',
    margin: '60px',
    zIndex: '999',
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.orange[1],
    width: '65px',
    height: '65px',
    textAlign: 'center',

    '&.ant-btn:hover': {
      width: '200px',
      color: theme.palette.orange[1],
      borderColor: theme.palette.orange[1],
    },
    '&.ant-btn:hover .anticon': {
      color: theme.palette.common.black,
      visibility: 'hidden',
      width: '0px',
    },
    '&.ant-btn .anticon': {
      color: theme.palette.common.white,
      fontSize: '150%',
    },
    '&.ant-btn .ant-typography': {
      color: theme.palette.common.white,
      marginLeft: '0',
      width: '0px',
      visibility: 'hidden',
    },
    '&.ant-btn:hover .ant-typography': {
      color: theme.palette.orange[1],
      width: '100%',
      visibility: 'visible',
    },
  },
  card: {
    padding: '24px',
    height: '100%',
  },
  cardBody: {
    margin: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  cardTitle: {
    fontWeight: 'bold',
  },
  email: {
    fontSize: '1em',
    marginLeft: '15px',
  },
});

export const Services: React.VFC<Props> = () => {
  const classes = useStyles();

  const renderOverlay = () => (
    <Card
      title={<Text className={classes.cardTitle}>{'Contactez-nous'}</Text>}
      className={classes.card}
    >
      <div className={classes.cardBody}>
        <MailOutlined />
        <Text copyable className={classes.email}>
          <Link to={'thibaut@stample.com'}>{'thibaut@stample.com'}</Link>
        </Text>
      </div>
    </Card>
  );

  return renderOverlay();
};

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slices/auth';
import ngoReducer from '../slices/ngo';
import classificationReducer from '../slices/classification';
import financingNeedReducer from '../slices/financingNeed';
import donorReducer from '../slices/donor';
import rnaReducer from '../slices/rna';
import dealFlowReducer from 'src/slices/dealFlow';
import dealFlowColumnReducer from 'src/slices/dealFlowColumn';
import dealFlowCardReducer from 'src/slices/dealFlowCard';
import dealFlowNoteReducer from 'src/slices/dealFlowNote';
import callForTendersReducer from 'src/slices/callForTender';
import contributorsReducer, {
  contributorsAdapter,
} from 'src/slices/contributor';
import communesReducer from 'src/slices/communes';

/* Reducers */
const rootReducer = combineReducers({
  auth: authReducer,
  ngo: ngoReducer,
  classification: classificationReducer,
  financingNeed: financingNeedReducer,
  donor: donorReducer,
  contributor: contributorsReducer,
  rna: rnaReducer,
  dealFlow: dealFlowReducer,
  dealFlowColumn: dealFlowColumnReducer,
  dealFlowCard: dealFlowCardReducer,
  dealFlowNote: dealFlowNoteReducer,
  callForTender: callForTendersReducer,
  communes: communesReducer,
});

export const store = configureStore({ reducer: rootReducer });

/* Infer the `RootState` and `AppDispatch` types from the store itself */
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const contributorsSelector = contributorsAdapter.getSelectors<RootState>(
  (state) => state.contributor,
);

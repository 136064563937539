import { Card } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { CallForTender } from 'src/slices/callForTender';

const { Meta } = Card;

const useStyles = createUseStyles({
  container: {
    background:
      'radial-gradient(ellipse at top right, #d2dcfd 0%, #f0f2ff 20%, transparent 50%),\
      radial-gradient(ellipse at top left, #fdfefe 0%, #f4f7ff 20%, transparent 50%),\
      radial-gradient(ellipse at bottom left, #e5ecfe, #f6f9fe 30%, transparent 50%),\
      radial-gradient(ellipse at bottom right, #f9fdfc, #f0f2ff 20%, transparent 50%)',
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    padding: '3.98% 4.59%',
    flexDirection: 'column',
  },
  cardContainer: {
    display: 'grid',
    columnGap: '3.372%',
    gridTemplateColumns: 'repeat(auto-fill, minmax(16.875vw, 0fr))',
  },
  card: {
    marginBottom: '7.21%',
    width: '16.875vw',
    borderRadius: '0.3125vw',
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    '& .ant-card-body': {
      padding: '7.81% 8.89% 7.81% 11.11%',
      fontSize: '0.8125vw',
      textOverflow: 'ellipsis',
    },
  },
  firstCard: {
    width: '16.875vw',
    borderRadius: '0.3125vw',
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    '& .ant-card-body': {
      padding: '7.81% 8.89% 7.81% 11.11%',
      fontSize: '0.8125vw',
      textOverflow: 'ellipsis',
    },
  },
  headerContainer: {
    marginBottom: '1.26%',
  },
  bannerImage: {
    width: '14.875vw',
    height: '4.49vw',
    borderTopRightRadius: '0.3125vw !important',
    borderTopLeftRadius: '0.3125vw !important',
  },
  cardMeta: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& .ant-card-meta-description': {
      color: 'black !important',
      lineHeight: 1,
    },
    '& .ant-card-meta-title': {
      fontWeight: 'bold',
      fontSize: 'calc(5px + 0.5vw)',
      lineHeight: 1,
      marginBottom: '0.9375vw !important',
      color: '#5050F9 !important',
      textOverflow: 'ellipsis',
    },
  },
  dateText: {
    marginTop: '10px',
  },
  openText: {
    color: 'green',
    position: 'absolute',
    top: '1vw',
    right: '1vw',
    fontSize: '0.6vw',
  },
  closedText: {
    color: 'red',
    position: 'absolute',
    top: '1vw',
    right: '1vw',
    fontSize: '0.6vw',
  },
});

interface Props {
  callForTenders: CallForTender[];
  donorId: string;
  isActive?: boolean;
}

export const CallForTenderCards: React.VFC<Props> = ({
  callForTenders,
  donorId,
  isActive,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const uniqueIds = new Set<string>();
  const uniqueCallForTenders = callForTenders.filter((c) => {
    if (uniqueIds.has(c._id)) {
      return false;
    } else {
      uniqueIds.add(c._id);
      return true;
    }
  });

  const renderDate = (
    openingDate: Date | undefined,
    closingDate: Date | undefined,
  ) => {
    const newOpeningDate = openingDate ? new Date(openingDate) : undefined;
    const newClosingDate = closingDate ? new Date(closingDate) : undefined;
    return (
      <>
        {newOpeningDate && (
          <div className={classes.dateText}>
            <Text style={{ fontWeight: 'bolder' }}>Début : </Text>
            {new Intl.DateTimeFormat('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(newOpeningDate)}
          </div>
        )}
        {newClosingDate && (
          <div className={classes.dateText}>
            <Text style={{ fontWeight: 'bolder' }}>Fin : </Text>
            {new Intl.DateTimeFormat('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(newClosingDate)}
          </div>
        )}
      </>
    );
  };

  return (
    <div className={classes.cardContainer}>
      {uniqueCallForTenders.map((callForTender) => (
        <Card
          bordered={true}
          hoverable
          key={callForTender?._id}
          className={classes.card}
          onClick={() =>
            history.push(`/donor/${donorId}/callForTender/${callForTender._id}`)
          }
        >
          <Meta
            title={callForTender?.name}
            className={classes.cardMeta}
            description={
              callForTender?.openingDate || callForTender?.closingDate
                ? renderDate(
                    callForTender?.openingDate,
                    callForTender?.closingDate,
                  )
                : undefined
            }
          />
          {isActive ? (
            <span className={classes.openText}>Ouvert</span>
          ) : (
            <span className={classes.closedText}>Fermé</span>
          )}
        </Card>
      ))}
    </div>
  );
};

import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import {
  DragDropContext,
  DraggableLocation,
  DropResult,
} from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks/store';
import {
  getDealFlowColumns,
  addColumnCard,
  removeColumnCard,
  updateDealFlowColumn,
} from 'src/slices/dealFlowColumn';
import { KanbanColumn } from './KanbanColumn';
import { updateDealFlowCard } from 'src/slices/dealFlowCard';

export interface KanbanProps {
  financingNeedId?: string;
}

const useStyles = createUseStyles({
  container: {
    height: '100%',
    width: '100%',
  },
  columnsContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
});

export const Kanban: React.VFC<KanbanProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { dealFlow, loading, columns } = useAppSelector(
    ({ dealFlow: { dealFlow, loading }, dealFlowColumn: { columns } }) => ({
      dealFlow,
      loading,
      columns,
    }),
  );

  useEffect(() => {
    dealFlow && dispatch(getDealFlowColumns(dealFlow._id));
  }, [dealFlow]);

  const getCardIdByIndex = (
    cardIndex: number,
    columnId: string,
  ): string | undefined => {
    const column = columns[columnId];
    return column?.cards.find((_card, index) => index == cardIndex);
  };

  const moveCard = (
    source: DraggableLocation,
    destination: DraggableLocation,
  ) => {
    if (!dealFlow) return;
    const cardId = getCardIdByIndex(source.index, source.droppableId);
    if (!cardId) return;
    dispatch(
      removeColumnCard({
        columnId: source.droppableId,
        index: source.index,
      }),
    );
    dispatch(
      addColumnCard({
        columnId: destination.droppableId,
        index: destination.index,
        cardId: cardId,
      }),
    );
    dispatch(
      updateDealFlowColumn({
        columnId: source.droppableId,
        body: { remove: { cards: [cardId] } },
        dealFlowId: dealFlow._id,
      }),
    );

    dispatch(
      updateDealFlowColumn({
        columnId: destination.droppableId,
        body: { add: { cards: [cardId] } },
        dealFlowId: dealFlow._id,
      }),
    );

    dispatch(
      updateDealFlowCard({
        cardId: cardId,
        body: {
          column: destination.droppableId,
        },
        dealFlowId: dealFlow._id,
      }),
    );
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    moveCard(source, destination);
  };

  const [columnsPositions, setColumnsPositions] = React.useState<any>({});

  if (loading) return null;

  function getKanbanColumns(columnsIds: string[]) {
    return columnsIds
      .map((columnId: string) => (
        <KanbanColumn
          key={columnId}
          columnId={columnId}
          setColumnPosition={(columnId: string, position: number) => {
            setColumnsPositions((prev: any) => ({
              ...prev,
              [columnId]: position,
            }));
          }}
        />
      ))
      .sort((a: any, b: any) => {
        return columnsPositions[a.key] - columnsPositions[b.key];
      });
  }
  const [kanbanColumns, setKanbanColumns] = React.useState<
    JSX.Element[] | null
  >(null);

  useEffect(() => {
    if (dealFlow?.columns) {
      setKanbanColumns(getKanbanColumns(dealFlow.columns));
    }
  }, [dealFlow?.columns]);

  useEffect(() => {
    if (Object.keys(columns).length === 4) {
      setKanbanColumns(getKanbanColumns(Object.keys(columns)));
    }
  }, [columns]);

  return (
    <div className={classes.container}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={classes.columnsContainer}>
          {dealFlow?.columns && kanbanColumns}
        </div>
      </DragDropContext>
    </div>
  );
};

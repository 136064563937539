import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import activityDomainsEn from './activityDomains.en.json';
import activityDomainsFr from './activityDomains.fr.json';
import activityZonesEn from './activityZones.en.json';
import activityZonesFr from './activityZones.fr.json';
import donationTypesEn from './donationTypes.en.json';
import donationTypesFr from './donationTypes.fr.json';
import en from './en.json';
import fr from './fr.json';
import statusesEn from './statuses.en.json';
import statusesFr from './statuses.fr.json';
import nationalitiesEn from './nationalities.en.json';
import nationalitiesFr from './nationalities.fr.json';
import targetPersonsEn from './targetPersons.en.json';
import targetPersonsFr from './targetPersons.fr.json';
import sustainableDevelopmentGoalsEn from './sustainableDevelopmentGoals.en.json';
import sustainableDevelopmentGoalsFr from './sustainableDevelopmentGoals.fr.json';
import tagsEn from './tags.en.json';
import tagsFr from './tags.fr.json';
import targetPopulationsEn from './targetPopulations.en.json';
import targetPopulationsFr from './targetPopulations.fr.json';
import numberOfBeneficiariesEn from './numberOfBeneficiaries.en.json';
import numberOfBeneficiariesFr from './numberOfBeneficiaries.fr.json';

const userLang = (navigator.language || 'en').substring(0, 2);

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
      activityDomains: activityDomainsEn,
      activityZones: activityZonesEn,
      donationTypes: donationTypesEn,
      statuses: statusesEn,
      nationalities: nationalitiesEn,
      targetPersons: targetPersonsEn,
      sustainableDevelopmentGoals: sustainableDevelopmentGoalsEn,
      tags: tagsEn,
      targetPopulations: targetPopulationsEn,
      numberOfBeneficiaries: numberOfBeneficiariesEn,
    },
    fr: {
      translation: fr,
      activityDomains: activityDomainsFr,
      activityZones: activityZonesFr,
      donationTypes: donationTypesFr,
      statuses: statusesFr,
      nationalities: nationalitiesFr,
      targetPersons: targetPersonsFr,
      sustainableDevelopmentGoals: sustainableDevelopmentGoalsFr,
      tags: tagsFr,
      targetPopulations: targetPopulationsFr,
      numberOfBeneficiaries: numberOfBeneficiariesFr,
    },
  },
  debug: process.env.NODE_ENV === 'development',
  lng: userLang,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

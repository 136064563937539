import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';
import { NewNgoEditForm } from '../containers/NewNgoEditForm';
import { NgoEditForm } from '../containers/NgoEditForm';

import { Ngo, updateNgo } from '../slices/ngo';
import { useAppSelector, useAppDispatch } from '../hooks/store';
import { CloseOutlined } from '@ant-design/icons';
import { LoggedIntercom } from '../utils/loggedIntercom';

const useStyles = createUseStyles({
  container: {
    padding: '42px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  view: {
    height: '100vh',
    width: '100vw',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  cancelButton: {
    color: 'red',
  },
});

type personnel = {
  lastName: string;
  firstName: string;
  email: string;
};

type formNgo = {
  name: string;
  object?: string;
  description: string;
  nbEmployees?: number;
  nbVolunteers?: number;
  admins: [string]; // [MongodbId]
  members: [string]; // [MongodbId]
  tags: [string]; // [MongodbId]
  places: [string]; // [MongodbId]
  roadAndNumber: string;
  zipcode: string;
  city: string;
  uniqueZipcode: string;
  sustainableDevelopmentGoals?: [string]; //[MongodbId]
  activityDomains: [string]; //[MongodbId]
  targetPopulations: [string]; // [MongodbId]
  donors: [string]; // [MongodbId]
  bannerImageUrl?: string;
  personals: personnel[];
  logoUrl?: string;
  siret?: string;
  headquarterAddress?: string;
  websiteUrl?: string;
  creationYear?: number;
  isPublicInterestNgo?: boolean;
  numberOfBeneficiaries?: string;
  activityZone?: string; // [MongodbId]
  contactMail: string;
  phoneNumber: string;
  linkedinUrl: string;
  instagramUrl: string;
  facebookUrl: string;
  twitterUrl: string;
};

interface Props {
  setIsNavBarHidden: (isNavBarHidden: boolean) => void;
}

export const EditNgo: React.VFC<Props> = ({ setIsNavBarHidden }: Props) => {
  const [form] = Form.useForm();
  const ngo = useAppSelector((state) => state.ngo.ngo);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { ngoId } = useParams<{ ngoId: string }>();

  useEffect(() => {
    if (user?.role === 'admin') {
      setIsNavBarHidden(false);
    } else {
      setIsNavBarHidden(true);
    }
  }, [user]);

  useEffect(() => {
    if (ngo) {
      form.setFieldsValue(ngo);
    }
  }, [ngo]);

  const onSave = (values: formNgo) => {
    dispatch(updateNgo({ id: ngoId, ...values, donors: undefined }));
    history.push(`/ngo/${ngoId}`);
  };

  const onSubmitNgoForm = (values: any) => {
    return dispatch(updateNgo({ id: ngoId, ...values }));
  };

  async function submitForm() {
    const fieldNames = [
      'rnaNumber',
      'name',
      'status',
      'creationYear',
      'headquarterZipcode',
      'headquarterCity',
      'headquarterAddress',
      'description',
      'tags',
      'places',
      'roadAndNumber',
      'uniqueZipcode',
      'zipcode',
      'city',
      'targetPopulations',
      'unverifiedDonors',
      'websiteUrl',
      'contactMail',
      'phoneNumber',
      'logoUrl',
      'linkedinUrl',
      'instagramUrl',
      'facebookUrl',
      'twitterUrl',
    ];
    const allFieldValues = fieldNames.reduce((acc: any, fieldName: string) => {
      acc[fieldName] = form.getFieldValue(fieldName);
      return acc;
    }, {});
    const ngoPost = await onSubmitNgoForm(allFieldValues);
    LoggedIntercom(
      user?.firstName + ' ' + user?.lastName,
      user?.email,
      user?._id,
      'EditedNgo',
    );
    window.dataLayer.push({
      event: 'ngoFirstSteps_completed',
    });
    if (ngoPost.payload) {
      const ngoPayload = ngoPost.payload as Ngo;
      console.log('ngoPayload', ngoPayload);
    }
    const payload = ngoPost.payload as Ngo;
    history.push(`/ngo/${payload._id}`);
  }

  return (
    <div className={classes.view}>
      {user?.role === 'admin' && (
        <>
          <div className={classes.container}>
            <div className={classes.buttonContainer}>
              <Button
                type="dashed"
                onClick={() => history.push(`/ngo/${ngoId}`)}
                danger
                icon={<CloseOutlined />}
                style={{ marginRight: '32px' }}
              >
                Annuler
              </Button>
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    const values = await form.validateFields();
                    onSave(values);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Sauvegarder
              </Button>
            </div>
            <NgoEditForm form={form} ngoId={ngoId} />
            <div className={classes.buttonContainer}>
              <Button
                type="dashed"
                onClick={() => history.push(`/ngo/${ngoId}`)}
                danger
                icon={<CloseOutlined />}
                style={{ marginRight: '32px' }}
              >
                Annuler
              </Button>
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    const values = await form.validateFields();
                    onSave(values);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Sauvegarder
              </Button>
            </div>
          </div>
        </>
      )}
      {user?.role !== 'admin' && (
        <div className={classes.view}>
          <NewNgoEditForm form={form} submitForm={submitForm} />
        </div>
      )}
    </div>
  );
};

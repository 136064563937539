import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { createUseStyles } from 'react-jss';
import { parse, stringify } from 'query-string';
import { useLocation, useHistory } from 'react-router';
import { Button, Table, TablePaginationConfig, Form, Select } from 'antd';
import { Link } from 'react-router-dom';

import {
  saveNgo,
  getNgosList,
  NGOS_MAX_RESULTS,
  //updateNgoAdmins,
  Ngo,
  deleteNgo,
  searchNgos,
  updateNgoPublished,
} from '../slices/ngo';
import { NgoEditForm } from '../containers/NgoEditForm';
import { FinancingNeed } from '../slices/financingNeed';
import { getUsers } from 'src/slices/auth';
import { CloseOutlined } from '@ant-design/icons';
import {
  NgoTableSearchBar,
  SearchFiltersParameters,
} from 'src/components/NgoTableSearchBar';
import { useCaptchaDispatch } from '../hooks/useCaptchaDispatch';

const { Option } = Select;

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    margin: '1vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  button: {
    display: 'block',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  column: {
    color: 'black',
    fontWeight: 'bold',
    cursor: 'pointer',
    maxWidth: '18.75vw',
  },
  columnNotClickable: {
    color: 'black',
    fontWeight: 'bold',
    maxWidth: '18.75vw',
    cursor: 'default',
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  ngoEditForm: {
    backgroundColor: 'white',
    padding: '1vw',
  },
  select: {
    display: 'flex',
    margin: '1.25vw auto',
    width: '15.625vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        height: '2vw !important',
        lineHeight: '2vw !important',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
  nbRef: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const AdminNgos: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const captchaDispatch = useCaptchaDispatch();
  const query = useLocation().search;
  const history = useHistory();
  const [editForm, setEditForm] = React.useState(false);
  const { searchResult, offset, users } = useAppSelector(({ auth, ngo }) => ({
    searchResult: ngo.searchResult,
    offset: ngo.offset,
    users: auth.users,
  }));
  const [searchFilters, setSearchFilters] = useState<SearchFiltersParameters>(
    {},
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [queryParamsObject, setQueryParamsObject] = useState<
    Record<string, unknown>
  >(parse(query, { arrayFormat: 'bracket' }));

  const args = {
    limit: NGOS_MAX_RESULTS,
    offset: 0,
  };

  const fetchNgos = (searchFilters: SearchFiltersParameters, page: number) => {
    console.log('AAAAA');
    updateQueryParams({ page, ...searchFilters });
    captchaDispatch(
      searchNgos,
      searchNgos({
        ...searchFilters,
        offset: (page - 1) * NGOS_MAX_RESULTS,
        limit: NGOS_MAX_RESULTS,
      }),
    );
  };

  const fetchNgosWithQueryParams = () => {
    const { page, name } = parse(query, { arrayFormat: 'bracket' });

    const newSearchFilters = {
      name,
    } as SearchFiltersParameters;

    setSearchFilters(newSearchFilters);

    fetchNgos(newSearchFilters, page ? parseInt(page as string) : 1);
  };

  const updateQueryParams = (params: Record<string, unknown>) => {
    const { name, ...rest } = params;
    const updatedQueryParamsObject = {
      ...queryParamsObject,
      ...rest,
      ...(name === '' ? {} : { name }),
    };

    setQueryParamsObject(updatedQueryParamsObject);

    history.replace({
      search: stringify(updatedQueryParamsObject, { arrayFormat: 'bracket' }),
    });
  };

  useEffect(() => {
    fetchNgosWithQueryParams();
  }, []);

  const onSearch = (value: SearchFiltersParameters) => {
    setSearchFilters(value);
    fetchNgos(value, 1);
  };

  const onSelectPublished = (value: boolean, ngoId: string) => {
    dispatch(
      updateNgoPublished({
        ngoIds: [ngoId],
        published: value,
      }),
    );
    fetchNgos(searchFilters, currentPage);
  };

  function renderModifPublished(name: string, record: Ngo) {
    return (
      <>
        <Select
          placeholder={record.published ? 'Yes' : 'No'}
          className={classes.select}
          allowClear
          onSelect={(value: string) =>
            onSelectPublished(value === 'Yes', record._id)
          }
        >
          {['Yes', 'No'].map((status, index) => (
            <Option
              key={index}
              value={status}
              label={status}
              style={{ fontSize: '0.875vw' }}
            >
              {status}
            </Option>
          ))}
        </Select>
      </>
    );
  }

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: '_id',
      width: '30%',
      render: function renderName(name: string, record: Ngo) {
        return (
          <Link to={`/ngo/${record._id}`}>
            <div className={classes.column}>{name}</div>
          </Link>
        );
      },
    },
    {
      title: 'Propriétaire',
      dataIndex: '_id',
      key: '_id',
      width: '15%',
      render: function renderOwner(_id: string) {
        const foundAdmin = Object.values(users).find(
          (user) => _id === user.ngoId,
        );
        if (foundAdmin?._id) {
          return (
            <div key={foundAdmin._id} className={classes.columnNotClickable}>
              {foundAdmin.firstName} {foundAdmin.lastName}
            </div>
          );
        }
        return <div className={classes.columnNotClickable}></div>;
      },
    },
    {
      title: 'Date de la dernière modification',
      dataIndex: 'updatedAt',
      key: '_id',
      width: '15%',
      render: function renderDate(updatedAt: number) {
        const newDate = updatedAt ? new Date(updatedAt) : undefined;
        return (
          <div>
            {newDate ? (
              <div>
                {new Intl.DateTimeFormat('fr-FR', {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                }).format(newDate)}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Publié',
      dataIndex: 'published',
      key: 'published',
      render: renderModifPublished,
    },
    {
      title: 'Suppression',
      key: 'deleteNgo',
      width: '10%',
      render: function renderDeleteNgo(record: Ngo) {
        return (
          <div className={classes.column}>
            <Button
              type="primary"
              danger
              className={classes.button}
              onClick={() => deleteNgoById(record._id)}
            >
              Supprimer
            </Button>
          </div>
        );
      },
    },
    {
      title: 'Nb de suivis',
      dataIndex: 'countRef',
      key: 'countRef',
      width: '5%',
      render: function renderCountRef(countRef: number) {
        return <div className={classes.nbRef}>{countRef || 0}</div>;
      },
      sorter: true,
    },
  ];

  useEffect(() => {
    dispatch(getNgosList(args));
  }, []);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 0;
    setCurrentPage(newPagination.current ?? 0);
    fetchNgos(searchFilters, newSearchPage);
  };

  const deleteNgoById = (ngoId: string) => {
    const value = prompt(
      'Veuillez saisir le mot de passe pour confirmer la suppression',
      '0000',
    );
    if (value !== '0122') {
      return alert('Mot de passe incorrect');
    }
    dispatch(deleteNgo(ngoId));
    dispatch(getNgosList({ offset: offset, limit: NGOS_MAX_RESULTS }));
  };

  const [ngoForm] = Form.useForm<FinancingNeed>();

  const onSubmitNgoForm = (values: any) => {
    dispatch(saveNgo(values));
  };

  // const onSelect = (ngoId: string, newAdmin: string, actualAdmin: string) => {
  //   dispatch(
  //     updateNgoAdmins({
  //       id: ngoId,
  //       adminsToAdd: newAdmin && [newAdmin],
  //       adminsToRemove: actualAdmin && [actualAdmin],
  //     }),
  //   );
  //   dispatch(getNgosList({ offset: offset, limit: NGOS_MAX_RESULTS }));
  // };

  return (
    <div>
      {editForm ? (
        <div className={classes.ngoEditForm}>
          <div className={classes.cancelButtonContainer}>
            <Button
              type="dashed"
              onClick={() => setEditForm(false)}
              danger
              icon={<CloseOutlined />}
              className={classes.button}
            >
              Annuler
            </Button>
          </div>
          <NgoEditForm form={ngoForm} />
          <div className={classes.buttonContainer}>
            <Button
              type="primary"
              className={classes.button}
              onClick={() => {
                ngoForm
                  .validateFields()
                  .then((values) => {
                    onSubmitNgoForm(values);
                    setEditForm(false);
                  })
                  .catch(Error);
              }}
            >
              Sauvegarder
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <Divider style={{ borderColor: 'transparent' }} /> */}
            <NgoTableSearchBar
              onSearch={onSearch}
              searchParameters={searchFilters}
              setSearchFilters={setSearchFilters}
            />
            <Button
              type="primary"
              onClick={() => setEditForm(true)}
              className={classes.button}
              style={{ margin: '2.5% 2.6% 1% 0%', float: 'right' }}
            >
              Créer une association
            </Button>
          </div>
          <Table
            className={classes.table}
            dataSource={searchResult?.resultsNgos}
            columns={columns}
            pagination={{
              total: searchResult?.resultsCount,
              current: offset / NGOS_MAX_RESULTS + 1,
              pageSize: NGOS_MAX_RESULTS,
              showSizeChanger: false,
              showQuickJumper: true,
            }}
            onChange={onTableChange}
            rowKey="_id"
          />
        </div>
      )}
    </div>
  );
};

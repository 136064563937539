import { Card } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { FinancingNeed } from '../slices/financingNeed';

const { Meta } = Card;

const useStyles = createUseStyles({
  cardContainer: {
    display: 'grid',
    columnGap: '3.372%',
    gridTemplateColumns: 'repeat(auto-fill, minmax(16.875vw, 0fr))',
  },
  card: {
    marginBottom: '7.21%',
    width: '13.875vw',
    height: '5.6875vw',
    borderRadius: '0.3125vw',
    webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.075)',
    '& .ant-card-body': {
      height: '7.1875vw',
      padding: '11.81% 8.89% 5.56% 11.11%',
      fontSize: '0.8125vw',
      textOverflow: 'ellipsis',
      '& .ant-card-meta-title': {
        // go to line if title is too long
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
      },
    },
  },
  cardMeta: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& .ant-card-meta-description': {
      color: 'black !important',
      lineHeight: 1,
    },
    '& .ant-card-meta-title': {
      fontWeight: 'bold',
      fontSize: 'calc(5px + 0.5vw)',
      lineHeight: 1,
      marginBottom: '0.9375vw !important',
      color: '#5050F9 !important',
      textOverflow: 'ellipsis',
    },
  },
});

interface Props {
  projects: FinancingNeed[];
}

export const ProjectCards: React.VFC<Props> = ({ projects }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.cardContainer}>
      {projects.map((project) => (
        <Card
          bordered={true}
          hoverable
          key={project?._id}
          className={classes.card}
          onClick={() => history.push(`/projects/${project._id}`)}
        >
          <div style={{ display: 'flex', height: '100%' }}>
            <Meta title={project?.name} className={classes.cardMeta} />
          </div>
        </Card>
      ))}
    </div>
  );
};

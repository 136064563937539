import { FormItem } from 'src/components/FormItem';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
  Space,
  Tag,
  Typography,
  Radio,
} from 'antd';
import type { RadioChangeEvent } from 'antd';
import { createUseStyles } from 'react-jss';
import { theme } from '../constants/theme';
import { Place } from '../slices/classification';
import { Commune } from '../slices/communes';
import { useFormRules } from '../hooks/useFormRules';
const { Panel } = Collapse;
const { Title, Text } = Typography;

const useStyles = createUseStyles({
  okButton: {
    backgroundColor: 'white',
    color: '#3B38F8',
    borderRadius: '0.5vw',
    borderColor: '#3B38F8',
    '&:hover': {
      backgroundColor: '#3B38F8',
      color: 'white',
      borderRadius: '0.5vw',
      borderColor: '#3B38F8',
    },
  },
  cancelButton: {
    backgroundColor: 'white',
    color: '#FF7E7E',
    borderRadius: '0.5vw',
    borderColor: '#FF7E7E',
    '&:hover': {
      backgroundColor: '#FF7E7E',
      color: 'white',
      borderRadius: '0.5vw',
      borderColor: '#FF7E7E',
    },
  },
  title: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    alignSelf: 'center',
    lineHeight: '1 !important',
  },
  subtitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: '400',
    fontSize: '1.5vw !important',
    alignSelf: 'center',
    lineHeight: '1 !important',
  },
  textHelper: {
    fontFamily: "'Baloo 2'",
    fontWeight: '400',
    fontSize: '1.2vw !important',
    alignSelf: 'center',
    lineHeight: '1 !important',
    // red
    color: '#f5222d',
  },
  header: {
    fontWeight: 'bold',
  },
  tag: {
    height: '1.5vw',
    fontSize: '0.9vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '0.5vw',
    maxWidth: '17.9vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  limitPlacesText: {
    textAlign: 'right',
    margin: '8px 0',
  },
  space: {
    width: '100%',
  },
  headerButtons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '10px',
    marginBottom: '10px',
  },
  radioButton: {
    display: 'inline-flex',
    alignItems: 'center',
    border: '1px solid #EFF1FF',
    borderRadius: 4,
    padding: '5px 10px',
    backgroundColor: '#EFF1FF',
    marginRight: 8,
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#888',
    },
    '&.ant-radio-wrapper-checked': {
      borderColor: '#3B38F8',
      backgroundColor: '#3B38F8',
      color: 'white',
    },
    '& .ant-radio': {
      display: 'none',
    },
  },
  customLabel: {
    position: 'relative',
    fontSize: '1vw',
    '&::after': {
      content: '"*"',
      color: '#ff4d4f',
      fontSize: '1.2vw',
      marginLeft: '0.2vw',
    },
  },
  customLabelOptional: {
    position: 'relative',
    fontSize: '1vw',
  },
  inputHeight: {
    '& input': {
      height: '100%',
      padding: '1vw 1vw',
      color: theme.palette.primary,
    },
    border: 'none',
    borderBottom: `1px solid ${theme.palette.primary}`,
    color: theme.palette.primary,
    '& input::placeholder': {
      color: theme.palette.primary,
    },
    '& textarea::placeholder': {
      color: theme.palette.primary,
    },
    '& textarea': {
      borderColor: theme.palette.primary,
    },
  },
});

interface PlacesSelectorModalProps {
  places: Place[];
  value: string[];
  onChange: (values?: string[]) => void;
  requestClose: () => void;
  isVisible: boolean;
  placesMaxQuantity?: number;
  form: FormInstance;
  uniqueZipcode: string;
  roadAndNumber: string;
  city: string;
  zipcode: string;
  communes: Commune[];
}

export const PlacesSelectorModal: React.VFC<PlacesSelectorModalProps> = ({
  places,
  isVisible,
  requestClose,
  onChange,
  value,
  form,
  uniqueZipcode,
  roadAndNumber,
  city,
  zipcode,
  communes,
}) => {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState<string[]>(value);
  const rules = useFormRules();

  const [cityState, setCityState] = useState<string>('');
  const [citiesState, setCitiesState] = useState<string[]>([]);

  const [nbPlacesSelectedInFrance, setNbPlacesSelectedInFrance] =
    useState<number>(0);

  const continents = places
    .filter((place) => place.countries && place.countries.length)
    .sort((a, b) =>
      a.name.includes('Europe') ? -1 : a.name.localeCompare(b.name),
    );
  const france = places
    .filter((place) => place.departments && place.departments.length)
    .sort((a, b) =>
      a.name.includes('DROM') || a.name.includes("Collectivités d'Outre-Mer")
        ? 1
        : a.name.localeCompare(b.name),
    ) as Place[];

  const GenExtra = ({ ids }: { ids: string[] }) => {
    return (
      <Space size={'large'}>
        <Button
          size={'small'}
          type="link"
          onClick={(event) => {
            event.stopPropagation();
            setSelectedValue(
              selectedValue
                ?.concat(ids)
                .filter((v, i, a) => a.indexOf(v) === i),
            );
          }}
          style={{ color: '#3B38F8' }}
        >
          Tout sélectionner
        </Button>
        <Button
          size={'small'}
          type="link"
          danger
          onClick={(event) => {
            event.stopPropagation();
            setSelectedValue(selectedValue?.filter((v) => !ids.includes(v)));
          }}
          style={{ color: '#FF7E7E' }}
        >
          Tout désélectionner
        </Button>
      </Space>
    );
  };

  const renderFrance = (region: Place) => {
    const tags = region.departments?.map((department) => {
      const isChecked = selectedValue?.includes(department._id);
      return (
        <Tag
          className={classes.tag}
          key={department._id}
          color={isChecked ? '#1890ff' : '#e7e7e7'}
          style={
            isChecked
              ? { color: 'white', backgroundColor: '#3B38F8' }
              : {
                  color: 'black',
                  backgroundColor: '#e7e7e7',
                  border: '1px solid #e7e7e7',
                }
          }
          onClick={() => {
            if (!isChecked) {
              setSelectedValue(selectedValue?.concat(department._id));
            } else {
              setSelectedValue(
                selectedValue?.filter((value) => value !== department._id),
              );
            }
          }}
        >
          {department.name}
        </Tag>
      );
    });
    return tags
      ?.reduce(
        (acc: JSX.Element[][], curr: JSX.Element, index: number) => {
          const minNumberOfElementsPerColumns = Math.floor(tags.length / 4);
          const rest = tags.length % 4;
          // fill every column with the same number of elements
          if (index < minNumberOfElementsPerColumns + (rest >= 1 ? 1 : 0)) {
            acc[0].push(curr);
            return acc;
          }
          if (index < 2 * minNumberOfElementsPerColumns + (rest >= 1 ? 2 : 0)) {
            acc[1].push(curr);
            return acc;
          }
          if (index < 3 * minNumberOfElementsPerColumns + (rest >= 1 ? 3 : 0)) {
            acc[2].push(curr);
            return acc;
          }
          acc[3].push(curr);
          return acc;
        },
        [[], [], [], []],
      )
      .map((tags: JSX.Element[], index: number) => (
        <Col key={index} span={6}>
          {tags.map((tag: JSX.Element, index: number) => (
            <Row key={index}>{tag}</Row>
          ))}
        </Col>
      ));
  };

  const renderContinent = (continent: Place) => {
    const tags = continent.countries?.map((country) => {
      const isChecked = selectedValue?.includes(country._id);
      return (
        <Tag
          className={classes.tag}
          key={country._id}
          color={isChecked ? '#1890ff' : '#e7e7e7'}
          style={
            isChecked
              ? { color: 'white', backgroundColor: '#3B38F8' }
              : {
                  color: 'black',
                  backgroundColor: '#e7e7e7',
                  border: '1px solid #e7e7e7',
                }
          }
          onClick={() => {
            if (!isChecked) {
              setSelectedValue(
                (selectedValue ? selectedValue : []).concat(country._id),
              );
            } else {
              setSelectedValue(
                (selectedValue ? selectedValue : []).filter(
                  (value) => value !== country._id,
                ),
              );
            }
          }}
        >
          {country.name}
        </Tag>
      );
    });
    return tags
      ?.reduce(
        (acc: JSX.Element[][], curr: JSX.Element, index: number) => {
          const minNumberOfElementsPerColumns = Math.floor(tags.length / 4);
          const rest = tags.length % 4;
          // fill every column with the same number of elements
          if (index < minNumberOfElementsPerColumns + (rest >= 1 ? 1 : 0)) {
            acc[0].push(curr);
            return acc;
          }
          if (index < 2 * minNumberOfElementsPerColumns + (rest >= 1 ? 2 : 0)) {
            acc[1].push(curr);
            return acc;
          }
          if (index < 3 * minNumberOfElementsPerColumns + (rest >= 1 ? 3 : 0)) {
            acc[2].push(curr);
            return acc;
          }
          acc[3].push(curr);
          return acc;
        },
        [[], [], [], []],
      )
      .map((tags: JSX.Element[], index: number) => (
        <Col key={index} span={6}>
          {tags.map((tag: JSX.Element, index: number) => (
            <Row key={index}>{tag}</Row>
          ))}
        </Col>
      ));
  };

  enum RadioValue {
    uniqueAddress = 'uniqueAddress',
    uniqueZipcode = 'uniqueZipcode',
    france = 'france',
    europe = 'europe',
    other = 'other',
    allFrance = 'allFrance',
  }

  const [radioValue, setRadioValue] = useState('uniqueAddress');

  useEffect(() => {
    if (value && value.length > 0) {
      if (
        value.length === getAllFranceIds().length &&
        [...getAllFranceIds()].sort().join('') === [...value].sort().join('')
      ) {
        setRadioValue(RadioValue.allFrance);
      } else if (value.every((v) => getAllFranceIds().includes(v))) {
        setRadioValue(RadioValue.france);
      } else {
        setRadioValue(RadioValue.europe);
      }
    } else if (uniqueZipcode) {
      setRadioValue(RadioValue.uniqueZipcode);
    } else if (zipcode) {
      setRadioValue(RadioValue.uniqueAddress);
    }
  }, []);

  const onRadioChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
    if (
      radioValue === RadioValue.uniqueAddress ||
      radioValue === RadioValue.uniqueZipcode ||
      radioValue === RadioValue.allFrance ||
      radioValue === RadioValue.france
    ) {
      setSelectedValue([]);
    }
  };

  const getAllFranceIds = (): string[] => {
    const franceIds = france?.reduce((previous: string[], place) => {
      const ids = place.departments?.map((d) => d._id);
      if (ids) {
        return previous.concat(ids);
      }
      return previous;
    }, []);

    if (franceIds != undefined) {
      return franceIds;
    }
    return [];
  };

  useEffect(() => {
    if (radioValue === RadioValue.allFrance) {
      setSelectedValue(getAllFranceIds());
    }
  }, [radioValue]);

  function updateCitiesState(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const cities = communes
      .filter((c) => {
        if (c.zipcode) {
          const zipcode = c.zipcode.toString();
          if (zipcode.length === 4) {
            return `0${zipcode}` === value.toString();
          } else {
            return zipcode === value.toString();
          }
        }
        return false;
      })
      .map((c) => c.name);
    setCitiesState(cities);
  }

  useEffect(() => {
    if (france && selectedValue) {
      setNbPlacesSelectedInFrance(
        france
          .reduce(
            (acc: boolean[], curr) => [
              ...acc,
              ...(curr.departments
                ? curr.departments?.map(
                    (d) => selectedValue && selectedValue.includes(d._id),
                  )
                : []),
            ],
            [],
          )
          .filter((b) => b).length,
      );
    }

    // setNbPlacesSelectedInFrance(selectedValue?.length);
  }, [selectedValue]);

  return (
    <Modal
      visible={isVisible}
      closable={false}
      onCancel={() => {
        setSelectedValue(value);
        form.setFieldsValue({
          uniqueZipcode: uniqueZipcode,
          zipcode: zipcode,
          roadAndNumber: roadAndNumber,
          city: city,
        });
        requestClose();
      }}
      onOk={() => {
        if (radioValue === RadioValue.uniqueAddress) {
          setSelectedValue([]);
          onChange([]);
          form.setFieldsValue({ uniqueZipcode: undefined });
          form.getFieldValue('roadAndNumber') &&
            form.getFieldValue('zipcode') &&
            form.getFieldValue('city') &&
            requestClose();
        } else if (radioValue === RadioValue.uniqueZipcode) {
          setSelectedValue([]);
          onChange([]);
          form.setFieldsValue({
            roadAndNumber: undefined,
            zipcode: undefined,
          });
          form.getFieldValue('uniqueZipcode') &&
            form.getFieldValue('city') &&
            requestClose();
        } else {
          form.setFieldsValue({
            uniqueZipcode: undefined,
            zipcode: undefined,
            roadAndNumber: undefined,
            city: undefined,
          });
          onChange(selectedValue);
          requestClose();
        }
      }}
      okButtonProps={{
        className: classes.okButton,
      }}
      cancelButtonProps={{
        className: classes.cancelButton,
      }}
      width={'100%'}
      style={{ top: '1vw', paddingBottom: '4vw', borderRadius: '1vw' }}
      okText="Valider"
      cancelText="Annuler"
    >
      <Form form={form} initialValues={{ remember: true }}>
        <Space direction={'vertical'} size={'large'} className={classes.space}>
          <Title className={classes.title} hidden>
            {"Lieux d'intervention ?"}
          </Title>
          <Radio.Group onChange={onRadioChange} value={radioValue}>
            <Radio
              value={RadioValue.uniqueAddress}
              className={classes.radioButton}
            >
              Adresse unique en France
            </Radio>
            <Radio
              value={RadioValue.uniqueZipcode}
              className={classes.radioButton}
            >
              Ville unique en France
            </Radio>
            <Radio value={RadioValue.france} className={classes.radioButton}>
              Départements ou régions en France
            </Radio>
            <Radio value={RadioValue.allFrance} className={classes.radioButton}>
              Toute la France
            </Radio>
            <Radio value={RadioValue.europe} className={classes.radioButton}>
              Pays en Europe
            </Radio>
            <Radio value={RadioValue.other} className={classes.radioButton}>
              Pays Reste du monde
            </Radio>
          </Radio.Group>
          <Collapse
            defaultActiveKey={[
              'uniqueAddress',
              'uniqueZipcode',
              'France',
              'Europe',
            ]}
            bordered={false}
            style={{ backgroundColor: 'white' }}
          >
            {radioValue === RadioValue.uniqueAddress && (
              <Panel key={'uniqueAddress'} header={'Adresse unique en France'}>
                <FormItem
                  label="→ Adresse"
                  name="roadAndNumber"
                  rules={
                    radioValue === RadioValue.uniqueAddress
                      ? [rules.required(), rules.max(100)]
                      : []
                  }
                  labelCol={{ span: 24 }}
                  customLabelClassName={classes.customLabel}
                >
                  <Input
                    allowClear
                    placeholder={'Numéro de rue et adresse'}
                    style={{ width: '35vw' }}
                    className={classes.inputHeight}
                  />
                </FormItem>
                <FormItem
                  label="→ Code postal"
                  name="zipcode"
                  rules={
                    radioValue === RadioValue.uniqueAddress
                      ? [rules.required()]
                      : []
                  }
                  labelCol={{ span: 24 }}
                  customLabelClassName={classes.customLabel}
                >
                  <Input
                    allowClear
                    placeholder={''}
                    maxLength={5}
                    onChange={(event) => {
                      updateCitiesState(event);
                    }}
                    style={{ width: '10vw' }}
                    className={classes.inputHeight}
                  />
                </FormItem>
                {citiesState.length > 0 && (
                  <>
                    <Text>Choisissez la ville :</Text>
                    {citiesState.map((city: string, index) => (
                      <Tag
                        key={index}
                        onClick={() => {
                          setCityState(city);
                          form.setFieldsValue({ city: city });
                        }}
                        className={classes.tag}
                        style={
                          city === cityState
                            ? { backgroundColor: '#3B38F8', color: 'white' }
                            : {}
                        }
                      >
                        {city}
                      </Tag>
                    ))}
                    <FormItem
                      label="→ Ville choisie"
                      name="city"
                      wrapperCol={{ span: 4 }}
                      rules={
                        radioValue === RadioValue.uniqueAddress
                          ? [rules.required()]
                          : []
                      }
                      key={1}
                      style={{ paddingTop: '1vw' }}
                      customLabelClassName={classes.customLabel}
                    >
                      <Input
                        allowClear
                        value={cityState}
                        // disabled
                        placeholder={''}
                        className={classes.inputHeight}
                      />
                    </FormItem>
                  </>
                )}
              </Panel>
            )}
            {radioValue === RadioValue.uniqueZipcode && (
              <Panel key={'uniqueZipcode'} header={'Ville unique'}>
                <>
                  <FormItem
                    label="→ Entrez le code postal correspondant"
                    name="uniqueZipcode"
                    labelCol={{ span: 24 }}
                    customLabelClassName={classes.customLabel}
                  >
                    <Input
                      allowClear
                      placeholder={''}
                      maxLength={5}
                      onChange={(event) => {
                        updateCitiesState(event);
                      }}
                      style={{ width: '10vw' }}
                      className={classes.inputHeight}
                    />
                  </FormItem>

                  {citiesState.length > 0 && (
                    <>
                      <Text>Choisissez la ville :</Text>
                      {citiesState.map((city: string, index) => (
                        <Tag
                          key={index}
                          onClick={() => {
                            setCityState(city);
                            form.setFieldsValue({ city: city });
                          }}
                          className={classes.tag}
                          style={
                            city === cityState
                              ? { backgroundColor: '#3B38F8', color: 'white' }
                              : {}
                          }
                        >
                          {city}
                        </Tag>
                      ))}
                      <FormItem
                        label="→ Ville choisie"
                        name="city"
                        wrapperCol={{ span: 4 }}
                        key={1}
                        style={{ paddingTop: '1vw' }}
                        customLabelClassName={classes.customLabel}
                      >
                        <Input
                          allowClear
                          value={cityState}
                          // disabled
                          placeholder={''}
                          className={classes.inputHeight}
                        />
                      </FormItem>
                    </>
                  )}
                </>
              </Panel>
            )}
            {radioValue === RadioValue.france && (
              <Panel
                key={'France'}
                header={'France (' + nbPlacesSelectedInFrance + ')'}
                extra={
                  <GenExtra
                    ids={france.reduce(
                      (acc: string[], curr) => [
                        ...acc,
                        ...(curr.departments
                          ? curr.departments?.map((d) => d._id)
                          : []),
                      ],
                      [],
                    )}
                  />
                }
                style={{ border: 'none' }}
              >
                {france.map((region) => {
                  return (
                    <Collapse
                      key={region.name}
                      style={{
                        border: 'none',
                      }}
                    >
                      <Panel
                        style={{
                          backgroundColor: 'white',
                        }}
                        header={
                          region.name +
                          ' (' +
                          france
                            .filter((r) => r.name === region.name)
                            .reduce(
                              (acc: boolean[], curr) => [
                                ...acc,
                                ...(curr.departments
                                  ? curr.departments?.map(
                                      (d) =>
                                        selectedValue &&
                                        selectedValue.includes(d._id),
                                    )
                                  : []),
                              ],
                              [],
                            )
                            .filter((b) => b)
                            .length.toString() +
                          ')'
                        }
                        key={region.name}
                        extra={
                          <GenExtra
                            ids={[
                              ...(region.departments
                                ? region.departments?.map((d) => d._id)
                                : []),
                            ]}
                          />
                        }
                      >
                        <Row gutter={[32, 16]}>{renderFrance(region)}</Row>
                      </Panel>
                    </Collapse>
                  );
                })}
              </Panel>
            )}
            {radioValue === RadioValue.europe &&
              continents
                .filter((continent) => continent.name === 'Europe')
                .map((continent) => {
                  return (
                    <Panel
                      key={continent.name}
                      header={
                        (continent.name == 'Europe'
                          ? 'Europe'
                          : continent.name) +
                        ' (' +
                        continents
                          .filter((c) => c.name === continent.name)
                          .reduce(
                            (acc: boolean[], curr) => [
                              ...acc,
                              ...(curr.countries
                                ? curr.countries?.map(
                                    (d) =>
                                      selectedValue &&
                                      selectedValue.includes(d._id),
                                  )
                                : []),
                            ],
                            [],
                          )
                          .filter((b) => b)
                          .length.toString() +
                        ')'
                      }
                      extra={
                        <GenExtra
                          ids={[
                            ...(continent.countries
                              ? continent.countries?.map((d) => d._id)
                              : []),
                          ]}
                        />
                      }
                    >
                      <Row gutter={[32, 16]}>{renderContinent(continent)}</Row>
                    </Panel>
                  );
                })}
            {radioValue == 'other' &&
              continents
                .filter((continent) => continent.name !== 'Europe')
                .map((continent) => {
                  return (
                    <Panel
                      key={continent.name}
                      header={
                        continent.name +
                        ' (' +
                        continents
                          .filter((c) => c.name === continent.name)
                          .reduce(
                            (acc: boolean[], curr) => [
                              ...acc,
                              ...(curr.countries
                                ? curr.countries?.map(
                                    (d) =>
                                      selectedValue &&
                                      selectedValue.includes(d._id),
                                  )
                                : []),
                            ],
                            [],
                          )
                          .filter((b) => b)
                          .length.toString() +
                        ')'
                      }
                      extra={
                        <GenExtra
                          ids={[
                            ...(continent.countries
                              ? continent.countries?.map((d) => d._id)
                              : []),
                          ]}
                        />
                      }
                    >
                      <Row gutter={[32, 16]}>{renderContinent(continent)}</Row>
                    </Panel>
                  );
                })}
          </Collapse>
        </Space>
      </Form>
    </Modal>
  );
};

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useStripe } from '@stripe/react-stripe-js';
import { message } from 'antd';

import { useAppDispatch } from '../../hooks/store';
import { apiRequest } from '../../helpers/api';
import { logout, fetchCurrentUser } from '../../slices/auth';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { Offer } from '../../slices/financingNeed';

export enum Pricing {
  inf100K = 'inf100K',
  inf500K = 'inf500K',
  sup500K = 'sup500K',
}

interface PaymentProps {
  projectId: string;
  pricing: Pricing;
  type: 'donor' | 'ngo';
  offer: Offer;
  isMonthly: 'true' | 'false';
}

interface SessionId {
  sessionId: string;
}

export const Payment: React.VFC<PaymentProps> = ({
  projectId,
  pricing,
  type,
  offer,
  isMonthly,
}) => {
  const { search } = useLocation();
  const stripe = useStripe();
  const history = useHistory();

  if (offer === Offer.visibility) {
    history.goBack();
  }

  const dispatch = useAppDispatch();

  const { sessionId, cancel } = parse(search);

  const checkoutSession = async () => {
    try {
      const { sessionId } = await apiRequest<SessionId>(
        'POST',
        `/payment/subscriptionDatabaseAccess/${projectId}/${pricing}/${type}/${offer.toString()}/${isMonthly}`,
        undefined,
        {
          originUrl: window.location.origin,
        },
      );

      stripe?.redirectToCheckout({ sessionId: sessionId });
    } catch (error) {
      message.error(
        'Une erreur est survenue. Merci de rafraichir la page pour réessayer',
      );
    }
  };

  const validateSession = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      dispatch(fetchCurrentUser());
    } catch (error) {
      message.error(
        'Une erreur est survenue. Merci de rafraichir la page pour réessayer',
      );
    }
  };

  useEffect(() => {
    if (stripe) {
      if (cancel) {
        dispatch(logout());
      } else if (!sessionId) {
        checkoutSession();
      } else {
        validateSession();
      }
    }
  }, [stripe]);

  return <FullscreenSpin />;
};

import React from 'react';
import { Spin } from 'antd';
import { createUseStyles } from 'react-jss';

import { theme } from '../constants/theme';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background,
  },
});

export const FullscreenSpin: React.VFC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Spin size="large" />
    </div>
  );
};

import { Radio } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { ProjectKanbanDonors } from '../containers/ProjectKanbanDonors';
import { CallForTenderNgosList } from '../containers/CallForTenderNgosList';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { CallForTender, getCallForTender } from '../slices/callForTender';
import { getDonor } from '../slices/donor';
import { LoggedIntercom } from 'src/utils/loggedIntercom';
import { useHistory } from 'react-router-dom';
import { Offer } from 'src/slices/financingNeed';

const useStyles = createUseStyles({
  container: {
    height: '100%',
    width: '100%',
    background:
      'radial-gradient(ellipse at top right, #d2dcfd 0%, #f0f2ff 20%, transparent 50%),\
      radial-gradient(ellipse at top left, #fdfefe 0%, #f4f7ff 20%, transparent 50%),\
      radial-gradient(ellipse at bottom left, #e5ecfe, #f6f9fe 30%, transparent 50%),\
      radial-gradient(ellipse at bottom right, #ffffff, #fbfbfb 5%, #f8fcfd 20%, #f0f2ff 30%, transparent 75%)',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '2.8%',
  },
  button: {
    fontSize: '0.8200vw',
    paddingBottom: '3.5%',
    marginBottom: '0%',
  },
});

export const CallForTenderNgos: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { callForTenderId } = useParams<{ callForTenderId: string }>();
  const [selectedTab, setSelectedTab] = useState<0 | 1>(0);

  const { callForTenders, user } = useAppSelector(
    ({ callForTender: { callForTenders }, auth: { user } }) => ({
      callForTenders,
      user,
    }),
  );

  const displayedCallForTender = useMemo<CallForTender>(
    () => callForTenders.byId[callForTenderId],
    [callForTenders.byId[callForTenderId]],
  );

  LoggedIntercom(
    user?.firstName + ' ' + user?.lastName,
    user?.email,
    user?._id,
    'callForTenderSawTrackingTable',
  );

  useEffect(() => {
    dispatch(getDonor(user?.donorId));
  }, [user?.donorId]);

  useEffect(() => {
    dispatch(getCallForTender(callForTenderId));
  }, [callForTenderId]);

  const onSelectTab = (tab: 1 | 0) => {
    setSelectedTab(tab);
  };

  if (!displayedCallForTender) {
    return <FullscreenSpin />;
  }

  function checkHasPaidOffer(callForTender: CallForTender) {
    const result = callForTender.offers.find(
      (o) =>
        o.offer != Offer.visibility &&
        o.expirationDate &&
        new Date(o.expirationDate) > new Date(),
    )
      ? 'true'
      : 'false';
    return result;
  }

  if (
    displayedCallForTender.offers &&
    checkHasPaidOffer(displayedCallForTender) === 'true'
  ) {
    return (
      <div className={classes.container}>
        <div className={classes.buttonsContainer}>
          <Radio.Group
            defaultValue={0}
            buttonStyle="outline"
            onChange={(e) => onSelectTab(e.target.value)}
          >
            <Radio.Button value={0} className={classes.button}>
              Vue kanban
            </Radio.Button>
            <Radio.Button value={1} className={classes.button}>
              Vue liste
            </Radio.Button>
          </Radio.Group>
        </div>
        {selectedTab === 0 ? (
          <ProjectKanbanDonors callForTender={displayedCallForTender} />
        ) : (
          <CallForTenderNgosList callForTender={displayedCallForTender} />
        )}
      </div>
    );
  } else if (
    displayedCallForTender.offers &&
    checkHasPaidOffer(displayedCallForTender) === 'false'
  ) {
    // useEffect(() => {
    //   window.location.reload();
    // }, []);
    history.push('/callForTenders');
    return (
      <div>
        <p>You do not have a valid subscription to access this feature.</p>
      </div>
    );
  } else {
    return <FullscreenSpin />;
  }
};

import React from 'react';
import { Button, Upload as UploadAntd, UploadProps } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';

import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from '../helpers/api';

const API_URL = process.env.REACT_APP_API_URL;

export interface IUploadProps {
  name: string;
  endpoint: string;
  onSuccess?: (file: UploadFile) => void;
  onError?: (file: UploadFile) => void;
  uploadProps?: UploadProps;
  children?: React.ReactNode;
  defaultFileList?: UploadFile[];
  text: string;
}

/**
 * See ant design doc: https://ant.design/components/upload
 */
export const Upload: React.VFC<IUploadProps> = ({
  name,
  uploadProps,
  endpoint,
  onSuccess,
  onError,
  children,
  text,
}) => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

  const onChange = (info: any) => {
    if (info.file.status === 'done') {
      if (onSuccess) {
        onSuccess(info);
      }
    } else if (info.file.status === 'error') {
      if (onError) {
        onError(info);
      }
    }
  };

  return (
    <UploadAntd
      {...uploadProps}
      name={name}
      onChange={onChange}
      data={{ nameTest: name }}
      action={API_URL + endpoint}
      headers={{
        authorization: `Bearer ${accessToken}`,
      }}
      listType="picture"
    >
      {children || <Button icon={<UploadOutlined />}>{text}</Button>}
    </UploadAntd>
  );
};

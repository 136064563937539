import React from 'react';
import { Form, Input, InputNumber } from 'antd';

import { useFormRules } from 'src/hooks/useFormRules';
import { FormInstance } from 'antd/es/form/Form';
import { FormItem } from 'src/components/FormItem';
import { SaveContributorDonorDto } from '../slices/donor';

interface Props {
  form: FormInstance<SaveContributorDonorDto>;
}

const layout = {
  labelCol: { span: 4 },
};

export const ContributorAddDonorForm: React.VFC<Props> = ({ form }) => {
  const rules = useFormRules();
  return (
    <Form {...layout} form={form}>
      <FormItem label={'Nom'} name="name" rules={[rules.required()]}>
        <Input placeholder={'Nom du mécène'} />
      </FormItem>
      <FormItem label="Adresse du siège social" name="headquarterAddress">
        <Input />
      </FormItem>
      <FormItem label="Site" name="websiteUrl">
        <Input type="url" />
      </FormItem>
      <FormItem label="Email de contact" name="contactEmail">
        <Input type="email" />
      </FormItem>
      <FormItem label="Numéro de téléphone" name="contactPhone">
        <Input type="tel" />
      </FormItem>
      <FormItem label="Année de création" name="creationYear">
        <InputNumber />
      </FormItem>
    </Form>
  );
};

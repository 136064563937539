import React from 'react';
import { createUseStyles } from 'react-jss';
import { Typography } from 'antd';

const { Text, Title } = Typography;

const useStyles = createUseStyles({
  container: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    padding: '3.98% 4.59%',
    flexDirection: 'column',
    textAlign: 'center',
  },
  error: {
    fontSize: '1.500vw',
    margin: 'auto',
  },
});

export const DonorAccessInConstruction: React.VFC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Title className={classes.error}>
        Accès Mécènes en cours de construction... <br />
      </Title>
      <Text className={classes.error}>
        Nous sommes en train de développer l&apos;accès à la plateforme pour les
        mécènes. Vous recevrez un mail dès que tout sera prêt pour vous
        accueillir !
      </Text>
    </div>
  );
};

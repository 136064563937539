import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { useHistory, useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import {
  Table,
  TablePaginationConfig,
  Button,
  Input,
  Select,
  DatePicker,
} from 'antd';
import {
  deleteUser,
  getUsersMoreInformations,
  PremiumFilter,
  SearchUsersParameters,
  User,
  USERS_MAX_RESULTS,
} from 'src/slices/auth';

const { Option } = Select;

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    padding: '1vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  name: {
    fontWeight: 'bold',
    cursor: 'pointer',
    maxWidth: '18.75vw',
  },
  button: {
    display: 'block',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  searchContainer: {
    marginTop: '1vw',
    marginLeft: '0.8vw',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'space-evenly',
  },
  searchBar: {
    width: '28vw',
    padding: '0.2vw',
    '& .ant-select .ant-select-selector': {
      height: '2.5vw',
      webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      borderRadius: '0.25vw',
    },
    '& .ant-badge': {
      marginLeft: '3.35%',
      width: '11.16%',
      webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      borderRadius: '0.25vw',
      '& .ant-btn': {
        borderRadius: '0.25vw',
        width: '100%',
        height: '2.5vw',
        padding: '8% 20%',
      },
    },
    '& .ant-input-affix-wrapper': {
      height: '2.5vw',
      padding: '1.14% 3.13%',
      borderRadius: '0.25vw',
      '& .ant-input': {
        fontSize: '0.875vw',
      },
    },
    '& .anticon-search': {
      fontSize: '1.75vw',
      color: '#ccc9e6',
    },
    '& .ant-input-group-addon': {
      verticalAlign: 'top',
      backgroundColor: 'transparent',
      fontSize: '0.875vw',
      lineHeight: 1,
      '& .ant-btn': {
        borderLeft: 'none',
        height: '2.5vw',
        width: '2.5vw',
        borderTopRightRadius: '0.25vw !important',
        borderBottomRightRadius: '0.25vw !important',
      },
    },
    '& .ant-btn': {
      lineHeight: '1',
    },
  },
  dateRangePicker: {
    marginLeft: '1.5vw',
    marginTop: '0.5vw',
  },
  selectSearchBar: {
    margin: '0.2vw',
    height: '2.5vw !important',
    width: '15vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        height: '2.5vw !important',
        lineHeight: '2.5vw !important',
        color: 'black',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
});

export const AdminUsers: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { organisationType } = useParams<{ organisationType: string }>();
  const history = useHistory();
  const { searchResult, offset } = useAppSelector(({ auth }) => ({
    searchResult: auth.searchResult,
    offset: auth.offset,
    users: auth.users,
  }));

  const [searchFilter, setSearchFilter] = useState<SearchUsersParameters>({
    premiumFilter: PremiumFilter.ALL,
    limit: USERS_MAX_RESULTS,
    offset: 0,
    organisationType: organisationType === 'ngo' ? 'PROJECT_HOLDER' : 'MECENE',
    startDate: undefined,
    endDate: undefined,
  });

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'firstName',
      key: '_id',
      width: '35%',
      render: function renderName(firstName: string, record: User) {
        return (
          <div className={classes.name}>
            {firstName} {record.lastName}
          </div>
        );
      },
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      render: function renderName(email: string) {
        return <div className={classes.name}>{email}</div>;
      },
    },
    {
      title: '💲',
      dataIndex: 'isPremium',
      key: 'isPremium',
      render: function renderIsPremium(isPremium: boolean) {
        return <div className={classes.name}>{isPremium ? '🟢' : '🔴'}</div>;
      },
    },
    {
      title: 'Téléphone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '15%',
      render: function renderPhoneNumber(phoneNumber: string) {
        return <div className={classes.name}>{phoneNumber}</div>;
      },
    },
    {
      title: 'Organisation',
      dataIndex: 'organisation',
      key: 'organisation',
      width: '5%',
      render: function renderName(_: string, record: User) {
        return (
          <Button
            type="primary"
            className={classes.button}
            onClick={() => {
              record.organisationType === 'PROJECT_HOLDER'
                ? history.push(`/ngo/${record.ngoId}`)
                : history.push(`/donor/${record.donorId}`);
            }}
          >
            Voir
          </Button>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'organisationType',
      key: 'organisationType',
      width: '5%',
      render: function renderName(organisationType: string) {
        return (
          <div className={classes.name}>
            {organisationType === 'PROJECT_HOLDER' ? 'PP' : 'M'}
          </div>
        );
      },
    },
    {
      title: `Date d'inscription`,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      render: function renderName(createdAt: string) {
        return <div className={classes.name}>{createdAt?.slice(0, 10)}</div>;
      },
    },
    {
      title: `Compte activé`,
      dataIndex: 'disabled',
      key: 'disabled',
      width: '15%',
      render: function renderName(disabled: boolean) {
        return <div className={classes.name}>{!disabled ? 'Oui' : 'Non'}</div>;
      },
    },
    {
      title: `Abonnement valide`,
      dataIndex: 'hasValidSubscription',
      key: 'hasValidSubscription',
      width: '15%',
      render: function renderName(hasValidSubscription: boolean) {
        return (
          <div className={classes.name}>
            {hasValidSubscription ? 'Oui' : 'Non'}
          </div>
        );
      },
    },
    {
      title: 'Modifier',
      dataIndex: '_id',
      key: '_id',
      width: '35%',
      render: function renderModify(_id: string) {
        return (
          <Button
            onClick={() => history.push(`/editUser/${_id}`)}
            type="dashed"
            className={classes.button}
          >
            Modifier
          </Button>
        );
      },
    },
    {
      title: 'Supprimer',
      dataIndex: '_id',
      key: '_id',
      width: '10%',
      render: function renderDelete(_id: string) {
        return (
          <Button
            type="primary"
            danger
            className={classes.button}
            onClick={() => deleteUserById(_id)}
          >
            Supprimer
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getUsersMoreInformations(searchFilter));
  }, []);

  useEffect(() => {
    dispatch(getUsersMoreInformations(searchFilter));
  }, [searchFilter]);

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 1;

    const newSearchFilter = {
      ...searchFilter,
      offset: (newSearchPage - 1) * USERS_MAX_RESULTS,
      limit: USERS_MAX_RESULTS,
    };
    setSearchFilter(newSearchFilter);
  };

  const onSearchFirstName = (value: string) => {
    const newSearchFilter = {
      ...searchFilter,
      firstName: value,
      offset: 0,
    };
    setSearchFilter(newSearchFilter);
  };

  const onSearchLastName = (value: string) => {
    const newSearchFilter = {
      ...searchFilter,
      lastName: value,
      offset: 0,
    };
    setSearchFilter(newSearchFilter);
  };

  const onSearchEmail = (value: string) => {
    const newSearchFilter = {
      ...searchFilter,
      email: value,
      offset: 0,
    };
    setSearchFilter(newSearchFilter);
  };

  const onSelectPremiumFilter = (value: PremiumFilter) => {
    const newSearchFilter = {
      ...searchFilter,
      premiumFilter: value,
      offset: 0,
    };
    setSearchFilter(newSearchFilter);
  };

  const onEndDateChange = (value: string | undefined) => {
    const newSearchFilter = {
      ...searchFilter,
      endDate: value,
      offset: 0,
    };
    setSearchFilter(newSearchFilter);
  };

  const onStartDateChange = (value: string | undefined) => {
    const newSearchFilter = {
      ...searchFilter,
      startDate: value,
      offset: 0,
    };
    setSearchFilter(newSearchFilter);
  };

  const deleteUserById = (id: string) => {
    const value = prompt(
      'Veuillez saisir le mot de passe pour confirmer la suppression',
      '0000',
    );
    if (value !== '0122') {
      return alert('Mot de passe incorrect');
    }
    dispatch(
      deleteUser({
        userId: id,
        searchPayload: searchFilter,
      }),
    );
  };

  const filterArray = [
    { value: PremiumFilter.ALL, label: 'Tous les utilisateurs' },
    { value: PremiumFilter.PREMIUM, label: 'Utilisateurs premium' },
    { value: PremiumFilter.FREE, label: 'Utilisateurs non premium' },
  ];

  return (
    <>
      <div className={classes.searchContainer}>
        <Input.Group compact>
          <Input.Search
            className={classes.searchBar}
            allowClear
            placeholder="Prénom"
            onSearch={(value) => {
              onSearchFirstName(value);
            }}
            onChange={(e) => onSearchFirstName(e.target.value)}
          />
          <Input.Search
            className={classes.searchBar}
            allowClear
            placeholder="Nom"
            onSearch={(value) => {
              onSearchLastName(value);
            }}
            onChange={(e) => onSearchLastName(e.target.value)}
          />
          <Input.Search
            className={classes.searchBar}
            allowClear
            placeholder="E-mail"
            onSearch={(value) => {
              onSearchEmail(value);
            }}
            onChange={(e) => onSearchEmail(e.target.value)}
          />
          <Select
            defaultValue={0}
            className={classes.selectSearchBar}
            onSelect={onSelectPremiumFilter}
          >
            {filterArray.map((filter, index) => (
              <Option key={index} value={filter.value}>
                {filter.label}
              </Option>
            ))}
          </Select>
        </Input.Group>
      </div>
      <div className={classes.dateRangePicker}>
        {'Date de création minimale: '}
        <DatePicker
          onChange={(value) =>
            onStartDateChange(value ? value.format('YYYY-MM-DD') : undefined)
          }
          placeholder={'Entrez une date'}
        />
      </div>
      <div className={classes.dateRangePicker}>
        {'Date de création maximale: '}
        <DatePicker
          onChange={(value) =>
            onEndDateChange(value ? value.format('YYYY-MM-DD') : undefined)
          }
          placeholder={'Entrez une date'}
        />
      </div>
      <Table
        className={classes.table}
        dataSource={searchResult?.resultsUsers}
        columns={columns}
        pagination={{
          total: searchResult?.resultsCount,
          current: offset / USERS_MAX_RESULTS + 1,
          pageSize: USERS_MAX_RESULTS,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={onTableChange}
        rowKey="_id"
      />
    </>
  );
};

import { notification, TablePaginationConfig } from 'antd';
import { min } from 'lodash';
import { parse, stringify } from 'query-string';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation, useHistory, useParams } from 'react-router';

import { useSortedClassification } from 'src/hooks/useSortedClassification';
import { SearchFiltersParameters } from '../components/DatabaseSearchBar';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { NgosTable } from '../components/NgosTable';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { useCaptchaDispatch } from '../hooks/useCaptchaDispatch';
import {
  addNgo,
  CallForTender,
  getCallForTenderFromDonorId,
  removeNgo,
  resetCallForTenderEvent,
} from '../slices/callForTender';
import {
  DONORS_MAX_RESULTS,
  getDonor,
  // getDonorsWithAlgorithm,
  // resetDonorEvent,
  // resetSuggestions,
  // searchDonorsWithAlgorithmAndGetSuggestions,
} from '../slices/donor';
import { fetchFinancingNeedsCountForNgo } from '../slices/financingNeed';
import { resetNgoEvent, searchNgosByCallForTender } from '../slices/ngo';

const MAX_DISPLAYED_PAGES_NUMBER = 10;

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
  },
  fondations: {
    paddingLeft: '5.59%',
    paddingTop: '4.59%',
    fontWeight: '600',
    fontFamily: "'Baloo 2'",
    fontSize: '2.125vw',
    lineHeight: '1',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2.8%',
  },
  button: {
    fontSize: '0.8200vw',
    margin: 'auto',
    width: '33%',
    textAlign: 'center',
  },
  error: {
    fontSize: '1.500vw',
    paddingTop: '17%',
    paddingLeft: '16.8%',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5%',
    marginTop: '1%',
    maxWidth: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '1.5%',
    // justifyContent: 'center',
    '& > *': {
      margin: '5px 0',
    },
  },
});

interface Props {
  callForTender: CallForTender;
}

export const CallForTenderNgosList: React.VFC<Props> = ({ callForTender }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useLocation().search;
  const { callForTenderId } = useParams<{ callForTenderId: string }>();
  const captchaDispatch = useCaptchaDispatch();

  const [searchFilters, setSearchFilters] = useState<SearchFiltersParameters>(
    {},
  );
  const [queryParamsObject, setQueryParamsObject] = useState<
    Record<string, unknown>
  >(parse(query, { arrayFormat: 'bracket' }));

  const {
    donors,
    callForTenders,
    loading,
    searchResult,
    user,
    offset,
    event,
    financingNeedsCounts,
  } = useAppSelector(
    ({
      donor: {
        donors, // event, // loading,
      },
      callForTender: { callForTenders },
      ngo: {
        loading,
        searchResult,
        event,
        offset,
        classificationsFilter,
        suggestionsNgos,
        suggestionsLoading,
      },
      financingNeed: { financingNeedsCounts },
      auth: { user },
    }) => ({
      suggestionsLoading,
      callForTenders,
      donors,
      classificationsFilter,
      loading,
      event,
      searchResult,
      user,
      offset,
      suggestionsNgos,
      financingNeedsCounts,
    }),
  );

  const callForTenderEvent = useAppSelector(({ callForTender: { event } }) => ({
    event,
  })).event;

  const classification = useSortedClassification();

  const fetchNgos = (page = 1) => {
    updateQueryParams({ page });
    captchaDispatch(
      searchNgosByCallForTender,
      searchNgosByCallForTender({
        id: callForTender._id,
        searchParameters: {
          ...searchFilters,
          offset: (page - 1) * DONORS_MAX_RESULTS,
        },
      }),
    );
  };

  const fetchNgosWithQueryParams = () => {
    const {
      page,
      name,
      tags,
      statuses,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      donationTypes,
      department,
    } = queryParamsObject;
    const newSearchFilters = {
      name,
      tags,
      statuses,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      donationTypes,
      department,
    } as SearchFiltersParameters;

    setSearchFilters(newSearchFilters);

    // fetchDonors(
    //   page ? parseInt(page as string) : undefined,
    //   algorithm as string,
    //   newSearchFilters,
    // );
    fetchNgos(page ? parseInt(page as string) : undefined);
  };

  const updateQueryParams = (params: Record<string, unknown>) => {
    const updatedQueryParamsObject = { ...queryParamsObject, ...params };
    history.replace({
      search: stringify(updatedQueryParamsObject, { arrayFormat: 'bracket' }),
    });
    setQueryParamsObject(updatedQueryParamsObject);
  };

  useEffect(() => {
    dispatch(getDonor(user?.donorId));
  }, [user?.donorId]);

  useEffect(() => {
    fetchNgosWithQueryParams();
    !callForTenders.allIds.length &&
      dispatch(getCallForTenderFromDonorId(user?.donorId));
  }, []);

  useEffect(() => {
    if (event) {
      event.error
        ? notification.error({
            message:
              event.action == 'addFavoriteDonor'
                ? "Le porteur de projet n'a pas pu être ajouté à vos favoris"
                : "Le porteur de projet n'a pas pu être retiré de vos favoris",
            duration: 2,
          })
        : notification.success({
            message:
              event.action == 'addFavoriteDonor'
                ? 'Le porteur de projet a été ajouté à vos favoris'
                : 'Le porteur de projet a été retiré de vos favoris',
            duration: 2,
          });
      dispatch(resetNgoEvent());
    }
  }, [event]);

  useEffect(() => {
    if (
      searchResult &&
      searchResult?.resultsNgos &&
      searchResult?.resultsNgos.length
    ) {
      const ngoIds = searchResult.resultsNgos.map((ngo) => ngo._id);
      dispatch(fetchFinancingNeedsCountForNgo(ngoIds));
    }
  }, [searchResult?.resultsNgos]);

  useEffect(() => {
    if (callForTenderEvent) {
      callForTenderEvent.error
        ? notification.error({
            message:
              callForTenderEvent.action == 'addDonor'
                ? "Le porteur de projet n'a pas pu être ajouté au projet"
                : "Le porteur de projet n'a pas pu être retiré du projet",
            duration: 2,
          })
        : notification.success({
            message:
              callForTenderEvent.action == 'addDonor'
                ? 'Le porteur de projet a été ajouté au projet'
                : 'Le porteur de projet a été retiré du projet',
            duration: 2,
          });
      dispatch(resetCallForTenderEvent());
    }
  }, [callForTenderEvent]);

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 0;
    fetchNgos(newSearchPage);
  };

  const onAddNgo = (ngoId: string, callForTenderId: string) => {
    dispatch(
      addNgo({
        callForTenderId: callForTenderId,
        body: { ngoId: ngoId },
      }),
    );
  };

  const onRemoveNgo = (ngoId: string, callForTenderId: string) => {
    dispatch(
      removeNgo({
        callForTenderId: callForTenderId,
        body: { ngoId: ngoId },
      }),
    );
  };

  const onEditNgo = (ngoId: string) => {
    history.push(`/ngo/${ngoId}/edit`);
  };

  if (!user || !classification || !donors) {
    return <FullscreenSpin />;
  }

  useEffect(() => {
    fetchNgos(1);
  }, [searchFilters]);

  return (
    <div className={classes.container}>
      <NgosTable
        ngos={searchResult?.resultsNgos ?? null}
        classification={classification}
        callForTenders={callForTenders.allIds.map(
          (id) => callForTenders.byId[id],
        )}
        financingNeedsCounts={financingNeedsCounts}
        callForTender={callForTenders.byId[callForTenderId]}
        onTableChange={onTableChange}
        onAddNgo={onAddNgo}
        onRemoveNgo={onRemoveNgo}
        onEditNgo={onEditNgo}
        loading={loading}
        pagination={{
          total: min([
            searchResult?.resultsCount,
            DONORS_MAX_RESULTS * MAX_DISPLAYED_PAGES_NUMBER,
          ]),
          current: offset / DONORS_MAX_RESULTS + 1,
          pageSize: DONORS_MAX_RESULTS,
          showSizeChanger: false,
        }}
        role={user.role}
      />
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { createUseStyles } from 'react-jss';
import { FinancingNeedStepsForm } from '../containers/FinancingNeedStepsForm';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { Offer, saveFinancingNeed } from '../slices/financingNeed';
import { LoggedIntercom } from '../utils/loggedIntercom';
import { useHistory } from 'react-router-dom';
import FinancingNeedConfirmation from './ngoPage/FinancingNeedConfirmation';
import { getFinancingNeeds } from '../slices/financingNeed';

const useStyles = createUseStyles({
  title: {
    fontSize: '2vw !important',
  },
  view: {
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    maxWidth: '100%',
    margin: '1vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  button: {
    display: 'block',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  column: {
    color: 'black',
    fontWeight: 'bold',
    cursor: 'pointer',
    maxWidth: '18.75vw',
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  projectEditForm: {},
  select: {
    display: 'flex',
    margin: '1.25vw auto',
    width: '15.625vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        height: '2vw !important',
        lineHeight: '2vw !important',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
  cancelButton: {
    color: 'red',
    marginRight: '32px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props {
  setIsNavBarHidden?: (isHidden: boolean) => void;
}

export const NewProject: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  const { financingNeeds, ngo } = useAppSelector(
    ({ financingNeed: { financingNeeds }, ngo: { ngo } }) => ({
      financingNeeds,
      ngo,
    }),
  );
  const [editForm, setEditForm] = useState(true);

  useEffect(() => {
    if (setIsNavBarHidden) {
      setIsNavBarHidden(true);
    }
  }, []);

  useEffect(() => {
    dispatch(getFinancingNeeds(user?.ngoId));
  }, []);

  const onSave = (values: any) => {
    dispatch(
      saveFinancingNeed({
        ...values,
        adminNgos: [ngo?._id],
        offers: [{ offer: Offer.visibility }],
      }),
    );
    LoggedIntercom(
      user?.firstName + ' ' + user?.lastName,
      user?.email,
      user?._id,
      'editedFinancingNeed',
    );
  };

  async function submitForm() {
    const fieldNames = [
      'name',
      'description',
      'targetPopulations',
      'tags',
      'places',
      'roadAndNumber',
      'uniqueZipcode',
      'zipcode',
      'city',
      'websiteUrl',
      'logoUrl',
    ];

    const allFieldValues = fieldNames.reduce((acc: any, fieldName: string) => {
      acc[fieldName] = form.getFieldValue(fieldName);
      return acc;
    }, {});
    onSave(allFieldValues);
    dispatch(getFinancingNeeds(user?.ngoId));
    if (ngo && financingNeeds && financingNeeds.allIds.length < 2) {
      setEditForm(false);
      form.resetFields();
    } else {
      history.push(`ngo/${ngo?._id}`);
      window.location.reload();
    }
    user?.role === 'admin' &&
      (history.push(`/ngo/${ngo?._id}`), window.location.reload());
  }

  //handleClosePrompt
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);
  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    const handler = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handler);
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [isFirstRender]);

  return (
    <div className={classes.view}>
      {editForm ? (
        <div className={classes.projectEditForm}>
          <FinancingNeedStepsForm
            form={form}
            isNewFinancingNeed={true}
            ngoId={ngo?._id}
            submitForm={submitForm}
          />
        </div>
      ) : (
        <div className={classes.container}>
          <FinancingNeedConfirmation setEditForm={setEditForm} />
        </div>
      )}
    </div>
  );
};

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import CheckMarkIcon from 'src/assets/icons/tick-outline.svg';
import {
  Col,
  Form,
  FormInstance,
  Input,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  InputNumber,
  Row,
  Select,
  Menu,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Dropdown,
  Typography,
  Divider,
  Button,
  Tag,
  Image,
  message,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { debounce } from 'lodash';
import { PlacesSelectorModal } from '../components/PlacesSelectorModal';
import { TagsSelectorModal } from '../components/TagsSelectorModal';
import { IUploadProps, Upload } from '../components/Upload';
import { theme } from '../constants/theme';
import { apiRequest } from '../helpers/api';

import { useAppDispatch, useAppSelector } from '../hooks/store';
import { useSortedClassification } from '../hooks/useSortedClassification';
import { fetchCurrentUser, updateUserNgoId } from '../slices/auth';
import { getClassification, Place } from '../slices/classification';
import { getCommunes } from '../slices/communes';
import { Ngo } from '../slices/ngo';

import { reset, searchRna } from '../slices/rna';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { placesValidator } from '../helpers/validators';
import { useFormRules } from '../hooks/useFormRules';
import { FormItem } from 'src/components/FormItem';
import {
  getDonor,
  getDonorsFromNgoId,
  saveContributorDonor,
  SaveContributorDonorDto,
  searchDonorsAndGetSuggestions,
} from 'src/slices/donor';
import { IsValidRnaNumber } from '../types/rna.types';
import { ContributorAddDonorForm } from './ContributorAddDonorForm';
import { MoreTagsModal } from 'src/components/MoreTagsModal';

const { Option } = Select;
const { Text, Paragraph, Link } = Typography;

const layout = {
  labelCol: { span: 6 },
};

const useStyles = createUseStyles({
  link: {
    '&:hover': {
      color: '#10B981 !important',
    },
  },
  checkIcon: {
    marginRight: 10,
    width: 24, // Adjust the size as needed
    height: 24, // Adjust the size as needed
  },
  crossIcon: {
    marginRight: 10,
    width: 32, // Adjust the size as needed
    height: 32, // Adjust the size as needed
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    '& .ant-form-item, .ant-form-item-label > label,\
        .ant-input, .ant-input-number': {
      fontSize: '0.875vw',
    },
    '& .ant-col': {
      maxWidth: '100%',
    },
  },
  stepContainer: {
    height: '75vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  stepColLeft1: {
    backgroundImage: `url("../../images/orgaStep1.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft2: {
    backgroundImage: `url("../../images/orgaStep2.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft3: {
    backgroundImage: `url("../../images/orgaStep3.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft4: {
    backgroundImage: `url("../../images/orgaStep4.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft5: {
    backgroundImage: `url("../../images/orgaStep5.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColRight: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    marginLeft: '40vw',
  },
  stepImage: {
    width: '40vw',
    position: 'fixed',
  },
  stepBar: {
    height: '1vw',
    backgroundColor: theme.palette.primary,
  },
  colRightPadding: {
    marginLeft: '6rem',
  },
  stepCounter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  stepNumber: {
    fontSize: '2.5vw',
  },
  stepTotal: {
    fontSize: '1.2vw',
    color: theme.palette.primary,
  },
  buttonBox: {
    position: 'fixed',
    bottom: '0',
    left: '40vw',
    width: '40%',
    paddingBottom: '2.5vw',
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    marginLeft: '6rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.8rem',
  },
  buttonPrevious: {
    width: 'auto',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  buttonNext: {
    width: 'auto',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  buttonUpload: {
    width: 'auto%',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: theme.palette.primary,
    color: 'white',
    borderRadius: '0.5vw',
  },
  textArea: {
    height: 200,
    marginBottom: 50,
  },
  tagCities: {
    fontSize: '1vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    color: 'white',
    minHeight: '1.5vw',
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: '0.5vw',
    backgroundColor: theme.palette.primary,
  },
  menu: {
    width: 800,
    whiteSpace: 'normal',
    height: '60vh',
    overflow: 'auto',
  },
  menuItem: {
    position: 'relative',
    width: '100%',
    wordWrap: 'break-word',
    marginBottom: 20,
  },
  name: {
    width: '100%',
    fontWeight: 'bold',
  },
  description: {
    width: '100%',
  },
  tag: {
    minHeight: '1.65vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '0.4vw',
    fontSize: '0.8vw',
    height: '1vw',
    color: 'white',
    backgroundColor: '#5050f6',
    border: '1px solid #5050f6',
    borderRadius: '1vw',
    paddingRight: '0.5vw',
    paddingLeft: '0.5vw',
  },
  placesTag: {
    minHeight: '2.5vw',
    fontSize: '1vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '1vw',
    color: 'white',
    backgroundColor: theme.palette.primary,
  },
  addressTag: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontSize: '1vw',
    marginLeft: '1rem',
    marginTop: '0.4rem',
  },
  button: {
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  search: {
    '& .ant-input-group-addon': {
      backgroundColor: 'white',
      fontSize: '0.875vw',
      lineHeight: '1.5715',
      height: '100%',
      '& .ant-btn': {
        padding: '4px 0 !important',
        height: 'unset',
        width: '2vw',
        fontSize: '0.875vw !important',
        boxShadow: 'none',
      },
    },
    '& .ant-btn-icon-only > *': {
      fontSize: '1vw',
    },
  },
  trashIcon: {
    color: '#002766',
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  headquarterCityFormItem: {
    '& .ant-input-affix-wrapper-disabled': {
      backgroundColor: 'white',
    },
    '& .ant-input[disabled]': {
      color: 'black',
    },
  },
  donorsFormItem: {
    marginTop: '1vh',
    '& .ant-form-item-tooltip': {
      color: '#5050F9 !important',
    },
  },
  modal: {
    '&.ant-modal': {
      maxWidth: '1000px',
      height: 900,
      zIndex: 2000,
    },
    '&.ant-modal-body': {
      height: 900,
    },
  },
  modalWrap: {
    zIndex: 2000,
  },
  customLabel: {
    position: 'relative',
    fontSize: '1vw',
    '&::after': {
      content: '"*"',
      color: '#ff4d4f',
      fontSize: '1.2vw',
      marginLeft: '0.2vw',
    },
  },
  customLabelOptional: {
    position: 'relative',
    fontSize: '1vw',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '75%',
    marginTop: '10dvh',
  },
  inputHeight: {
    minHeight: '2vw',
    '& input': {
      height: '100%',
      padding: '0.5vw 1vw',
      color: 'black',
    },
    border: 'none',
    borderBottom: '1px solid #5050f6',
    color: '#5050f6',
    '& input::placeholder': {
      color: theme.palette.primary,
    },
    '& textarea::placeholder': {
      color: theme.palette.primary,
    },
    '& .ant-select-selection-placeholder': {
      color: theme.palette.primary,
    },
    '& textarea': {
      borderColor: '#5050f6',
      borderBottom: 'none',
      color: 'black',
    },
  },
  selectTag: {
    '& .ant-select-selection-item': {
      border: 'none',
      backgroundColor: 'white',
      maxHeight: '1.65vw',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .ant-select-selection-item-content': {
      color: 'white',
      backgroundColor: '#5050f6',
      border: '1px solid #5050f6',
      paddingRight: '0.5vw',
      paddingLeft: '0.5vw',
      borderRadius: '1vw',
    },
  },
});

interface INgoFirstStepsForm {
  form: FormInstance;
  ngoId?: string;
  setEditForm: (value: boolean) => void;
  submitForm: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const debouncedSearchCall = debounce((searchFunction: any, value: string) => {
  searchFunction(value);
}, 500);

export const NgoFirstStepsForm: React.VFC<INgoFirstStepsForm> = ({
  form,
  ngoId,
  setEditForm,
  submitForm,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const rules = useFormRules();
  const history = useHistory();
  const [menu, setMenu] = useState<null | React.ReactElement>(null);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showMoreTagsModal, setShowMoreTagsModal] = useState(false);
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string | undefined | null>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cityState, setCityState] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [citiesState, setCitiesState] = useState<string[]>([]);
  const [addDonorModalOpen, setAddDonorModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [addDonorForm] = Form.useForm<SaveContributorDonorDto>();
  const [isValidRna, setIsValidRna] = useState(false);
  const [rnaNumber, setRnaNumber] = useState('');

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  useEffect(() => {
    fetchCurrentUser();
  }, [user]);

  // Update rnaNumber state when input changes
  const handleRnaNumberChange = (event: { target: { value: any } }) => {
    const newRnaNumber = event.target.value;
    form.setFieldsValue({ rnaNumber: newRnaNumber });
    console.log('newRnaNumber', newRnaNumber);
    setRnaNumber(newRnaNumber);
  };

  useEffect(() => {
    if (rnaNumber) {
      apiRequest<IsValidRnaNumber>('GET', `/rna/isValid/${rnaNumber}`)
        .then((data) => setIsValidRna(data.isValid))
        .catch((error) => console.error('Error fetching RNA validity:', error));
    }
  }, [rnaNumber]);

  const classification = useSortedClassification();
  const { results, loading, userRole, suggestionsDonors, donors, communes } =
    useAppSelector(
      ({
        rna: { loading, results },
        auth,
        donor: { suggestionsDonors, donors },
        communes: { communes },
      }) => ({
        loading,
        results,
        userRole: auth.user?.role,
        suggestionsDonors,
        donors,
        communes,
      }),
    );

  useEffect(() => {
    dispatch(getClassification());

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (ngoId) {
      dispatch(getDonorsFromNgoId(ngoId));
    }
    if (communes.length === 0) {
      dispatch(getCommunes());
    }
  }, [ngoId, communes]);

  useEffect(() => {
    if (results && results.length > 0) {
      setMenu(
        <Menu className={classes.menu}>
          {results.map(
            ({
              idRna,
              object,
              name,
              rnaNumber,
              siret,
              headquarterAddress,
              websiteUrl,
              creationYear,
              isPublicInterestNgo,
            }) => (
              <Menu.Item
                key={rnaNumber}
                className={classes.menuItem}
                onClick={() => {
                  form.setFieldsValue({
                    name,
                    idRna,
                    object: (object?.length || 0) > 1 ? object : null,
                    siret,
                    headquarterAddress,
                    websiteUrl,
                    creationYear,
                    isPublicInterestNgo,
                  });
                  dispatch(reset());
                }}
              >
                <div className={classes.name}>{name}</div>
                {(object?.length || 0) > 1 && (
                  <Paragraph
                    className={classes.description}
                    type="secondary"
                    ellipsis={{ rows: 2 }}
                  >
                    {object}
                  </Paragraph>
                )}
                <Text type="secondary">RNA : {idRna}</Text>
                {headquarterAddress && (
                  <>
                    <br />
                    <Text type="secondary">Adresse : {headquarterAddress}</Text>
                  </>
                )}
              </Menu.Item>
            ),
          )}
        </Menu>,
      );
    } else {
      setMenu(null);
    }
  }, [results]);

  const optionsDonors = useMemo(
    () =>
      (suggestionsDonors || [])
        .concat(
          Object.entries(donors.byId || {})
            .map(([id, donor]) => ({
              _id: id,
              name: donor.name,
            }))
            .filter(
              (donor) =>
                (suggestionsDonors || []).findIndex(
                  (searchDonor) => searchDonor._id === donor._id,
                ) === -1,
            ),
        )
        .filter(
          (donor) => !(form.getFieldValue('donors') || []).includes(donor._id),
        )
        .map((donor) => ({ label: donor.name, value: donor._id })),
    [donors.byId, suggestionsDonors],
  );

  useEffect(() => {
    form.setFieldsValue({ logoUrl });
  }, [logoUrl]);

  useEffect(() => {
    if (form.getFieldValue('logoUrl')) {
      setLogoUrl(form.getFieldValue('logoUrl'));
    }
  }, [form.getFieldValue('logoUrl')]);

  if (!classification) return <FullscreenSpin />;

  const handleChange = () => {
    initSearchFundingDonor();
  };

  const initSearchFundingDonor = () => {
    dispatch(searchDonorsAndGetSuggestions({ name: '', offset: 0 }));
  };

  const handleSearch = (value: string) => {
    if (value !== '' && value !== undefined) {
      dispatch(searchDonorsAndGetSuggestions({ name: value, offset: 0 }));
    }
  };

  const { tags, places, activityDomains, targetPopulations } = classification;

  let lastSearchNamePromise: any;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchName = (name: string) => {
    if (name.length >= 3) {
      lastSearchNamePromise?.abort();
      lastSearchNamePromise = dispatch(searchRna({ text: name }));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderDonorFormItem = () => (
    <FormItem
      label="→ Liste des mécènes qui soutiennent ou ont soutenu votre organisation"
      name="unverifiedDonors"
      tooltip={"À remplir afin d'augmenter la qualité des mécènes proposés"}
      className={classes.donorsFormItem}
      customLabelClassName={classes.customLabelOptional}
    >
      <Select
        allowClear
        style={{ fontSize: '1vw' }}
        mode="multiple"
        onChange={handleChange}
        onSearch={handleSearch}
        onFocus={initSearchFundingDonor}
        filterOption={(input, option) => {
          const label = (option?.label as string) || '';

          return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        options={optionsDonors}
        bordered={false}
        className={`${classes.inputHeight} ${classes.selectTag}`}
        placeholder={'Rechercher  un mécène'}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                padding: 8,
              }}
            >
              <a
                style={{
                  flex: 'none',
                  padding: '8px',
                  display: 'block',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setAddDonorModalOpen(true);
                }}
              >
                {"Un Mécène n'apparait pas ? Ajoutez-le "}
                <PlusOutlined />
              </a>
            </div>
          </div>
        )}
      />
    </FormItem>
  );

  const renderLogo = () => {
    return (
      logoUrl && (
        <div>
          <Row align="middle">
            <Col xs={6} />
            <Col xs={5} xxl={3}>
              <div
                style={{ display: 'flex', float: 'left', marginTop: '10px' }}
              >
                <Image
                  src={logoUrl}
                  width="90px"
                  height="90px"
                  fallback="error"
                  alt="Logo"
                />
              </div>
            </Col>
            <Col xs={9} xxl={10}>
              <Link onClick={() => setLogoUrl(null)} style={{ float: 'left' }}>
                <DeleteOutlined className={classes.trashIcon} />
              </Link>
            </Col>
          </Row>
        </div>
      )
    );
  };

  const onSuccessLogoUpload = (info: any) => {
    if (info.file.type === 'image/png' || info.file.type === 'image/jpeg') {
      message.success('Logo ajouté avec succès !');
      setLogoUrl(info.file.response);
    } else {
      message.error('Seul les formats jpeg et png sont acceptés');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function updateCitiesState(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const cities = communes
      .filter((c) => {
        if (c.zipcode) {
          const zipcode = c.zipcode.toString();
          if (value.toString().startsWith('0')) {
            return zipcode === value.toString().slice(1);
          }
          return zipcode === value.toString();
        }
        return false;
      })
      .map((c) => c.name);
    setCitiesState(cities);
  }

  const uploadLogoProps: IUploadProps = {
    name: 'file',
    endpoint: '/file/upload',
    onSuccess: onSuccessLogoUpload,
    text: 'Importer un logo',
    children: (
      <Button
        className={classes.buttonPrevious}
        style={{
          height: '3vw',
          fontSize: '0.875vw',
        }}
      >
        J’importe le logo de mon organisation
      </Button>
    ),
    uploadProps: {
      showUploadList: false,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderUploadLogo = () => {
    return (
      <div style={{ marginBottom: '25px' }}>
        <Row align="middle" style={{ paddingBottom: '8px' }}>
          <Text
            style={{
              textAlign: 'left',
              width: '100%',
              marginRight: '8px',
              fontSize: '1vw',
            }}
          >
            → Importez votre Logo :
          </Text>
        </Row>
        <Row align="middle" style={{ paddingBottom: '8px' }}>
          <Upload {...uploadLogoProps} />
        </Row>
        {renderLogo()}
      </div>
    );
  };

  const france = places
    .filter((place) => place.departments && place.departments.length)
    .sort((a, b) =>
      a.name.includes('DROM') || a.name.includes("Collectivités d'Outre-Mer")
        ? 1
        : a.name.localeCompare(b.name),
    ) as Place[];

  const countries = places
    .filter((place) => place.countries && place.countries.length)
    .sort((a, b) => a.name.localeCompare(b.name)) as Place[];

  function renderFrance(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    return isFranceComplete && <Tag className={classes.tag}>France</Tag>;
  }

  function renderDepartments(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return (
      results.length > 0 &&
      results.map((department, index) => (
        <Tag key={index} className={classes.tag}>
          {department}
        </Tag>
      ))
    );
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return;
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return (
      results.length > 0 &&
      results.map((currentCountry, index) => (
        <Tag key={index} className={classes.tag}>
          {currentCountry}
        </Tag>
      ))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function renderLieuxIntervention() {
    return (
      <FormItem
        label="→ Lieu(x) d’intervention"
        name="places"
        rules={
          userRole !== 'admin' &&
          !form.getFieldValue('city') &&
          !form.getFieldValue('zipcode') &&
          !form.getFieldValue('roadAndNumber') &&
          !form.getFieldValue('uniqueZipcode')
            ? [
                {
                  required: true,
                  type: 'array',
                  validator: placesValidator,
                },
              ]
            : undefined
        }
        customLabelClassName={classes.customLabel}
      >
        <div>
          <Button
            onClick={() => setShowPlacesModal(true)}
            className={classes.buttonPrevious}
            style={{ height: '3vw', fontSize: '0.875vw' }}
          >
            Choisir les lieu(x) d’intervention
          </Button>
        </div>
        <div style={{ marginTop: '1vw', marginLeft: '' }}>
          {!!form.getFieldValue('roadAndNumber') &&
            !!form.getFieldValue('city') &&
            !!form.getFieldValue('zipcode') && (
              <div>
                <Tag
                  key={'uniqueAddressWithInformations'}
                  className={classes.tag}
                >
                  {form.getFieldValue('roadAndNumber')}
                  {', '}
                  {form.getFieldValue('city')}
                  {', '}
                  {form.getFieldValue('zipcode')}
                </Tag>
              </div>
            )}
          {!!form.getFieldValue('uniqueZipcode') &&
            !!form.getFieldValue('city') && (
              <div>
                <Tag key={'uniqueZipcode'} className={classes.tag}>
                  {form.getFieldValue('uniqueZipcode')}
                  {', '}
                  {form.getFieldValue('city')}
                </Tag>
              </div>
            )}
          {!!form.getFieldValue('places')?.length &&
            (renderFrance(form.getFieldValue('places')) ||
              renderDepartments(form.getFieldValue('places')))}
          {!!form.getFieldValue('places')?.length &&
            renderCountries(form.getFieldValue('places'))}
        </div>
      </FormItem>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function renderBeneficiaresCibles() {
    return (
      <FormItem
        label="→ Bénéficiaires cibles"
        name="targetPopulations"
        rules={
          userRole !== 'admin' ? [rules.required({ type: 'array' })] : undefined
        }
        customLabelClassName={classes.customLabel}
      >
        <Select
          mode="multiple"
          allowClear
          optionFilterProp="label"
          bordered={false}
          className={`${classes.inputHeight} ${classes.selectTag}`}
          style={{ fontSize: '0.8vw' }}
          placeholder={'Entrez votre réponse ici'}
        >
          {targetPopulations.map((targetPopulation) => {
            return (
              <Option
                value={targetPopulation._id}
                key={targetPopulation._id}
                label={targetPopulation.translatedName}
                style={{ color: theme.palette.primary }}
              >
                {targetPopulation.translatedName}
              </Option>
            );
          })}
        </Select>
      </FormItem>
    );
  }

  useEffect(() => {
    if (form.getFieldValue('tags')?.length > 0 && classification.tags?.length) {
      form.setFieldsValue({
        activityDomains: form
          .getFieldValue('tags')
          .reduce((previousValue: string[], tagId: string) => {
            const activityDomains = classification.tags.find(
              (t) => t._id === tagId,
            )?.associatedDomains;
            if (activityDomains) {
              return previousValue.concat(activityDomains);
            }
            return previousValue;
          }, [])
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index,
          ),
      });
    } else {
      form.setFieldsValue({
        activityDomains: [],
      });
    }
  }, [form.getFieldValue('tags')]);

  const onAddDonor = async () => {
    const valuesNewDonor = await addDonorForm.validateFields();
    setAddDonorModalOpen(false);
    const resultAction = await dispatch(saveContributorDonor(valuesNewDonor));

    if (saveContributorDonor.fulfilled.match(resultAction)) {
      dispatch(getDonor(resultAction.payload._id));
      message.success('Mécène ajouté avec succès !');
    } else {
      message.error("Erreur lors de l'ajout du Mécène");
    }
  };

  const renderAddDonorModal = () => (
    <Modal
      className={classes.modal}
      maskStyle={{ zIndex: 1500 }}
      width={1000}
      wrapClassName={classes.modalWrap}
      title={'Ajouter un Mécène'}
      onOk={onAddDonor}
      onCancel={() => setAddDonorModalOpen(false)}
      visible={addDonorModalOpen}
      okText={'Confirmer'}
      cancelText={'Annuler'}
      confirmLoading={loading}
    >
      {<ContributorAddDonorForm form={addDonorForm} />}
    </Modal>
  );

  const handleNextClick = async () => {
    if (currentStep === 1) {
      if (isValidRna && user) {
        // handle ngo creation with rnaNumber and redirect to
        const ngoPayload = await apiRequest<Ngo>(
          'POST',
          '/ngo/rna',
          {},
          { rnaNumber },
        );
        dispatch(
          updateUserNgoId({
            updateUserDto: {
              ngoId: ngoPayload._id,
              hasValidSubscription: true,
            },
            id: user._id,
          }),
        );
        history.push(`createFirstProject`);
      } else {
        validateStep1()
          .then(() => {
            setCurrentStep(2);
          })
          .catch((errorInfo) => {
            console.log('Validation failed:', errorInfo);
          });
      }
    } else if (currentStep === 2) {
      validateStep2()
        .then(() => {
          submitForm();
        })
        .catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        });
      // .then(() => {
      //   setCurrentStep(3);
      // })
      // .catch((errorInfo) => {
      //   console.log('Validation failed:', errorInfo);
      // });
      // } else if (currentStep === 3) {
      //   validateStep3()
      //     .then(() => {
      //       setCurrentStep(4);
      //     })
      //     .catch((errorInfo) => {
      //       console.log('Validation failed:', errorInfo);
      //     });
      // } else if (currentStep === 4) {
      //   validateStep4()
      //     .then(() => {
      //       setCurrentStep(5);
      //     })
      //     .catch((errorInfo) => {
      //       console.log('Validation failed:', errorInfo);
      //     });
      // } else if (currentStep === 5) {
      //   validateStep5()
      //     .then(() => {
      //       submitForm();
      //     })
      //     .catch((errorInfo) => {
      //       console.log('Validation failed:', errorInfo);
      //     });
    }
  };

  const validateStep1 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields([
          'rnaNumber',
          // 'name',
          // 'status',
          // 'creationYear',
          // 'headquarterZipcode',
          // 'headquarterCity',
          // 'headquarterAddress',
        ])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep1 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft1}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '20%' }}></div>
            <div className={classes.colRightPadding}>
              {/*<div className={classes.stepCounter}>*/}
              {/*  <div className={classes.stepNumber}>1</div>*/}
              {/*  <div className={classes.stepTotal}>/5</div>*/}
              {/*</div>*/}
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  <FormItem
                    label="→ Numéro RNA (Répertoire National des Associations)"
                    name="rnaNumber"
                    normalize={(value) => (value === '' ? null : value)}
                    rules={[rules.required(), rules.min(1), rules.max(14)]}
                    customLabelClassName={classes.customLabel}
                  >
                    <>
                      <Input
                        allowClear
                        placeholder="Entrez votre réponse ici"
                        className={classes.inputHeight}
                        onChange={handleRnaNumberChange}
                        value={rnaNumber}
                      />
                      <Link
                        href="https://lecompteasso.associations.gouv.fr/faq/le-rna/"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.link}
                      >
                        Je ne connais pas mon numéro RNA
                      </Link>
                    </>
                  </FormItem>
                </div>
                <div>
                  {isValidRna && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '0.8vw',
                        color: '#10B981',
                      }}
                    >
                      <Image
                        src={CheckMarkIcon}
                        preview={false}
                        className={classes.checkIcon}
                      />
                      <Paragraph
                        style={{
                          fontSize: '0.8vw',
                          color: '#10B981',
                          marginBottom: 0,
                        }}
                      >
                        Votre numéro de RNA a bien été reconnu. Vous pouvez
                        continuer votre inscription en cliquant sur suivant.
                      </Paragraph>
                    </div>
                  )}
                </div>
                {/*<div className={classes.field}>*/}
                {/*  <Dropdown overlay={(menu as any) || <></>} visible={!!menu}>*/}
                {/*    <FormItem*/}
                {/*      label="→ Nom de l'organisation"*/}
                {/*      name="name"*/}
                {/*      rules={[rules.required(), rules.max(100)]}*/}
                {/*      labelCol={{ span: 24 }}*/}
                {/*      customLabelClassName={classes.customLabel}*/}
                {/*    >*/}
                {/*      <Input*/}
                {/*        allowClear*/}
                {/*        placeholder="Entrez votre réponse ici"*/}
                {/*        className={`${classes.search} ${classes.inputHeight}`}*/}
                {/*        onChange={(event) =>*/}
                {/*          debouncedSearchCall(searchName, event.target.value)*/}
                {/*        }*/}
                {/*      />*/}
                {/*    </FormItem>*/}
                {/*  </Dropdown>*/}
                {/*</div>*/}
                {/*<div className={classes.field}>*/}
                {/*  <FormItem*/}
                {/*    label="→ Statut"*/}
                {/*    name="status"*/}
                {/*    initialValue={'Entrez votre réponse ici'}*/}
                {/*    rules={*/}
                {/*      userRole !== 'admin'*/}
                {/*        ? [rules.required(), rules.status()]*/}
                {/*        : undefined*/}
                {/*    }*/}
                {/*    customLabelClassName={classes.customLabel}*/}
                {/*    style={{ height: '1vw' }}*/}
                {/*  >*/}
                {/*    <Select*/}
                {/*      allowClear*/}
                {/*      style={{ fontSize: '0.88vw' }}*/}
                {/*      className={classes.inputHeight}*/}
                {/*      bordered={false}*/}
                {/*    >*/}
                {/*      <Option value="Association">Association</Option>*/}
                {/*      <Option value="Autre">Autre</Option>*/}
                {/*    </Select>*/}
                {/*  </FormItem>*/}
                {/*</div>*/}
                {/*<div className={classes.field} style={{ marginTop: '3.5vw' }}>*/}
                {/*  <FormItem*/}
                {/*    label="→ Année de création"*/}
                {/*    name="creationYear"*/}
                {/*    normalize={(value) => (value === '' ? null : value)}*/}
                {/*    rules={*/}
                {/*      userRole !== 'admin' ? [rules.creationYear()] : undefined*/}
                {/*    }*/}
                {/*    customLabelClassName={classes.customLabelOptional}*/}
                {/*  >*/}
                {/*    <InputNumber*/}
                {/*      placeholder="Entrez votre réponse ici"*/}
                {/*      style={{ width: '100%' }}*/}
                {/*      className={classes.inputHeight}*/}
                {/*    />*/}
                {/*  </FormItem>*/}
                {/*</div>*/}
                {/*<div className={classes.field}>*/}
                {/*  <FormItem*/}
                {/*    label="→ Code postal du siège social"*/}
                {/*    name="headquarterZipcode"*/}
                {/*    wrapperCol={{ span: 2 }}*/}
                {/*    rules={[*/}
                {/*      {*/}
                {/*        pattern: new RegExp(/^[0-9]{5}$/),*/}
                {/*        message: 'Un code postal complet est requis.',*/}
                {/*      },*/}
                {/*      {*/}
                {/*        required: true,*/}
                {/*        message: 'Un code postal valide est requis.',*/}
                {/*      },*/}
                {/*    ]}*/}
                {/*    customLabelClassName={classes.customLabel}*/}
                {/*  >*/}
                {/*    <Input*/}
                {/*      allowClear*/}
                {/*      placeholder={''}*/}
                {/*      onChange={(event) => {*/}
                {/*        if (event.target.value.length === 5) {*/}
                {/*          updateCitiesState(event);*/}
                {/*        }*/}
                {/*      }}*/}
                {/*      className={classes.inputHeight}*/}
                {/*    />*/}
                {/*  </FormItem>*/}
                {/*</div>*/}
                {/*<div className={classes.field}>*/}
                {/*  {citiesState.length > 0 && (*/}
                {/*    <div*/}
                {/*      style={{*/}
                {/*        display: 'flex',*/}
                {/*        flexDirection: 'column',*/}
                {/*        justifyContent: 'space-between',*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <div>*/}
                {/*        <FormItem*/}
                {/*          label=""*/}
                {/*          name="headquarterCity"*/}
                {/*          wrapperCol={{ span: 4 }}*/}
                {/*          key={1}*/}
                {/*          className={classes.headquarterCityFormItem}*/}
                {/*          rules={[*/}
                {/*            {*/}
                {/*              required: true,*/}
                {/*              message: 'Cliquez sur une ville proposée.',*/}
                {/*            },*/}
                {/*          ]}*/}
                {/*        >*/}
                {/*          <Input*/}
                {/*            allowClear*/}
                {/*            value={cityState}*/}
                {/*            disabled*/}
                {/*            placeholder={''}*/}
                {/*            className={classes.inputHeight}*/}
                {/*          />*/}
                {/*        </FormItem>*/}
                {/*      </div>*/}
                {/*      <div*/}
                {/*        style={{*/}
                {/*          display: 'flex',*/}
                {/*          flexDirection: 'row',*/}
                {/*          flexWrap: 'wrap',*/}
                {/*          gap: '0.5vw',*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        {Array.from(new Set(citiesState)).map(*/}
                {/*          (city: string, index) => (*/}
                {/*            <div style={{ height: '2vw' }} key={index}>*/}
                {/*              <Tag*/}
                {/*                key={index}*/}
                {/*                onClick={() => {*/}
                {/*                  setCityState(city);*/}
                {/*                  form.setFieldsValue({*/}
                {/*                    headquarterCity: city,*/}
                {/*                  });*/}
                {/*                }}*/}
                {/*                className={classes.tagCities}*/}
                {/*                style={*/}
                {/*                  city === cityState*/}
                {/*                    ? {*/}
                {/*                        minHeight: '2vw',*/}
                {/*                        paddingRight: '1vw',*/}
                {/*                        paddingLeft: '1vw',*/}
                {/*                        display: 'inline-flex',*/}
                {/*                        alignItems: 'center',*/}
                {/*                        borderRadius: '1vw',*/}
                {/*                        color: 'white',*/}
                {/*                        backgroundColor: '#3B38F8',*/}
                {/*                      }*/}
                {/*                    : {*/}
                {/*                        minHeight: '2vw',*/}
                {/*                        display: 'inline-flex',*/}
                {/*                        alignItems: 'center',*/}
                {/*                        borderRadius: '1vw',*/}
                {/*                        color: 'black',*/}
                {/*                        backgroundColor: '#e7e7e7',*/}
                {/*                        border: '1px solid #e7e7e7',*/}
                {/*                      }*/}
                {/*                }*/}
                {/*              >*/}
                {/*                {city}*/}
                {/*              </Tag>*/}
                {/*            </div>*/}
                {/*          ),*/}
                {/*        )}*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  )}*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  className={classes.field}*/}
                {/*  style={{ paddingBottom: '100px' }}*/}
                {/*>*/}
                {/*  <FormItem*/}
                {/*    label="→ Numéro et adresse du siège social"*/}
                {/*    name="headquarterAddress"*/}
                {/*    wrapperCol={{ span: 8 }}*/}
                {/*    rules={[rules.max(100)]}*/}
                {/*    customLabelClassName={classes.customLabelOptional}*/}
                {/*    style={{ marginTop: '1vw' }}*/}
                {/*  >*/}
                {/*    <Input*/}
                {/*      allowClear*/}
                {/*      placeholder="Entrez votre réponse ici"*/}
                {/*      className={classes.inputHeight}*/}
                {/*    />*/}
                {/*  </FormItem>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={() => setEditForm(false)}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const validateStep2 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields([
          'name',
          'status',
          'creationYear',
          'headquarterZipcode',
          'headquarterCity',
          'headquarterAddress',

          //
          // 'tags',
          // 'places',
          // 'roadAndNumber',
          // 'uniqueZipcode',
          // 'zipcode',
          // 'city',
          // 'targetPopulations',
          // 'description',
        ])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep2 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft2}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '40%' }}></div>
            <div className={classes.colRightPadding}>
              {/*<div className={classes.stepCounter}>*/}
              {/*  <div className={classes.stepNumber}>2</div>*/}
              {/*  <div className={classes.stepTotal}>/5</div>*/}
              {/*</div>*/}
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  <Dropdown overlay={(menu as any) || <></>} visible={!!menu}>
                    <FormItem
                      label="→ Nom de l'organisation"
                      name="name"
                      rules={[rules.required(), rules.max(100)]}
                      labelCol={{ span: 24 }}
                      customLabelClassName={classes.customLabel}
                    >
                      <Input
                        allowClear
                        placeholder="Entrez votre réponse ici"
                        className={`${classes.search} ${classes.inputHeight}`}
                        onChange={(event) =>
                          debouncedSearchCall(searchName, event.target.value)
                        }
                      />
                    </FormItem>
                  </Dropdown>
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Statut"
                    name="status"
                    initialValue={'Entrez votre réponse ici'}
                    rules={
                      userRole !== 'admin'
                        ? [rules.required(), rules.status()]
                        : undefined
                    }
                    customLabelClassName={classes.customLabel}
                    style={{ height: '1vw' }}
                  >
                    <Select
                      allowClear
                      style={{ fontSize: '0.88vw' }}
                      className={classes.inputHeight}
                      bordered={false}
                    >
                      <Option value="Association">Association</Option>
                      <Option value="Autre">Autre</Option>
                    </Select>
                  </FormItem>
                </div>
                <div className={classes.field} style={{ marginTop: '3.5vw' }}>
                  <FormItem
                    label="→ Année de création"
                    name="creationYear"
                    normalize={(value) => (value === '' ? null : value)}
                    rules={
                      userRole !== 'admin' ? [rules.creationYear()] : undefined
                    }
                    customLabelClassName={classes.customLabelOptional}
                  >
                    <InputNumber
                      placeholder="Entrez votre réponse ici"
                      style={{ width: '100%' }}
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Code postal du siège social"
                    name="headquarterZipcode"
                    wrapperCol={{ span: 2 }}
                    rules={[
                      {
                        pattern: new RegExp(/^[0-9]{5}$/),
                        message: 'Un code postal complet est requis.',
                      },
                      {
                        required: true,
                        message: 'Un code postal valide est requis.',
                      },
                    ]}
                    customLabelClassName={classes.customLabel}
                  >
                    <Input
                      allowClear
                      placeholder={''}
                      onChange={(event) => {
                        if (event.target.value.length === 5) {
                          updateCitiesState(event);
                        }
                      }}
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
                <div className={classes.field}>
                  {citiesState.length > 0 && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          gap: '0.5vw',
                          marginBottom: '2dvh',
                        }}
                      >
                        {Array.from(new Set(citiesState)).map(
                          (city: string, index) => (
                            <div style={{ height: '2vw' }} key={index}>
                              <Tag
                                key={index}
                                onClick={() => {
                                  setCityState(city);
                                  form.setFieldsValue({
                                    headquarterCity: city,
                                  });
                                }}
                                className={classes.tagCities}
                                style={
                                  city === cityState
                                    ? {
                                        minHeight: '2vw',
                                        paddingRight: '1vw',
                                        paddingLeft: '1vw',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        borderRadius: '1vw',
                                        color: 'white',
                                        backgroundColor: '#3B38F8',
                                      }
                                    : {
                                        minHeight: '2vw',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        borderRadius: '1vw',
                                        color: 'black',
                                        backgroundColor: '#e7e7e7',
                                        border: '1px solid #e7e7e7',
                                      }
                                }
                              >
                                {city}
                              </Tag>
                            </div>
                          ),
                        )}
                      </div>
                      <div>
                        <FormItem
                          label=""
                          name="headquarterCity"
                          wrapperCol={{ span: 4 }}
                          key={1}
                          className={classes.headquarterCityFormItem}
                          rules={[
                            {
                              required: true,
                              message: 'Cliquez sur une ville proposée.',
                            },
                          ]}
                        >
                          <Input
                            allowClear
                            value={cityState}
                            disabled
                            placeholder={''}
                            className={classes.inputHeight}
                          />
                        </FormItem>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={classes.field}
                  style={{ paddingBottom: '100px' }}
                >
                  <FormItem
                    label="→ Numéro et adresse du siège social"
                    name="headquarterAddress"
                    wrapperCol={{ span: 8 }}
                    rules={[rules.max(100)]}
                    customLabelClassName={classes.customLabelOptional}
                    style={{ marginTop: '1vw' }}
                  >
                    <Input
                      allowClear
                      placeholder="Entrez votre réponse ici"
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
                {/*<div className={classes.field}>*/}
                {/*  <FormItem*/}
                {/*    label="→ Mots clés"*/}
                {/*    name="tags"*/}
                {/*    rules={*/}
                {/*      userRole !== 'admin'*/}
                {/*        ? [*/}
                {/*            {*/}
                {/*              required: true,*/}
                {/*              type: 'array',*/}
                {/*              validator: tagsValidator,*/}
                {/*            },*/}
                {/*          ]*/}
                {/*        : undefined*/}
                {/*    }*/}
                {/*    customLabelClassName={classes.customLabel}*/}
                {/*  >*/}
                {/*    <div*/}
                {/*      style={{*/}
                {/*        display: 'flex',*/}
                {/*        flexDirection: 'column',*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <Button*/}
                {/*        onClick={() => setShowTagsModal(true)}*/}
                {/*        className={classes.buttonPrevious}*/}
                {/*        style={{*/}
                {/*          height: '3vw',*/}
                {/*          width: '12vw',*/}
                {/*          fontSize: '0.875vw',*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        Choisir les mots clés*/}
                {/*      </Button>*/}
                {/*      {!!form.getFieldValue('tags')?.length && (*/}
                {/*        <div*/}
                {/*          style={{*/}
                {/*            marginTop: '1vw',*/}
                {/*            display: 'inline-flex',*/}
                {/*            alignItems: 'center',*/}
                {/*            flexDirection: 'row',*/}
                {/*            flexWrap: 'wrap',*/}
                {/*            gap: '0.4vw',*/}
                {/*          }}*/}
                {/*        >*/}
                {/*          {form*/}
                {/*            .getFieldValue('tags')*/}
                {/*            ?.slice(0, 4)*/}
                {/*            .map((tagId: string) => (*/}
                {/*              <Tag key={tagId} className={classes.tag}>*/}
                {/*                {*/}
                {/*                  tags.find((tag) => tag._id === tagId)*/}
                {/*                    ?.translatedName*/}
                {/*                }*/}
                {/*              </Tag>*/}
                {/*            ))}*/}
                {/*          {form.getFieldValue('tags')?.length > 4 && (*/}
                {/*            <Tag*/}
                {/*              style={{*/}
                {/*                backgroundColor: 'white',*/}
                {/*                borderRadius: '1vw',*/}
                {/*                maxHeight: '1.65vw',*/}
                {/*                display: 'inline-flex',*/}
                {/*                alignItems: 'center',*/}
                {/*              }}*/}
                {/*            >*/}
                {/*              <span*/}
                {/*                style={{*/}
                {/*                  marginRight: '0.5vw',*/}
                {/*                  fontSize: '1vw',*/}
                {/*                }}*/}
                {/*              >*/}
                {/*                +{form.getFieldValue('tags')?.length - 4}*/}
                {/*              </span>{' '}*/}
                {/*              <span>*/}
                {/*                <Link*/}
                {/*                  style={{*/}
                {/*                    fontSize: '0.7vw',*/}
                {/*                    textDecoration: 'underline',*/}
                {/*                  }}*/}
                {/*                  onClick={() => setShowMoreTagsModal(true)}*/}
                {/*                >*/}
                {/*                  voir plus*/}
                {/*                </Link>*/}
                {/*              </span>*/}
                {/*            </Tag>*/}
                {/*          )}*/}
                {/*        </div>*/}
                {/*      )}*/}
                {/*    </div>*/}
                {/*  </FormItem>*/}
                {/*</div>*/}
                {/*<div className={classes.field}>{renderLieuxIntervention()}</div>*/}
                {/*<div*/}
                {/*  className={classes.field}*/}
                {/*  style={{ paddingBottom: '100px' }}*/}
                {/*>*/}
                {/*  {renderBeneficiaresCibles()}*/}
                {/*</div>*/}
                {/*<div className={classes.field}>*/}
                {/*  <FormItem*/}
                {/*    label="→ Description"*/}
                {/*    name="description"*/}
                {/*    rules={userRole !== 'admin' ? [rules.max(2000)] : undefined}*/}
                {/*    customLabelClassName={classes.customLabelOptional}*/}
                {/*  >*/}
                {/*    <TextArea*/}
                {/*      placeholder="Entrez votre réponse ici"*/}
                {/*      autoSize={{ minRows: 4, maxRows: 10 }}*/}
                {/*      maxLength={2000}*/}
                {/*      showCount={true}*/}
                {/*      className={classes.inputHeight}*/}
                {/*    />*/}
                {/*  </FormItem>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={() => setCurrentStep(1)}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const validateStep3 = () => {
  //   return new Promise((resolve, reject) => {
  //     form
  //       .validateFields(['unverifiedDonors'])
  //       .then(() => {
  //         resolve(true);
  //       })
  //       .catch((errorInfo) => {
  //         reject(errorInfo);
  //       });
  //   });
  // };
  //
  // const renderStep3 = () => {
  //   return (
  //     <div className={classes.stepContainer}>
  //       <div className={classes.stepColLeft3}></div>
  //       <div className={classes.stepColRight}>
  //         <div>
  //           <div className={classes.stepBar} style={{ width: '60%' }}></div>
  //           <div className={classes.colRightPadding}>
  //             <div className={classes.stepCounter}>
  //               <div className={classes.stepNumber}>3</div>
  //               <div className={classes.stepTotal}>/5</div>
  //             </div>
  //             <div className={classes.fieldsContainer}>
  //               <div
  //                 className={classes.field}
  //                 style={{ paddingBottom: '100px' }}
  //               >
  //                 {renderDonorFormItem()}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className={classes.buttonBox}>
  //           <div className={classes.buttonContainer}>
  //             <Button
  //               type="default"
  //               className={classes.buttonPrevious}
  //               onClick={() => setCurrentStep(2)}
  //             >
  //               ← Précédent
  //             </Button>
  //             <Button
  //               type="default"
  //               className={classes.buttonNext}
  //               onClick={() => handleNextClick()}
  //             >
  //               Suivant →
  //             </Button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  //
  // const validateStep4 = () => {
  //   return new Promise((resolve, reject) => {
  //     form
  //       .validateFields(['websiteUrl', 'contactMail', 'phoneNumber', 'logoUrl'])
  //       .then(() => {
  //         resolve(true);
  //       })
  //       .catch((errorInfo) => {
  //         reject(errorInfo);
  //       });
  //   });
  // };
  //
  // const renderStep4 = () => {
  //   return (
  //     <div className={classes.stepContainer}>
  //       <div className={classes.stepColLeft4}></div>
  //       <div className={classes.stepColRight}>
  //         <div>
  //           <div className={classes.stepBar} style={{ width: '80%' }}></div>
  //           <div className={classes.colRightPadding}>
  //             <div className={classes.stepCounter}>
  //               <div className={classes.stepNumber}>4</div>
  //               <div className={classes.stepTotal}>/5</div>
  //             </div>
  //             <div className={classes.fieldsContainer}>
  //               <div className={classes.field}>
  //                 <FormItem
  //                   label="→ Site"
  //                   name="websiteUrl"
  //                   rules={[rules.url()]}
  //                   normalize={(value) => (value === '' ? null : value)}
  //                   customLabelClassName={classes.customLabelOptional}
  //                 >
  //                   <Input
  //                     allowClear
  //                     type="url"
  //                     placeholder={'https://'}
  //                     className={classes.inputHeight}
  //                   />
  //                 </FormItem>
  //               </div>
  //               <div className={classes.field}>
  //                 <FormItem
  //                   label="→ Mail de contact"
  //                   name="contactMail"
  //                   rules={
  //                     userRole === 'admin'
  //                       ? [rules.email()]
  //                       : [rules.required(), rules.email()]
  //                   }
  //                   normalize={(value) => (value === '' ? null : value)}
  //                   customLabelClassName={classes.customLabel}
  //                 >
  //                   <Input
  //                     allowClear
  //                     type="email"
  //                     placeholder={'clothilde@organisation.com'}
  //                     className={classes.inputHeight}
  //                   />
  //                 </FormItem>
  //               </div>
  //               <div className={classes.field}>
  //                 <FormItem
  //                   label="→ Numéro de téléphone"
  //                   name="phoneNumber"
  //                   rules={[rules.phone(), rules.required()]}
  //                   normalize={(value) => (value === '' ? null : value)}
  //                   customLabelClassName={classes.customLabel}
  //                 >
  //                   <Input
  //                     allowClear
  //                     type="tel"
  //                     placeholder={'+33'}
  //                     className={classes.inputHeight}
  //                   />
  //                 </FormItem>
  //               </div>
  //               <div style={{ paddingBottom: '100px' }}>
  //                 {renderUploadLogo()}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className={classes.buttonBox}>
  //           <div className={classes.buttonContainer}>
  //             <Button
  //               type="default"
  //               className={classes.buttonPrevious}
  //               onClick={() => setCurrentStep(3)}
  //             >
  //               ← Précédent
  //             </Button>
  //             <Button
  //               type="default"
  //               className={classes.buttonNext}
  //               onClick={() => handleNextClick()}
  //             >
  //               Suivant →
  //             </Button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  //
  // const validateStep5 = () => {
  //   return new Promise((resolve, reject) => {
  //     form
  //       .validateFields(['linkedinUrl, instagramUrl, facebookUrl, twitterUrl'])
  //       .then(() => {
  //         resolve(true);
  //       })
  //       .catch((errorInfo) => {
  //         reject(errorInfo);
  //       });
  //   });
  // };
  //
  // const renderStep5 = () => {
  //   return (
  //     <div className={classes.stepContainer}>
  //       <div className={classes.stepColLeft5}></div>
  //       <div className={classes.stepColRight}>
  //         <div>
  //           <div className={classes.stepBar} style={{ width: '100%' }}></div>
  //           <div className={classes.colRightPadding}>
  //             <div className={classes.stepCounter}>
  //               <div className={classes.stepNumber}>5</div>
  //               <div className={classes.stepTotal}>/5</div>
  //             </div>
  //             <div className={classes.fieldsContainer}>
  //               <div className={classes.field}>
  //                 <FormItem
  //                   label="→ URL de la page Linkedin"
  //                   name="linkedinUrl"
  //                   rules={[rules.url()]}
  //                   normalize={(value) => (value === '' ? null : value)}
  //                 >
  //                   <Input
  //                     allowClear
  //                     type="url"
  //                     placeholder={'https://'}
  //                     className={classes.inputHeight}
  //                   />
  //                 </FormItem>
  //               </div>
  //               <div className={classes.field}>
  //                 <FormItem
  //                   label="→ URL de la page Instagram"
  //                   name="instagramUrl"
  //                   rules={[rules.url()]}
  //                   normalize={(value) => (value === '' ? null : value)}
  //                 >
  //                   <Input
  //                     allowClear
  //                     type="url"
  //                     placeholder={'https://'}
  //                     className={classes.inputHeight}
  //                   />
  //                 </FormItem>
  //               </div>
  //               <div className={classes.field}>
  //                 <FormItem
  //                   label="→ URL de la page Facebook"
  //                   name="facebookUrl"
  //                   rules={[rules.url()]}
  //                   normalize={(value) => (value === '' ? null : value)}
  //                 >
  //                   <Input
  //                     allowClear
  //                     type="url"
  //                     placeholder={'https://'}
  //                     className={classes.inputHeight}
  //                   />
  //                 </FormItem>
  //               </div>
  //               <div className={classes.field}>
  //                 <FormItem
  //                   label="→ URL de la page Twitter"
  //                   name="twitterUrl"
  //                   rules={[rules.url()]}
  //                   normalize={(value) => (value === '' ? null : value)}
  //                 >
  //                   <Input
  //                     allowClear
  //                     type="url"
  //                     placeholder={'https://'}
  //                     className={classes.inputHeight}
  //                   />
  //                 </FormItem>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className={classes.buttonBox}>
  //           <div className={classes.buttonContainer}>
  //             <Button
  //               type="default"
  //               className={classes.buttonPrevious}
  //               onClick={() => setCurrentStep(4)}
  //             >
  //               ← Précédent
  //             </Button>
  //             <Button
  //               type="default"
  //               className={classes.buttonNext}
  //               onClick={() => handleNextClick()}
  //             >
  //               Suivant →
  //             </Button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div
      className={classes.container}
      onClick={() => {
        if (menu) {
          dispatch(reset());
        }
      }}
    >
      <Row>
        <Col span={5} />
        <Col span={14}>
          <Form
            {...layout}
            form={form}
            layout={'vertical'}
            initialValues={{ remember: true }}
          >
            {userRole === 'admin' && (
              <>
                <Divider style={{ fontSize: '1vw' }} orientation={'left'}>
                  Champs Admin
                </Divider>
                <div className={classes.field}>
                  <FormItem label="Mécènes vérifiés" name="donors">
                    {form.getFieldValue('donors')?.map((donorId: string) => (
                      <Tag key={donorId} className={classes.tag}>
                        {donors.byId[donorId]?.name}
                      </Tag>
                    ))}
                  </FormItem>
                </div>
                <Divider style={{ fontSize: '1vw' }} orientation={'left'}>
                  Champs Non-Admin
                </Divider>
              </>
            )}
            {currentStep === 1 && renderStep1()}
            {currentStep === 2 && renderStep2()}
            {/*{currentStep === 3 && renderStep3()}*/}
            {/*{currentStep === 4 && renderStep4()}*/}
            {/*{currentStep === 5 && renderStep5()}*/}
          </Form>
        </Col>
      </Row>
      {renderAddDonorModal()}
      <TagsSelectorModal
        activityDomains={activityDomains}
        tags={tags}
        isVisible={showTagsModal}
        requestClose={() => setShowTagsModal(false)}
        value={form.getFieldValue('tags')}
        onChange={(value) => form.setFieldsValue({ tags: value })}
      />
      <MoreTagsModal
        values={form.getFieldValue('tags')}
        tags={tags}
        isVisible={showMoreTagsModal}
        requestClose={() => setShowMoreTagsModal(false)}
      />
      <PlacesSelectorModal
        form={form}
        uniqueZipcode={form.getFieldValue('uniqueZipcode')}
        zipcode={form.getFieldValue('zipcode')}
        roadAndNumber={form.getFieldValue('roadAndNumber')}
        city={form.getFieldValue('city')}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
        value={form.getFieldValue('places')}
        onChange={(value) => {
          form.setFieldsValue({ places: value });
        }}
        communes={communes}
      />
    </div>
  );
};

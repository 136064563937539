import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, message, Col, List, Row, Table, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';

import { ListItemClickable } from '../components/ListItemClickable';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { getClassification } from '../slices/classification';
import {
  getDuplicateNgos,
  ignoreDuplicateNgo,
  mergeNgo,
  useMergeNgo,
} from 'src/slices/ngo';

const useStyles = createUseStyles({
  container: {
    height: '100%',
    padding: '20px',
  },
  list: {
    backgroundColor: '#FFFFFF',
  },
  listContainer: {
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
  },
  tableContainer: {
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
  },
  columnHeader: {
    fontWeight: 'bold',
    padding: '16px',
  },
  columnFieldName: {
    fontWeight: 'bold',
  },
  column: {
    padding: '0 !important',
  },
  cellContainer: {
    padding: '16px',
  },
  refresh: {
    display: 'block',
    margin: 'auto',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
    '& span:last-child': {
      width: '90%',
      textOverflow: 'ellipsis',
      // overflow: 'clip',
      whiteSpace: 'nowrap',
      margin: '0 !important',
    },
    '& span:first-child': {
      margin: 'auto',
      height: '2vw',
    },
  },
  button: {
    display: 'block',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
    float: 'right',
  },
});

const fields = [
  'name',
  'description',
  'object',
  'headquarterAddress',
  'creationYear',
  'contactMail',
  'phoneNumber',
  'websiteUrl',
  'linkedinUrl',
  'instagramUrl',
  'facebookUrl',
  'twitterUrl',
  'nbEmployees',
  'nbVolunteers',
];

const isEmptyValue = (value: any) =>
  ['', null, undefined].includes(value) ||
  (Array.isArray(value) && value.length === 0);

export const NgosMergeDuplicates: React.VFC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedFields, setSelectedFields] = useState<
    Record<string, Record<string, boolean>> | undefined
  >(undefined);

  const { duplicateNgos, loading } = useAppSelector(({ ngo }) => ({
    duplicateNgos: ngo.duplicateNgos,
    loading: ngo.loading,
  }));

  const selectedNgo =
    duplicateNgos && selectedIndex > -1 ? duplicateNgos[selectedIndex] : null;

  useEffect(() => {
    if (!duplicateNgos) {
      dispatch(getDuplicateNgos());
    }
    dispatch(getClassification());
  }, []);

  useEffect(() => {
    if (selectedNgo && duplicateNgos) {
      const buildSelectedFields: any = duplicateNgos[selectedIndex].reduce(
        (accumulator, current) => ({
          ...accumulator,
          [current._id]: {},
        }),
        {},
      );

      fields.forEach((fieldName) => {
        selectedNgo.some((ngo: any) => {
          if (!isEmptyValue(ngo[fieldName])) {
            buildSelectedFields[ngo._id][fieldName] = true;
            return true;
          }
        });
      });
      setSelectedFields(buildSelectedFields);
    }
  }, [selectedNgo]);

  const handleUserKeyPress = useCallback(
    (key: KeyboardEvent) => {
      if (key.code === 'Enter' && selectedNgo) {
        handleValidateMerge();
      }
    },
    [selectedFields, selectedNgo],
  );

  useEffect(() => {
    window.addEventListener('keypress', handleUserKeyPress);

    return () => {
      window.removeEventListener('keypress', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const computeFieldValues = (value: any, record: any) => {
    switch (record.fieldName) {
      case 'description':
      case 'object':
        if (!['', null, undefined].includes(value)) {
          return value?.substring(0, 200) + (value?.length > 200 ? '...' : '');
        }
        break;
    }
    return value;
  };

  const handleUseMerge = (selectedId: string) => {
    // Modal.confirm({
    //   content:
    //     'Voulez vous vraiment utiliser les données de ce mécène ? Tous les autres ne seront plus visible.',
    //   onOk: () => {
    if (duplicateNgos && selectedIndex > -1) {
      dispatch(
        useMergeNgo({
          selectedId,
          index: selectedIndex,
        }),
      );
      setSelectedIndex(-1);
      setSelectedFields(undefined);
    }
    // },
    // });
  };

  const handleIgnoreDuplicate = (ignoreId: string) => {
    // Modal.confirm({
    //   content:
    //     'Voulez vous vraiment ignorer ce doublon ? Il ne sera plus proposé dans la fenêtre de fusion.',
    //   onOk: () => {
    if (duplicateNgos && selectedIndex > -1) {
      dispatch(
        ignoreDuplicateNgo({
          ignoreId,
          index: selectedIndex,
        }),
      );
      setSelectedIndex(-1);
      setSelectedFields(undefined);
    }
    //   },
    // });
  };

  const handleValidateMerge = () => {
    // Modal.confirm({
    //   content:
    //     'Voulez vous vraiment valider le merge ? Tous les champs non sélectionnés seront supprimés.',
    //   onOk: () => {
    if (selectedNgo && selectedFields && duplicateNgos) {
      const payload: any = {
        mergeIds: selectedNgo.map((ngo) => ngo._id),
      };

      fields.forEach((fieldName) => {
        selectedNgo.some((ngo, ngoIndex) => {
          const ngoId = ngo._id;

          if (selectedFields[ngoId][fieldName]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload[fieldName] = selectedNgo[ngoIndex][fieldName];
            return true;
          }
        });
        if (!payload[fieldName]) {
          payload[fieldName] = null;
        }
      });

      if (!payload['name']) {
        delete payload['name'];
      }

      dispatch(
        mergeNgo({
          index: selectedIndex,
          id: duplicateNgos[selectedIndex][0]._id,
          payload,
        }),
      ).then((actionResult) => {
        if (mergeNgo.fulfilled.match(actionResult)) {
          message.success(`Merge effectué avec succès`);
        } else {
          message.error(`Erreur du merge. Log dans la console`);
          console.log(actionResult.error);
        }
      });
    }
  };

  const columns = useMemo<ColumnsType<any>>(
    () => [
      {
        dataIndex: 'fieldName',
        className: classes.columnFieldName,
      },
      ...(selectedNgo || []).map((ngo) => ({
        dataIndex: ngo._id,
        title: ngo.name,
        className: classes.column,
        // eslint-disable-next-line react/display-name
        render: ({ value, ngoId }: any, record: any) => {
          const computedValue = computeFieldValues(value, record);

          if (record.key === 'actions') {
            return (
              <div
                style={{
                  margin: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Button
                  style={{ marginBottom: '16px' }}
                  className={classes.button}
                  onClick={() => handleUseMerge(value)}
                >
                  Utiliser ce mécène
                </Button>
                <Button
                  onClick={() => handleIgnoreDuplicate(value)}
                  className={classes.button}
                  type="dashed"
                >
                  Ce n&apos;est pas un doublon
                </Button>
              </div>
            );
          }

          return (
            !isEmptyValue(computedValue) && (
              <ListItemClickable
                className={classes.cellContainer}
                isSelected={
                  !!selectedFields &&
                  selectedFields[ngoId] &&
                  selectedFields[ngoId][record.fieldName]
                }
                selectedColor="#8FBC8F"
                onClick={() => {
                  if (selectedFields) {
                    const otherNgos =
                      selectedNgo
                        ?.filter((ngo) => ngo._id !== ngoId)
                        .map((ngo) => ngo._id) || [];

                    setSelectedFields({
                      [ngoId]: {
                        ...selectedFields[ngoId],
                        [record.fieldName]:
                          !selectedFields[ngoId][record.fieldName],
                      },
                      ...otherNgos.reduce(
                        (accumulator, current) => ({
                          ...accumulator,
                          [current]: {
                            ...selectedFields[current],
                            [record.fieldName]: false,
                          },
                        }),
                        {},
                      ),
                    });
                  }
                }}
              >
                {computedValue}
              </ListItemClickable>
            )
          );
        },
      })),
    ],
    [selectedNgo, selectedFields],
  );
  const dataSource = useMemo(
    () => [
      {
        key: 'actions',
        ...selectedNgo?.reduce(
          (accumulator, current) => ({
            ...accumulator,
            [current._id]: { value: current._id },
          }),
          [],
        ),
      },
      ...fields
        .filter((field) => {
          return selectedNgo?.some((ngo: any) => !isEmptyValue(ngo[field]));
        })
        .map((field) => ({
          key: field,
          fieldName: field,
          ...selectedNgo?.reduce(
            (accumulator, current: any) => ({
              ...accumulator,
              [current._id]: {
                ngoId: current._id,
                value: !Array.isArray(current[field])
                  ? current[field]?.toString()
                  : current[field],
              },
            }),
            {},
          ),
        })),
    ],
    [selectedNgo],
  );

  return (
    <div className={classes.container}>
      <Row style={{ maxHeight: '100%', height: '100%' }}>
        <Col span={5} className={classes.listContainer}>
          <List
            loading={loading}
            className={classes.list}
            bordered
            dataSource={duplicateNgos || []}
            header={
              <>
                <Button
                  style={{ width: '100%' }}
                  className={classes.refresh}
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    setSelectedIndex(-1);
                    setSelectedFields(undefined);
                    dispatch(getDuplicateNgos());
                  }}
                  disabled={loading}
                >
                  Rafraîchir
                </Button>
                {!loading && (
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: '0.875vw' }}
                  >
                    {duplicateNgos?.length} doublon(s)
                  </Typography.Text>
                )}
              </>
            }
            itemLayout="vertical"
            renderItem={(item, i) => (
              <ListItemClickable
                isSelected={!!selectedNgo && selectedNgo[0]._id === item[0]._id}
                onClick={() => {
                  if (i !== selectedIndex) {
                    setSelectedIndex(i);
                  }
                }}
              >
                <Typography.Paragraph
                  ellipsis={{ rows: 1 }}
                  style={{ fontSize: '0.875vw' }}
                >
                  {item[0].name}
                </Typography.Paragraph>
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: '0.875vw' }}
                >
                  {item.length} duplicatas possible
                </Typography.Text>
              </ListItemClickable>
            )}
          />
        </Col>
        <Col className={classes.tableContainer} span={19}>
          {selectedNgo && selectedFields && (
            <>
              <Table
                columns={columns}
                bordered
                dataSource={dataSource}
                pagination={false}
              />
              <Button
                type="primary"
                style={{ marginTop: '16px' }}
                className={classes.button}
                onClick={handleValidateMerge}
              >
                Valider le merge
              </Button>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

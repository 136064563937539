function trackIntercomEvent(event, metadata) {
  if (typeof window.Intercom === 'function') {
    window.Intercom('trackEvent', event, metadata);
  }
}

export const LoggedIntercom = (userName, userEmail, userId, eventName) => {
  window.onload = function () {
    window.intercomSettings = {
      /* eslint-disable camelcase */
      api_base: 'https://api-iam.intercom.io',
      /* eslint-disable camelcase */
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      /* eslint-disable camelcase */
      name: userName, // Full name
      /* eslint-disable camelcase */
      email: userEmail, // Email address
      /* eslint-disable camelcase */
      user_id: userId, // User ID
      /* eslint-disable camelcase */
      hide_default_launcher: true,
    };
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/vxvwn1vz'
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}`;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  };
  if (eventName !== undefined) {
    trackIntercomEvent(eventName, { data: 'test' });
  }
};

import React, { useEffect, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { Button, Form, Input, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { theme } from '../constants/theme';
import { useAppSelector } from '../hooks/store';
import { signInUpGoogle } from '../slices/auth';

import { useFormRules } from '../hooks/useFormRules';
import { FormItem } from 'src/components/FormItem';
import { LockOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface Props {
  onFinish: (values: any) => void;
  error?: string;
  setError: (error?: string) => void;
  dispatch: any;
  organisationTypeValue: 'MECENE' | 'PROJECT_HOLDER' | undefined;
  setOrganisationTypeValue: (
    value: 'MECENE' | 'PROJECT_HOLDER' | undefined,
  ) => void;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepContainer: {
    marginBottom: '1rem',
    minHeight: '30vw',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  stepColLeft: {
    backgroundImage: `url("./images/signUpStep.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  stepColRight: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '5vw',
    marginLeft: '50vw',
    position: 'relative',
  },
  stepImage: {
    width: '100%',
  },
  colRightPadding: {
    marginLeft: '1rem',
    marginRight: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.7vw',
  },
  customLabel: {
    position: 'relative',
    fontSize: '0.85vw',
    '&::after': {
      content: '"*"',
      color: '#ff4d4f',
      fontSize: '1.2vw',
      marginLeft: '0.2vw',
    },
  },
  customLabelOptional: {
    fontSize: '0.85vw',
    position: 'relative',
  },
  buttonPrevious: {
    height: '3vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: '70%',
  },
  fieldDisabled: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: '70%',
    height: '0',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  googleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.4vw',
  },
  form: {
    width: '100%',
    height: '100%',
    padding: '0 1rem',
  },
  inputHeight: {
    height: '2vw',
    fontSize: '1vw',
    border: 'none',
    borderBottom: '1px solid #B6B6B6',
    '& textarea': {
      borderColor: '#5050f6',
      borderBottom: 'none',
      color: theme.palette.primary,
    },
  },
});

export const SignUpForm: React.VFC<Props> = ({
  onFinish,
  error,
  setError,
  dispatch,
  organisationTypeValue,
  setOrganisationTypeValue,
}) => {
  const { t } = useTranslation();
  const rules = useFormRules();
  const classes = useStyles();

  const { loading } = useAppSelector(({ auth: { loading } }) => ({
    loading,
  }));

  function vwToPixels(vw: number) {
    return (vw * window.innerWidth) / 100;
  }

  const [googleLoginWidth, setGoogleLoginWidth] = useState(vwToPixels(14));

  useEffect(() => {
    const handleResize = () => {
      setGoogleLoginWidth(vwToPixels(14));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const responseGoogle = async (response: any) => {
    if (error) {
      setError(undefined);
    }

    if (response.credential) {
      const resultAction = await dispatch(
        signInUpGoogle({
          googleAccessToken: response.credential,
          organisationTypeValue: organisationTypeValue,
        }),
      );
      if (signInUpGoogle.rejected.match(resultAction)) {
        setError(resultAction.error.message);
      } else {
        window.dataLayer.push({
          event: 'signed_up',
        });
      }
    }
  };

  return (
    <div className={classes.container}>
      <Row>
        <Form
          {...formItemLayout}
          initialValues={{ organisationType: organisationTypeValue }}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
          layout={'vertical'}
          className={classes.form}
        >
          <div className={classes.stepContainer}>
            <div className={classes.stepColLeft}></div>
            <div className={classes.stepColRight}>
              <div>
                <div className={classes.colRightPadding}>
                  <div>
                    <Button
                      type="primary"
                      onClick={() => setOrganisationTypeValue(undefined)}
                      className={classes.buttonPrevious}
                      style={{ width: '8vw' }}
                    >
                      Retour
                    </Button>
                  </div>
                  <div>
                    <Text style={{ fontSize: '2vw' }}>
                      Inscrivez-vous chez Sonar
                    </Text>
                  </div>
                  <div>
                    <Text style={{ fontSize: '1vw' }}>
                      Profitez à 100% de votre expérience Sonar en vous
                      inscrivant
                    </Text>
                  </div>
                  <div className={classes.fieldsContainer}>
                    <div className={classes.field}>
                      <FormItem
                        name="email"
                        rules={[rules.required(), rules.email()]}
                        customLabelClassName={classes.customLabel}
                      >
                        <Input
                          placeholder="E-Mail"
                          className={classes.inputHeight}
                        />
                      </FormItem>
                    </div>
                    <div className={classes.field}>
                      <FormItem
                        name="password"
                        rules={[rules.required(), rules.min(10)]}
                        hasFeedback
                        customLabelClassName={classes.customLabel}
                      >
                        <Input
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder={t('common.password')}
                          className={classes.inputHeight}
                        />
                      </FormItem>
                    </div>
                    <div className={classes.field}>
                      <FormItem
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          rules.required(),
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  t('forms.errorConfirmPasswordNotMatch'),
                                ),
                              );
                            },
                          }),
                        ]}
                        customLabelClassName={classes.customLabel}
                      >
                        <Input
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder={t('forms.confirmPassword')}
                          className={classes.inputHeight}
                        />
                      </FormItem>
                    </div>
                    <div className={classes.field}>
                      <FormItem
                        name="firstName"
                        rules={[rules.required({ whitespace: true })]}
                        customLabelClassName={classes.customLabel}
                      >
                        <Input
                          placeholder={t('common.firstName')}
                          className={classes.inputHeight}
                        />
                      </FormItem>
                    </div>
                    <div className={classes.field}>
                      <FormItem
                        name="lastName"
                        rules={[rules.required({ whitespace: true })]}
                        customLabelClassName={classes.customLabel}
                      >
                        <Input
                          placeholder={t('common.lastName')}
                          className={classes.inputHeight}
                        />
                      </FormItem>
                    </div>
                    {/*<Form.Item*/}
                    {/*  name="organisationName"*/}
                    {/*  label={t('common.organisationName')}*/}
                    {/*  rules={[rules.required({ whitespace: true })]}*/}
                    {/*>*/}
                    {/*  <Input />*/}
                    {/*</Form.Item>*/}
                    <div className={classes.fieldDisabled}>
                      <FormItem
                        name="organisationType"
                        rules={[rules.required({ whitespace: true })]}
                        customLabelClassName={classes.customLabel}
                      ></FormItem>
                    </div>
                    <div className={classes.field}>
                      <FormItem
                        name="phoneNumber"
                        rules={[rules.phone()]}
                        customLabelClassName={classes.customLabelOptional}
                      >
                        <Input
                          placeholder={"Numéro de téléphone de l'utilisateur"}
                          className={classes.inputHeight}
                        />
                      </FormItem>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'start',
                        flexDirection: 'column',
                        marginTop: '0.5vw',
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className={classes.buttonPrevious}
                        style={{ width: '16vw' }}
                        onClick={() => {
                          window.dataLayer.push({
                            event: 'signed_up',
                          });
                        }}
                      >
                        {t('common.register')}
                      </Button>
                      <div>
                        <Text type="danger">
                          {error &&
                            t(
                              `pages.SignUp.errors.${error}`,
                              t('common.errorGeneric'),
                            )}
                          &nbsp;
                        </Text>
                      </div>
                    </div>
                    <div className={classes.googleContainer}>
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          responseGoogle(credentialResponse);
                        }}
                        onError={() => {
                          responseGoogle({ error: 'error' });
                          console.log('Login Failed');
                        }}
                        text="signup_with"
                        locale="fr"
                        size="large"
                        width={googleLoginWidth.toString()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Row>
    </div>
  );
};

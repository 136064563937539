import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { getDealFlowCard } from './dealFlowCard';

export type DealFlowNote = {
  _id: string;
  content: string;
  author: string;
  card: string;
  createdAt: number;
};

export type createDealFlowNoteDto = {
  content: string;
  author: string;
  card: string;
};

export type updateDealFlowNoteDto = {
  _id: string;
  content?: string;
  author?: string;
  card?: string;
};

interface NoteState {
  notes: Record<string, DealFlowNote>;
  saved: boolean;
  loading: boolean;
  updating: boolean;
  error: any;
}

const initialState: NoteState = {
  notes: {},
  saved: false,
  loading: false,
  updating: false,
  error: null,
};

export const createDealFlowNote = createAsyncThunk(
  'createDealFlowNote',
  async (
    payload: { body: createDealFlowNoteDto; dealFlowId: string },
    { dispatch },
  ) => {
    const response = await apiRequest<DealFlowNote>(
      'POST',
      '/dealFlow/note',
      undefined,
      payload.body,
    );
    if (response) dispatch(getDealFlowCard(payload.body.card));
    return response;
  },
);

export const updateDealFlowNote = createAsyncThunk(
  'updateDealFlowNote',
  async (payload: {
    body: updateDealFlowNoteDto;
    noteId: string;
    dealFlowId: string;
  }) => {
    return await apiRequest<DealFlowNote>(
      'PATCH',
      `/dealFlow/card/${payload.noteId}`,
      undefined,
      payload.body,
    );
  },
);

export const getDealFlowNotes = createAsyncThunk(
  'getDealFlowNotes',
  async (cardId: string | undefined) => {
    // return dealflow;
    return await apiRequest<DealFlowNote[]>(
      'GET',
      `/dealFlow/card/${cardId}/notes`,
    );
  },
);

const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDealFlowNote.pending, (state: NoteState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(
        createDealFlowNote.fulfilled,
        (state: NoteState, { payload }) => {
          state.loading = false;
          state.saved = false;
          state.notes[payload._id] = payload;
        },
      )
      .addCase(createDealFlowNote.rejected, (state: NoteState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updateDealFlowNote.pending, (state: NoteState) => {
        state.updating = true;
      })
      .addCase(
        updateDealFlowNote.fulfilled,
        (state: NoteState, { payload }) => {
          state.saved = true;
          state.notes[payload._id] = payload;
          state.updating = false;
        },
      )
      .addCase(updateDealFlowNote.rejected, (state: NoteState, { payload }) => {
        state.saved = false;
        state.updating = false;
        state.error = payload;
      })
      .addCase(getDealFlowNotes.pending, (state: NoteState) => {
        state.updating = true;
      })
      .addCase(getDealFlowNotes.fulfilled, (state: NoteState, { payload }) => {
        const notes = payload.reduce(
          (
            accumulator: Record<string, DealFlowNote>,
            current: DealFlowNote,
          ) => ({ ...accumulator, [current._id]: current }),
          {},
        );
        Object.assign(state.notes, notes);
        state.updating = false;
        state.updating = false;
      })
      .addCase(getDealFlowNotes.rejected, (state: NoteState, { payload }) => {
        state.saved = false;
        state.updating = false;
        state.error = payload;
      });
  },
});

export default noteSlice.reducer;

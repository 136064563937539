import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import {
  apiRequest,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from '../helpers/api';

export const USERS_MAX_RESULTS = 10;

export enum PremiumFilter {
  ALL,
  FREE,
  PREMIUM,
}

export type SearchUsersParameters = {
  firstName?: string;
  lastName?: string;
  email?: string;
  premiumFilter: PremiumFilter;
  offset: number;
  limit: number;
  organisationType?: string;
  startDate?: string;
  endDate?: string;
};

type SearchResults = {
  items: User[];
  totalItems: number;
};

export type NotificationSettings = {
  emailNewDonorMatchProject?: boolean;
  emailCallForTendersAddedFromProject?: boolean;
  emailAdminSummary?: boolean;
};

export type UpdateUserDto = {
  hasValidSubscription?: boolean;
  disabled?: boolean;
  maxNumberOfProjects?: number;
  ngoId?: string;
  donorId?: string;
  organisationType?: 'MECENE' | 'PROJECT_HOLDER';
  phoneNumber?: string;
};

export interface User {
  _id: string;
  firstName?: string;
  lastName?: string;
  profilePictureUrl?: string;
  email?: string;
  ngoId?: string;
  donorId?: string;
  hasValidSubscription?: boolean;
  disabled?: boolean;
  role: string;
  notificationSettings?: NotificationSettings;
  maxNumberOfProjects?: number;
  organisationType?: 'MECENE' | 'PROJECT_HOLDER';
  organisationName: string;
  phoneNumber?: string;
  isPremium?: boolean;
}

interface Tokens {
  accessToken: string;
  refreshToken?: string;
}

export type LoadingStatus = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface AuthState {
  user: User | null;
  users: { [userId: string]: User };
  userNbConnections: number | null;
  userCount: number | null;
  userNgoCount: number | null;
  userNgoIdCount: number | null;
  userDonorIdCount: number | null;
  userDonorCount: number | null;
  members: User[] | null;
  isLogged: boolean;
  loading: boolean;
  loadingUsersCount: LoadingStatus;
  isInitialLoading: boolean;
  error: string | null;
  searchResult: {
    resultsUsers: User[];
    resultsCount: number;
  } | null;
  offset: number;
}

const initialState: AuthState = {
  isLogged: false,
  loading: false,
  loadingUsersCount: 'idle',
  isInitialLoading: true,
  user: null,
  users: {},
  userNbConnections: 0,
  userCount: 0,
  userNgoCount: 0,
  userNgoIdCount: 0,
  userDonorIdCount: 0,
  userDonorCount: 0,
  members: null,
  error: null,
  searchResult: null,
  offset: 0,
};

const setAccessRefreshTokenAndGetUser = async (
  accessToken: string,
  refreshToken?: string,
) => {
  setAccessRefreshToken(accessToken, refreshToken);
  const user = await getUser();

  await incrementUserNbConnections({
    _id: user._id,
  });

  return getUser();
};

const getUser = async () => {
  return apiRequest<User>('GET', '/auth/user');
};

const setAccessRefreshToken = (accessToken: string, refreshToken?: string) => {
  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);
  if (refreshToken) {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken);
  }
};

/* Thunks */

export const fetchUserNbConnections = createAsyncThunk(
  'fetchUserNbConnections',
  async (_id: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ nbConnections: number }>(
        'GET',
        `/user/${_id}/nbConnections`,
      );
      const nbConnections = Number(result);
      dispatch(authSlice.actions.setUserNbConnections(nbConnections));
      console.log(result.nbConnections);
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const incrementUserNbConnections = createAsyncThunk(
  'incrementNbConnections',
  async (payload: { _id: string }) => {
    const result = await apiRequest<number>(
      'PATCH',
      `/user/${payload._id}/incrementNbConnections`,
    );
    return result;
  },
);

export const fetchUsersCount = createAsyncThunk(
  'fetchUsersCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>('GET', '/user/count');
      dispatch(authSlice.actions.setUsersCount(Number(result)));
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchUsersNgoCount = createAsyncThunk(
  'fetchUsersNgoCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/user/countngo',
      );
      dispatch(authSlice.actions.setUsersNgoCount(Number(result)));
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchUsersNgoIdCount = createAsyncThunk(
  'fetchUsersNgoIdCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/user/countngoId',
      );
      dispatch(authSlice.actions.setUsersNgoIdCount(Number(result)));
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchUsersDonorIdCount = createAsyncThunk(
  'fetchUsersDonorIdCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/user/countdonorId',
      );
      dispatch(authSlice.actions.setUsersDonorIdCount(Number(result)));
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchUsersDonorCount = createAsyncThunk(
  'fetchUsersDonorCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/user/countdonor',
      );
      dispatch(authSlice.actions.setUsersDonorCount(Number(result)));
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const updateUser = createAsyncThunk(
  'updateUser',
  async (
    payload: { updateUserDto: UpdateUserDto; id: string },
    { getState, dispatch },
  ) => {
    const { auth } = getState() as { auth: AuthState };
    const result = await apiRequest<User>(
      'PATCH',
      `/user/${payload.id}`,
      undefined,
      payload.updateUserDto,
    );
    if (payload.id === auth?.user?._id) {
      dispatch(fetchCurrentUser());
    }
    return result;
  },
);

export const updateUserNgoId = createAsyncThunk(
  'updateUserNgoId',
  async (
    payload: { updateUserDto: UpdateUserDto; id: string },
    { getState, dispatch },
  ) => {
    const { auth } = getState() as { auth: AuthState };
    const result = await apiRequest<User>(
      'PATCH',
      `/user/${payload.id}/ngo`,
      undefined,
      payload.updateUserDto,
    );
    if (payload.id === auth?.user?._id) {
      dispatch(fetchCurrentUser());
    }
    return result;
  },
);

export const updateUserDonorId = createAsyncThunk(
  'updateUserDonorId',
  async (
    payload: { updateUserDto: UpdateUserDto; id: string },
    { getState, dispatch },
  ) => {
    const { auth } = getState() as { auth: AuthState };
    const result = await apiRequest<User>(
      'PATCH',
      `/user/${payload.id}/donor`,
      undefined,
      payload.updateUserDto,
    );
    if (payload.id === auth?.user?._id) {
      dispatch(fetchCurrentUser());
    }
    return result;
  },
);

export const sendWelcomeEmail = createAsyncThunk(
  'sendWelcomeEmail',
  async (
    payload: {
      userId: string;
      donorId?: string;
      organizationType: 'DONOR' | 'PROJECT_HOLDER';
      ngoId?: string;
    },
    {},
  ) => {
    return await apiRequest<boolean>(
      'POST',
      `/user/${payload.userId}/${
        payload.organizationType === 'DONOR' ? 'donor' : 'ngo'
      }/welcome-email`,
      undefined,
      payload.organizationType === 'DONOR'
        ? { donorId: payload.donorId }
        : { ngoId: payload.ngoId },
    );
  },
);

export const assignOrganizationType = createAsyncThunk(
  'assignOrganizationType',
  async (payload: { updateUserDto: UpdateUserDto }, { getState, dispatch }) => {
    const { auth } = getState() as { auth: AuthState };
    const result = await apiRequest<User>(
      'PATCH',
      `/user/${auth.user?._id}/change-organisation-type`,
      undefined,
      payload.updateUserDto,
    );
    dispatch(fetchCurrentUser());
    return result;
  },
);

export const deleteUser = createAsyncThunk(
  'deleteUser',
  async (
    payload: { userId: string; searchPayload: SearchUsersParameters },
    { dispatch },
  ) => {
    const result = await apiRequest<{ status: boolean }>(
      'DELETE',
      `/user/${payload.userId}`,
    );
    dispatch(getUsersMoreInformations(payload.searchPayload));
    return result;
  },
);

export const fetchCurrentUser = createAsyncThunk(
  'auth/fetchCurrentUser',
  async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

    if (!accessToken) {
      return null;
    }

    try {
      return await apiRequest<User>('GET', '/auth/user');
    } catch (error) {
      return null;
    }
  },
);

export const findUserById = createAsyncThunk(
  'auth/findUserById',
  async (payload: { userId: string }, { dispatch, getState }) => {
    try {
      const result = await apiRequest<User>('GET', `/user/${payload.userId}`);
      const state: AuthState | null = getState() as AuthState;
      if (payload.userId === state?.user?._id) {
        dispatch(fetchCurrentUser());
      }
      return result;
    } catch (error) {
      return null;
    }
  },
);

export const getUsers = createAsyncThunk('auth/getUsers', async () => {
  try {
    return await apiRequest<User[]>('GET', `/user/`);
  } catch (error) {
    return null;
  }
});

export const getUsersMoreInformations = createAsyncThunk(
  'auth/getUsersMoreInformations',
  async (payload: SearchUsersParameters) => {
    try {
      return {
        ...(await apiRequest<SearchResults>(
          'GET',
          `/user/moreInformations`,
          payload,
        )),
        offset: payload.offset,
      };
    } catch (error) {
      return null;
    }
  },
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (payload: { email: string; password: string }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      '/auth/signIn',
      undefined,
      payload,
    );

    const result = await setAccessRefreshTokenAndGetUser(
      accessToken,
      refreshToken,
    );

    return result;
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({
    email,
    password,
    firstName,
    lastName,
    organisationName,
    organisationType,
    isContributor,
    phoneNumber,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    organisationName: string;
    organisationType: 'MECENE' | 'PROJECT_HOLDER';
    isContributor?: boolean;
    phoneNumber?: string;
  }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      `/auth/signUp${isContributor ? '/contributor' : ''}`,
      undefined,
      {
        email,
        password,
        firstName,
        lastName,
        organisationName,
        organisationType,
        phoneNumber,
      },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const signInUpGoogle = createAsyncThunk(
  'auth/signInUpGoogle',
  async ({
    googleAccessToken,
    isContributor,
    organisationTypeValue,
  }: {
    googleAccessToken: string;
    isContributor?: boolean;
    organisationTypeValue?: 'MECENE' | 'PROJECT_HOLDER' | undefined;
  }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      `/auth/google${isContributor ? '/contributor' : ''}`,
      undefined,
      {
        // eslint-disable-next-line camelcase
        access_token: googleAccessToken,
        organisationTypeValue: organisationTypeValue,
      },
    );
    return setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const sendLostPassword = createAsyncThunk(
  'auth/sendLostPassword',
  async (payload: { email: string }) => {
    await apiRequest<{ status: boolean }>(
      'POST',
      '/auth/lostPassword',
      undefined,
      payload,
    );
  },
);

export const useLostPasswordToken = createAsyncThunk(
  'auth/useLostPasswordToken',
  async (payload: { token: string; password: string }) => {
    const { token, password } = payload;
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      `/auth/lostPassword/${encodeURIComponent(token)}`,
      undefined,
      { password },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const updateUserSettings = createAsyncThunk(
  'updateCallForTenders',
  async (updateUserSettingsDto: NotificationSettings) => {
    return await apiRequest<User>(
      'PATCH',
      `/user/notificationSettings`,
      undefined,
      updateUserSettingsDto,
    );
  },
);

/* Shared reducers */
const signInUpPendingReducer = (state: AuthState) => {
  state.isLogged = false;
  state.loading = true;
  state.error = null;
  state.user = null;
};

const signInUpFulfilledReducer = (
  state: AuthState,
  { payload }: { payload: User },
) => {
  state.isLogged = true;
  state.loading = false;
  state.user = payload;
};

const pendingReducer = (state: AuthState) => {
  state.loading = true;
  state.error = null;
};

const fulfilledReducer = (state: AuthState) => {
  state.loading = false;
};

const rejectedReducer = (
  state: AuthState,
  { error }: { error: SerializedError },
) => {
  state.loading = false;
  state.error = error.message || 'error';
};

/* Slice */

const receiveUsersReducer = (
  state: AuthState,
  { payload }: { payload: any },
) => {
  state.loading = false;
  if (payload) {
    state.offset = payload.offset;
    state.searchResult = {
      resultsCount: payload.totalItems,
      resultsUsers: payload.items,
    };
  }
};

const authSlice = createSlice({
  name: 'auth',
  reducers: {
    logout(state: AuthState) {
      state.user = null;
      state.isLogged = false;
      state.loading = false;

      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
      localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
      window.location.reload();
    },
    setUserNbConnections: (state, action: PayloadAction<number>) => {
      state.userNbConnections = action.payload;
    },
    setUsersCount: (state, action: PayloadAction<number>) => {
      state.userCount = action.payload;
    },
    setUsersNgoCount: (state, action: PayloadAction<number>) => {
      state.userNgoCount = action.payload;
    },
    setUsersNgoIdCount: (state, action: PayloadAction<number>) => {
      state.userNgoIdCount = action.payload;
    },
    setUsersDonorIdCount: (state, action: PayloadAction<number>) => {
      state.userDonorIdCount = action.payload;
    },
    setUsersDonorCount: (state, action: PayloadAction<number>) => {
      state.userDonorCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state: AuthState) => {
        state.isInitialLoading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state: AuthState, { payload }) => {
        state.isInitialLoading = false;
        state.user = payload;
        state.isLogged = !!payload;
      })
      .addCase(findUserById.pending, (state: AuthState) => {
        state.loading = true;
      })
      .addCase(findUserById.fulfilled, (state: AuthState, { payload }) => {
        if (payload) state.users = { ...state.users, [payload._id]: payload };
        state.loading = false;
      })
      .addCase(getUsers.pending, (state: AuthState) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state: AuthState, { payload }) => {
        if (!payload) return;
        payload.forEach((user) => {
          if (state.users) state.users[user._id] = user;
        });
      })
      .addCase(getUsers.rejected, rejectedReducer)
      .addCase(updateUser.pending, (state: AuthState) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state: AuthState, { payload }) => {
        if (!payload) return;
        state.users[payload._id] = payload;
      })
      .addCase(updateUser.rejected, rejectedReducer)
      .addCase(incrementUserNbConnections.pending, (state: AuthState) => {
        state.loading = true;
      })
      .addCase(incrementUserNbConnections.fulfilled, (state: AuthState) => {
        state.loading = false;
      })
      .addCase(incrementUserNbConnections.rejected, rejectedReducer)
      .addCase(updateUserNgoId.pending, (state: AuthState) => {
        state.loading = true;
      })
      .addCase(updateUserNgoId.fulfilled, (state: AuthState, { payload }) => {
        if (!payload) return;
        state.users[payload._id] = payload;
      })
      .addCase(updateUserNgoId.rejected, rejectedReducer)
      .addCase(updateUserDonorId.pending, (state: AuthState) => {
        state.loading = true;
      })
      .addCase(updateUserDonorId.fulfilled, (state: AuthState, { payload }) => {
        if (!payload) return;
        state.users[payload._id] = payload;
      })
      .addCase(updateUserDonorId.rejected, rejectedReducer)
      .addCase(sendWelcomeEmail.pending, (state: AuthState) => {
        state.loading = true;
      })
      .addCase(sendWelcomeEmail.fulfilled, (state: AuthState) => {
        state.loading = false;
      })
      .addCase(sendWelcomeEmail.rejected, rejectedReducer)
      .addCase(getUsersMoreInformations.pending, pendingReducer)
      .addCase(getUsersMoreInformations.fulfilled, receiveUsersReducer)
      .addCase(getUsersMoreInformations.rejected, rejectedReducer)
      .addCase(signIn.pending, signInUpPendingReducer)
      .addCase(signIn.fulfilled, signInUpFulfilledReducer)
      .addCase(signIn.rejected, rejectedReducer)
      .addCase(signUp.pending, signInUpPendingReducer)
      .addCase(signUp.fulfilled, signInUpFulfilledReducer)
      .addCase(signUp.rejected, rejectedReducer)
      .addCase(signInUpGoogle.pending, signInUpPendingReducer)
      .addCase(signInUpGoogle.fulfilled, signInUpFulfilledReducer)
      .addCase(signInUpGoogle.rejected, rejectedReducer)
      .addCase(sendLostPassword.pending, pendingReducer)
      .addCase(sendLostPassword.fulfilled, fulfilledReducer)
      .addCase(sendLostPassword.rejected, rejectedReducer)
      .addCase(useLostPasswordToken.pending, pendingReducer)
      .addCase(useLostPasswordToken.fulfilled, signInUpFulfilledReducer)
      .addCase(useLostPasswordToken.rejected, rejectedReducer)
      .addCase(updateUserSettings.rejected, rejectedReducer)
      .addCase(updateUserSettings.pending, pendingReducer)
      .addCase(
        updateUserSettings.fulfilled,
        (state: AuthState, { payload }) => {
          state.user?.notificationSettings
            ? (state.user.notificationSettings = payload.notificationSettings)
            : {};
        },
      )
      .addCase(deleteUser.pending, pendingReducer)
      .addCase(deleteUser.fulfilled, fulfilledReducer)
      .addCase(deleteUser.rejected, rejectedReducer)
      .addCase(fetchUserNbConnections.pending, pendingReducer)
      .addCase(fetchUserNbConnections.rejected, rejectedReducer)
      .addCase(fetchUserNbConnections.fulfilled, fulfilledReducer)
      .addCase(fetchUsersCount.pending, pendingReducer)
      .addCase(fetchUsersCount.rejected, rejectedReducer)
      .addCase(fetchUsersCount.fulfilled, fulfilledReducer)
      .addCase(fetchUsersNgoCount.pending, pendingReducer)
      .addCase(fetchUsersNgoCount.rejected, rejectedReducer)
      .addCase(fetchUsersNgoCount.fulfilled, fulfilledReducer)
      .addCase(fetchUsersNgoIdCount.pending, pendingReducer)
      .addCase(fetchUsersNgoIdCount.rejected, rejectedReducer)
      .addCase(fetchUsersNgoIdCount.fulfilled, fulfilledReducer)
      .addCase(fetchUsersDonorIdCount.pending, pendingReducer)
      .addCase(fetchUsersDonorIdCount.rejected, rejectedReducer)
      .addCase(fetchUsersDonorIdCount.fulfilled, fulfilledReducer)
      .addCase(fetchUsersDonorCount.pending, pendingReducer)
      .addCase(fetchUsersDonorCount.rejected, rejectedReducer)
      .addCase(fetchUsersDonorCount.fulfilled, fulfilledReducer);
  },
  initialState,
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;

import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { AutoComplete, Input } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { debounce } from 'lodash';
// Components never must use `useSelector` or `useDispatch`. This functions must be implemented in containers or
// pages

const { Search } = Input;

export type SearchFiltersParameters = {
  name?: string;
};

interface Props {
  onSearch: (searchFiltersParameters: SearchFiltersParameters) => void;
  searchParameters: SearchFiltersParameters;
  onChangeName?: (value: string) => void;
  setSearchFilters: React.Dispatch<
    React.SetStateAction<SearchFiltersParameters>
  >;
}

const useStyles = createUseStyles({
  container: {
    padding: '2% 0 2% 3%',
  },
  searchBar: {
    width: '28vw',
    display: 'flex',
    '& .ant-select .ant-select-selector': {
      height: '2.5vw',
      webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      borderRadius: '0.25vw',
    },
    '& .ant-input-affix-wrapper': {
      height: '2.5vw',
      padding: '1.14% 3.13%',
      borderRadius: '0.25vw',
      '& .ant-input': {
        fontSize: '0.875vw',
      },
    },
    '& .anticon-search': {
      fontSize: '1.75vw',
      color: '#ccc9e6',
    },
    '& .ant-input-group-addon': {
      verticalAlign: 'top',
      backgroundColor: 'transparent',
      fontSize: '0.875vw',
      lineHeight: 1,
      '& .ant-btn': {
        borderLeft: 'none',
        height: '2.5vw',
        width: '2.5vw',
        borderTopRightRadius: '0.25vw !important',
        borderBottomRightRadius: '0.25vw !important',
      },
    },
    '& .ant-btn': {
      lineHeight: '1',
    },
  },
});

const debouncedSearchCall = debounce((searchFunction: any, value: any) => {
  searchFunction(value);
}, 50);

export const NgoTableSearchBar: React.VFC<Props> = ({
  onSearch,
  searchParameters,
  onChangeName,
}) => {
  const classes = useStyles();

  const onChangeField = (field: string, value: string | SelectValue) => {
    debouncedSearchCall(onSearch, { ...searchParameters, [field]: value });
  };

  const renderSearchBar = () => (
    <div className={classes.searchBar}>
      <AutoComplete
        style={{ width: '85.49%' }}
        onSelect={(value: any) => onChangeField('name', value)}
        value={searchParameters.name}
        onSearch={(value) => onChangeField('name', value)}
      >
        <Search
          allowClear
          onChange={(e) => onChangeName && onChangeName(e.target.value)}
          onSearch={(value) => onChangeField('name', value)}
        />
      </AutoComplete>
    </div>
  );

  const ref = useRef(null);

  return (
    <div ref={ref} className={classes.container}>
      {renderSearchBar()}
    </div>
  );
};

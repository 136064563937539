import { Popover, PopoverProps, Tag } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { ClassificationElement } from '../slices/classification';

interface Props {
  elementIds: string[];
  collection: ClassificationElement[];
  collectionName: string;
  children: React.ReactNode;
  highlightedIds?: string[];
}

const useStyles = createUseStyles({
  tag: {
    '&.ant-tag': {
      margin: '0.093vw 0 0.093vw',
      cursor: 'default',
      border: 'none',
      padding: '0.1875vw 0.5vw',
      fontSize: '0.75vw',
      lineHeight: '1vw',
      whiteSpace: 'nowrap',
      marginLeft: '0.25vw',
      marginRight: '0.25vw',
    },
  },
  popoverTags: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
});

export const PopoverTagList: React.VFC<Props & PopoverProps> = ({
  elementIds,
  collection,
  collectionName,
  children,
  highlightedIds,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const elements = useMemo<ClassificationElement[]>(
    () =>
      elementIds?.reduce(
        (elementList: ClassificationElement[], elementId: string) => {
          const element = collection.find(
            (element: ClassificationElement) => element._id === elementId,
          );
          if (element) {
            elementList.push(element);
          }
          return elementList;
        },
        [],
      ),
    [elementIds, collection],
  );

  const renderTag = (tag: ClassificationElement) => (
    <Tag
      className={classes.tag}
      key={tag._id}
      style={
        highlightedIds && highlightedIds.includes(tag._id)
          ? { backgroundColor: 'rgba(0,189,156,0.66)' }
          : undefined
      }
    >
      {t(`${collectionName}:${tag.name}`)}
    </Tag>
  );

  if (!collection) return null;

  return (
    <Popover
      {...rest}
      content={
        <div className={classes.popoverTags}>
          {elements
            ?.sort((a, b) => {
              if (highlightedIds?.includes(a._id)) {
                return -1;
              }
              if (highlightedIds?.includes(b._id)) {
                return 1;
              }
              return -1;
            })
            ?.map((element) => renderTag(element))}
        </div>
      }
    >
      {children}
    </Popover>
  );
};

import { createAddRemoveObject } from '@goodwave/utils';
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { AuthState } from './auth';

enum FinancingNeedAction {
  GET_FINANCING_NEEDS = 'getFinancingNeeds',
  GET_FINANCING_NEED = 'getFinancingNeed',
  SAVE_FINANCING_NEED = 'saveFinancingNeed',
  ADD_DONOR = 'addDonor',
  REMOVE_DONOR = 'removeDonor',
}

export const FINANCING_NEEDS_MAX_RESULTS = 10;

interface FinancingNeedEvent {
  action: FinancingNeedAction;
  message?: string;
  error?: any;
}

export enum Offer {
  visibility,
  complete,
}

export const offersAsArray = [
  { enumName: 'visibility', label: 'visibilité', value: Offer.visibility },
  { enumName: 'complete', label: 'nationale', value: Offer.complete },
];

type SearchResults = {
  items: FinancingNeed[];
  totalItems: number;
};

export type FinancingNeedOffer = {
  offer: Offer;
  expirationDate?: Date;
  subscriptionId?: string;
};

export type FinancingNeed = {
  _id: string;
  name: string;
  description: string;
  dealFlow: string;
  amountNeeded: string;
  currency: string;
  adminNgos: { _id: string; name: string }[];
  memberNgos: string[];
  tags: string[];
  places: string[];
  zipcode: string;
  city: string;
  roadAndNumber: string;
  uniqueZipcode: string;
  sustainableDevelopmentGoals?: [string];
  activityDomains: [string];
  targetPopulations: [string];
  activityZones?: [string];
  donors: string[];
  bannerImageUrl: string;
  logoUrl?: string | null;
  websiteUrl?: string;
  offers: FinancingNeedOffer[];
  expirationDate: Date;
  published: boolean;
};

export type SearchProjectParametersAdmin = {
  id?: string;
  name?: string;
  tags?: string[];
  adminNgo?: string;
  places?: string[];
  zipcode?: string;
  city?: string;
  activityDomains?: string[];
  activityZones?: string[];
  targetPopulations?: string[];
  amountNeeded?: number;
  amountNeededStart?: number;
  amountNeededEnd?: number;
  offers?: FinancingNeedOffer[];
  published?: boolean;
  createdDateStart?: Date;
  createdDateEnd?: Date;
  updatedDateStart?: Date;
  updatedDateEnd?: Date;
  offset?: number;
};

export type LoadingStatus = 'idle' | 'pending' | 'succeeded' | 'failed';

interface FinancingNeedState {
  saved: boolean;
  loading: boolean;
  financingNeedCount: number | null;
  loadingFinancingNeedsCount: LoadingStatus;
  financingNeedsCounts: { [key: string]: number };
  loadingFinancingNeedsCounts: LoadingStatus;
  uniqueAdminNgosCount: number | null;
  uniqueAdminNgosPaidCount: number | null;
  loadingUniqueAdminNgosCount: LoadingStatus;
  financingNeedPaidCount: number | null;
  loadingFinancingNeedPaidCount: LoadingStatus;
  event: FinancingNeedEvent | null;
  addDonorLoading: boolean;
  removeDonorLoading: boolean;
  lastCreatedId?: string;

  searchResult: {
    resultsIds: string[] | null;
    resultsCount: number;
  } | null;
  offset: number;
  error: any;
  addDonorError: any;
  removeDonorError: any;

  financingNeeds: {
    byId: { [id: string]: FinancingNeed };
    allIds: string[];
  };
}

const initialState: FinancingNeedState = {
  saved: false,
  loading: false,
  financingNeedCount: 0,
  loadingFinancingNeedsCount: 'idle',
  financingNeedsCounts: {},
  loadingFinancingNeedsCounts: 'idle',
  uniqueAdminNgosCount: 0,
  uniqueAdminNgosPaidCount: 0,
  loadingUniqueAdminNgosCount: 'idle',
  financingNeedPaidCount: 0,
  loadingFinancingNeedPaidCount: 'idle',
  event: null,
  addDonorLoading: false,
  removeDonorLoading: false,
  error: null,
  addDonorError: null,
  removeDonorError: null,
  financingNeeds: {
    byId: {},
    allIds: [],
  },
  offset: 0,
  searchResult: null,
};

export const fetchFinancingNeedsCount = createAsyncThunk(
  'fetchFinancingNeedsCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/financing-need/count',
      );
      dispatch(
        financingNeedSlice.actions.setFinancingNeedsCount(Number(result)),
      );
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchFinancingNeedsCountForNgo = createAsyncThunk(
  'fetchFinancingNeedsCountForNgo',
  async (ngoIds: string[], { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ [key: string]: number }>(
        'GET',
        `/financing-need/financingNeedsCountForNgo?ngoIds=${ngoIds.join(',')}`,
      );
      dispatch(financingNeedSlice.actions.setFinancingNeedsCounts(result));
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchUniqueAdminNgosCount = createAsyncThunk(
  'fetchUniqueAdminNgosCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/financing-need/uniqueAdminNgosCount',
      );
      dispatch(
        financingNeedSlice.actions.setUniqueAdminNgosCount(Number(result)),
      );
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchUniqueAdminNgosPaidCount = createAsyncThunk(
  'fetchUniqueAdminNgosPaidCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/financing-need/uniqueAdminNgosPaidCount',
      );
      dispatch(
        financingNeedSlice.actions.setUniqueAdminNgosPaidCount(Number(result)),
      );
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchFinancingNeedPaidCount = createAsyncThunk(
  'fetchFinancingNeedPaidCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/financing-need/financingNeedPaidCount',
      );
      dispatch(
        financingNeedSlice.actions.setFinancingNeedPaidCount(Number(result)),
      );
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const getFinancingNeeds = createAsyncThunk(
  'getFinancingNeeds',
  async (ngoId: string | undefined) => {
    return await apiRequest<[FinancingNeed]>(
      'GET',
      `/financing-need/ngo/${ngoId}`,
    );
  },
);

export const getMatchedDonorsLength = createAsyncThunk(
  'getMatchedDonorsLength',
  async (id: string) => {
    return await apiRequest<{ matchedCount: number }>(
      'GET',
      `/financing-need/${id}/get-matched-donors-length`,
    );
  },
);

export const saveFinancingNeed = createAsyncThunk(
  'saveFinancingNeed',
  async (payload: FinancingNeed) => {
    return await apiRequest<FinancingNeed>(
      'POST',
      '/financing-need',
      undefined,
      {
        ...payload,
        activityDomains: payload.activityDomains || [],
        targetPopulations: payload.targetPopulations || [],
        activityZones: payload.activityZones || [],
        published: true,
      },
    );
  },
);

export const getFinancingNeed = createAsyncThunk(
  'getFinancingNeed',
  async (financingNeedId: string | undefined) => {
    return await apiRequest<FinancingNeed>(
      'GET',
      `/financing-need/${financingNeedId}`,
    );
  },
);

export const addDonor = createAsyncThunk(
  'addDonor',

  async (
    payload: { financingNeedId: string; body: { donorId: string } },
    { dispatch },
  ) => {
    const result = await apiRequest<{ status: boolean }>(
      'POST',
      `/financing-need/${payload.financingNeedId}/donor`,
      undefined,
      payload.body,
    );
    if (result) dispatch(getFinancingNeed(payload.financingNeedId));
    return result;
  },
);

export const removeDonor = createAsyncThunk(
  'removeDonor',

  async (
    payload: { financingNeedId: string; body: { donorId: string } },
    { dispatch },
  ) => {
    const result = await apiRequest<{ status: boolean }>(
      'DELETE',
      `/financing-need/${payload.financingNeedId}/donor`,
      undefined,
      payload.body,
    );
    if (result) dispatch(getFinancingNeed(payload.financingNeedId));
    return result;
  },
);

export const removeFinancingNeed = createAsyncThunk(
  'removeFinancingNeed',
  async (payload: { financingNeedId: string }, { dispatch, getState }) => {
    const { auth } = getState() as { auth: AuthState };
    const result = await apiRequest<boolean>(
      'DELETE',
      `/financing-need/${payload.financingNeedId}`,
      undefined,
    );
    dispatch(getFinancingNeeds(auth.user?.ngoId));
    return result;
  },
);

export const invalidateFinancingNeeds = createAsyncThunk(
  'invalidateFinancingNeeds',
  () => {
    return Promise.resolve();
  },
);

export const updateFinancingNeed = createAsyncThunk(
  'updateFinancingNeed',
  async (payload: { id: string; updateFinancingNeedDto: any }, getThunkApi) => {
    const {
      financingNeed: { financingNeeds },
    }: any = getThunkApi.getState();
    const previousFinancingNeed: FinancingNeed =
      financingNeeds.byId[payload.id];

    const {
      tags,
      activityDomains,
      activityZones,
      targetPopulations,
      places,
      ...rest
    } = payload.updateFinancingNeedDto;

    const previousArrays = {
      ...(tags && { tags: previousFinancingNeed.tags }),
      ...(places && { places: previousFinancingNeed.places }),
      ...(activityDomains && {
        activityDomains: previousFinancingNeed.activityDomains,
      }),
      ...(activityZones && {
        activityZones: previousFinancingNeed.activityZones,
      }),
      ...(targetPopulations && {
        targetPopulations: previousFinancingNeed.targetPopulations,
      }),
    };

    return await apiRequest<FinancingNeed>(
      'PATCH',
      `/financing-need/${payload.id}`,
      undefined,
      {
        ...rest,
        uniqueZipcode: rest.uniqueZipcode || '',
        zipcode: rest.zipcode || '',
        city: rest.city || '',
        roadAndNumber: rest.roadAndNumber || '',
        ...createAddRemoveObject(previousArrays, {
          ...(tags ? { tags: tags } : {}),
          ...(places ? { places: places } : {}),
          ...(activityDomains ? { activityDomains } : {}),
          ...(activityZones ? { activityZones } : {}),
          ...(targetPopulations ? { targetPopulations } : {}),
        }),
      },
    );
  },
);

export const searchAllFinancingNeeds = createAsyncThunk(
  'searchAllFinancingNeeds',
  async (payload: SearchProjectParametersAdmin) => {
    const params: any = {
      ...payload,
      limit: FINANCING_NEEDS_MAX_RESULTS,
    };
    return {
      ...(await apiRequest<SearchResults>(
        'GET',
        '/financing-need/searchAll',
        params,
      )),
      offset: payload.offset,
    };
  },
);

export const updateFinancingNeedOffer = createAsyncThunk(
  'updateFinancingNeedOffer',
  async (payload: { financingNeedId: string; offer: Offer; type: string }) => {
    const { financingNeedId, offer, type } = payload;
    return await apiRequest<FinancingNeed>(
      'PATCH',
      `/financing-need/updateOffer/${financingNeedId}`,
      undefined,
      {
        offer: offer,
        type: type,
      },
    );
  },
);

export const updateFinancingNeedPublished = createAsyncThunk(
  'updateFinancingNeedPublished',
  async (payload: { financingNeedId: string; published: boolean }) => {
    const { financingNeedId, published } = payload;
    return await apiRequest<FinancingNeed>(
      'PATCH',
      `/financing-need/updatePublished/${financingNeedId}`,
      undefined,
      {
        published: published,
      },
    );
  },
);

function normalizeFinancingNeeds(list: FinancingNeed[]) {
  return list.reduce(
    (accumulator, financingNeed: FinancingNeed) => ({
      ...accumulator,
      [financingNeed._id]: financingNeed,
    }),
    {},
  );
}

export const reportFinancingNeedProblem = createAsyncThunk(
  'ReportFinancingNeedProblem',
  async (payload: {
    _id: string;
    name: string;
    problemDescription: string;
  }) => {
    await apiRequest(
      'POST',
      `/financing-need/${payload._id}/problem`,
      undefined,
      payload,
    );
    return payload;
  },
);

const receiveFinancingNeedReducer = (
  state: FinancingNeedState,
  { payload }: { payload: any },
) => {
  state.loading = false;
  if (payload) {
    const financingNeedsIds = payload.items.map((item: any) => item._id);
    const financingNeedsById = normalizeFinancingNeeds(payload.items);
    state.financingNeeds = {
      allIds: financingNeedsIds,
      byId: financingNeedsById,
    };
    state.offset = payload.offset;
    state.searchResult = {
      resultsCount: payload.totalItems,
      resultsIds: financingNeedsIds,
    };
  }
};

const pendingReducer = (state: FinancingNeedState) => {
  state.loading = true;
  state.error = null;
};

const fulfilledReducer = (state: FinancingNeedState) => {
  state.loading = false;
};

const rejectedReducer = (
  state: FinancingNeedState,
  { error }: { error: SerializedError },
) => {
  state.loading = false;
  state.error = error.message || 'error';
};

const financingNeedSlice = createSlice({
  name: 'financingNeed',
  initialState,
  reducers: {
    resetFinancingNeedEvent: (state: FinancingNeedState) => {
      state.event = null;
    },
    setFinancingNeedsCount: (state, action: PayloadAction<number>) => {
      state.financingNeedCount = action.payload;
    },
    setFinancingNeedsCounts(
      state,
      action: PayloadAction<{ [key: string]: number }>,
    ) {
      state.financingNeedsCounts = action.payload;
    },
    setUniqueAdminNgosCount: (state, action: PayloadAction<number>) => {
      state.uniqueAdminNgosCount = action.payload;
    },
    setUniqueAdminNgosPaidCount: (state, action: PayloadAction<number>) => {
      state.uniqueAdminNgosPaidCount = action.payload;
    },
    setFinancingNeedPaidCount: (state, action: PayloadAction<number>) => {
      state.financingNeedPaidCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveFinancingNeed.pending, (state: FinancingNeedState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(reportFinancingNeedProblem.pending, pendingReducer)
      .addCase(reportFinancingNeedProblem.rejected, rejectedReducer)
      .addCase(
        reportFinancingNeedProblem.fulfilled,
        (state: FinancingNeedState) => {
          state.loading = false;
        },
      )
      .addCase(
        saveFinancingNeed.fulfilled,
        (state: FinancingNeedState, { payload }) => {
          state.saved = true;
          state.loading = false;
          state.lastCreatedId = payload._id;
        },
      )
      .addCase(
        saveFinancingNeed.rejected,
        (state: FinancingNeedState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(getFinancingNeeds.pending, (state: FinancingNeedState) => {
        state.loading = true;
      })
      .addCase(
        getFinancingNeeds.fulfilled,
        (state: FinancingNeedState, { payload }) => {
          state.loading = false;
          const financingNeedsIds = payload.map(
            (financingNeed) => financingNeed._id,
          );
          const financingNeedsById = payload.reduce(
            (accumulator, donor: FinancingNeed) => ({
              ...accumulator,
              [donor._id]: donor,
            }),
            {},
          );
          state.financingNeeds = {
            ...state.financingNeeds,
            byId: { ...state.financingNeeds.byId, ...financingNeedsById },
            allIds: [
              ...state.financingNeeds.allIds,
              ...financingNeedsIds,
            ].filter((value, index, self) => self.indexOf(value) === index),
          };
        },
      )
      .addCase(
        getFinancingNeeds.rejected,
        (state: FinancingNeedState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(getFinancingNeed.pending, (state: FinancingNeedState) => {
        state.loading = true;
      })
      .addCase(
        getFinancingNeed.fulfilled,
        (state: FinancingNeedState, { payload }) => {
          state.loading = false;
          state.financingNeeds.byId[payload._id] = payload;
          if (!state.financingNeeds.allIds.includes(payload._id))
            state.financingNeeds.allIds.push(payload._id);
        },
      )
      .addCase(
        updateFinancingNeed.rejected,
        (state: FinancingNeedState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(updateFinancingNeed.pending, (state: FinancingNeedState) => {
        state.loading = true;
      })
      .addCase(
        updateFinancingNeed.fulfilled,
        (state: FinancingNeedState, { payload }) => {
          state.loading = false;
          state.financingNeeds.byId[payload._id] = payload;
        },
      )
      .addCase(
        getFinancingNeed.rejected,
        (state: FinancingNeedState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(addDonor.pending, (state: FinancingNeedState) => {
        state.addDonorLoading = true;
      })
      .addCase(addDonor.fulfilled, (state: FinancingNeedState) => {
        state.event = { action: FinancingNeedAction.ADD_DONOR };
        state.addDonorLoading = false;
      })
      .addCase(addDonor.rejected, (state: FinancingNeedState, { payload }) => {
        state.event = {
          action: FinancingNeedAction.ADD_DONOR,
          error: payload,
        };
        state.addDonorLoading = false;
        state.addDonorError = payload;
      })
      .addCase(removeDonor.pending, (state: FinancingNeedState) => {
        state.removeDonorLoading = true;
      })
      .addCase(removeDonor.fulfilled, (state: FinancingNeedState) => {
        state.event = { action: FinancingNeedAction.REMOVE_DONOR };
        state.removeDonorLoading = false;
      })
      .addCase(
        removeDonor.rejected,
        (state: FinancingNeedState, { payload }) => {
          state.event = {
            action: FinancingNeedAction.REMOVE_DONOR,
            error: payload,
          };
          state.removeDonorLoading = false;
          state.removeDonorError = payload;
        },
      )
      .addCase(removeFinancingNeed.pending, (state: FinancingNeedState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(removeFinancingNeed.fulfilled, (state: FinancingNeedState) => {
        state.saved = true;
        state.loading = false;
      })
      .addCase(removeFinancingNeed.rejected, (state: FinancingNeedState) => {
        state.saved = false;
        state.loading = false;
      })
      .addCase(searchAllFinancingNeeds.pending, (state: FinancingNeedState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchAllFinancingNeeds.fulfilled, receiveFinancingNeedReducer)
      .addCase(searchAllFinancingNeeds.rejected, rejectedReducer)
      .addCase(
        updateFinancingNeedOffer.pending,
        (state: FinancingNeedState) => {
          state.loading = true;
          state.saved = false;
        },
      )
      .addCase(
        updateFinancingNeedOffer.fulfilled,
        (state: FinancingNeedState, { payload }) => {
          state.loading = false;
          state.saved = true;
          if (payload) {
            state.financingNeeds.byId[payload._id] = payload;
          }
        },
      )
      .addCase(
        updateFinancingNeedOffer.rejected,
        (state: FinancingNeedState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(
        updateFinancingNeedPublished.pending,
        (state: FinancingNeedState) => {
          state.loading = true;
          state.saved = false;
        },
      )
      .addCase(
        updateFinancingNeedPublished.fulfilled,
        (state: FinancingNeedState, { payload }) => {
          state.loading = false;
          state.saved = true;
          if (payload) {
            state.financingNeeds.byId[payload._id] = payload;
          }
        },
      )
      .addCase(
        updateFinancingNeedPublished.rejected,
        (state: FinancingNeedState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(
        invalidateFinancingNeeds.fulfilled,
        (state: FinancingNeedState) => {
          state.financingNeeds = initialState.financingNeeds;
        },
      )
      .addCase(fetchFinancingNeedsCount.pending, pendingReducer)
      .addCase(fetchFinancingNeedsCount.rejected, rejectedReducer)
      .addCase(fetchFinancingNeedsCount.fulfilled, fulfilledReducer)
      .addCase(fetchFinancingNeedsCountForNgo.pending, pendingReducer)
      .addCase(fetchFinancingNeedsCountForNgo.rejected, rejectedReducer)
      .addCase(fetchFinancingNeedsCountForNgo.fulfilled, fulfilledReducer)
      .addCase(fetchUniqueAdminNgosCount.pending, pendingReducer)
      .addCase(fetchUniqueAdminNgosCount.rejected, rejectedReducer)
      .addCase(fetchUniqueAdminNgosCount.fulfilled, fulfilledReducer)
      .addCase(fetchUniqueAdminNgosPaidCount.pending, pendingReducer)
      .addCase(fetchUniqueAdminNgosPaidCount.rejected, rejectedReducer)
      .addCase(fetchUniqueAdminNgosPaidCount.fulfilled, fulfilledReducer)
      .addCase(fetchFinancingNeedPaidCount.pending, pendingReducer)
      .addCase(fetchFinancingNeedPaidCount.rejected, rejectedReducer)
      .addCase(fetchFinancingNeedPaidCount.fulfilled, fulfilledReducer);
  },
});

export const { resetFinancingNeedEvent } = financingNeedSlice.actions;
export default financingNeedSlice.reducer;

import React from 'react';
import { Col, Form, FormInstance, Row, Radio, InputNumber } from 'antd';
import { createUseStyles } from 'react-jss';
import { FormItem } from '../components/FormItem';

interface Props {
  form: FormInstance;
}
const layout = {
  labelCol: { span: 8 },
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '5%',
    paddingBottom: '37.5%',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
  },
});

export const UserEditForm: React.VFC<Props> = ({ form }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Row>
        <Col span={5} />
        <Col span={14}>
          <Form {...layout} form={form}>
            <FormItem label={'Accès utilisateur'} name={'disabled'}>
              <Radio.Group>
                <Radio.Button value={false}>Activer</Radio.Button>
                <Radio.Button value={true}>Desactiver</Radio.Button>
              </Radio.Group>
            </FormItem>
            <FormItem
              label={'Controle 1 (ex - abonnement)'}
              name={'hasValidSubscription'}
            >
              <Radio.Group>
                <Radio.Button value={true}>Oui</Radio.Button>
                <Radio.Button value={false}>Non</Radio.Button>
              </Radio.Group>
            </FormItem>
            <FormItem
              label={'Nombre maximal de projets'}
              name={'maxNumberOfProjects'}
            >
              <InputNumber placeholder="0"></InputNumber>
            </FormItem>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

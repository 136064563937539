import { Donor } from '../../slices/donor';

export const getDonorUrl = (donor: Donor): string => {
  return donor.homepageUrl || donor.otherUrl || donor.linkedinUrl || '';
};

export const isNewlyCreated = (createdAt: Date) => {
  const twoWeeksAgo = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
  return new Date(createdAt) >= twoWeeksAgo;
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiError, apiRequest } from '../helpers/api';

interface RnaResult {
  idRna: string;

  name: string;

  rnaNumber: string;

  siret: string;

  object?: string;

  socialObject?: string;

  headquarterAddress?: string;

  websiteUrl?: string;

  creationYear?: number;

  isPublicInterestNgo: boolean;
}

interface RnaState {
  loading: boolean;
  error: any;
  results: RnaResult[] | null;
}

const initialState: RnaState = {
  loading: false,
  error: null,
  results: null,
};

export const searchRna = createAsyncThunk(
  'rna/search',
  async (payload: { text: string }) => {
    const { text } = payload;
    const request = () => apiRequest<RnaResult[]>('GET', '/rna/text/' + text);

    try {
      return await request();
    } catch (error) {
      if (error instanceof ApiError && error.statusCode === 429) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        return await request();
      }
      throw error;
    }
  },
);

const rnaSlice = createSlice({
  name: 'rna',
  initialState,
  reducers: {
    reset(state: RnaState) {
      state.loading = false;
      state.results = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchRna.pending, (state: RnaState) => {
        state.loading = true;
        state.results = null;
      })
      .addCase(searchRna.fulfilled, (state: RnaState, { payload }) => {
        state.loading = false;
        state.results = payload;
      })
      .addCase(searchRna.rejected, (state: RnaState, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { reset } = rnaSlice.actions;

export default rnaSlice.reducer;

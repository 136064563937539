import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { Offers } from '../components/subscription/Offers';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { fetchCurrentUser } from '../slices/auth';
import { getCallForTenderFromDonorId } from '../slices/callForTender';
import { getFinancingNeeds } from '../slices/financingNeed';
import { LoggedIntercom } from '../utils/loggedIntercom';

const useStyles = createUseStyles({
  container: {
    backgroundImage: `url("/images/dashboardLogo.png")`,
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',

    minHeight: '100%',
    // display: 'inline-flex',
    width: '100%',
    padding: '3.98% 3.06% 3.98% 2.91%',
  },
});

export const Subscription: React.VFC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { offerCompleteExists } = useParams<{ offerCompleteExists: string }>();
  const dispatch = useAppDispatch();
  const { user, users } = useAppSelector(({ auth: { user, users } }) => ({
    user,
    users,
  }));
  const { financingNeeds, callForTenders } = useAppSelector(
    ({
      financingNeed: { financingNeeds },
      callForTender: { callForTenders },
    }) => ({
      financingNeeds,
      callForTenders,
    }),
  );

  LoggedIntercom(
    user?.firstName + ' ' + user?.lastName,
    user?.email,
    user?._id,
    'userSawSubscriptionPage',
  );

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getFinancingNeeds(user?.ngoId));
    }
  }, [user]);

  useEffect(() => {
    if (user?.donorId) {
      dispatch(getCallForTenderFromDonorId(user?.donorId));
    }
  }, [user]);

  useEffect(() => {
    fetchCurrentUser();
  }, [users]);

  return (
    <div className={classes.container}>
      <Offers
        projectId={id}
        type={user?.ngoId ? 'ngo' : 'donor'}
        offerCompleteExists={offerCompleteExists}
        projectName={
          user?.ngoId
            ? Object?.values(financingNeeds.byId)?.find(
                (project) => project._id === id,
              )?.name
            : Object?.values(callForTenders.byId)?.find(
                (project) => project._id === id,
              )?.name
        }
      />
    </div>
  );
};

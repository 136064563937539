import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { apiRequest } from '../helpers/api';

interface Props {
  hCaptchaSiteKey?: string;
  children: React.ReactNode;
}

type Callback = () => void;
type HandleRequestCaptcha = (
  onSuccess: Callback,
  onFailOrCancel: Callback,
) => void;

export const CaptchaModalContext = React.createContext<
  (() => Promise<void>) | undefined
>(undefined);

export const CaptchaModalProvider: React.VFC<Props> = ({
  hCaptchaSiteKey,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [callbacks, setCallbacks] = useState<{
    onSuccess: Callback;
    onFailOrCancel: Callback;
  } | null>(null);

  const handleRequestCaptcha: HandleRequestCaptcha = (
    onSuccess,
    onFailOrCancel,
  ) => {
    setVisible(true);
    setCallbacks({ onSuccess, onFailOrCancel });
  };

  const handleFailOrCancel = () => {
    setVisible(false);
    callbacks?.onFailOrCancel();
    setCallbacks(null);
  };

  const handleSuccess = async (token: string) => {
    setLoading(true);
    const { status } = await apiRequest<Response>(
      'POST',
      '/captcha/verify',
      undefined,
      {
        token,
      },
    );

    setLoading(false);
    if (status) {
      callbacks?.onSuccess();
      setVisible(false);
      setCallbacks(null);
    } else {
      handleFailOrCancel();
    }
  };

  return (
    <CaptchaModalContext.Provider
      value={() =>
        new Promise((resolve, reject) => {
          handleRequestCaptcha(resolve, reject);
        })
      }
    >
      {hCaptchaSiteKey ? (
        <>
          <Modal
            visible={visible}
            keyboard={false}
            maskClosable={true}
            destroyOnClose
            footer={[
              <Button
                key="back"
                onClick={handleFailOrCancel}
                loading={loading}
                disabled={loading}
              >
                Annuler
              </Button>,
            ]}
            onCancel={handleFailOrCancel}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <span>
                Pour continuer, merci d&apos;effectuer cette validation:
              </span>
              <br />
              <HCaptcha
                sitekey={hCaptchaSiteKey}
                onExpire={handleFailOrCancel}
                onError={handleFailOrCancel}
                onVerify={handleSuccess}
              />
            </div>
          </Modal>
          {children}
        </>
      ) : (
        children
      )}
    </CaptchaModalContext.Provider>
  );
};

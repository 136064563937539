import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Typography } from 'antd';
import { theme } from '../constants/theme';

const { Text } = Typography;

const useStyles = createUseStyles({
  description: {
    whiteSpace: 'pre-line',
    marginBottom: '3.716%',
    fontSize: 'calc(5px + 0.4vw)',
    textJustify: 'auto',
    textAlign: 'justify',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  readOrHide: {
    color: theme.palette.primary,
    cursor: 'pointer',
    float: 'right',
    lineHeight: 1,
  },
});

interface Props {
  description: string;
  maximumNumberOfCharacters: number;
  styles?: Record<string, unknown>;
}

const ReadMore: React.VFC<Props> = ({
  description,
  maximumNumberOfCharacters,
  styles,
}) => {
  const classes = useStyles();
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const ReadMoreText = (
    <span onClick={toggleReadMore} className={classes.readOrHide}>
      {isReadMore ? '...Lire la suite' : 'Réduire'}
    </span>
  );
  return (
    <div className={classes.description}>
      <Text style={styles}>
        {isReadMore
          ? description?.slice(0, maximumNumberOfCharacters)
          : description}
        {description?.length > maximumNumberOfCharacters ? ReadMoreText : ''}
      </Text>
    </div>
  );
};

export default ReadMore;

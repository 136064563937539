import React, { useEffect } from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import { BrowserRouter as Router } from 'react-router-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { useAppDispatch, useAppSelector } from './hooks/store';
import { fetchCurrentUser } from './slices/auth';
import { GuestSwitch } from './routes/GuestSwitch';
import { LoggedSwitch } from './routes/LoggedSwitch';
import { FullscreenSpin } from './components/FullscreenSpin';
import { DeviceNotAllowed } from './components/DeviceNotAllowed';
import './locales/i18n';
import { GoogleOAuthProvider } from '@react-oauth/google';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.REACT_APP_SENTRY_DNS
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
const App: React.VFC = () => {
  const dispatch = useAppDispatch();
  const { isLogged, isInitialLoading } = useAppSelector(
    ({ auth: { isLogged, isInitialLoading } }) => ({
      isLogged,
      isInitialLoading,
    }),
  );
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { isDesktop } = selectors;

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  if (!isDesktop) {
    return <DeviceNotAllowed />;
  }
  if (isInitialLoading) {
    return <FullscreenSpin />;
  }

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
    >
      <I18nextProvider i18n={i18next}>
        {/* <ElementsProvider stripe={stripePromise}> */}
        <Router>{isLogged ? <LoggedSwitch /> : <GuestSwitch />}</Router>
        {/* </ElementsProvider> */}
      </I18nextProvider>
    </GoogleOAuthProvider>
  );
};

export default App;

import React from 'react';
import { AutoComplete, Form, Input, InputNumber } from 'antd';
import { useFormRules } from 'src/hooks/useFormRules';
import { searchRna } from 'src/slices/rna';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { FormInstance } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { createUseStyles } from 'react-jss';
import { FormItem } from 'src/components/FormItem';
import { SaveContributorNgoDto } from 'src/slices/ngo';

const { Option } = AutoComplete;

interface Props {
  form: FormInstance<SaveContributorNgoDto>;
}

const layout = {
  labelCol: { span: 4 },
};

const useStyles = createUseStyles({
  autocompleteDropdown: {
    zIndex: 2000,
  },
});

export const ContributorAddNgoForm: React.VFC<Props> = ({ form }) => {
  const rules = useFormRules();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { results, loading } = useAppSelector(
    ({ rna: { loading, results } }) => ({ loading, results }),
  );
  let lastSearchNamePromise: any;
  const searchName = (name: string) => {
    if (name.length >= 3) {
      lastSearchNamePromise?.abort();
      lastSearchNamePromise = dispatch(searchRna({ text: name }));
    }
  };

  return (
    <Form {...layout} form={form}>
      <FormItem label={'Nom'} name="name" rules={[rules.required()]}>
        <AutoComplete
          onSearch={searchName}
          placeholder={"Cherchez le nom de l'organisation"}
          dropdownClassName={classes.autocompleteDropdown}
          onChange={(value: string) => {
            const rna = results?.find((result) => result.name == value);
            if (!rna) {
              form.setFieldsValue({
                idRna: undefined,
                object: undefined,
                siret: undefined,
                headquarterAddress: undefined,
                websiteUrl: undefined,
                creationYear: undefined,
                isPublicInterestNgo: undefined,
              });
            }
          }}
          onSelect={(value: string) => {
            const rna = results?.find((result) => result.name == value);
            if (!rna) return;
            form.setFieldsValue({
              idRna: rna.rnaNumber || undefined,
              object: rna.object || undefined,
              siret: rna.siret || undefined,
              headquarterAddress: rna.headquarterAddress || undefined,
              websiteUrl: rna.websiteUrl || undefined,
              creationYear: rna.creationYear || undefined,
              isPublicInterestNgo: rna.isPublicInterestNgo || undefined,
            });
          }}
        >
          {loading ? (
            <></>
          ) : (
            results?.map((rna, index) => (
              <Option key={index} value={rna.name}>
                {rna.name}
              </Option>
            ))
          )}
        </AutoComplete>
      </FormItem>
      <FormItem label="Objet" name="object">
        <TextArea autoSize={{ minRows: 2, maxRows: 8 }} disabled />
      </FormItem>
      <FormItem label="SIRET" name="siret">
        <Input disabled />
      </FormItem>
      <FormItem label="Adresse du siège social" name="headquarterAddress">
        <Input disabled />
      </FormItem>
      <FormItem label="Site" name="websiteUrl">
        <Input type="url" disabled />
      </FormItem>
      <FormItem label="Année de création" name="creationYear">
        <InputNumber disabled />
      </FormItem>
    </Form>
  );
};

export const tagsValidator = (rule: any, value: any) => {
  if (value && value.length > 1) {
    return Promise.resolve();
  }
  return Promise.reject('Veuillez sélectionner au moins 2 mots clés');
};

export const placesValidator = (rule: any, value: any) => {
  if (value && value.length >= 1) {
    return Promise.resolve();
  }
  return Promise.reject("Veuillez sélectionner au moins 1 lieu d'intervention");
};

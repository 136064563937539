import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Dropdown,
  Typography,
  Divider,
  Button,
  Tag,
  Image,
  message,
  Radio,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { PlacesSelectorModal } from '../components/PlacesSelectorModal';
import { TagsSelectorModal } from '../components/TagsSelectorModal';
import { IUploadProps, Upload } from '../components/Upload';

import { useAppDispatch, useAppSelector } from '../hooks/store';
import { useSortedClassification } from '../hooks/useSortedClassification';
import { getClassification, Place } from '../slices/classification';
import { getCommunes } from '../slices/communes';

import { searchRna } from '../slices/rna';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { placesValidator, tagsValidator } from '../helpers/validators';
import { useFormRules } from '../hooks/useFormRules';
import { FormItem } from 'src/components/FormItem';
import { Donor, ModusOperandi } from '../slices/donor';
import {
  getFundedNgosFromDonorId,
  getNgo,
  saveContributorNgo,
  SaveContributorNgoDto,
  searchNgosAndGetSuggestions,
} from 'src/slices/ngo';
import { ContributorAddNgoForm } from './ContributorAddNgoForm';
import { theme } from 'src/constants/theme';
import clsx from 'clsx';
import { MoreTagsModal } from 'src/components/MoreTagsModal';

const { Option } = Select;
const { Text, Link } = Typography;

const layout = {
  labelCol: { span: 6 },
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    textAlign: 'left',
    '& .ant-form-item, .ant-form-item-label > label,\
        .ant-input, .ant-input-number': {
      fontSize: '0.875vw',
    },
    '& .ant-col': {
      maxWidth: '100%',
    },
  },
  stepContainer: {
    height: '75vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  stepColLeft1: {
    backgroundImage: `url("../../images/orgaStep1.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft2: {
    backgroundImage: `url("../../images/orgaStep2.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft3: {
    backgroundImage: `url("../../images/orgaStep3.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft4: {
    backgroundImage: `url("../../images/orgaStep4.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft5: {
    backgroundImage: `url("../../images/orgaStep5.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColRight: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    marginLeft: '40vw',
  },
  stepImage: {
    width: '40vw',
    position: 'fixed',
  },
  stepBar: {
    height: '1vw',
    backgroundColor: theme.palette.primary,
  },
  colRightPadding: {
    marginLeft: '6rem',
  },
  stepCounter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  stepNumber: {
    fontSize: '2.5vw',
  },
  stepTotal: {
    fontSize: '1.2vw',
    color: theme.palette.primary,
  },
  buttonBox: {
    position: 'fixed',
    bottom: '0',
    left: '40vw',
    width: '40%',
    paddingBottom: '2.5vw',
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    marginLeft: '6rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.8rem',
  },
  buttonPrevious: {
    width: 'auto',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  buttonNext: {
    width: 'auto',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  buttonUpload: {
    width: 'auto%',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: theme.palette.primary,
    color: 'white',
    borderRadius: '0.5vw',
  },
  textArea: {
    height: 200,
    marginBottom: 50,
  },
  tagCities: {
    fontSize: '1vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    color: 'white',
    minHeight: '1.5vw',
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: '0.5vw',
    backgroundColor: theme.palette.primary,
  },
  menu: {
    width: 800,
    whiteSpace: 'normal',
    height: '60vh',
    overflow: 'auto',
  },
  validateCallForTender: {
    marginBottom: 0,
  },
  menuItem: {
    position: 'relative',
    width: '100%',
    wordWrap: 'break-word',
    marginBottom: 20,
  },
  name: {
    width: '100%',
    fontWeight: 'bold',
  },
  description: {
    width: '100%',
  },
  tag: {
    minHeight: '1.65vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '0.4vw',
    fontSize: '0.8vw',
    height: '1vw',
    color: 'white',
    backgroundColor: theme.palette.primary,
    border: '1px solid #5050f6',
    borderRadius: '1vw',
    paddingRight: '0.5vw',
    paddingLeft: '0.5vw',
  },
  placesTag: {
    fontSize: '0.9vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '1vw',
    color: 'white',
    backgroundColor: theme.palette.primary,
  },
  addressTag: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontSize: '1vw',
    marginLeft: '1rem',
    marginTop: '0.4rem',
  },
  nonRelevantMessage: {
    opacity: 0.75,
    color: theme.palette.red[4],
  },
  button: {
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  search: {
    '& .ant-input-group-addon': {
      backgroundColor: 'white',
      fontSize: '0.875vw',
      lineHeight: '1.5715',
      height: '100%',
      '& .ant-btn': {
        padding: '4px 0 !important',
        height: 'unset',
        width: '2vw',
        fontSize: '0.875vw !important',
        boxShadow: 'none',
      },
    },
    '& .ant-btn-icon-only > *': {
      fontSize: '1vw',
    },
  },
  trashIcon: {
    color: '#002766',
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  headquarterCityFormItem: {
    '& .ant-input-affix-wrapper-disabled': {
      backgroundColor: 'white',
    },
    '& .ant-input[disabled]': {
      color: 'black',
    },
  },
  fundedNgosFormItem: {
    marginTop: '1vh',
    '& .ant-form-item-tooltip': {
      color: '#5050F9 !important',
    },
  },
  modal: {
    '&.ant-modal': {
      maxWidth: '1000px',
      height: 900,
      zIndex: 2000,
    },
    '&.ant-modal-body': {
      height: 900,
    },
  },
  modalWrap: {
    zIndex: 2000,
  },
  customLabel: {
    position: 'relative',
    fontSize: '1vw',
    '&::after': {
      content: '"*"',
      color: '#ff4d4f',
      fontSize: '1.2vw',
      marginLeft: '0.2vw',
    },
  },
  customLabelOptional: {
    position: 'relative',
    fontSize: '1vw',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '75%',
  },
  inputHeight: {
    minHeight: '2vw',
    '& input': {
      height: '100%',
      padding: '0.5vw 1vw',
      color: 'black',
    },
    border: 'none',
    borderBottom: '1px solid #5050f6',
    color: theme.palette.primary,
    '& input::placeholder': {
      color: theme.palette.primary,
    },
    '& textarea::placeholder': {
      color: theme.palette.primary,
    },
    '& .ant-select-selection-placeholder': {
      color: theme.palette.primary,
    },
    '& textarea': {
      borderColor: theme.palette.primary,
      borderBottom: 'none',
      color: 'black',
    },
  },
  selectTag: {
    '& .ant-select-selection-item': {
      border: 'none',
      backgroundColor: 'white',
      maxHeight: '1.65vw',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .ant-select-selection-item-content': {
      color: 'white',
      backgroundColor: theme.palette.primary,
      border: '1px solid #5050f6',
      borderRadius: '1vw',
      paddingRight: '0.5vw',
      paddingLeft: '0.5vw',
    },
  },
});

interface IDonorFirstStepsForm {
  form: FormInstance;
  donor?: Donor;
  uploadLogo?: JSX.Element;
  uploadFiles?: JSX.Element;
  submitForm: () => void;
}

const debouncedSearchCall = debounce((searchFunction: any, value: string) => {
  searchFunction(value);
}, 500);

const normalizeNullToUndefined = (value: unknown) =>
  value === null ? undefined : value;

const normalizeEmptyStringToNull = (value: unknown) =>
  value === '' ? null : value;

export const NewDonorEditForm: React.VFC<IDonorFirstStepsForm> = ({
  form,
  donor,
  uploadFiles,
  submitForm,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const rules = useFormRules();
  const [menu] = useState<null | React.ReactElement>(null);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showMoreTagsModal, setShowMoreTagsModal] = useState(false);
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string | undefined | null>(undefined);
  const [nationality, setNationality] = useState<string | undefined>(
    donor?.nationality,
  );

  const [cityState, setCityState] = useState<string>('');
  const [citiesState, setCitiesState] = useState<string[]>([]);

  const [addNgoModalOpen, setAddNgoModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading, saved, userRole, searchNgos, ngos, communes } =
    useAppSelector(
      ({
        ngo: { searchNgos, loading, saved, error, ngos },
        auth,
        communes: { communes },
      }) => ({
        searchNgos,
        loading,
        saved,
        error,
        userRole: auth.user?.role,
        ngos,
        communes,
      }),
    );
  const [currentStep, setCurrentStep] = useState(1);

  const [addNgoForm] = Form.useForm<SaveContributorNgoDto>();

  const [
    dateOfLastCallForTenderValidation,
    setDateOfLastCallForTenderValidation,
  ] = useState<Date | undefined>(donor?.lastCallForTenderValidation);

  const [isValid, setIsValid] = useState<boolean>(
    dateOfLastCallForTenderValidation ? true : false,
  );

  const optionsFundedNgos = useMemo(
    () =>
      (searchNgos || [])
        .concat(
          (ngos || []).filter(
            (ngo) =>
              (searchNgos || []).findIndex(
                (searchNgo) => searchNgo._id === ngo._id,
              ) === -1,
          ),
        )
        .filter(
          (ngo) =>
            !(form.getFieldValue('unverifiedFundedNgos') || []).includes(
              ngo._id,
            ),
        )
        .map((ngo) => ({ label: ngo.name, value: ngo._id })),
    [ngos, searchNgos],
  );

  const classification = useSortedClassification();

  useEffect(() => {
    dispatch(getClassification());
  }, [dispatch]);

  useEffect(() => {
    if (donor) dispatch(getFundedNgosFromDonorId(donor._id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCommunes());
  }, [dispatch]);

  useEffect(() => {
    if ((!loading || saved) && addNgoModalOpen) {
      setAddNgoModalOpen(false);
    }
  }, [saved, loading, addNgoModalOpen]);

  const onFormValuesChange = (changedValues: any) => {
    if (changedValues['nationality']) {
      setNationality(changedValues['nationality']);
    }
    if (changedValues['lastCallForTenderValidation']) {
      setDateOfLastCallForTenderValidation(
        changedValues['lastCallForTenderValidation'],
      );
    }
  };

  useEffect(() => {
    form.setFieldsValue({ logoUrl });
  }, [logoUrl]);

  useEffect(() => {
    if (form.getFieldValue('logoUrl')) {
      setLogoUrl(form.getFieldValue('logoUrl'));
    }
  }, [form.getFieldValue('logoUrl')]);

  if (!classification) return <FullscreenSpin />;

  const {
    tags,
    activityDomains,
    statuses,
    nationalities,
    targetPersons,
    targetPopulations,
    donationTypes,
    places,
  } = classification;

  let lastSearchNamePromise: any;
  const searchName = (name: string) => {
    if (name.length >= 3) {
      lastSearchNamePromise?.abort();
      lastSearchNamePromise = dispatch(searchRna({ text: name }));
    }
  };

  const renderLogo = () => {
    return (
      logoUrl && (
        <div>
          <Row align="middle">
            <Col xs={6} />
            <Col xs={5} xxl={3}>
              <div
                style={{ display: 'flex', float: 'left', marginTop: '10px' }}
              >
                <Image
                  src={logoUrl}
                  width="90px"
                  height="90px"
                  fallback="error"
                  alt="Logo"
                />
              </div>
            </Col>
            <Col xs={9} xxl={10}>
              <Link onClick={() => setLogoUrl(null)} style={{ float: 'left' }}>
                <DeleteOutlined className={classes.trashIcon} />
              </Link>
            </Col>
          </Row>
        </div>
      )
    );
  };

  const onSuccessLogoUpload = (info: any) => {
    if (info.file.type === 'image/png' || info.file.type === 'image/jpeg') {
      message.success('Logo ajouté avec succès !');
      setLogoUrl(info.file.response);
    } else {
      message.error('Seul les formats jpeg et png sont acceptés');
    }
  };

  function updateCitiesState(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const cities = communes
      .filter((c) => {
        if (c.zipcode) {
          const zipcode = c.zipcode.toString();
          if (zipcode.length === 4) {
            return `0${zipcode}` === value.toString();
          } else {
            return zipcode === value.toString();
          }
        }
        return false;
      })
      .map((c) => c.name);
    setCitiesState(cities);
  }

  const uploadLogoProps: IUploadProps = {
    name: 'file',
    endpoint: '/file/upload',
    onSuccess: onSuccessLogoUpload,
    text: 'Importer un logo',
    children: (
      <Button
        className={classes.buttonPrevious}
        style={{
          height: '3vw',
          fontSize: '0.875vw',
        }}
      >
        J’importe le logo de mon organisation
      </Button>
    ),
    uploadProps: {
      showUploadList: false,
    },
  };

  const renderUploadLogo = () => {
    return (
      <div style={{ marginBottom: '25px' }}>
        <Row align="middle" style={{ paddingBottom: '8px' }}>
          <Text
            style={{
              textAlign: 'left',
              width: '100%',
              marginRight: '8px',
              fontSize: '1vw',
            }}
          >
            → Importez votre Logo :
          </Text>
        </Row>
        <Row align="middle" style={{ paddingBottom: '8px' }}>
          <Upload {...uploadLogoProps} />
        </Row>
        {renderLogo()}
      </div>
    );
  };

  useEffect(() => {
    if (form.getFieldValue('tags')?.length > 0 && classification.tags?.length) {
      form.setFieldsValue({
        activityDomains: form
          .getFieldValue('tags')
          .reduce((previousValue: string[], tagId: string) => {
            const activityDomains = classification.tags.find(
              (t) => t._id === tagId,
            )?.associatedDomains;
            if (activityDomains) {
              return previousValue.concat(activityDomains);
            }
            return previousValue;
          }, [])
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index,
          ),
      });
    } else {
      form.setFieldsValue({
        activityDomains: [],
      });
    }
  }, [form.getFieldValue('tags')]);

  const onAddNgo = async () => {
    const valuesNewNgo = await addNgoForm.validateFields();
    setAddNgoModalOpen(false);
    const resultAction = await dispatch(saveContributorNgo(valuesNewNgo));

    if (saveContributorNgo.fulfilled.match(resultAction)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const fundedNgos = await form.getFieldValue('fundedNgos');
      dispatch(getNgo(resultAction.payload._id));
      message.success('ONG ajoutée avec succès !');
    } else {
      message.error("Erreur lors de l'ajout de l'ONG");
    }
  };

  const renderAddNgoModal = () => (
    <Modal
      className={classes.modal}
      maskStyle={{ zIndex: 1500 }}
      width={1000}
      wrapClassName={classes.modalWrap}
      title={'Ajouter une organisation'}
      onOk={onAddNgo}
      onCancel={() => setAddNgoModalOpen(false)}
      visible={addNgoModalOpen}
      okText={'Confirmer'}
      cancelText={'Annuler'}
      confirmLoading={loading}
    >
      {<ContributorAddNgoForm form={addNgoForm} />}
    </Modal>
  );

  const renderUrlFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem
      label={label}
      name={name}
      rules={[rules.url()]}
      normalize={normalizeEmptyStringToNull}
    >
      <Input type="url" placeholder={'https://'} />
    </FormItem>
  );

  const renderLinkedinUrlFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem
      label={label}
      name={name}
      rules={[rules.url()]}
      normalize={normalizeEmptyStringToNull}
    >
      <Input type="url" placeholder={'https://'} />
    </FormItem>
  );

  const renderTagsFormItem = () => (
    <div className={classes.field}>
      <FormItem
        label="→ Mots clés"
        name="tags"
        rules={
          userRole !== 'admin'
            ? [
                {
                  required: true,
                  type: 'array',
                  validator: tagsValidator,
                },
              ]
            : undefined
        }
        customLabelClassName={classes.customLabel}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            onClick={() => setShowTagsModal(true)}
            className={classes.buttonPrevious}
            style={{
              height: '3vw',
              width: '12vw',
              fontSize: '0.875vw',
            }}
          >
            Choisir les mots clés
          </Button>
          {!!form.getFieldValue('tags')?.length && (
            <div
              style={{
                marginTop: '1vw',
                display: 'inline-flex',
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '0.4vw',
              }}
            >
              {form
                .getFieldValue('tags')
                ?.slice(0, 4)
                .map((tagId: string) => (
                  <Tag key={tagId} className={classes.tag}>
                    {tags.find((tag) => tag._id === tagId)?.translatedName}
                  </Tag>
                ))}
              {form.getFieldValue('tags')?.length > 4 && (
                <Tag
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '1vw',
                    maxHeight: '1.65vw',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ marginRight: '0.5vw', fontSize: '1vw' }}>
                    +{form.getFieldValue('tags')?.length - 4}
                  </span>{' '}
                  <span>
                    <Link
                      style={{
                        fontSize: '0.7vw',
                        textDecoration: 'underline',
                      }}
                      onClick={() => setShowMoreTagsModal(true)}
                    >
                      voir plus
                    </Link>
                  </span>
                </Tag>
              )}
            </div>
          )}
        </div>
      </FormItem>
    </div>
  );

  const renderActivityDomainsFormItem = () => (
    <FormItem
      label="//Domaine(s) d'intervention"
      name="activityDomains"
      hidden
      rules={
        userRole !== 'admin' ? [rules.required({ type: 'array' })] : undefined
      }
    >
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        optionFilterProp="label"
      >
        {activityDomains.map((activityDomain) => {
          return (
            <Option
              value={activityDomain._id}
              key={activityDomain._id}
              label={activityDomain.translatedName}
              style={{ color: theme.palette.primary }}
            >
              {activityDomain.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const [statusState, setStatusState] = useState<string>(
    form.getFieldValue('status') || undefined,
  );

  useEffect(() => {
    if (form.getFieldValue('status')) {
      setStatusState(form.getFieldValue('status'));
    }
  }, [form.getFieldValue('status')]);

  const renderStatusesFormItem = () => (
    <FormItem
      label="→ Statut"
      name="status"
      initialValue={'Entrez votre réponse ici'}
      rules={
        userRole !== 'admin' ? [rules.required(), rules.status()] : undefined
      }
      customLabelClassName={classes.customLabel}
      style={{ height: '1vw' }}
    >
      <Select
        allowClear
        style={{ fontSize: '0.88vw' }}
        optionFilterProp="label"
        className={classes.inputHeight}
        bordered={false}
        value={statusState}
        onChange={(value) => setStatusState(value)}
      >
        {statuses.map((status) => {
          return (
            <Option
              value={status._id}
              key={status._id}
              label={status.translatedName}
              style={{ color: theme.palette.primary }}
            >
              {status.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const renderNationalitiesFormItem = () => (
    <FormItem
      label="Nationalité"
      name="nationality"
      rules={userRole !== 'admin' ? [rules.required()] : undefined}
      customLabelClassName={classes.customLabel}
    >
      <Select
        allowClear
        optionFilterProp="label"
        value={nationalityState}
        onChange={(value) => setNationalityState(value)}
        bordered={false}
        className={classes.inputHeight}
        style={{ fontSize: '0.8vw' }}
      >
        {nationalities.map((nationality) => {
          return (
            <Option
              value={nationality._id}
              key={nationality._id}
              label={nationality.translatedName}
              style={{ color: theme.palette.primary }}
            >
              {nationality.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const [targetPersonState, setTargetPersonState] = useState<string[]>(
    form.getFieldValue('targetPerson') || [],
  );

  useEffect(() => {
    form.setFieldsValue({ targetPerson: targetPersonState });
  }, [targetPersonState]);

  const [nationalityState, setNationalityState] = useState<string>(
    form.getFieldValue('nationality') || undefined,
  );

  const renderTargetPersonsFormItem = () => (
    <FormItem
      label="Soutien orienté vers"
      name="targetPerson"
      rules={
        userRole !== 'admin' ? [rules.required({ type: 'array' })] : undefined
      }
      customLabelClassName={classes.customLabel}
    >
      <Select
        mode="multiple"
        allowClear
        optionFilterProp="label"
        value={targetPersonState}
        onChange={(value) => setTargetPersonState(value)}
        bordered={false}
        className={`${classes.inputHeight} ${classes.selectTag}`}
        style={{ fontSize: '0.8vw' }}
      >
        {targetPersons.map((targetPerson) => {
          return (
            <Option
              value={targetPerson._id}
              key={targetPerson._id}
              label={targetPerson.translatedName}
              style={{ color: theme.palette.primary }}
            >
              {targetPerson.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const renderTargetPopulationsFormItem = () => (
    <FormItem
      label="Bénéficiaires cibles"
      name="targetPopulations"
      rules={
        userRole !== 'admin' ? [rules.required({ type: 'array' })] : undefined
      }
      customLabelClassName={classes.customLabel}
    >
      <Select
        mode="multiple"
        allowClear
        optionFilterProp="label"
        bordered={false}
        className={`${classes.inputHeight} ${classes.selectTag}`}
        style={{ fontSize: '0.8vw' }}
      >
        {targetPopulations.map((targetPopulation) => {
          return (
            <Option
              value={targetPopulation._id}
              key={targetPopulation._id}
              label={targetPopulation.translatedName}
              style={{ color: theme.palette.primary }}
            >
              {targetPopulation.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const renderActivityZoneFormItem = () => (
    <FormItem
      label="Zones d'intervention"
      name="activityZones"
      customLabelClassName={classes.customLabelOptional}
    >
      <Select
        mode="multiple"
        allowClear
        optionFilterProp="label"
        bordered={false}
        className={`${classes.inputHeight} ${classes.selectTag}`}
        style={{ fontSize: '0.8vw' }}
      >
        {classification.activityZones.map((activityZone) => {
          return (
            <Option
              value={activityZone._id}
              key={activityZone._id}
              label={activityZone.translatedName}
              style={{ color: theme.palette.primary }}
            >
              {activityZone.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const renderDonationTypeFormItem = () => (
    <FormItem
      label="Type de mécénat"
      name="donationTypes"
      rules={
        userRole !== 'admin' ? [rules.required({ type: 'array' })] : undefined
      }
      customLabelClassName={classes.customLabel}
    >
      <Select
        mode="multiple"
        allowClear
        optionFilterProp="label"
        bordered={false}
        className={`${classes.inputHeight} ${classes.selectTag}`}
        style={{ width: '100%', fontSize: '0.8vw' }}
      >
        {donationTypes.map((donationType) => {
          return (
            <Option
              value={donationType._id}
              key={donationType._id}
              label={donationType.translatedName}
              style={{
                color: theme.palette.primary,
              }}
            >
              <div>{donationType.translatedName}</div>
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const todayDate = () => {
    const date = new Date();
    return date.toDateString();
  };

  const renderLastCallForTenderValidation = () => {
    const date = dateOfLastCallForTenderValidation
      ? new Date(dateOfLastCallForTenderValidation)
      : undefined;
    return (
      <div>
        <p>Valider appels d&apos;offres</p>
        <Select
          defaultValue={dateOfLastCallForTenderValidation ? 'Oui' : 'Non'}
          style={{ width: '100%' }}
          optionFilterProp="label"
          onChange={(value) =>
            value === 'Oui' ? setIsValid(true) : setIsValid(false)
          }
        >
          <Option
            value="Oui"
            label="Oui"
            style={{ color: theme.palette.primary }}
          >
            Oui
          </Option>
          <Option
            value="Non"
            label="Non"
            style={{ color: theme.palette.primary }}
          >
            Non
          </Option>
        </Select>
        {isValid && (
          <FormItem
            label=""
            name="lastCallForTenderValidation"
            className={clsx(
              dateOfLastCallForTenderValidation
                ? classes.validateCallForTender
                : null,
            )}
          >
            <Radio.Group>
              <Radio.Button
                onClick={() => setDateOfLastCallForTenderValidation(new Date())}
                value={todayDate()}
              >
                Valider
              </Radio.Button>
            </Radio.Group>
          </FormItem>
        )}
        {dateOfLastCallForTenderValidation && (
          <div style={{ marginLeft: '9.5vw', marginBottom: '1.5vw' }}>
            Dernière validation:{' '}
            {new Intl.DateTimeFormat('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(date)}
          </div>
        )}
      </div>
    );
  };

  const handleChange = () => {
    initSearchFundedNgo();
  };

  const initSearchFundedNgo = () => {
    dispatch(searchNgosAndGetSuggestions({ name: '' }));
  };

  const handleSearch = (value: string) => {
    dispatch(searchNgosAndGetSuggestions({ name: value }));
  };

  const handleRemoveUnverifiedFundedNgo = (ngoId: string) => {
    form.setFieldsValue({
      unverifiedFundedNgos: form
        .getFieldValue('unverifiedFundedNgos')
        .filter((id: string) => id !== ngoId),
    });
  };

  const renderFundedNgosFormItem = () => (
    <>
      <FormItem
        label="→ Porteurs de projets soutenus"
        name="fundedNgos"
        tooltip={
          "À remplir afin d'augmenter la qualité des associations proposées"
        }
        className={classes.fundedNgosFormItem}
        customLabelClassName={classes.customLabelOptional}
      >
        <Select
          allowClear
          mode="multiple"
          onChange={handleChange}
          onSearch={handleSearch}
          onFocus={initSearchFundedNgo}
          filterOption={(input, option) => {
            const label = (option?.label as string) || '';

            return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          options={optionsFundedNgos}
          placeholder={'Recherchez'}
          bordered={false}
          className={`${classes.inputHeight} ${classes.selectTag}`}
          style={{ fontSize: '0.8vw' }}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  padding: 8,
                }}
              >
                <a
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setAddNgoModalOpen(true);
                  }}
                >
                  {"Une organisation n'apparait pas ? Ajoutez-la "}
                  <PlusOutlined />
                </a>
              </div>
            </div>
          )}
        />
      </FormItem>
    </>
  );

  const renderNonRelevantMessage = () => (
    <Text className={classes.nonRelevantMessage}>
      {'Merci de noter cet organisme comme «Non pertinent»'}
    </Text>
  );

  const france = places
    .filter((place) => place.departments && place.departments.length)
    .sort((a, b) =>
      a.name.includes('DROM') || a.name.includes("Collectivités d'Outre-Mer")
        ? 1
        : a.name.localeCompare(b.name),
    ) as Place[];

  const countries = places
    .filter((place) => place.countries && place.countries.length)
    .sort((a, b) => a.name.localeCompare(b.name)) as Place[];

  function renderFrance(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    return isFranceComplete && <Tag className={classes.tag}>France</Tag>;
  }

  function renderDepartments(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return (
      results.length > 0 &&
      results.map((department, index) => (
        <Tag key={index} className={classes.tag}>
          {department}
        </Tag>
      ))
    );
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return;
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return (
      results.length > 0 &&
      results.map((currentCountry, index) => (
        <Tag key={index} className={classes.tag}>
          {currentCountry}
        </Tag>
      ))
    );
  }

  function renderLieuxInterventionFormItem() {
    return (
      <FormItem
        label="→ Lieu(x) d’intervention"
        name="places"
        rules={
          userRole !== 'admin' &&
          !form.getFieldValue('city') &&
          !form.getFieldValue('zipcode') &&
          !form.getFieldValue('roadAndNumber') &&
          !form.getFieldValue('uniqueZipcode')
            ? [
                {
                  required: true,
                  type: 'array',
                  validator: placesValidator,
                },
              ]
            : undefined
        }
        customLabelClassName={classes.customLabel}
      >
        <div>
          <Button
            onClick={() => setShowPlacesModal(true)}
            className={classes.buttonPrevious}
            style={{ height: '3vw', fontSize: '0.875vw' }}
          >
            Choisir les lieu(x) d’intervention
          </Button>
        </div>
        <div style={{ marginTop: '1vw', marginLeft: '' }}>
          {!!form.getFieldValue('roadAndNumber') &&
            !!form.getFieldValue('city') &&
            !!form.getFieldValue('zipcode') && (
              <div>
                <Tag
                  key={'uniqueAddressWithInformations'}
                  className={classes.tag}
                >
                  {form.getFieldValue('roadAndNumber')}
                  {', '}
                  {form.getFieldValue('city')}
                  {', '}
                  {form.getFieldValue('zipcode')}
                </Tag>
              </div>
            )}
          {!!form.getFieldValue('uniqueZipcode') &&
            !!form.getFieldValue('city') && (
              <div>
                <Tag key={'uniqueZipcode'} className={classes.tag}>
                  {form.getFieldValue('uniqueZipcode')}
                  {', '}
                  {form.getFieldValue('city')}
                </Tag>
              </div>
            )}
          {!!form.getFieldValue('places')?.length &&
            (renderFrance(form.getFieldValue('places')) ||
              renderDepartments(form.getFieldValue('places')))}
          {!!form.getFieldValue('places')?.length &&
            renderCountries(form.getFieldValue('places'))}
        </div>
      </FormItem>
    );
  }

  function renderHeadquartersFormItem() {
    return (
      <>
        <div className={classes.field}>
          <FormItem
            label="→ Code postal du siège social"
            name="headquarterZipcode"
            wrapperCol={{ span: 2 }}
            rules={[
              {
                pattern: new RegExp(/^[0-9]{4,5}$/),
                message: 'Un code postal complet est requis.',
              },
              {
                required: true,
                message: 'Un code postal valide est requis.',
              },
            ]}
            customLabelClassName={classes.customLabel}
          >
            <Input
              allowClear
              placeholder={''}
              onChange={(event) => {
                updateCitiesState(event);
              }}
              className={classes.inputHeight}
            />
          </FormItem>
        </div>
        <div className={classes.field} style={{ paddingBottom: '1vw' }}>
          {citiesState.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <FormItem
                  label=""
                  name="headquarterCity"
                  wrapperCol={{ span: 4 }}
                  key={1}
                  className={classes.headquarterCityFormItem}
                  rules={[
                    {
                      required: true,
                      message: 'Cliquez sur une ville proposée.',
                    },
                  ]}
                >
                  <Input
                    allowClear
                    value={cityState}
                    disabled
                    placeholder={''}
                    className={classes.inputHeight}
                  />
                </FormItem>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '0.5vw',
                }}
              >
                {Array.from(new Set(citiesState)).map((city: string, index) => (
                  <div style={{ height: '2vw' }} key={index}>
                    <Tag
                      key={index}
                      onClick={() => {
                        setCityState(city);
                        form.setFieldsValue({
                          headquarterCity: city,
                        });
                      }}
                      className={classes.tagCities}
                      style={
                        city === cityState
                          ? {
                              minHeight: '2vw',
                              paddingRight: '1vw',
                              paddingLeft: '1vw',
                              display: 'inline-flex',
                              alignItems: 'center',
                              borderRadius: '1vw',
                              color: 'white',
                              backgroundColor: '#3B38F8',
                            }
                          : {
                              minHeight: '2vw',
                              display: 'inline-flex',
                              alignItems: 'center',
                              borderRadius: '1vw',
                              color: 'black',
                              backgroundColor: '#e7e7e7',
                              border: '1px solid #e7e7e7',
                            }
                      }
                    >
                      {city}
                    </Tag>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={classes.field} style={{ paddingBottom: '100px' }}>
          <FormItem
            label="→ Numéro et adresse du siège social"
            name="headquarterAddress"
            wrapperCol={{ span: 8 }}
            customLabelClassName={classes.customLabelOptional}
            style={{ marginTop: '1vw' }}
          >
            <Input
              allowClear
              placeholder="Entrez votre réponse ici"
              className={classes.inputHeight}
            />
          </FormItem>
        </div>
      </>
    );
  }

  const handleNextClick = () => {
    if (currentStep === 1) {
      validateStep1()
        .then(() => {
          setCurrentStep(2);
        })
        .catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        });
    } else if (currentStep === 2) {
      validateStep2()
        .then(() => {
          setCurrentStep(3);
        })
        .catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        });
    } else if (currentStep === 3) {
      validateStep3()
        .then(() => {
          setCurrentStep(4);
        })
        .catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        });
    } else if (currentStep === 4) {
      validateStep4()
        .then(() => {
          setCurrentStep(5);
        })
        .catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        });
    } else if (currentStep === 5) {
      validateStep5()
        .then(() => {
          submitForm();
        })
        .catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        });
    }
  };

  const validateStep1 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields([
          'name',
          'status',
          'creationYear',
          'headquarterZipcode',
          'headquarterCity',
          'headquarterAddress',
        ])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep1 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft1}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '20%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.stepCounter}>
                <div className={classes.stepNumber}>1</div>
                <div className={classes.stepTotal}>/5</div>
              </div>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  <Dropdown overlay={(menu as any) || <></>} visible={!!menu}>
                    <FormItem
                      label="→ Nom de l'organisation"
                      name="name"
                      rules={[rules.required()]}
                      normalize={normalizeNullToUndefined}
                      labelCol={{ span: 24 }}
                      customLabelClassName={classes.customLabel}
                    >
                      <Input
                        allowClear
                        placeholder="Entrez votre réponse ici"
                        className={`${classes.search} ${classes.inputHeight}`}
                        onChange={(event) =>
                          debouncedSearchCall(searchName, event.target.value)
                        }
                      />
                    </FormItem>
                  </Dropdown>
                </div>
                <div className={classes.field}>
                  {renderStatusesFormItem()}
                  {statusState ===
                    classification.statuses.find(
                      (status) => status.name === 'shelteredFoundation',
                    )?._id && (
                    <FormItem label="Abritée par" name="hostedBy">
                      <Input />
                    </FormItem>
                  )}
                </div>
                <div className={classes.field} style={{ marginTop: '3.5vw' }}>
                  <FormItem
                    label="→ Année de création"
                    name="creationYear"
                    normalize={(value) => (value === '' ? null : value)}
                    rules={
                      userRole !== 'admin' ? [rules.creationYear()] : undefined
                    }
                    customLabelClassName={classes.customLabelOptional}
                  >
                    <InputNumber
                      placeholder="Entrez votre réponse ici"
                      style={{ width: '100%' }}
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
                {renderHeadquartersFormItem()}
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={() => history.goBack()}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const validateStep2 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields([
          'donationTypes',
          'modusOperandi',
          'tags',
          'places',
          'roadAndNumber',
          'uniqueZipcode',
          'zipcode',
          'city',
          'activityZones',
          'targetPopulations',
          'nationality',
          'targetPerson',
        ])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep2 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft2}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '40%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.stepCounter}>
                <div className={classes.stepNumber}>2</div>
                <div className={classes.stepTotal}>/5</div>
              </div>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  {renderDonationTypeFormItem()}
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="Mode opératoire"
                    name="modusOperandi"
                    rules={
                      userRole !== 'admin'
                        ? [rules.required({ type: 'array' })]
                        : undefined
                    }
                    customLabelClassName={classes.customLabel}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      optionFilterProp="label"
                      bordered={false}
                      className={`${classes.inputHeight} ${classes.selectTag}`}
                      style={{ width: '100%', fontSize: '0.8vw' }}
                    >
                      {Object.values(ModusOperandi).map((modus) => {
                        return (
                          <Option
                            value={modus}
                            key={modus}
                            label={modus}
                            style={{ color: theme.palette.primary }}
                          >
                            {modus}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </div>
                <div className={classes.field}>{renderTagsFormItem()}</div>
                <div className={classes.field}>
                  {renderActivityDomainsFormItem()}
                </div>
                <div className={classes.field}>
                  {renderLieuxInterventionFormItem()}
                </div>
                <div className={classes.field}>
                  {renderActivityZoneFormItem()}
                </div>
                <div className={classes.field}>
                  {renderTargetPopulationsFormItem()}
                </div>
                <div className={classes.field}>
                  {renderNationalitiesFormItem()}
                </div>
                <div
                  className={classes.field}
                  style={{ paddingBottom: '100px' }}
                >
                  {renderTargetPersonsFormItem()}
                </div>
                <div>
                  {nationality === 'other' && (
                    <Row style={{ marginBottom: '24px' }}>
                      <Col span={5} />
                      <Col span={16}>{renderNonRelevantMessage()}</Col>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={() => setCurrentStep(1)}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const validateStep3 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields(['fundedNgos, description'])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep3 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft3}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '60%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.stepCounter}>
                <div className={classes.stepNumber}>3</div>
                <div className={classes.stepTotal}>/5</div>
              </div>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  {renderFundedNgosFormItem()}
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Description"
                    name="description"
                    rules={userRole !== 'admin' ? [rules.max(2000)] : undefined}
                    normalize={normalizeNullToUndefined}
                    customLabelClassName={classes.customLabel}
                  >
                    <TextArea
                      placeholder="Entrez votre réponse ici"
                      autoSize={{ minRows: 4, maxRows: 10 }}
                      maxLength={2000}
                      showCount={true}
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={() => setCurrentStep(2)}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const validateStep4 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields([
          'contactEmail',
          'contactPhone',
          'homepageUrl',
          'logoUrl',
        ])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep4 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft4}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '80%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.stepCounter}>
                <div className={classes.stepNumber}>4</div>
                <div className={classes.stepTotal}>/5</div>
              </div>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  <FormItem
                    label="→ Site"
                    name="homepageUrl"
                    rules={[rules.url()]}
                    normalize={(value) => (value === '' ? null : value)}
                    customLabelClassName={classes.customLabelOptional}
                  >
                    <Input
                      allowClear
                      type="url"
                      placeholder={'https://'}
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Mail de contact"
                    name="contactEmail"
                    rules={
                      userRole === 'admin'
                        ? [rules.email()]
                        : [rules.required(), rules.email()]
                    }
                    normalize={(value) => (value === '' ? null : value)}
                    customLabelClassName={classes.customLabel}
                  >
                    <Input
                      allowClear
                      type="email"
                      placeholder={'clothilde@organisation.com'}
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Numéro de téléphone"
                    name="contactPhone"
                    rules={[rules.phone(), rules.required()]}
                    normalize={(value) => (value === '' ? null : value)}
                    customLabelClassName={classes.customLabel}
                  >
                    <Input
                      allowClear
                      type="tel"
                      placeholder={'+33'}
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
                <div>{renderUploadLogo()}</div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={() => setCurrentStep(3)}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const validateStep5 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields(['linkedinUrl'])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep5 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft5}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '100%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.stepCounter}>
                <div className={classes.stepNumber}>5</div>
                <div className={classes.stepTotal}>/5</div>
              </div>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  <FormItem
                    label="URL de la page Linkedin"
                    name="linkedinUrl"
                    rules={[rules.url()]}
                    normalize={(value) => (value === '' ? null : value)}
                  >
                    <Input
                      allowClear
                      type="url"
                      placeholder={'https://'}
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={() => setCurrentStep(4)}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <Row>
        <Col span={5} />
        <Col span={14}>
          <Form
            {...layout}
            form={form}
            layout={'vertical'}
            initialValues={{ ...donor }}
            onValuesChange={onFormValuesChange}
          >
            {userRole === 'admin' && (
              <>
                <Divider style={{ fontSize: '1vw' }} orientation={'left'}>
                  Champs Admin
                </Divider>
                {uploadFiles}
                {renderUrlFormItem({
                  label: 'Autre url',
                  name: 'otherUrl',
                })}
                {renderUrlFormItem({
                  label: 'Wachete url',
                  name: 'wacheteUrl',
                })}
                {renderUrlFormItem({
                  label: 'Wachete url 2',
                  name: 'wacheteUrl2',
                })}
                {renderUrlFormItem({
                  label: 'Wachete url 3',
                  name: 'wacheteUrl3',
                })}
                {renderLastCallForTenderValidation()}
                {renderLinkedinUrlFormItem({
                  label: 'Linkedin entreprise',
                  name: 'companyLinkedinUrl',
                })}
                <FormItem
                  label="Porteurs de projets soutenus non vérifiés"
                  name="unverifiedFundedNgos"
                >
                  {form
                    .getFieldValue('unverifiedFundedNgos')
                    ?.map((ngoId: string) => (
                      <Tag
                        key={ngoId}
                        className={classes.tag}
                        closable
                        onClose={() => handleRemoveUnverifiedFundedNgo(ngoId)}
                      >
                        {ngos?.find((ngo) => ngo._id === ngoId)?.name}
                      </Tag>
                    ))}
                </FormItem>
                <FormItem
                  label="Commentaires"
                  name="comments"
                  normalize={normalizeNullToUndefined}
                >
                  <TextArea autoSize={{ minRows: 3, maxRows: 10 }} />
                </FormItem>

                <Divider style={{ fontSize: '1vw' }} orientation={'left'}>
                  Champs Non-Admin
                </Divider>
              </>
            )}
            {currentStep === 1 && renderStep1()}
            {currentStep === 2 && renderStep2()}
            {currentStep === 3 && renderStep3()}
            {currentStep === 4 && renderStep4()}
            {currentStep === 5 && renderStep5()}
          </Form>
        </Col>
      </Row>
      {renderAddNgoModal()}
      <TagsSelectorModal
        activityDomains={activityDomains}
        tags={tags}
        isVisible={showTagsModal}
        requestClose={() => setShowTagsModal(false)}
        value={form.getFieldValue('tags')}
        onChange={(value) => form.setFieldsValue({ tags: value })}
      />
      <MoreTagsModal
        values={form.getFieldValue('tags')}
        tags={tags}
        isVisible={showMoreTagsModal}
        requestClose={() => setShowMoreTagsModal(false)}
      />
      <PlacesSelectorModal
        form={form}
        uniqueZipcode={form.getFieldValue('uniqueZipcode')}
        zipcode={form.getFieldValue('zipcode')}
        roadAndNumber={form.getFieldValue('roadAndNumber')}
        city={form.getFieldValue('city')}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
        value={form.getFieldValue('places')}
        onChange={(value) => {
          form.setFieldsValue({ places: value });
        }}
        communes={communes}
      />
    </div>
  );
};

import TextArea from 'antd/es/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  DatePicker,
  Select,
  Tag,
  Button,
  message,
} from 'antd';
import { createUseStyles } from 'react-jss';
import { FormItem } from '../components/FormItem';
import { PlacesSelectorModal } from '../components/PlacesSelectorModal';
import { TagsSelectorModal } from '../components/TagsSelectorModal';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { useFormRules } from '../hooks/useFormRules';
import { theme } from 'src/constants/theme';
import { useSortedClassification } from '../hooks/useSortedClassification';
import { getClassification, Place } from '../slices/classification';
import { getCommunes } from '../slices/communes';
import { getDonor, ModusOperandi } from '../slices/donor';
import { saveContributorNgo, SaveContributorNgoDto } from '../slices/ngo';
import { reset } from '../slices/rna';
import { ContributorAddNgoForm } from './ContributorAddNgoForm';
const { Option } = Select;

interface Props {
  form: FormInstance;
  isNewCallForTender: boolean;
  donorId?: string | undefined;
}

const layout = {
  labelCol: { span: 5 },
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    '& .ant-col': {
      maxWidth: '100% !important', // prevent antd from stretching the form label
    },
  },
  modal: {
    '&.ant-modal': {
      maxWidth: '1000px',
      height: 900,
      zIndex: 2000,
    },
    '&.ant-modal-body': {
      height: 900,
    },
  },
  modalWrap: {
    zIndex: 2000,
  },
  nonRelevantMessage: {
    opacity: 0.75,
    color: theme.palette.red[4],
  },
  tag: {
    fontSize: '14px',
    margin: '2px',
    padding: '2px 7px',
  },
  textArea: {
    height: 200,
    marginBottom: 50,
  },
  menu: {
    width: 800,
    whiteSpace: 'normal',
    height: '60vh',
    overflow: 'auto',
  },
  menuItem: {
    position: 'relative',
    width: '100%',
    wordWrap: 'break-word',
    marginBottom: 20,
  },
  name: {
    width: '100%',
    fontWeight: 'bold',
  },
  description: {
    width: '100%',
  },
  button: {
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  search: {
    '& .ant-input-group-addon': {
      backgroundColor: 'white',
      fontSize: '0.875vw',
      lineHeight: '1.5715',
      height: '100%',
      '& .ant-btn': {
        padding: '4px 0 !important',
        height: 'unset',
        width: '2vw',
        fontSize: '0.875vw !important',
        boxShadow: 'none',
      },
    },
    '& .ant-btn-icon-only > *': {
      fontSize: '1vw',
    },
  },
  trashIcon: {
    color: '#002766',
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
});

const normalizeNullToUndefined = (value: unknown) =>
  value === null ? undefined : value;

const normalizeEmptyStringToNull = (value: unknown) =>
  value === '' ? null : value;

const dateFormat = 'DD/MM/YYYY';

export const DonorEditTenderForm: React.VFC<Props> = ({
  form,
  isNewCallForTender,
  donorId,
}) => {
  const rules = useFormRules();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const classification = useSortedClassification();
  const { communes } = useAppSelector(({ communes: { communes } }) => ({
    communes,
  }));

  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const [addNgoModalOpen, setAddNgoModalOpen] = useState(false);

  useEffect(() => {
    if (communes.length === 0) {
      dispatch(getCommunes());
    }
  }, [communes]);

  useEffect(() => {
    dispatch(getClassification());

    return () => {
      dispatch(reset());
    };
  }, []);

  const { loading, saved, donors, user } = useAppSelector(
    ({ ngo: { loading, saved }, auth: { user }, donor: { donors } }) => ({
      donors,
      loading,
      saved,
      user,
    }),
  );

  const { activityDomains, tags, targetPopulations, places, donationTypes } =
    classification;

  const { RangePicker } = DatePicker;

  const [addNgoForm] = Form.useForm<SaveContributorNgoDto>();

  useEffect(() => {
    if (user?.donorId) {
      dispatch(getDonor(user.donorId));
    }
  }, [user]);

  useEffect(() => {
    if (donorId) {
      dispatch(getDonor(donorId));
    }
  }, [donorId]);

  useEffect(() => {
    if ((!loading || saved) && addNgoModalOpen) {
      setAddNgoModalOpen(false);
    }
  }, [saved]);

  const onAddNgo = async () => {
    const valuesNewNgo = await addNgoForm.validateFields();
    setAddNgoModalOpen(false);
    const resultAction = await dispatch(saveContributorNgo(valuesNewNgo));

    if (saveContributorNgo.fulfilled.match(resultAction)) {
      const fundedNgos = await form.getFieldValue('fundedNgos');

      form.setFieldsValue({
        fundedNgos: [...(fundedNgos || []), resultAction.payload._id],
      });
      message.success('ONG ajoutée avec succès !');
    } else {
      message.error("Erreur lors de l'ajout de l'ONG");
    }
  };

  const renderAddNgoModal = () => (
    <Modal
      className={classes.modal}
      maskStyle={{ zIndex: 1500 }}
      width={1000}
      wrapClassName={classes.modalWrap}
      title={'Ajouter une organisation'}
      onOk={onAddNgo}
      onCancel={() => setAddNgoModalOpen(false)}
      open={addNgoModalOpen} // Replaced `visible` with `open`
      okText={'Confirmer'}
      cancelText={'Annuler'}
      confirmLoading={loading}
    >
      <ContributorAddNgoForm form={addNgoForm} />
    </Modal>
  );

  const renderUrlFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem
      label={label}
      name={name}
      rules={[rules.url()]}
      normalize={normalizeEmptyStringToNull}
    >
      <Input
        type="url"
        placeholder={
          'Déposez ici un lien concernant votre appel à projets : https://...'
        }
      />
    </FormItem>
  );

  const renderNameFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem
      label={label}
      name={name}
      rules={[rules.required()]}
      normalize={normalizeEmptyStringToNull}
    >
      <Input
        placeholder={'Veuillez indiquer le nom de votre appel à projets'}
      />
    </FormItem>
  );

  const renderCommentsFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem label={label} name={name} normalize={normalizeEmptyStringToNull}>
      <TextArea
        autoSize={{ minRows: 3, maxRows: 10 }}
        placeholder={'Espace commentaires ...'}
      />
    </FormItem>
  );

  const renderDescriptionFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem label={label} name={name} normalize={normalizeNullToUndefined}>
      <TextArea
        autoSize={{ minRows: 4, maxRows: 10 }}
        maxLength={2000}
        showCount={true}
        placeholder={'Veuillez décrire votre appel à projets'}
      />
    </FormItem>
  );

  const renderDateFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => {
    return (
      <FormItem label={label} name={name}>
        <RangePicker
          allowEmpty={[true, true]}
          placeholder={['Date de debut', 'Date de fin']}
          format={dateFormat}
        />
      </FormItem>
    );
  };

  const france = places
    .filter((place) => place.departments && place.departments.length)
    .sort((a, b) =>
      a.name.includes('DROM') || a.name.includes("Collectivités d'Outre-Mer")
        ? 1
        : a.name.localeCompare(b.name),
    ) as Place[];

  const countries = places
    .filter((place) => place.countries && place.countries.length)
    .sort((a, b) => a.name.localeCompare(b.name)) as Place[];

  function renderFrance(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    return isFranceComplete && <Tag className={classes.tag}>France</Tag>;
  }

  function renderDepartments(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return (
      results.length > 0 &&
      results.map((department, index) => (
        <Tag key={index} className={classes.tag}>
          {department}
        </Tag>
      ))
    );
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return;
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return (
      results.length > 0 &&
      results.map((currentCountry, index) => (
        <Tag key={index} className={classes.tag}>
          {currentCountry}
        </Tag>
      ))
    );
  }

  function getTags() {
    if (!isNewCallForTender) {
      return form.getFieldValue('tags');
    }
    if (donorId) {
      const tags = donors.byId[donorId]?.tags;
      if (
        user &&
        user.role &&
        user.role === 'admin' &&
        donorId &&
        tags &&
        tags.length <= 12
      ) {
        return donors.byId[donorId].tags;
      }
    }
    const userTags = user?.donorId && donors.byId[user.donorId]?.tags;
    if (user && user.donorId && userTags && userTags.length <= 12) {
      return donors.byId[user?.donorId].tags;
    }
    return [];
  }

  function renderTagsFormItem() {
    return (
      <FormItem label="Mots clés" name="tags">
        <div>
          {!!form.getFieldValue('tags')?.length && (
            <div style={{ marginBottom: '20px' }}>
              {form.getFieldValue('tags')?.map((tagId: string) => (
                <Tag key={tagId} className={classes.tag}>
                  {tags.find((tag) => tag._id === tagId)?.translatedName}
                </Tag>
              ))}
            </div>
          )}
          <Button
            onClick={() => setShowTagsModal(true)}
            className={classes.button}
          >
            Choisir les mots clés
          </Button>
        </div>
      </FormItem>
    );
  }

  useEffect(() => {
    if (form.getFieldValue('tags')?.length > 0 && classification.tags?.length) {
      form.setFieldsValue({
        activityDomains: form
          .getFieldValue('tags')
          .reduce((previousValue: string[], tagId: string) => {
            const activityDomains = classification.tags.find(
              (t) => t._id === tagId,
            )?.associatedDomains;
            if (activityDomains) {
              return previousValue.concat(activityDomains);
            }
            return previousValue;
          }, [])
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index,
          ),
      });
    } else {
      form.setFieldsValue({
        activityDomains: [],
      });
    }
  }, [form.getFieldValue('tags')]);

  function renderActivityDomainsFormItem() {
    return (
      <FormItem label="Domaines d'intervention" name="activityDomains" hidden>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          optionFilterProp="label"
        >
          {activityDomains.map((activityDomain) => {
            return (
              <Option
                value={activityDomain._id}
                key={activityDomain._id}
                label={activityDomain.translatedName}
              >
                {activityDomain.translatedName}
              </Option>
            );
          })}
        </Select>
      </FormItem>
    );
  }

  function renderLieuxInterventionFormItem() {
    return (
      <FormItem label="Lieu(x) d’intervention" name="places">
        <div>
          <div style={{ marginBottom: '20px' }}>
            {!!form.getFieldValue('roadAndNumber') &&
              !!form.getFieldValue('city') &&
              !!form.getFieldValue('zipcode') && (
                <div>
                  <Tag
                    key={'uniqueAddressWithInformations'}
                    className={classes.tag}
                  >
                    {form.getFieldValue('roadAndNumber')}
                    {', '}
                    {form.getFieldValue('city')}
                    {', '}
                    {form.getFieldValue('zipcode')}
                  </Tag>
                </div>
              )}
            {!!form.getFieldValue('uniqueZipcode') &&
              !!form.getFieldValue('city') && (
                <div>
                  <Tag key={'uniqueZipcode'} className={classes.tag}>
                    {form.getFieldValue('uniqueZipcode')}
                    {', '}
                    {form.getFieldValue('city')}
                  </Tag>
                </div>
              )}
            {!!form.getFieldValue('places')?.length &&
              (renderFrance(form.getFieldValue('places')) ||
                renderDepartments(form.getFieldValue('places')))}
            {!!form.getFieldValue('places')?.length &&
              renderCountries(form.getFieldValue('places'))}
          </div>
          <Button
            onClick={() => setShowPlacesModal(true)}
            className={classes.button}
          >
            Choisir les lieu(x) d’intervention
          </Button>
        </div>
      </FormItem>
    );
  }

  function renderTargetPopulationsFormItem() {
    return (
      <FormItem label="Bénéficiaires cibles" name="targetPopulations">
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          optionFilterProp="label"
        >
          {targetPopulations.map((targetPopulation) => {
            return (
              <Option
                value={targetPopulation._id}
                key={targetPopulation._id}
                label={targetPopulation.translatedName}
              >
                {targetPopulation.translatedName}
              </Option>
            );
          })}
        </Select>
      </FormItem>
    );
  }

  return (
    <div className={classes.container}>
      <Row>
        <Col span={5} />
        <Col span={14}>
          <Form {...layout} form={form} layout={'vertical'}>
            {renderNameFormItem({ name: 'name', label: 'Nom' })}
            {renderDescriptionFormItem({
              name: 'description',
              label: 'Description',
            })}
            <FormItem label="Type de mécénat" name="donationTypes">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="label"
              >
                {donationTypes.map((donationType) => {
                  return (
                    <Option
                      value={donationType._id}
                      key={donationType._id}
                      label={donationType.translatedName}
                    >
                      {donationType.translatedName}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
            <FormItem
              label="Modalités du programme de soutien"
              name="modusOperandi"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="label"
              >
                {Object.values(ModusOperandi).map((modus) => {
                  return (
                    <Option value={modus} key={modus} label={modus}>
                      {modus}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
            {renderUrlFormItem({ name: 'url', label: 'Url' })}

            {renderTagsFormItem()}

            {renderActivityDomainsFormItem()}
            {renderLieuxInterventionFormItem()}
            {renderTargetPopulationsFormItem()}

            {renderDateFormItem({
              name: 'openingDate',
              label: 'Dates',
            })}

            {user?.role === 'admin' &&
              renderCommentsFormItem({
                name: 'comments',
                label: 'Commentaires',
              })}
          </Form>
        </Col>
      </Row>
      {renderAddNgoModal()}
      <TagsSelectorModal
        activityDomains={activityDomains}
        tags={tags}
        isVisible={showTagsModal}
        requestClose={() => setShowTagsModal(false)}
        value={getTags()}
        onChange={(value) => form.setFieldsValue({ tags: value })}
      />
      <PlacesSelectorModal
        form={form}
        uniqueZipcode={form.getFieldValue('uniqueZipcode')}
        zipcode={form.getFieldValue('zipcode')}
        roadAndNumber={form.getFieldValue('roadAndNumber')}
        city={form.getFieldValue('city')}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
        value={form.getFieldValue('places')}
        onChange={(value) => {
          form.setFieldsValue({ places: value });
        }}
        communes={communes}
      />
    </div>
  );
};

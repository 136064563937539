import {
  Button,
  Input,
  message,
  Select,
  Table,
  TablePaginationConfig,
} from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  CALL_FOR_TENDERS_MAX_RESULTS,
  CallForTender,
  removeCallForTender,
  searchAllCFT,
  SearchCFTParametersAdmin,
  updateCFTOffer,
  updateCFTPublished,
  CFTOffer,
} from '../slices/callForTender';
import {
  FINANCING_NEEDS_MAX_RESULTS,
  Offer,
  offersAsArray,
} from '../slices/financingNeed';

const { Option } = Select;

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    paddingLeft: '1.25vw',
    paddingRight: '1.25vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  name: {
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '12.5vw',
  },
  id: {
    cursor: 'pointer',
    width: '12.5vw',
  },
  select: {
    display: 'block',
    marginRight: '4px',
    width: '7vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        height: '&vw !important',
        lineHeight: '&vw !important',
        color: 'black',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
  selectSearchBar: {
    display: 'block',
    marginRight: '4px',
    height: '2.5vw !important',
    width: '15vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        height: '2.5vw !important',
        lineHeight: '2.5vw !important',
        color: 'black',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
  searchBar: {
    width: '28vw',
    display: 'flex',
    '& .ant-select .ant-select-selector': {
      height: '2.5vw',
      webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      borderRadius: '0.25vw',
    },
    '& .ant-badge': {
      marginLeft: '3.35%',
      width: '11.16%',
      webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      borderRadius: '0.25vw',
      '& .ant-btn': {
        borderRadius: '0.25vw',
        width: '100%',
        height: '2.5vw',
        padding: '8% 20%',
      },
    },
    '& .ant-input-affix-wrapper': {
      height: '2.5vw',
      padding: '1.14% 3.13%',
      borderRadius: '0.25vw',
      '& .ant-input': {
        fontSize: '0.875vw',
      },
    },
    '& .anticon-search': {
      fontSize: '1.75vw',
      color: '#ccc9e6',
    },
    '& .ant-input-group-addon': {
      verticalAlign: 'top',
      backgroundColor: 'transparent',
      fontSize: '0.875vw',
      lineHeight: 1,
      '& .ant-btn': {
        borderLeft: 'none',
        height: '2.5vw',
        width: '2.5vw',
        borderTopRightRadius: '0.25vw !important',
        borderBottomRightRadius: '0.25vw !important',
      },
    },
    '& .ant-btn': {
      lineHeight: '1',
    },
  },
  column: {
    color: 'black',
    fontWeight: 'bold',
    cursor: 'pointer',
    maxWidth: '18.75vw',
  },
  searchContainer: {
    paddingLeft: '1.25vw',
    paddingRight: '1.25vw',
    marginTop: '1.5vw',
    marginBottom: '1.5vw',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const CallForTendersAdmin: React.VFC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { callForTenders, loading, offset, searchResult, saved } =
    useAppSelector(
      ({
        callForTender: { callForTenders, loading, offset, searchResult, saved },
      }) => ({
        callForTenders,
        loading,
        offset,
        searchResult,
        saved,
      }),
    );
  enum fieldType {
    ID = 'id',
    NAME = 'name',
    ADMINDONOR = 'adminDonor',
  }

  const [searchFilters, setSearchFilters] = useState<SearchCFTParametersAdmin>(
    {},
  );
  // const [field, setField] = useState<fieldType>(fieldType.NAME);
  const [field] = useState<fieldType>(fieldType.NAME);

  useEffect(() => {
    fetchCallForTenders(1);
  }, []);

  useEffect(() => {
    if (saved) {
      fetchCallForTenders(offset / CALL_FOR_TENDERS_MAX_RESULTS + 1);
    }
  }, [saved]);

  useEffect(() => {
    fetchCallForTenders(1);
  }, [searchFilters]);

  const callForTendersList = useMemo<CallForTender[]>(() => {
    return callForTenders.allIds.reduce(
      (accumulator: CallForTender[], current: string) =>
        callForTenders.byId[current]
          ? [...accumulator, callForTenders.byId[current]]
          : accumulator,
      [],
    );
  }, [callForTenders]);

  const fetchCallForTenders = (page: number) => {
    dispatch(
      searchAllCFT({
        ...searchFilters,
        offset: (page - 1) * CALL_FOR_TENDERS_MAX_RESULTS,
      }),
    );
  };

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 1;
    fetchCallForTenders(newSearchPage);
  };

  const onSelectOffer = (value: Offer, callForTender: CallForTender) => {
    if (
      callForTender.offers &&
      !callForTender.offers.find((o) => o.offer === value)
    ) {
      dispatch(
        updateCFTOffer({
          cftId: callForTender._id,
          offer: value,
          type: 'add',
        }),
      );
      console.log('Offer added');
    } else if (callForTender.offers) {
      console.log('Offer already exists and offers array exists');
    } else {
      console.log('Error: offers array does not exist');
    }
  };

  const onDeselectOffer = (value: Offer, callForTender: CallForTender) => {
    if (
      callForTender.offers &&
      callForTender.offers.find((o) => o.offer === value)
    ) {
      dispatch(
        updateCFTOffer({
          cftId: callForTender._id,
          offer: value,
          type: 'delete',
        }),
      );
      console.log('Offer deleted');
    } else if (callForTender.offers) {
      console.log('Offer does not exist but offers array exists');
    } else {
      console.log('Error: offers array does not exist');
    }
  };

  const onSelectOfferFilter = (value: string) => {
    let offer: Offer | undefined;
    switch (value) {
      case 'visibility':
        offer = Offer.visibility;
        break;
      case 'complete':
        offer = Offer.complete;
        break;
      default:
        offer = undefined;
        break;
    }
    setSearchFilters((old) => {
      return {
        ...old,
        offer: offer,
      };
    });
    return;
  };

  const onSelectPublished = (value: boolean, callForTenderId: string) => {
    dispatch(
      updateCFTPublished({
        cftId: callForTenderId,
        published: value,
      }),
    );
  };

  const isValidMongoId = (id: string) => {
    return id.match(/^[0-9a-fA-F]{24}$/);
  };

  const onChangeField = (value: string | SelectValue) => {
    if (
      ((field === fieldType.ID || field === fieldType.ADMINDONOR) &&
        !isValidMongoId(value as string)) ||
      (field === fieldType.NAME && (value as string) === '')
    ) {
      value = undefined;
    }
    const newSearchFilters = {
      ...searchFilters,
      name: field === fieldType.NAME ? value : undefined,
      id: field === fieldType.ID ? value : undefined,
      adminDonor: field === fieldType.ADMINDONOR ? value : undefined,
    } as SearchCFTParametersAdmin;

    setSearchFilters(newSearchFilters);
  };

  // const onSelectOption = (value: fieldType) => {
  //   setField(value);
  // };

  function getOfferLabel(record: CallForTender, offerToDisplay: Offer) {
    const offerFound =
      offerToDisplay === Offer.complete
        ? record.offers.find((o) => o.offer === Offer.complete)
        : undefined;
    if (offerFound && offerFound.expirationDate) {
      const expirationDate = offerFound.expirationDate;
      const currentDate = new Date();
      const expirationDateObj = expirationDate
        ? new Date(expirationDate)
        : null;
      if (expirationDateObj && expirationDateObj > currentDate) {
        return `Souscrit ${offerFound?.expirationDate}`.split('T')[0];
      } else {
        return `Non renouvelé ${offerFound?.expirationDate}`.split('T')[0];
      }
    } else {
      return 'Non Souscrit';
    }
  }

  function renderModifOffer(name: string, record: CallForTender) {
    const defaultValues: Offer[] = [];
    if (record.offers) {
      record.offers.map((o) => defaultValues.push(o.offer));
    }
    return (
      <>
        <Select
          mode="multiple"
          defaultValue={defaultValues}
          className={classes.select}
          onSelect={(value: Offer) => onSelectOffer(value, record)}
          onDeselect={(value: Offer) => onDeselectOffer(value, record)}
        >
          {offersAsArray.map((offer, index) => (
            <Option
              key={index}
              value={offer.value}
              label={offer.label}
              style={{ fontSize: '0.875vw' }}
            >
              {offer.label}
            </Option>
          ))}
        </Select>
      </>
    );
  }

  function renderModifPublished(name: string, record: CallForTender) {
    return (
      <>
        <Select
          placeholder={record.published ? 'Yes' : 'No'}
          className={classes.select}
          onSelect={(value: string) =>
            onSelectPublished(value === 'Yes', record._id)
          }
        >
          {['Yes', 'No'].map((status, index) => (
            <Option
              key={index}
              value={status}
              label={status}
              style={{ fontSize: '0.875vw' }}
            >
              {status}
            </Option>
          ))}
        </Select>
      </>
    );
  }

  const deleteCallForTenderById = (
    projectId: string,
    offers: CFTOffer[],
    adminDonors: { _id: string; name: string }[],
  ) => {
    if (offers.find((o) => o.offer !== Offer.visibility)) {
      return message.error(
        'Vous ne pouvez pas supprimer un programme de soutien avec une offre autre que "Visibilité"',
      );
    }
    const value = prompt(
      'Veuillez saisir le mot de passe pour confirmer la suppression',
      '0000',
    );
    if (value !== '0122') {
      return alert('Mot de passe incorrect');
    }
    dispatch(
      removeCallForTender({
        callForTenderId: projectId,
        body: {
          donorId:
            adminDonors && adminDonors.length > 0
              ? adminDonors[0]._id
              : undefined,
        },
      }),
    );
    fetchCallForTenders(offset / FINANCING_NEEDS_MAX_RESULTS + 1);
  };

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
      render: function renderName(name: string, record: CallForTender) {
        return (
          <div
            className={classes.name}
            onClick={() =>
              record.adminDonors &&
              history.push(
                `/donor/${record?.adminDonors[0]._id}/callForTender/${record._id}`,
              )
            }
          >
            {name}
          </div>
        );
      },
    },
    {
      title: 'Mécène',
      dataIndex: 'donor',
      key: 'donor',
      render: function renderName(_: any, record: CallForTender) {
        return record.adminDonors && record.adminDonors[0] ? (
          <div
            className={classes.id}
            onClick={() => history.push(`/donor/${record.adminDonors[0]._id}`)}
          >
            {record.adminDonors.length ? record.adminDonors[0].name : ''}
          </div>
        ) : (
          <div className={classes.id}>-</div>
        );
      },
    },
    {
      title: 'Offre',
      dataIndex: 'offer',
      key: 'offer',
      render: renderModifOffer,
    },
    {
      title: 'Offre Nationale',
      dataIndex: 'offers',
      key: 'offers',
      render: function renderOfferComplete(_: any, record: CallForTender) {
        return (
          <div className={classes.id}>
            {getOfferLabel(record, Offer.complete)}
          </div>
        );
      },
    },
    {
      title: 'Publié',
      dataIndex: 'published',
      key: 'published',
      render: renderModifPublished,
    },
    {
      title: 'Suppression',
      key: 'deleteFinancingNeed',
      width: '10%',
      render: function renderDeleteDonor(record: CallForTender) {
        return (
          <div className={classes.column}>
            <Button
              type="primary"
              danger
              // className={classes.button}
              onClick={() =>
                deleteCallForTenderById(
                  record._id,
                  record.offers,
                  record.adminDonors,
                )
              }
            >
              Supprimer
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className={classes.searchContainer}>
        <Input.Group compact>
          {/* <Select
            defaultValue={field}
            className={classes.selectSearchBar}
            onSelect={onSelectOption}
          >
            <Option key="0" value={fieldType.NAME}>
              Par nom
            </Option>
            <Option key="1" value={fieldType.ID}>
              Par id de l&#39;appel à projet
            </Option>
            <Option key="2" value={fieldType.ADMINDONOR}>
              Par id du mécène
            </Option>
          </Select> */}
          <Input.Search
            className={classes.searchBar}
            allowClear
            placeholder="Rechercher"
            onSearch={(value) => {
              onChangeField(value);
            }}
            onChange={(e) => onChangeField(e.target.value)}
          />
          <Select
            defaultValue={'all'}
            className={classes.selectSearchBar}
            onSelect={onSelectOfferFilter}
          >
            <Option key="0" value={'all'}>
              Toutes les offres
            </Option>
            <Option key="1" value={'visibility'}>
              Offre visibilité
            </Option>
            <Option key="2" value={'complete'}>
              Offre nationale
            </Option>
          </Select>
        </Input.Group>
      </div>
      <Table
        className={classes.table}
        dataSource={callForTendersList}
        rowKey={(record) => record._id}
        columns={columns}
        onChange={onTableChange}
        loading={loading || !CallForTendersAdmin}
        pagination={{
          pageSize: CALL_FOR_TENDERS_MAX_RESULTS,
          total: searchResult?.resultsCount,
          current: offset / CALL_FOR_TENDERS_MAX_RESULTS + 1,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
      />
    </>
  );
};

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Tag,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FormItem } from '../components/FormItem';
import { PlacesSelectorModal } from '../components/PlacesSelectorModal';
import { TagsSelectorModal } from '../components/TagsSelectorModal';
import { IUploadProps, Upload } from '../components/Upload';
import { theme } from '../constants/theme';

import { useAppSelector, useAppDispatch } from '../hooks/store';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { placesValidator, tagsValidator } from '../helpers/validators';
import { useFormRules } from '../hooks/useFormRules';
import { useSortedClassification } from '../hooks/useSortedClassification';
import { Place } from '../slices/classification';
import { getCommunes } from '../slices/communes';
import { getNgo } from '../slices/ngo';
import { FinancingNeed } from 'src/slices/financingNeed';
import { FormInstance } from 'antd/es/form/Form';
import { MoreTagsModal } from 'src/components/MoreTagsModal';

const { Link } = Typography;
const { Option } = Select;

const TAGS_MAX_QUANTITY = 12;

const layout = {
  labelCol: { span: 8 },
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    textAlign: 'left',
    '& .ant-form-item, .ant-form-item-label > label,\
        .ant-input, .ant-input-number': {
      fontSize: '0.875vw',
    },
    '& .ant-col': {
      maxWidth: '100%',
    },
  },
  stepContainer: {
    height: '75vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  stepColLeft1: {
    backgroundImage: `url("../../images/projectStep1.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft2: {
    backgroundImage: `url("../../images/projectStep2.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColRight: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '40vw',
    position: 'relative',
  },
  stepImage: {
    width: '40vw',
    position: 'fixed',
  },
  stepBar: {
    height: '1vw',
    backgroundColor: theme.palette.primary,
  },
  colRightPadding: {
    marginLeft: '6rem',
  },
  stepCounter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  stepNumber: {
    fontSize: '2.5vw',
  },
  stepTotal: {
    fontSize: '1.2vw',
    color: theme.palette.primary,
  },
  buttonBox: {
    position: 'fixed',
    bottom: '0',
    left: '40vw',
    width: '40%',
    paddingBottom: '2.5vw',
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    marginLeft: '6rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.8rem',
  },
  buttonPrevious: {
    width: 'auto',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  buttonNext: {
    width: 'auto',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  buttonUpload: {
    width: 'auto%',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: theme.palette.primary,
    color: 'white',
    borderRadius: '0.5vw',
  },
  textArea: {
    height: 200,
    marginBottom: 50,
  },
  tagCities: {
    fontSize: '1vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    color: 'white',
    minHeight: '1.5vw',
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: '0.5vw',
    backgroundColor: theme.palette.primary,
  },
  menu: {
    width: 800,
    whiteSpace: 'normal',
    height: '60vh',
    overflow: 'auto',
  },
  menuItem: {
    position: 'relative',
    width: '100%',
    wordWrap: 'break-word',
    marginBottom: 20,
  },
  name: {
    width: '100%',
    fontWeight: 'bold',
  },
  description: {
    width: '100%',
  },
  tag: {
    minHeight: '1.65vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '0.4vw',
    fontSize: '0.8vw',
    height: '1vw',
    color: 'white',
    backgroundColor: '#5050f6',
    border: '1px solid #5050f6',
    borderRadius: '1vw',
    paddingRight: '0.5vw',
    paddingLeft: '0.5vw',
  },
  placesTag: {
    minHeight: '2.5vw',
    fontSize: '1vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '1vw',
    color: 'white',
    backgroundColor: theme.palette.primary,
  },
  addressTag: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontSize: '1vw',
    marginLeft: '1rem',
    marginTop: '0.4rem',
  },
  button: {
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  search: {
    '& .ant-input-group-addon': {
      backgroundColor: 'white',
      fontSize: '0.875vw',
      lineHeight: '1.5715',
      height: '100%',
      '& .ant-btn': {
        padding: '4px 0 !important',
        height: 'unset',
        width: '2vw',
        fontSize: '0.875vw !important',
        boxShadow: 'none',
      },
    },
    '& .ant-btn-icon-only > *': {
      fontSize: '1vw',
    },
  },
  trashIcon: {
    color: '#002766',
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  headquarterCityFormItem: {
    '& .ant-input-affix-wrapper-disabled': {
      backgroundColor: 'white',
    },
    '& .ant-input[disabled]': {
      color: 'black',
    },
  },
  donorsFormItem: {
    marginTop: '1vh',
    '& .ant-form-item-tooltip': {
      color: '#5050F9 !important',
    },
  },
  modal: {
    '&.ant-modal': {
      maxWidth: '1000px',
      height: 900,
      zIndex: 2000,
    },
    '&.ant-modal-body': {
      height: 900,
    },
  },
  modalWrap: {
    zIndex: 2000,
  },
  customLabel: {
    position: 'relative',
    fontSize: '1vw',
    '&::after': {
      content: '"*"',
      color: '#ff4d4f',
      fontSize: '1.2vw',
      marginLeft: '0.2vw',
    },
  },
  customLabelOptional: {
    position: 'relative',
    fontSize: '1vw',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '75%',
  },
  inputHeight: {
    minHeight: '2vw',
    '& input': {
      height: '100%',
      padding: '0.5vw 1vw',
      color: 'black',
    },
    border: 'none',
    borderBottom: '1px solid #5050f6',
    color: '#5050f6',
    '& input::placeholder': {
      color: theme.palette.primary,
    },
    '& textarea::placeholder': {
      color: theme.palette.primary,
    },
    '& .ant-select-selection-placeholder': {
      color: theme.palette.primary,
    },
    '& textarea': {
      borderColor: '#5050f6',
      borderBottom: 'none',
      color: 'black',
    },
  },
  selectTag: {
    '& .ant-select-selection-item': {
      border: 'none',
      backgroundColor: 'white',
      maxHeight: '1.65vw',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .ant-select-selection-item-content': {
      color: 'white',
      backgroundColor: '#5050f6',
      border: '1px solid #5050f6',
      borderRadius: '1vw',
      paddingRight: '0.5vw',
      paddingLeft: '0.5vw',
    },
  },
});

const normalizeEmptyStringToNull = (value: unknown) =>
  value === '' ? null : value;

interface IFinancingNeedStepForm {
  form: FormInstance<FinancingNeed>;
  isNewFinancingNeed: boolean;
  ngoId?: string;
  submitForm: () => void;
  onCancel: () => void;
}

export const NewFinancingNeedEditForm: React.VFC<IFinancingNeedStepForm> = ({
  form,
  isNewFinancingNeed,
  ngoId,
  submitForm,
  onCancel,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const rules = useFormRules();
  const classification = useSortedClassification();
  const { ngo, user, userRole, communes } = useAppSelector(
    ({ ngo: { ngo }, auth: { user }, communes: { communes } }) => ({
      ngo,
      user,
      userRole: user?.role,
      communes,
    }),
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showMoreTagsModal, setShowMoreTagsModal] = useState(false);
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string | undefined | null>(undefined);

  useEffect(() => {
    if (ngoId) {
      dispatch(getNgo(ngoId));
      return;
    }
    if (user?.ngoId && !ngo) {
      dispatch(getNgo(user?.ngoId));
    }
  }, [user?.ngoId, ngoId]);

  useEffect(() => {
    dispatch(getCommunes());
  }, []);

  useEffect(() => {
    form.setFieldsValue({ logoUrl });
  }, [logoUrl]);

  useEffect(() => {
    if (form.getFieldValue('logoUrl')) {
      setLogoUrl(form.getFieldValue('logoUrl'));
    }
  }, [form.getFieldValue('logoUrl')]);

  useEffect(() => {
    if (form.getFieldValue('tags')?.length > 0 && classification.tags?.length) {
      form.setFieldsValue({
        activityDomains: form
          .getFieldValue('tags')
          .reduce((previousValue: string[], tagId: string) => {
            const activityDomains = classification.tags.find(
              (t) => t._id === tagId,
            )?.associatedDomains;
            if (activityDomains) {
              return previousValue.concat(activityDomains);
            }
            return previousValue;
          }, [])
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index,
          ),
      });
    } else {
      form.setFieldsValue({
        activityDomains: [],
      });
    }
  }, [form.getFieldValue('tags')]);

  if (!classification || !ngo) return <FullscreenSpin />;

  const { tags, places, activityDomains, targetPopulations } = classification;

  const renderLogo = () => {
    return (
      logoUrl && (
        <div>
          <Row align="middle">
            <Col xs={8} />
            <Col xs={5} xxl={3}>
              <div
                style={{ display: 'flex', float: 'left', marginTop: '10px' }}
              >
                <Image
                  src={logoUrl}
                  width="90px"
                  height="90px"
                  fallback="error"
                  alt="Logo"
                />
              </div>
            </Col>
            <Col xs={9} xxl={10}>
              <Link onClick={() => setLogoUrl(null)} style={{ float: 'left' }}>
                <DeleteOutlined className={classes.trashIcon} />
              </Link>
            </Col>
          </Row>
        </div>
      )
    );
  };

  const onSuccessLogoUpload = (info: any) => {
    if (info.file.type === 'image/png' || info.file.type === 'image/jpeg') {
      message.success('Logo ajouté avec succès !');
      setLogoUrl(info.file.response);
    } else {
      message.error('Seul les formats jpeg et png sont acceptés');
    }
  };

  const uploadLogoProps: IUploadProps = {
    name: 'file',
    endpoint: '/file/upload',
    onSuccess: onSuccessLogoUpload,
    text: 'Importez votre logo',
    children: (
      <Button
        className={classes.buttonPrevious}
        style={{
          height: '3vw',
          fontSize: '0.875vw',
        }}
        icon={<UploadOutlined />}
      >
        J&#39;importe le logo de mon projet
      </Button>
    ),
    uploadProps: {
      showUploadList: false,
    },
  };

  const renderUploadLogo = () => {
    return (
      <div>
        <Row align="middle" style={{ paddingBottom: '1vw' }}>
          <Text
            style={{
              textAlign: 'left',
              width: '100%',
              marginRight: '8px',
              fontSize: '1vw',
              marginBottom: '8000px !important',
            }}
          >
            → Importez votre logo
          </Text>
        </Row>
        <Row align="middle" style={{ paddingBottom: '8px' }}>
          <Upload {...uploadLogoProps} />
        </Row>
        {renderLogo()}
      </div>
    );
  };

  const france = places
    .filter((place) => place.departments && place.departments.length)
    .sort((a, b) =>
      a.name.includes('DROM') || a.name.includes("Collectivités d'Outre-Mer")
        ? 1
        : a.name.localeCompare(b.name),
    ) as Place[];

  const countries = places
    .filter((place) => place.countries && place.countries.length)
    .sort((a, b) => a.name.localeCompare(b.name)) as Place[];

  function renderFrance(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    return isFranceComplete && <Tag className={classes.tag}>France</Tag>;
  }

  function renderDepartments(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return (
      results.length > 0 &&
      results.map((department, index) => (
        <Tag key={index} className={classes.tag}>
          {department}
        </Tag>
      ))
    );
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return;
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return (
      results.length > 0 &&
      results.map((currentCountry, index) => (
        <Tag key={index} className={classes.tag}>
          {currentCountry}
        </Tag>
      ))
    );
    return false;
  }

  function getTags() {
    if (!isNewFinancingNeed) {
      return form.getFieldValue('tags');
    }
    if (ngo && ngo.tags && ngo.tags.length <= 12) {
      return ngo.tags;
    }
    return [];
  }

  const handleNextClick = () => {
    if (currentStep === 1) {
      validateStep1()
        .then(() => {
          setCurrentStep(2);
        })
        .catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        });
    } else if (currentStep === 2) {
      validateStep2()
        .then(() => {
          submitForm();
        })
        .catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        });
    }
  };

  const validateStep1 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields([
          'name',
          'targetPopulations',
          'tags',
          'places',
          'roadAndNumber',
          'uniqueZipcode',
          'zipcode',
          'city',
          'description',
        ])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep1 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft1}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '33%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.stepCounter}>
                <div className={classes.stepNumber}>1</div>
                <div className={classes.stepTotal}>/2</div>
              </div>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  <FormItem
                    label="→ Nom de votre projet"
                    name="name"
                    rules={[rules.required()]}
                    customLabelClassName={classes.customLabel}
                  >
                    <Input
                      allowClear
                      className={classes.inputHeight}
                      placeholder="Entrez votre réponse ici"
                    />
                  </FormItem>
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Bénéficiaires cibles"
                    name="targetPopulations"
                    rules={[rules.required({ type: 'array' })]}
                    customLabelClassName={classes.customLabel}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ fontSize: '0.8vw' }}
                      optionFilterProp="label"
                      bordered={false}
                      className={`${classes.inputHeight} ${classes.selectTag}`}
                      placeholder={'Entrez votre réponse ici'}
                    >
                      {targetPopulations.map((targetPopulation) => {
                        return (
                          <Option
                            value={targetPopulation._id}
                            key={targetPopulation._id}
                            label={targetPopulation.translatedName}
                            style={{ color: theme.palette.primary }}
                          >
                            {targetPopulation.translatedName}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Mots clés"
                    name="tags"
                    rules={[
                      {
                        required: true,
                        type: 'array',
                        validator: tagsValidator,
                      },
                    ]}
                    customLabelClassName={classes.customLabel}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Button
                        onClick={() => setShowTagsModal(true)}
                        className={classes.buttonPrevious}
                        style={{
                          height: '3vw',
                          width: '12vw',
                          fontSize: '0.875vw',
                        }}
                      >
                        Choisir les mots clés
                      </Button>
                      {!!form.getFieldValue('tags')?.length && (
                        <div
                          style={{
                            marginTop: '1vw',
                            display: 'inline-flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: '0.4vw',
                          }}
                        >
                          {form
                            .getFieldValue('tags')
                            ?.slice(0, 4)
                            .map((tagId: string) => (
                              <Tag key={tagId} className={classes.tag}>
                                {
                                  tags.find((tag) => tag._id === tagId)
                                    ?.translatedName
                                }
                              </Tag>
                            ))}
                          {form.getFieldValue('tags')?.length > 4 && (
                            <Tag
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                borderRadius: '1vw',
                                maxHeight: '1.65vw',
                              }}
                            >
                              <span
                                style={{
                                  marginRight: '0.5vw',
                                  fontSize: '1vw',
                                }}
                              >
                                +{form.getFieldValue('tags')?.length - 4}
                              </span>{' '}
                              <span>
                                <Link
                                  style={{
                                    fontSize: '0.7vw',
                                    textDecoration: 'underline',
                                  }}
                                  onClick={() => setShowMoreTagsModal(true)}
                                >
                                  voir plus
                                </Link>
                              </span>
                            </Tag>
                          )}
                        </div>
                      )}
                    </div>
                  </FormItem>
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Lieu(x) d’intervention"
                    name="places"
                    rules={
                      userRole !== 'admin' &&
                      !form.getFieldValue('city') &&
                      !form.getFieldValue('zipcode') &&
                      !form.getFieldValue('roadAndNumber') &&
                      !form.getFieldValue('uniqueZipcode')
                        ? [
                            {
                              required: true,
                              type: 'array',
                              validator: placesValidator,
                            },
                          ]
                        : undefined
                    }
                    customLabelClassName={classes.customLabel}
                  >
                    <div>
                      <Button
                        onClick={() => setShowPlacesModal(true)}
                        className={classes.buttonPrevious}
                        style={{ height: '3vw', fontSize: '0.875vw' }}
                      >
                        Choisir les lieu(x) d’intervention
                      </Button>
                    </div>
                    <div style={{ marginTop: '1vw', marginLeft: '' }}>
                      {!!form.getFieldValue('roadAndNumber') &&
                        !!form.getFieldValue('city') &&
                        !!form.getFieldValue('zipcode') && (
                          <div>
                            <Tag
                              key={'uniqueAddressWithInformations'}
                              className={classes.tag}
                            >
                              {form.getFieldValue('roadAndNumber')}
                              {', '}
                              {form.getFieldValue('city')}
                              {', '}
                              {form.getFieldValue('zipcode')}
                            </Tag>
                          </div>
                        )}
                      {!!form.getFieldValue('uniqueZipcode') &&
                        !!form.getFieldValue('city') && (
                          <div>
                            <Tag key={'uniqueZipcode'} className={classes.tag}>
                              {form.getFieldValue('uniqueZipcode')}
                              {', '}
                              {form.getFieldValue('city')}
                            </Tag>
                          </div>
                        )}
                      {!!form.getFieldValue('places')?.length &&
                        (renderFrance(form.getFieldValue('places')) ||
                          renderDepartments(form.getFieldValue('places')))}
                      {!!form.getFieldValue('places')?.length &&
                        renderCountries(form.getFieldValue('places'))}
                    </div>
                  </FormItem>
                </div>
                <div
                  className={classes.field}
                  style={{ marginBottom: 'max(10dvh, 3dvw)' }}
                >
                  <FormItem
                    label="→ Description"
                    name="description"
                    rules={[rules.max(2000)]}
                    customLabelClassName={classes.customLabelOptional}
                  >
                    <TextArea
                      className={classes.inputHeight}
                      placeholder="Entrez votre réponse ici"
                      autoSize={{ minRows: 4, maxRows: 10 }}
                      maxLength={2000}
                      showCount={true}
                    />
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={onCancel}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const validateStep2 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields(['websiteUrl', 'logoUrl'])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep2 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft2}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '66%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.stepCounter}>
                <div className={classes.stepNumber}>2</div>
                <div className={classes.stepTotal}>/2</div>
              </div>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  <FormItem
                    label="→ Url du Site web dédié au projet"
                    name="websiteUrl"
                    rules={[rules.url()]}
                    normalize={normalizeEmptyStringToNull}
                    customLabelClassName={classes.customLabelOptional}
                  >
                    <Input
                      allowClear
                      className={classes.inputHeight}
                      type="url"
                      placeholder={'https://'}
                    />
                  </FormItem>
                </div>
                <div className={classes.field}>
                  {renderUploadLogo()}
                  <Form.Item name="logoUrl" noStyle>
                    <Input type="hidden" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={() => setCurrentStep(1)}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <Row>
        <Col span={5} />
        <Col span={20}>
          <Form
            {...layout}
            form={form}
            initialValues={{ adminNgos: [ngo._id] }}
            layout={'vertical'}
          >
            {currentStep === 1 && renderStep1()}
            {currentStep === 2 && renderStep2()}
            <FormItem
              label="Domaines d'intervention"
              name="activityDomains"
              hidden
              rules={
                userRole !== 'admin'
                  ? [rules.required({ type: 'array' })]
                  : undefined
              }
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="label"
              >
                {activityDomains.map((activityDomain) => {
                  return (
                    <Option
                      value={activityDomain._id}
                      key={activityDomain._id}
                      label={activityDomain.translatedName}
                    >
                      {activityDomain.translatedName}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          </Form>
        </Col>
      </Row>
      <TagsSelectorModal
        activityDomains={activityDomains}
        tags={tags}
        isVisible={showTagsModal}
        requestClose={() => setShowTagsModal(false)}
        value={getTags()}
        onChange={(value) => form.setFieldsValue({ tags: value })}
        tagsMaxQuantity={TAGS_MAX_QUANTITY}
      />
      <MoreTagsModal
        values={form.getFieldValue('tags')}
        tags={tags}
        isVisible={showMoreTagsModal}
        requestClose={() => setShowMoreTagsModal(false)}
      />
      <PlacesSelectorModal
        form={form}
        uniqueZipcode={form.getFieldValue('uniqueZipcode')}
        roadAndNumber={form.getFieldValue('roadAndNumber')}
        city={form.getFieldValue('city')}
        zipcode={form.getFieldValue('zipcode')}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
        value={form.getFieldValue('places')}
        onChange={(value) => {
          form.setFieldsValue({ places: value });
        }}
        placesMaxQuantity={12}
        communes={communes}
      />
    </div>
  );
};

import { Button, Select, Table, TablePaginationConfig } from 'antd';
import { parse, stringify } from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  DatabaseAdminSearchBar,
  SortOrder,
} from 'src/components/DatabseAdminSearchBar';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { fetchAllContributors } from 'src/slices/contributor';

import {
  deleteDonor,
  Donor,
  DONORS_MAX_RESULTS,
  searchAllDonors,
  updateDonorPublicationStatus,
  updateDonorPublished,
} from 'src/slices/donor';
import { contributorsSelector } from 'src/store';
import { SearchFiltersAdminParameters } from '../components/DatabseAdminSearchBar';
import { useCaptchaDispatch } from '../hooks/useCaptchaDispatch';
import { getClassification } from '../slices/classification';
import { getUsers } from 'src/slices/auth';

const { Option } = Select;

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    paddingLeft: '1.25vw',
    paddingRight: '1.25vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  button: {
    display: 'block',
    margin: 'auto',
    padding: '0.25vw 0.9375vw',
    height: '2.4vw',
    fontSize: '0.875vw',
    width: '17.5vw',
  },
  select: {
    display: 'block',
    marginRight: '4px',
    width: '10vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        color: 'black',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
  column: {
    color: 'black',
    fontWeight: 'bold',
    cursor: 'pointer',
    maxWidth: '18.75vw',
  },
  columnNotClickable: {
    color: 'black',
    fontWeight: 'bold',
    maxWidth: '18.75vw',
    cursor: 'default',
  },
  name: {
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '12.5vw',
  },
  publicationStatus: {
    width: '8.5vw',
  },
  placeholder: {
    width: '12.5vw',
    color: 'black',
  },
  selectContainer: {
    display: 'flex',
    alignContent: 'left',
    paddingLeft: '10%',
  },
  searchContainer: {
    paddingLeft: '1.25vw',
    paddingRight: '1.25vw',
    marginBottom: '2vw',
    maxWidth: '100%',
    display: 'flex',
  },
  createContainer: {
    position: 'relative',
    display: 'flex',
    float: 'right',
    margin: '0 20px 10px 90px',
  },
  nbRef: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const Donors: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const query = useLocation().search;
  const history = useHistory();
  const captchaDispatch = useCaptchaDispatch();

  const {
    donors,
    loading,
    searchResult,
    offset,
    saved,
    classification,
    users,
  } = useAppSelector(
    ({
      auth,
      donor: { donors, loading, searchResult, offset, saved },
      classification,
    }) => ({
      donors,
      loading,
      searchResult,
      offset,
      saved,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      classification: classification.classification!,
      users: auth.users,
    }),
  );

  const contributors = useAppSelector(contributorsSelector.selectAll);

  const [searchFilters, setSearchFilters] =
    useState<SearchFiltersAdminParameters>({});

  let queryParamsObject: Record<string, unknown> = {};

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    dispatch(fetchAllContributors(true));
    !classification && dispatch(getClassification());
    fetchDonorsWithQueryParams();
  }, []);

  useEffect(() => {
    if (saved) {
      dispatch(fetchAllContributors(true));
      fetchDonorsWithQueryParams();
    }
  }, [saved]);

  const donorsList = useMemo<Donor[]>(
    () =>
      donors.allIds.reduce(
        (accumulator: Donor[], current: string) =>
          donors.byId[current]
            ? [...accumulator, donors.byId[current]]
            : accumulator,
        [],
      ),
    [donors],
  );

  const updateQueryParams = (params: Record<string, unknown>) => {
    const { name, ...rest } = params;

    queryParamsObject = {
      ...queryParamsObject,
      ...rest,
      ...(name === '' ? {} : { name }),
    };
    history.replace({
      search: stringify(queryParamsObject, { arrayFormat: 'bracket' }),
    });
  };

  const fetchDonorsWithQueryParams = () => {
    const {
      page,
      name,
      tags,
      statuses,
      targetPersons,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      source,
      nationality,
      publicationStatus,
      author,
      createdAtStart,
      createdAtEnd,
      updatedAtStart,
      updatedAtEnd,
    } = parse(query, { arrayFormat: 'bracket' });

    const {
      hasHomepageUrl,
      worksInIsolation,
      hasLinkedinUrl,
      isHostedBy,
      hasDescription,
      usesCallsToTender,
      hasComments,
    } = parse(query, {
      parseBooleans: true,
    });

    const {
      callForTendersCountStart,
      callForTendersCountEnd,
      callForTendersCount,
      fundedNgosCount,
      fundedNgosCountStart,
      fundedNgosCountEnd,
      unverifiedFundedNgosCount,
      unverifiedFundedNgosCountStart,
      unverifiedFundedNgosCountEnd,
      unverifiedAndVerifiedFundedNgosCount,
      unverifiedAndVerifiedFundedNgosCountStart,
      unverifiedAndVerifiedFundedNgosCountEnd,
    } = parse(query, { parseNumbers: true });

    const newSearchFilters = {
      name,
      tags,
      statuses,
      targetPersons,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      source,
      nationality,
      publicationStatus,
      author,
      hasHomepageUrl,
      worksInIsolation,
      hasLinkedinUrl,
      isHostedBy,
      hasDescription,
      usesCallsToTender,
      hasComments,
      callForTendersCountStart,
      callForTendersCountEnd,
      callForTendersCount,
      fundedNgosCount,
      fundedNgosCountStart,
      fundedNgosCountEnd,
      unverifiedFundedNgosCount,
      unverifiedFundedNgosCountStart,
      unverifiedFundedNgosCountEnd,
      unverifiedAndVerifiedFundedNgosCount,
      unverifiedAndVerifiedFundedNgosCountStart,
      unverifiedAndVerifiedFundedNgosCountEnd,
      createdAtStart,
      createdAtEnd,
      updatedAtStart,
      updatedAtEnd,
    } as SearchFiltersAdminParameters;

    setSearchFilters(newSearchFilters);

    fetchDonors(newSearchFilters, page ? parseInt(page as string) : 1);
  };

  const fetchDonors = (
    searchFilters: SearchFiltersAdminParameters,
    page: number,
  ) => {
    updateQueryParams({ page, ...searchFilters });
    captchaDispatch(
      searchAllDonors,
      searchAllDonors({
        ...searchFilters,
        offset: (page - 1) * DONORS_MAX_RESULTS,
      }),
    );
  };

  const onSearch = (value: SearchFiltersAdminParameters) => {
    setSearchFilters(value);
    fetchDonors(value, 1);
  };

  const onTableChange = (
    newPagination: TablePaginationConfig,
    filter: any,
    sorter: any,
  ) => {
    const order =
      sorter.order === 'ascend'
        ? SortOrder.ASC
        : sorter.order === 'descend'
        ? SortOrder.DESC
        : SortOrder.NAME;
    const newSearchPage = newPagination.current ?? 1;
    const newSearchFilters = {
      ...searchFilters,
      sort: order,
    };
    setSearchFilters(newSearchFilters);
    fetchDonors(newSearchFilters, newSearchPage);
  };

  const onSelectStatus = (value: string, donorId: string) => {
    dispatch(
      updateDonorPublicationStatus({
        donorsIds: [donorId],
        publicationStatus: value,
      }),
    );
  };

  const onSelectPublished = (value: boolean, donorId: string) => {
    dispatch(
      updateDonorPublished({
        donorsIds: [donorId],
        published: value,
      }),
    );
  };

  function renderModifStatus(name: string, record: Donor) {
    return (
      <>
        <Select
          placeholder={record.publicationStatus}
          className={classes.select}
          allowClear
          onSelect={(value: string) => onSelectStatus(value, record._id)}
        >
          {Array.from({ length: 10 }, (_, i) => i + 1).map((status, index) => (
            <Option
              key={index}
              value={`${status}`}
              label={`${status}`}
              style={{ fontSize: '0.875vw' }}
            >
              {status}
            </Option>
          ))}
        </Select>
      </>
    );
  }

  function renderModifPublished(name: string, record: Donor) {
    return (
      <>
        <Select
          placeholder={record.published ? 'Yes' : 'No'}
          className={classes.select}
          allowClear
          onSelect={(value: string) =>
            onSelectPublished(value === 'Yes', record._id)
          }
        >
          {['Yes', 'No'].map((status, index) => (
            <Option
              key={index}
              value={status}
              label={status}
              style={{ fontSize: '0.875vw' }}
            >
              {status}
            </Option>
          ))}
        </Select>
      </>
    );
  }

  const deleteDonorById = (donorId: string) => {
    const value = prompt(
      'Veuillez saisir le mot de passe pour confirmer la suppression',
      '0000',
    );
    if (value !== '0122') {
      return alert('Mot de passe incorrect');
    }
    dispatch(deleteDonor(donorId));
    fetchDonorsWithQueryParams();
  };

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: '_id',
      width: '20%',
      render: function renderName(name: string, record: Donor) {
        return (
          <div
            className={classes.name}
            onClick={() => history.push(`/donor/${record._id}`)}
          >
            {name}
          </div>
        );
      },
    },
    {
      title: 'Propriétaire',
      dataIndex: '_id',
      key: '_id',
      width: '15%',
      render: function renderOwner(_id: string) {
        const foundAdmin = Object.values(users).find(
          (user) => _id === user.donorId,
        );
        if (foundAdmin?._id) {
          return (
            <div key={foundAdmin._id} className={classes.columnNotClickable}>
              {foundAdmin.firstName} {foundAdmin.lastName}
            </div>
          );
        }
        return <div className={classes.columnNotClickable}></div>;
      },
    },
    {
      title: 'Date de la dernière modification',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: function renderDate(updatedAt: number) {
        const newDate = updatedAt ? new Date(updatedAt) : undefined;
        return (
          <div>
            {newDate && (
              <div>
                {new Intl.DateTimeFormat('fr-FR', {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                }).format(newDate)}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Etat',
      dataIndex: 'publicationStatus',
      key: 'publicationStatus',
      render: renderModifStatus,
    },
    {
      title: 'Publié',
      dataIndex: 'published',
      key: 'published',
      render: renderModifPublished,
    },
    {
      title: 'Suppression',
      key: 'deleteDonor',
      width: '10%',
      render: function renderDeleteNgo(record: Donor) {
        return (
          <div className={classes.column}>
            <Button
              type="primary"
              danger
              onClick={() => deleteDonorById(record._id)}
            >
              Supprimer
            </Button>
          </div>
        );
      },
    },
    {
      title: 'Nb de suivis',
      dataIndex: 'countRef',
      key: 'countRef',
      render: function renderCountRef(countRef: number) {
        return <div className={classes.nbRef}>{countRef || 0}</div>;
      },
      sorter: true,
    },
  ];

  return (
    <>
      <div className={classes.searchContainer}>
        <div style={{ width: '40%' }}>
          <DatabaseAdminSearchBar
            classification={classification}
            onSearch={onSearch}
            suggestions={null}
            suggestionsLoading={false}
            searchParameters={searchFilters}
            setSearchFilters={setSearchFilters}
            isFromProject={false}
            contributors={contributors}
          />
        </div>

        <Button
          className={classes.button}
          type="primary"
          onClick={() => history.push('/create-donor')}
        >
          Créer un mécène
        </Button>
      </div>
      <div className={classes.createContainer}></div>
      <Table
        className={classes.table}
        dataSource={donorsList}
        rowKey={(record) => record._id}
        columns={columns}
        onChange={onTableChange}
        loading={loading || !donors}
        pagination={{
          pageSize: DONORS_MAX_RESULTS,
          total: searchResult?.resultsCount,
          current: offset / DONORS_MAX_RESULTS + 1,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
      />
    </>
  );
};

import { Button, message } from 'antd';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import '../../index.css';
import { useHistory } from 'react-router-dom';
import { theme } from '../../constants/theme';
import { User } from '../../slices/auth';
import { FinancingNeed } from '../../slices/financingNeed';

const useStyles = createUseStyles({
  stepContainer: {
    paddingBottom: '3rem',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  stepColLeft: {
    backgroundImage: `url("../images/firstProject.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColRight: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    position: 'relative',
    marginLeft: '40vw',
  },
  stepImage: {
    width: '100%',
  },
  stepBar: {
    height: '1vw',
    backgroundColor: theme.palette.primary,
  },
  buttonContainer: {
    marginTop: '3vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonDashboardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  buttonDashboard: {
    width: '15vw',
    height: '2.8vw',
    fontSize: '0.8vw',
    color: 'grey',
    marginTop: '6vw',
  },
  buttonNext: {
    width: '18vw',
    height: '2.8vw',
    fontSize: '1vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  infos: {
    marginLeft: '3rem',
    marginRight: '3rem',
    marginTop: '9vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.6vw',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '1.1vw',
    marginLeft: '2rem',
    marginRight: '2rem',
    textAlign: 'center',
    width: '50vw',
  },
});

interface Props {
  user: User;
  projects: FinancingNeed[];
  setIsNavBarHidden?: (isNavBarHidden: boolean) => void;
}

const NgoFirstProject: React.VFC<Props> = ({
  user,
  projects,
  setIsNavBarHidden,
}) => {
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (setIsNavBarHidden) {
      setIsNavBarHidden(true);
    }
  }, []);

  const onClickNewProject = () => {
    if (user?.role === 'admin') {
      history.push('/newProject');
    } else {
      if (
        user?.maxNumberOfProjects !== undefined &&
        user?.maxNumberOfProjects !== null &&
        projects.length + 1 > user?.maxNumberOfProjects
      ) {
        message.error(
          user.maxNumberOfProjects === 1
            ? 'Vous ne pouvez pas créer plus de ' +
                user.maxNumberOfProjects +
                ' projet'
            : 'Vous ne pouvez pas créer plus de ' +
                user.maxNumberOfProjects +
                ' projets',
        );
      } else {
        history.push('/createFirstProject');
      }
    }
  };

  return (
    <div className={classes.stepContainer}>
      <div className={classes.stepColLeft}></div>
      <div className={classes.stepColRight}>
        <div className={classes.stepBar} style={{ width: '100%' }}></div>
        <div className={classes.infos}>
          <div className={classes.title}>
            <p>Bienvenue chez Sonar !</p>
          </div>
          <div className={classes.subtitle}>
            <p>Votre organisation est désormais visible par les mécènes.</p>
          </div>
          <div className={classes.subtitle}>
            <p>
              En ce moment le référencement de projet est gratuit, 3 minutes
              suffisent.
            </p>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            type="default"
            className={classes.buttonNext}
            onClick={onClickNewProject}
          >
            Créer mon premier projet
          </Button>
        </div>
        <div className={classes.buttonDashboardContainer}>
          <Button
            type="link"
            className={classes.buttonDashboard}
            onClick={() => history.push(`/projects`)}
          >
            Accéder au tableau de bord
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NgoFirstProject;

import React from 'react';
import { createUseStyles } from 'react-jss';

import { theme } from '../constants/theme';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background,
    padding: '8px',
  },
});

export const DeviceNotAllowed: React.VFC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        Sonar n&apos;est pas accessible depuis un smartphone ou une tablette,
        merci de vous connecter depuis un ordinateur.
      </div>
    </div>
  );
};

import React from 'react';
import { FormItemProps as AntFormItemProps } from 'antd/lib/form/FormItem';
import { Form, Typography } from 'antd';

interface FormItemProps extends AntFormItemProps {
  label?: string;
  className?: string;
  customLabelClassName?: string;
}

export const FormItem = (props: FormItemProps) => {
  return (
    <Form.Item
      style={{ whiteSpace: 'normal', height: 'auto' }}
      {...props}
      className={props.className}
      label={
        props.label && (
          <Typography
            style={{
              whiteSpace: 'normal',
              height: 'auto',
              display: 'inline-block',
            }}
            className={props.customLabelClassName}
          >
            {props.label}
          </Typography>
        )
      }
      required={false}
    >
      {props.children}
    </Form.Item>
  );
};

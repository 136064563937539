import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Tag, Collapse, Typography, notification } from 'antd';
import { createUseStyles } from 'react-jss';
import { theme } from '../constants/theme';

import { SortedClassificationElement } from '../hooks/useSortedClassification';
import { Tag as TagType } from '../slices/classification';

const { Panel } = Collapse;
const { Text } = Typography;

const useStyles = createUseStyles({
  okButton: {
    backgroundColor: 'white',
    color: '#3B38F8',
    borderRadius: '0.5vw',
    borderColor: '#3B38F8',
    '&:hover': {
      backgroundColor: '#3B38F8',
      color: 'white',
      borderRadius: '0.5vw',
      borderColor: '#3B38F8',
    },
  },
  cancelButton: {
    backgroundColor: 'white',
    color: '#FF7E7E',
    borderRadius: '0.5vw',
    borderColor: '#FF7E7E',
    '&:hover': {
      backgroundColor: '#FF7E7E',
      color: 'white',
      borderRadius: '0.5vw',
      borderColor: '#FF7E7E',
    },
  },
  cell: {
    padding: '2px',
  },
  header: {
    fontWeight: 'bold',
    width: '150px',
  },
  column: {
    display: 'flex',
    flex: '25%',
    // border: '1px solid',
  },
  row: {
    display: 'flex',
    flex: '100%',
    flexShrink: 0,
    flexGrow: 0,
    flexDirection: 'row',
    padding: '10px',
  },
  tag: {
    color: 'black',
    margin: '0.25vw 0',
    fontSize: '0.9vw',
    padding: '0.25vw 0.5vw',
    whiteSpace: 'normal',
    display: 'inline-block',
    lineHeight: '1.2',
    maxWidth: '19vw',
  },
  limitTagsText: {
    textAlign: 'right',
    margin: '8px 0',
  },
  headerButtons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '10px',
    marginBottom: '10px',
  },
});

interface TagsSelectorModalProps {
  activityDomains: SortedClassificationElement[];
  tags: (SortedClassificationElement & TagType)[];
  value: string[];
  onChange: (values?: string[]) => void;
  requestClose: () => void;
  isVisible: boolean;
  tagsMaxQuantity?: number;
}

type Column = {
  id: string;
  name: string;
  tags: (SortedClassificationElement & TagType)[];
};

export const TagsSelectorModal: React.VFC<TagsSelectorModalProps> = ({
  activityDomains,
  tags,
  isVisible,
  requestClose,
  onChange,
  value,
  tagsMaxQuantity,
}) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState<string[]>([]);

  useEffect(() => {
    if (isVisible) {
      setSelectedValue(value); // Sync with the passed `value` when modal opens
    }
  }, [isVisible, value]);

  const columns = useMemo<Column[]>(() => {
    if (!tags || !activityDomains) return [];

    const computedColumns = activityDomains.map((activityDomain) => ({
      id: activityDomain._id,
      name: activityDomain.translatedName,
      tags: tags.filter((tag) =>
        tag.associatedDomains.includes(activityDomain._id),
      ),
    }));

    // Ensure columns are set properly before returning
    if (computedColumns.length > 0) {
      computedColumns.push(computedColumns[0]);
      computedColumns.splice(0, 1);
    }

    return computedColumns;
  }, [activityDomains, tags]);

  useEffect(() => {
    if (
      tagsMaxQuantity &&
      selectedValue != undefined &&
      selectedValue.length > tagsMaxQuantity
    ) {
      notification.error({
        message: `Vous ne pouvez pas sélectionner plus de ${tagsMaxQuantity} mots-clés`,
        duration: 2,
      });
    }
  }, [selectedValue]);

  const renderTags = (tags: (SortedClassificationElement & TagType)[]) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: '100%',
      }}
    >
      {tags?.map((tag) => {
        const isChecked = selectedValue?.includes(tag._id);
        return (
          <div className={classes.cell} key={tag._id}>
            <Tag
              className={classes.tag}
              color={isChecked ? theme.palette.primary : '#e7e7e7'}
              style={
                isChecked
                  ? { color: 'white', borderRadius: '0.5vw' }
                  : { color: 'black', borderRadius: '0.5vw' }
              }
              onClick={() => {
                if (!isChecked) {
                  setSelectedValue((old) => {
                    if (old != undefined) {
                      return old.concat(tag._id);
                    }
                    return [tag._id];
                  });
                } else {
                  setSelectedValue(
                    selectedValue.filter((value) => value !== tag._id),
                  );
                }
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: tag.translatedName.replace(/\//g, '/&#8203;'),
                }}
              ></span>
            </Tag>
          </div>
        );
      })}
    </div>
  );

  // const GenExtra = ({ ids }: { ids: string[] }) => {
  //   return (
  //     <Space size={'large'}>
  //       <Button
  //         size={'small'}
  //         type="link"
  //         onClick={(event) => {
  //           event.stopPropagation();
  //           setSelectedValue(() =>
  //             (selectedValue ?? [])
  //               ?.concat(ids)
  //               .filter((v, i, a) => a.indexOf(v) === i),
  //           );
  //         }}
  //         style={{ color: '#3B38F8', fontSize: '1vw' }}
  //       >
  //         Tout sélectionner
  //       </Button>
  //       <Button
  //         size={'small'}
  //         type="link"
  //         danger
  //         onClick={(event) => {
  //           event.stopPropagation();
  //           setSelectedValue(selectedValue?.filter((v) => !ids.includes(v)));
  //         }}
  //         style={{ color: '#FF7E7E', fontSize: '1vw' }}
  //       >
  //         Tout désélectionner
  //       </Button>
  //     </Space>
  //   );
  // };

  const renderColumns = (columns: Column[]) =>
    columns.map((column) => {
      const columnTagsLength = column.tags?.length / 4;
      const selectedLength = column.tags?.filter((tag) =>
        selectedValue?.includes(tag._id),
      ).length;

      return (
        <Panel
          key={column.id}
          // uncomment the line below to add the select all and deselect all buttons
          // extra={<GenExtra ids={column.tags?.map((tag) => tag._id) || []} />}
          header={
            <span style={{ fontWeight: 'bold', fontSize: '1vw' }}>
              {column.name}
              {!!selectedLength && (
                <span
                  style={{ color: theme.palette.primary, fontSize: '0.8vw' }}
                >{` (${selectedLength} sélectionné${
                  selectedLength > 1 ? 's' : ''
                })`}</span>
              )}
            </span>
          }
          style={{ border: 'none', borderBottom: '1px solid grey' }}
        >
          <div className={classes.row} key={column.id}>
            <div className={classes.column}>
              {renderTags(column.tags?.slice(0, columnTagsLength))}
            </div>
            <div className={classes.column}>
              {renderTags(
                column.tags?.slice(columnTagsLength, columnTagsLength * 2),
              )}
            </div>
            <div className={classes.column}>
              {renderTags(
                column.tags?.slice(columnTagsLength * 2, columnTagsLength * 3),
              )}
            </div>
            <div className={classes.column}>
              {renderTags(column.tags?.slice(columnTagsLength * 3))}
            </div>
          </div>
        </Panel>
      );
    });

  return (
    <Modal
      visible={isVisible}
      closable={false}
      onCancel={() => {
        setSelectedValue(value);
        requestClose();
      }}
      onOk={() => {
        onChange(selectedValue);
        requestClose();
      }}
      okButtonProps={{
        className: classes.okButton,
        disabled:
          tagsMaxQuantity !== undefined &&
          selectedValue &&
          selectedValue.length > tagsMaxQuantity,
      }}
      cancelButtonProps={{
        className: classes.cancelButton,
      }}
      width={'100%'}
      style={{ top: '5px', paddingBottom: '4vw', borderRadius: '1vw' }}
      okText="Valider"
      cancelText="Annuler"
    >
      <>
        <Collapse style={{ border: 'none', backgroundColor: 'white' }}>
          {renderColumns(columns)}
        </Collapse>
        {tagsMaxQuantity != undefined && selectedValue !== undefined && (
          <div className={classes.limitTagsText}>
            <Text
              type={
                selectedValue.length > tagsMaxQuantity ? 'danger' : undefined
              }
            >
              {selectedValue.length} / {tagsMaxQuantity}{' '}
              {selectedValue.length > 1
                ? 'mots clés sélectionnés'
                : 'mot clé sélectionné'}
            </Text>
          </div>
        )}
      </>
    </Modal>
  );
};

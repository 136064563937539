import { Badge, Card, Image } from 'antd';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { theme } from '../../constants/theme';
import { Donor } from '../../slices/donor';
import { getDonorUrl, isNewlyCreated } from '../../utils/donors/donors.utils';
const { Meta } = Card;

const useStyles = createUseStyles({
  'ant-card-meta-description': {
    fontSize: 12,
    color: '#5050F6 !important',
    textDecoration: 'underline !important',
    '&:hover': {
      color: 'green !important',
    },
    whiteSpace: 'nowrap', // Prevents text from wrapping to next line
    overflow: 'hidden', // Hides overflow text
    textOverflow: 'ellipsis', // Adds ellipsis to overflow text
  },
  title: {
    fontFamily: "'Baloo 2'",
    fontSize: '18px !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    display: '-webkit-box !important',
    '-webkit-line-clamp': '2 !important',
    '-webkit-box-orient': 'vertical !important',
    lineHeight: '24px !important',
    maxHeight: '54px !important', // Adjusted based on new lineHeight
    width: '100%', // Ensuring full width is used
    whiteSpace: 'normal !important', // Override to allow wrapping
  },
});

export const DonorCard = ({
  donor,
  financingNeedId,
}: {
  donor: Donor;
  financingNeedId?: string | undefined;
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [imgUrl, setImgUrl] = React.useState(`/images/donorCardDefault.svg`);

  const checkImageUrl = async (url: string) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok; // Returns true if the status code is 2xx
    } catch (error) {
      console.error('Failed to fetch image URL:', error);
      return false;
    }
  };

  const getImgUrl = async (): Promise<string> => {
    if (donor.ogImageUrl) {
      const isOgImageOk = await checkImageUrl(donor.ogImageUrl);
      if (isOgImageOk) {
        return donor.ogImageUrl;
      }
    }
    if (donor.logoUrl) {
      return donor.logoUrl;
    }
    // return `/images/oddCards/mecene.png`;
    return '/images/donorCardDefault.svg';
  };

  useEffect(() => {
    getImgUrl().then((url) => setImgUrl(url));
  }, []);

  const renderCardWithBadgeIfNewlyCreated = (
    children: React.ReactNode,
    donor: Donor,
  ) => {
    return isNewlyCreated(donor.createdAt) ? (
      <Badge.Ribbon text="Nouveau" color={theme.palette.secondary}>
        {children}
      </Badge.Ribbon>
    ) : (
      children
    );
  };

  return (
    <>
      {renderCardWithBadgeIfNewlyCreated(
        <Card
          hoverable
          style={{
            width: 250,
            height: 240,
            marginBottom: '2dvh',
            borderRadius: 8,
            boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
          }}
          cover={
            <Image
              alt="example"
              style={{
                width: 250,
                height: 130,
                objectFit: 'cover',
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
              src={imgUrl}
            />
          }
          onClick={() =>
            history.push(
              `/donor/${donor._id}${
                financingNeedId ? `?projectId=${financingNeedId}` : ''
              }`,
            )
          }
        >
          <Meta
            title={<div className={classes.title}>{donor.name}</div>}
            description={
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(getDonorUrl(donor), '_blank');
                }}
                className={classes['ant-card-meta-description']}
              >
                {getDonorUrl(donor)}
              </a>
            }
          />
        </Card>,
        donor,
      )}
    </>
  );
};

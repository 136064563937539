import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
  EntityId,
} from '@reduxjs/toolkit';
import { apiRequest } from 'src/helpers/api';

export interface Contributor {
  _id: string;
  firstName: string;
  lastName: string;
  rawData?: number;
  toValidate?: number;
  published?: number;
  referencedNgos?: number;
  total?: number;
}

export const fetchAllContributors = createAsyncThunk(
  'contributors/fetchAll',
  async (stats?: boolean) => {
    const results = await apiRequest<Contributor[]>(
      'GET',
      '/user/contributors/all',
      {
        stats: stats ? 'true' : 'true',
      },
    );

    const contributors: Contributor[] = results.map((document) => ({
      _id: document?._id,
      firstName: document?.firstName,
      lastName: document?.lastName,
      rawData: document?.rawData,
      toValidate: document?.toValidate,
      published: document?.published,
      referencedNgos: document?.referencedNgos,
      total: document?.total,
    }));
    return { contributors };
  },
);

export const contributorsAdapter = createEntityAdapter<Contributor>({
  selectId: (contributor) => contributor._id,
});

export const deleteContributor = createAsyncThunk(
  'deleteContributor',
  async (contributorId: string, { dispatch }) => {
    const result = await apiRequest<{ status: boolean }>(
      'DELETE',
      `/user/contributor/${contributorId}`,
    );
    if (result) dispatch(fetchAllContributors());
    return result;
  },
);

const contributorsSlice = createSlice({
  name: 'contributors',
  initialState: contributorsAdapter.getInitialState({
    loading: false,
  }),
  reducers: {
    removeContributor: (state, action: PayloadAction<EntityId>) => {
      if (state.entities[action.payload]) {
        contributorsAdapter.removeOne(state, action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllContributors.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllContributors.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAllContributors.fulfilled, (state, { payload }) => {
        contributorsAdapter.setAll(state, payload.contributors);
        state.loading = false;
      });
  },
});

export const { removeContributor } = contributorsSlice.actions;

export default contributorsSlice.reducer;

import TextArea from 'antd/es/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Tag,
  Button,
  message,
  Typography,
  DatePicker,
} from 'antd';
import { createUseStyles } from 'react-jss';
import { FormItem } from '../components/FormItem';
import { PlacesSelectorModal } from '../components/PlacesSelectorModal';
import { TagsSelectorModal } from '../components/TagsSelectorModal';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { placesValidator, tagsValidator } from '../helpers/validators';

import { useFormRules } from '../hooks/useFormRules';
import { theme } from 'src/constants/theme';
import { useSortedClassification } from '../hooks/useSortedClassification';
import { getClassification, Place } from '../slices/classification';
import { getCommunes } from '../slices/communes';
import { getDonor, ModusOperandi } from '../slices/donor';
import { saveContributorNgo, SaveContributorNgoDto } from '../slices/ngo';
import { reset } from '../slices/rna';
import { ContributorAddNgoForm } from './ContributorAddNgoForm';
import { MoreTagsModal } from 'src/components/MoreTagsModal';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import moment, { Moment } from 'moment';

const { Link } = Typography;
const { Option } = Select;

interface Props {
  form: FormInstance;
  isNewCallForTender: boolean;
  donorId?: string | undefined;
  submitForm: () => void;
  onCancel: () => void;
}

const layout = {
  labelCol: { span: 5 },
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    textAlign: 'left',
    '& .ant-form-item, .ant-form-item-label > label,\
        .ant-input, .ant-input-number': {
      fontSize: '0.875vw',
    },
    '& .ant-col': {
      maxWidth: '100%',
    },
  },

  modal: {
    '&.ant-modal': {
      maxWidth: '1000px',
      height: 900,
      zIndex: 2000,
    },
    '&.ant-modal-body': {
      height: 900,
    },
  },
  modalWrap: {
    zIndex: 2000,
  },
  nonRelevantMessage: {
    opacity: 0.75,
    color: theme.palette.red[4],
  },
  tag: {
    minHeight: '1.65vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '0.4vw',
    fontSize: '0.8vw',
    height: '1vw',
    color: 'white',
    backgroundColor: '#5050f6',
    border: '1px solid #5050f6',
    borderRadius: '1vw',
    paddingRight: '0.5vw',
    paddingLeft: '0.5vw',
  },
  placesTag: {
    minHeight: '2.5vw',
    fontSize: '1vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '1vw',
    color: 'white',
    backgroundColor: theme.palette.primary,
  },
  textArea: {
    height: 200,
    marginBottom: 50,
  },
  menu: {
    width: 800,
    whiteSpace: 'normal',
    height: '60vh',
    overflow: 'auto',
  },
  menuItem: {
    position: 'relative',
    width: '100%',
    wordWrap: 'break-word',
    marginBottom: 20,
  },
  name: {
    width: '100%',
    fontWeight: 'bold',
  },
  description: {
    width: '100%',
  },
  button: {
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  search: {
    '& .ant-input-group-addon': {
      backgroundColor: 'white',
      fontSize: '0.875vw',
      lineHeight: '1.5715',
      height: '100%',
      '& .ant-btn': {
        padding: '4px 0 !important',
        height: 'unset',
        width: '2vw',
        fontSize: '0.875vw !important',
        boxShadow: 'none',
      },
    },
    '& .ant-btn-icon-only > *': {
      fontSize: '1vw',
    },
  },
  trashIcon: {
    color: '#002766',
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  stepContainer: {
    height: '75vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  stepColLeft1: {
    backgroundImage: `url("../../images/projectStep1.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft2: {
    backgroundImage: `url("../../images/projectStep2.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColRight: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '40vw',
    position: 'relative',
  },
  stepImage: {
    width: '40vw',
    position: 'fixed',
  },
  stepBar: {
    height: '1vw',
    backgroundColor: theme.palette.primary,
  },
  colRightPadding: {
    marginLeft: '6rem',
  },
  stepCounter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  stepNumber: {
    fontSize: '2.5vw',
  },
  stepTotal: {
    fontSize: '1.2vw',
    color: theme.palette.primary,
  },
  buttonBox: {
    position: 'fixed',
    bottom: '0',
    left: '40vw',
    width: '40%',
    paddingBottom: '2.5vw',
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    marginLeft: '6rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.8rem',
  },
  buttonPrevious: {
    width: 'auto',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  buttonNext: {
    width: 'auto',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  customLabel: {
    position: 'relative',
    fontSize: '1vw',
    '&::after': {
      content: '"*"',
      color: '#ff4d4f',
      fontSize: '1.2vw',
      marginLeft: '0.2vw',
    },
  },
  customLabelOptional: {
    position: 'relative',
    fontSize: '1vw',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '75%',
  },
  inputHeight: {
    minHeight: '2vw',
    '& input': {
      height: '100%',
      padding: '0.5vw 1vw',
      color: 'black',
    },
    border: 'none',
    borderBottom: '1px solid #5050f6',
    color: '#5050f6',
    '& input::placeholder': {
      color: theme.palette.primary,
    },
    '& textarea::placeholder': {
      color: theme.palette.primary,
    },
    '& .ant-select-selection-placeholder': {
      color: theme.palette.primary,
    },
    '& textarea': {
      borderColor: '#5050f6',
      borderBottom: 'none',
      color: 'black',
    },
  },
  selectTag: {
    '& .ant-select-selection-item': {
      border: 'none',
      backgroundColor: 'white',
      maxHeight: '1.65vw',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .ant-select-selection-item-content': {
      color: 'white',
      backgroundColor: '#5050f6',
      border: '1px solid #5050f6',
      borderRadius: '1vw',
      paddingRight: '0.5vw',
      paddingLeft: '0.5vw',
    },
  },
  datePicker: {
    borderRadius: '1vw',
    width: '10vw',
    height: '3vw',
    '& .ant-picker-input > input': {
      fontSize: '1vw',
    },
  },
  datePickerContainer: {
    marginBottom: '1vw',
  },
});

const normalizeNullToUndefined = (value: unknown) =>
  value === null ? undefined : value;

const normalizeEmptyStringToNull = (value: unknown) =>
  value === '' ? null : value;

export const NewCallForTenderEditForm: React.VFC<Props> = ({
  form,
  isNewCallForTender,
  donorId,
  submitForm,
  onCancel,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const rules = useFormRules();
  const classification = useSortedClassification();
  const { communes } = useAppSelector(({ communes: { communes } }) => ({
    communes,
  }));

  const [currentStep, setCurrentStep] = useState(1);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showMoreTagsModal, setShowMoreTagsModal] = useState(false);
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const [addNgoModalOpen, setAddNgoModalOpen] = useState(false);
  const [openingDateState, setOpeningDateState] = useState<Moment | null>();
  const [closingDateState, setClosingDateState] = useState<Moment | null>();

  useEffect(() => {
    if (communes.length === 0) {
      dispatch(getCommunes());
    }
  }, [communes]);

  useEffect(() => {
    dispatch(getClassification());

    return () => {
      dispatch(reset());
    };
  }, []);

  const { loading, saved, donors, user, userRole } = useAppSelector(
    ({ ngo: { loading, saved }, auth: { user }, donor: { donors } }) => ({
      donors,
      loading,
      saved,
      user,
      userRole: user?.role,
    }),
  );

  const { activityDomains, tags, targetPopulations, places, donationTypes } =
    classification;

  const [addNgoForm] = Form.useForm<SaveContributorNgoDto>();

  useEffect(() => {
    if (user?.donorId) {
      dispatch(getDonor(user.donorId));
    }
  }, [user]);

  useEffect(() => {
    if (donorId) {
      dispatch(getDonor(donorId));
    }
  }, [donorId]);

  useEffect(() => {
    if ((!loading || saved) && addNgoModalOpen) {
      setAddNgoModalOpen(false);
    }
  }, [saved]);

  const onAddNgo = async () => {
    const valuesNewNgo = await addNgoForm.validateFields();
    setAddNgoModalOpen(false);
    const resultAction = await dispatch(saveContributorNgo(valuesNewNgo));

    if (saveContributorNgo.fulfilled.match(resultAction)) {
      const fundedNgos = await form.getFieldValue('fundedNgos');

      await form.setFieldsValue({
        fundedNgos: [...(fundedNgos || []), resultAction.payload._id],
      });
      message.success('ONG ajoutée avec succès !');
    } else {
      message.error("Erreur lors de l'ajout de l'ONG");
    }
  };

  const renderAddNgoModal = () => (
    <Modal
      className={classes.modal}
      maskStyle={{ zIndex: 1500 }}
      width={1000}
      wrapClassName={classes.modalWrap}
      title={'Ajouter une organisation'}
      onOk={onAddNgo}
      onCancel={() => setAddNgoModalOpen(false)}
      visible={addNgoModalOpen}
      okText={'Confirmer'}
      cancelText={'Annuler'}
      confirmLoading={loading}
    >
      {<ContributorAddNgoForm form={addNgoForm} />}
    </Modal>
  );

  const renderUrlFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem
      label={label}
      name={name}
      rules={[rules.url()]}
      normalize={normalizeEmptyStringToNull}
      customLabelClassName={classes.customLabelOptional}
    >
      <Input
        allowClear
        type="url"
        placeholder={'https://'}
        className={classes.inputHeight}
      />
    </FormItem>
  );

  const renderNameFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem
      label={label}
      name={name}
      rules={[rules.required()]}
      normalize={normalizeEmptyStringToNull}
      customLabelClassName={classes.customLabel}
    >
      <Input
        allowClear
        className={classes.inputHeight}
        placeholder={'Entrez votre réponse ici'}
      />
    </FormItem>
  );

  const renderCommentsFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <div className={classes.field}>
      <FormItem
        label={label}
        name={name}
        normalize={normalizeEmptyStringToNull}
      >
        <TextArea
          autoSize={{ minRows: 3, maxRows: 10 }}
          placeholder={'Espace commentaires ...'}
        />
      </FormItem>
    </div>
  );

  const renderDescriptionFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem
      label={label}
      name={name}
      normalize={normalizeNullToUndefined}
      rules={[rules.max(2000)]}
      customLabelClassName={classes.customLabelOptional}
    >
      <TextArea
        className={classes.inputHeight}
        autoSize={{ minRows: 4, maxRows: 10 }}
        maxLength={2000}
        showCount={true}
        placeholder={'Entrez votre réponse ici'}
      />
    </FormItem>
  );

  const france = places
    .filter((place) => place.departments && place.departments.length)
    .sort((a, b) =>
      a.name.includes('DROM') || a.name.includes("Collectivités d'Outre-Mer")
        ? 1
        : a.name.localeCompare(b.name),
    ) as Place[];

  const countries = places
    .filter((place) => place.countries && place.countries.length)
    .sort((a, b) => a.name.localeCompare(b.name)) as Place[];

  function renderFrance(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    return isFranceComplete && <Tag className={classes.tag}>France</Tag>;
  }

  function renderDepartments(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return (
      results.length > 0 &&
      results.map((department, index) => (
        <Tag key={index} className={classes.tag}>
          {department}
        </Tag>
      ))
    );
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return;
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return (
      results.length > 0 &&
      results.map((currentCountry, index) => (
        <Tag key={index} className={classes.tag}>
          {currentCountry}
        </Tag>
      ))
    );
  }

  function getTags() {
    if (!isNewCallForTender) {
      return form.getFieldValue('tags');
    }
    if (donorId) {
      const tags = donors.byId[donorId]?.tags;
      if (
        user &&
        user.role &&
        user.role === 'admin' &&
        donorId &&
        tags &&
        tags.length <= 12
      ) {
        return donors.byId[donorId].tags;
      }
    }
    const userTags = user?.donorId && donors.byId[user.donorId]?.tags;
    if (user && user.donorId && userTags && userTags.length <= 12) {
      return donors.byId[user?.donorId].tags;
    }
    return [];
  }

  function renderTagsFormItem() {
    return (
      <FormItem
        label="→ Mots clés"
        name="tags"
        rules={[
          {
            required: true,
            type: 'array',
            validator: tagsValidator,
          },
        ]}
        customLabelClassName={classes.customLabel}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            onClick={() => setShowTagsModal(true)}
            className={classes.buttonPrevious}
            style={{
              height: '3vw',
              width: '12vw',
              fontSize: '0.875vw',
            }}
          >
            Choisir les mots clés
          </Button>
          {!!form.getFieldValue('tags')?.length && (
            <div
              style={{
                marginTop: '1vw',
                display: 'inline-flex',
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '0.4vw',
              }}
            >
              {form
                .getFieldValue('tags')
                ?.slice(0, 4)
                .map((tagId: string) => (
                  <Tag key={tagId} className={classes.tag}>
                    {tags.find((tag) => tag._id === tagId)?.translatedName}
                  </Tag>
                ))}
              {form.getFieldValue('tags')?.length > 4 && (
                <Tag
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '1vw',
                    maxHeight: '1.65vw',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ marginRight: '0.5vw', fontSize: '1vw' }}>
                    +{form.getFieldValue('tags')?.length - 4}
                  </span>{' '}
                  <span>
                    <Link
                      style={{
                        fontSize: '0.7vw',
                        textDecoration: 'underline',
                      }}
                      onClick={() => setShowMoreTagsModal(true)}
                    >
                      voir plus
                    </Link>
                  </span>
                </Tag>
              )}
            </div>
          )}
        </div>
      </FormItem>
    );
  }

  useEffect(() => {
    if (form.getFieldValue('tags')?.length > 0 && classification.tags?.length) {
      form.setFieldsValue({
        activityDomains: form
          .getFieldValue('tags')
          .reduce((previousValue: string[], tagId: string) => {
            const activityDomains = classification.tags.find(
              (t) => t._id === tagId,
            )?.associatedDomains;
            if (activityDomains) {
              return previousValue.concat(activityDomains);
            }
            return previousValue;
          }, [])
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index,
          ),
      });
    } else {
      form.setFieldsValue({
        activityDomains: [],
      });
    }
  }, [form.getFieldValue('tags')]);

  function renderLieuxInterventionFormItem() {
    return (
      <FormItem
        label="→ Lieu(x) d’intervention"
        name="places"
        rules={
          userRole !== 'admin' &&
          !form.getFieldValue('city') &&
          !form.getFieldValue('zipcode') &&
          !form.getFieldValue('roadAndNumber') &&
          !form.getFieldValue('uniqueZipcode')
            ? [
                {
                  required: true,
                  type: 'array',
                  validator: placesValidator,
                },
              ]
            : undefined
        }
        customLabelClassName={classes.customLabel}
      >
        <div>
          <Button
            onClick={() => setShowPlacesModal(true)}
            className={classes.buttonPrevious}
            style={{ height: '3vw', fontSize: '0.875vw' }}
          >
            Choisir les lieu(x) d’intervention
          </Button>
        </div>
        <div style={{ marginTop: '1vw', marginLeft: '' }}>
          {!!form.getFieldValue('roadAndNumber') &&
            !!form.getFieldValue('city') &&
            !!form.getFieldValue('zipcode') && (
              <div>
                <Tag
                  key={'uniqueAddressWithInformations'}
                  className={classes.tag}
                >
                  {form.getFieldValue('roadAndNumber')}
                  {', '}
                  {form.getFieldValue('city')}
                  {', '}
                  {form.getFieldValue('zipcode')}
                </Tag>
              </div>
            )}
          {!!form.getFieldValue('uniqueZipcode') &&
            !!form.getFieldValue('city') && (
              <div>
                <Tag key={'uniqueZipcode'} className={classes.tag}>
                  {form.getFieldValue('uniqueZipcode')}
                  {', '}
                  {form.getFieldValue('city')}
                </Tag>
              </div>
            )}
          {!!form.getFieldValue('places')?.length &&
            (renderFrance(form.getFieldValue('places')) ||
              renderDepartments(form.getFieldValue('places')))}
          {!!form.getFieldValue('places')?.length &&
            renderCountries(form.getFieldValue('places'))}
        </div>
      </FormItem>
    );
  }

  function renderTargetPopulationsFormItem() {
    return (
      <FormItem
        label="→ Bénéficiaires cibles"
        name="targetPopulations"
        rules={[rules.required({ type: 'array' })]}
        customLabelClassName={classes.customLabel}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ fontSize: '0.8vw' }}
          optionFilterProp="label"
          bordered={false}
          className={`${classes.inputHeight} ${classes.selectTag}`}
          placeholder={'Entrez votre réponse ici'}
        >
          {targetPopulations.map((targetPopulation) => {
            return (
              <Option
                value={targetPopulation._id}
                key={targetPopulation._id}
                label={targetPopulation.translatedName}
              >
                {targetPopulation.translatedName}
              </Option>
            );
          })}
        </Select>
      </FormItem>
    );
  }

  const handleNextClick = () => {
    if (currentStep === 1) {
      validateStep1()
        .then(() => {
          setCurrentStep(2);
        })
        .catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        });
    } else if (currentStep === 2) {
      validateStep2()
        .then(() => {
          submitForm();
        })
        .catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        });
    }
  };

  const handleDateChange = (date: any, dateString: string, name: string) => {
    if (date === null || date === undefined) {
      dateString = '';
    }

    if (name === 'openingDate') {
      setOpeningDateState(date ? moment(dateString, 'DD/MM/YYYY') : null);
    }

    if (name === 'closingDate') {
      setClosingDateState(date ? moment(dateString, 'DD/MM/YYYY') : null);
    }

    form.setFieldValue(name, date ? moment(dateString, 'DD/MM/YYYY') : null);
  };

  const validateStep1 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields([
          'name',
          'donationTypes',
          'modusOperandi',
          'targetPopulations',
          'tags',
          'places',
          'roadAndNumber',
          'uniqueZipcode',
          'zipcode',
          'city',
          'openingDate',
          'closingDate',
        ])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep1 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft1}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '33%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.stepCounter}>
                <div className={classes.stepNumber}>1</div>
                <div className={classes.stepTotal}>/2</div>
              </div>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  {renderNameFormItem({
                    name: 'name',
                    label: '→ Nom du programme de soutien',
                  })}
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Type de mécénat"
                    name="donationTypes"
                    rules={[rules.required({ type: 'array' })]}
                    customLabelClassName={classes.customLabel}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%', fontSize: '0.8vw' }}
                      optionFilterProp="label"
                      bordered={false}
                      className={`${classes.inputHeight} ${classes.selectTag}`}
                      placeholder="Rechercher"
                    >
                      {donationTypes.map((donationType) => {
                        return (
                          <Option
                            value={donationType._id}
                            key={donationType._id}
                            label={donationType.translatedName}
                          >
                            {donationType.translatedName}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Modalités"
                    name="modusOperandi"
                    rules={[rules.required({ type: 'array' })]}
                    customLabelClassName={classes.customLabel}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ fontSize: '0.8vw' }}
                      optionFilterProp="label"
                      bordered={false}
                      className={`${classes.inputHeight} ${classes.selectTag}`}
                      placeholder={'Entrez votre réponse ici'}
                    >
                      {Object.values(ModusOperandi).map((modus) => {
                        return (
                          <Option value={modus} key={modus} label={modus}>
                            {modus}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </div>
                <div className={classes.field}>
                  {renderTargetPopulationsFormItem()}
                </div>
                <div className={classes.field}>{renderTagsFormItem()}</div>
                <div className={classes.field}>
                  {renderLieuxInterventionFormItem()}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: '100px',
                  }}
                >
                  <div className={classes.field}>
                    <div className={classes.datePickerContainer}>
                      <FormItem
                        label={'→ Date de début'}
                        name={'openingDate'}
                        customLabelClassName={classes.customLabelOptional}
                      >
                        <DatePicker
                          allowClear
                          locale={locale}
                          onChange={(date, dateString) => {
                            handleDateChange(date, dateString, 'openingDate');
                          }}
                          format={'DD/MM/YYYY'}
                          className={classes.datePicker}
                        ></DatePicker>
                      </FormItem>
                    </div>
                  </div>
                  <div className={classes.field}>
                    <div className={classes.datePickerContainer}>
                      <FormItem
                        label={'→ Date de fin'}
                        name={'closingDate'}
                        customLabelClassName={classes.customLabelOptional}
                        rules={
                          openingDateState && closingDateState
                            ? [
                                rules.checkClosingDate(
                                  openingDateState,
                                  closingDateState,
                                ),
                              ]
                            : []
                        }
                      >
                        <DatePicker
                          allowClear
                          locale={locale}
                          onChange={(date, dateString) => {
                            handleDateChange(date, dateString, 'closingDate');
                          }}
                          format={'DD/MM/YYYY'}
                          className={classes.datePicker}
                        ></DatePicker>
                      </FormItem>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={onCancel}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const validateStep2 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields(['url', 'comments, description'])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep2 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft2}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '66%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.stepCounter}>
                <div className={classes.stepNumber}>2</div>
                <div className={classes.stepTotal}>/2</div>
              </div>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  {renderUrlFormItem({
                    name: 'url',
                    label: '→ Url du Site web dédié au programme de soutien',
                  })}
                </div>
                {user?.role === 'admin' &&
                  renderCommentsFormItem({
                    name: 'comments',
                    label: 'Commentaires',
                  })}
                <div className={classes.field}>
                  {renderDescriptionFormItem({
                    name: 'description',
                    label: '→ Description',
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonPrevious}
                onClick={() => setCurrentStep(1)}
              >
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => handleNextClick()}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <Row>
        <Col span={5} />
        <Col span={14}>
          <Form {...layout} form={form} layout={'vertical'}>
            {currentStep === 1 && renderStep1()}
            {currentStep === 2 && renderStep2()}
          </Form>
        </Col>
      </Row>
      {renderAddNgoModal()}
      <TagsSelectorModal
        activityDomains={activityDomains}
        tags={tags}
        isVisible={showTagsModal}
        requestClose={() => setShowTagsModal(false)}
        value={getTags()}
        onChange={(value) => form.setFieldsValue({ tags: value })}
      />
      <MoreTagsModal
        values={form.getFieldValue('tags')}
        tags={tags}
        isVisible={showMoreTagsModal}
        requestClose={() => setShowMoreTagsModal(false)}
      />
      <PlacesSelectorModal
        form={form}
        uniqueZipcode={form.getFieldValue('uniqueZipcode')}
        zipcode={form.getFieldValue('zipcode')}
        roadAndNumber={form.getFieldValue('roadAndNumber')}
        city={form.getFieldValue('city')}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
        value={form.getFieldValue('places')}
        onChange={(value) => {
          form.setFieldsValue({ places: value });
        }}
        communes={communes}
      />
    </div>
  );
};

import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getClassification,
  Place,
  SustainableDevelopmentGoal,
  Tag,
} from 'src/slices/classification';
import { useAppDispatch, useAppSelector } from './store';

export type SortedClassificationElement = {
  _id: string;
  name: string;
  translatedName: string;
};

export type SortedClassification = {
  activityDomains: SortedClassificationElement[];
  activityZones: SortedClassificationElement[];
  donationTypes: SortedClassificationElement[];
  statuses: SortedClassificationElement[];
  nationalities: SortedClassificationElement[];
  targetPersons: SortedClassificationElement[];
  sustainableDevelopmentGoals: (SortedClassificationElement &
    SustainableDevelopmentGoal)[];
  tags: (SortedClassificationElement & Tag)[];
  places: Place[];
  targetPopulations: SortedClassificationElement[];
};

export const useSortedClassification = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classification = useAppSelector(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (state) => state.classification.classification!,
  );
  useEffect(() => {
    if (!classification) dispatch(getClassification());
  }, []);

  return useMemo<SortedClassification>(() => {
    const sortFunction = (
      a: SortedClassificationElement,
      b: SortedClassificationElement,
    ) => a.translatedName.localeCompare(b.translatedName);
    if (!classification)
      return {
        activityDomains: [],
        activityZones: [],
        donationTypes: [],
        statuses: [],
        nationalities: [],
        targetPersons: [],
        sustainableDevelopmentGoals: [],
        tags: [],
        places: [],
        targetPopulations: [],
      };

    const sortedClassificationWithTranslation = {
      activityDomains: classification.activityDomains.map((activityDomain) => ({
        ...activityDomain,
        translatedName: t(`activityDomains:${activityDomain.name}`),
      })),
      activityZones: classification.activityZones.map((activityZone) => ({
        ...activityZone,
        translatedName: t(`activityZones:${activityZone.name}`),
      })),
      donationTypes: classification.donationTypes.map((donationType) => ({
        ...donationType,
        translatedName: t(`donationTypes:${donationType.name}`),
      })),
      statuses: classification.statuses.map((status) => ({
        ...status,
        translatedName: t(`statuses:${status.name}`),
      })),
      nationalities: classification.nationalities.map((nationality) => ({
        ...nationality,
        translatedName: t(`nationalities:${nationality.name}`),
      })),
      targetPersons: classification.targetPersons.map((targetPerson) => ({
        ...targetPerson,
        translatedName: t(`targetPersons:${targetPerson.name}`),
      })),
      sustainableDevelopmentGoals:
        classification.sustainableDevelopmentGoals.map(
          (sustainableDevelopmentGoal) => ({
            ...sustainableDevelopmentGoal,
            translatedName: t(
              `sustainableDevelopmentGoals:${sustainableDevelopmentGoal.name}`,
            ),
          }),
        ),
      tags: classification.tags.map((tag) => ({
        ...tag,
        translatedName: t(`tags:${tag.name}`),
      })),
      places: classification.places,
      targetPopulations: classification.targetPopulations.map(
        (targetPopulation) => ({
          ...targetPopulation,
          translatedName: t(`targetPopulations:${targetPopulation.name}`),
        }),
      ),
    };

    sortedClassificationWithTranslation.activityDomains.sort(sortFunction);
    sortedClassificationWithTranslation.activityZones.sort(sortFunction);
    sortedClassificationWithTranslation.donationTypes.sort(sortFunction);
    sortedClassificationWithTranslation.statuses.sort(sortFunction);
    sortedClassificationWithTranslation.nationalities.sort(sortFunction);
    sortedClassificationWithTranslation.targetPersons.sort(sortFunction);
    sortedClassificationWithTranslation.sustainableDevelopmentGoals.sort(
      sortFunction,
    );
    sortedClassificationWithTranslation.tags.sort(sortFunction);
    sortedClassificationWithTranslation.targetPopulations.sort(sortFunction);

    return sortedClassificationWithTranslation;
  }, [classification]);
};

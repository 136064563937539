import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState, useMemo } from 'react';
import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Divider,
  message,
  Button,
  Tag,
  Radio,
} from 'antd';
import { createUseStyles } from 'react-jss';
import { FormItem } from '../components/FormItem';
import { PlacesSelectorModal } from '../components/PlacesSelectorModal';
import { TagsSelectorModal } from '../components/TagsSelectorModal';
import { placesValidator, tagsValidator } from '../helpers/validators';
import { useSortedClassification } from '../hooks/useSortedClassification';
import { Place } from '../slices/classification';
import { getCommunes } from '../slices/communes';

import { Donor, ModusOperandi } from '../slices/donor';
import { useFormRules } from '../hooks/useFormRules';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  getFundedNgosFromDonorId,
  getNgo,
  saveContributorNgo,
  SaveContributorNgoDto,
  searchNgosAndGetSuggestions,
} from 'src/slices/ngo';
import { PlusOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { ContributorAddNgoForm } from './ContributorAddNgoForm';
import Text from 'antd/lib/typography/Text';
import { theme } from 'src/constants/theme';
import clsx from 'clsx';

interface Props {
  donor?: Donor;
  form: FormInstance;
  uploadLogo?: JSX.Element;
  uploadFiles?: JSX.Element;
}

const { Option } = Select;
const layout = {
  labelCol: { span: 5 },
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '3vh',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    '& .ant-col': {
      maxWidth: '100%',
    },
  },
  modal: {
    '&.ant-modal': {
      maxWidth: '1000px',
      height: 900,
      zIndex: 2000,
    },
    '&.ant-modal-body': {
      height: 900,
    },
  },
  modalWrap: {
    zIndex: 2000,
  },
  nonRelevantMessage: {
    opacity: 0.75,
    color: theme.palette.red[4],
  },
  tag: {
    fontSize: '14px',
    margin: '2px',
    padding: '2px 7px',
  },
  tagCities: {
    fontSize: '14px',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
  },
  validateCallForTender: {
    marginBottom: 0,
  },
  button: {
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  headquarterCityFormItem: {
    '& .ant-input-affix-wrapper-disabled': {
      backgroundColor: 'white',
    },
    '& .ant-input[disabled]': {
      color: 'black',
    },
  },
});

const normalizeNullToUndefined = (value: unknown) =>
  value === null ? undefined : value;

const normalizeEmptyStringToNull = (value: unknown) =>
  value === '' ? null : value;

export const DonorEditForm: React.VFC<Props> = ({
  donor,
  form,
  uploadLogo,
  uploadFiles,
}) => {
  const rules = useFormRules();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const classification = useSortedClassification();
  const [addNgoForm] = Form.useForm<SaveContributorNgoDto>();
  const [addNgoModalOpen, setAddNgoModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>(
    form.getFieldValue('tags') || [],
  );
  const { loading, saved, userRole, searchNgos, ngos, communes } =
    useAppSelector(
      ({
        ngo: { searchNgos, loading, saved, error, ngos },
        auth,
        communes: { communes },
      }) => ({
        searchNgos,
        loading,
        saved,
        error,
        userRole: auth.user?.role,
        ngos,
        communes,
      }),
    );

  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);

  const [nationality, setNationality] = useState<string | undefined>(
    donor?.nationality,
  );

  const [cityState, setCityState] = useState<string>('');
  const [citiesState, setCitiesState] = useState<string[]>([]);

  const [
    dateOfLastCallForTenderValidation,
    setDateOfLastCallForTenderValidation,
  ] = useState<Date | undefined>(donor?.lastCallForTenderValidation);

  const [isValid, setIsValid] = useState<boolean>(
    dateOfLastCallForTenderValidation ? true : false,
  );

  const optionsFundedNgos = useMemo(
    () =>
      (searchNgos || [])
        .concat(
          (ngos || []).filter(
            (ngo) =>
              (searchNgos || []).findIndex(
                (searchNgo) => searchNgo._id === ngo._id,
              ) === -1,
          ),
        )
        .filter(
          (ngo) =>
            !(form.getFieldValue('unverifiedFundedNgos') || []).includes(
              ngo._id,
            ),
        )
        .map((ngo) => ({ label: ngo.name, value: ngo._id })),
    [ngos, searchNgos],
  );

  useEffect(() => {
    if (donor) dispatch(getFundedNgosFromDonorId(donor._id));
  }, []);

  useEffect(() => {
    dispatch(getCommunes());
  }, []);

  useEffect(() => {
    if ((!loading || saved) && addNgoModalOpen) {
      setAddNgoModalOpen(false);
    }
  }, [saved]);

  const {
    tags,
    activityDomains,
    statuses,
    nationalities,
    targetPersons,
    // activityZones,
    targetPopulations,
    donationTypes,
    places,
  } = classification;

  const onAddNgo = async () => {
    const valuesNewNgo = await addNgoForm.validateFields();
    setAddNgoModalOpen(false);
    const resultAction = await dispatch(saveContributorNgo(valuesNewNgo));

    if (saveContributorNgo.fulfilled.match(resultAction)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const fundedNgos = await form.getFieldValue('fundedNgos');
      dispatch(getNgo(resultAction.payload._id));

      // await form.setFieldsValue({
      //   fundedNgos: [...(fundedNgos || []), resultAction.payload._id],
      // });
      message.success('ONG ajoutée avec succès !');
    } else {
      message.error("Erreur lors de l'ajout de l'ONG");
    }
  };

  function updateCitiesState(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const cities = communes
      .filter((c) => {
        if (c.zipcode) {
          const zipcode = c.zipcode.toString();
          if (zipcode.length === 4) {
            return `0${zipcode}` === value.toString();
          } else {
            return zipcode === value.toString();
          }
        }
        return false;
      })
      .map((c) => c.name);
    setCitiesState(cities);
  }

  const onFormValuesChange = (changedValues: any) => {
    if (changedValues['nationality']) {
      setNationality(changedValues['nationality']);
    }
    if (changedValues['lastCallForTenderValidation']) {
      setDateOfLastCallForTenderValidation(
        changedValues['lastCallForTenderValidation'],
      );
    }
  };

  const renderAddNgoModal = () => (
    <Modal
      className={classes.modal}
      maskStyle={{ zIndex: 1500 }}
      width={1000}
      wrapClassName={classes.modalWrap}
      title={'Ajouter une organisation'}
      onOk={onAddNgo}
      onCancel={() => setAddNgoModalOpen(false)}
      visible={addNgoModalOpen}
      okText={'Confirmer'}
      cancelText={'Annuler'}
      confirmLoading={loading}
    >
      {<ContributorAddNgoForm form={addNgoForm} />}
    </Modal>
  );

  const renderUrlFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem
      label={label}
      name={name}
      rules={[rules.url()]}
      normalize={normalizeEmptyStringToNull}
    >
      <Input type="url" placeholder={'https://'} />
    </FormItem>
  );

  const renderLinkedinUrlFormItem = ({
    name,
    label,
  }: {
    name: string;
    label: string;
  }) => (
    <FormItem
      label={label}
      name={name}
      rules={[rules.url()]}
      normalize={normalizeEmptyStringToNull}
    >
      <Input type="url" placeholder={'https://'} />
    </FormItem>
  );

  const renderTagsFormItem = () => (
    <FormItem
      label="Mots clés"
      name="tags"
      rules={
        userRole !== 'admin'
          ? [
              {
                required: true,
                type: 'array',
                validator: tagsValidator,
              },
            ]
          : undefined
      }
    >
      <div>
        {!!form.getFieldValue('tags')?.length && (
          <div style={{ marginBottom: '20px' }}>
            {form.getFieldValue('tags')?.map((tagId: string) => (
              <Tag key={tagId} className={classes.tag}>
                {tags.find((tag) => tag._id === tagId)?.translatedName}
              </Tag>
            ))}
          </div>
        )}
        <Button
          onClick={() => setShowTagsModal(true)}
          className={classes.button}
        >
          Choisir les mots clés
        </Button>
      </div>
    </FormItem>
  );

  useEffect(() => {
    if (selectedTags?.length > 0 && classification.tags?.length) {
      form.setFieldsValue({
        activityDomains: form
          .getFieldValue('tags')
          .reduce((previousValue: string[], tagId: string) => {
            const activityDomains = classification.tags.find(
              (t) => t._id === tagId,
            )?.associatedDomains;
            if (activityDomains) {
              return previousValue.concat(activityDomains);
            }
            return previousValue;
          }, [])
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index,
          ),
      });
    } else {
      form.setFieldsValue({
        activityDomains: [],
      });
    }
  }, [selectedTags]);

  const renderActivityDomainsFormItem = () => (
    <FormItem
      label="Domaine(s) d'intervention"
      name="activityDomains"
      hidden
      rules={
        userRole !== 'admin' ? [rules.required({ type: 'array' })] : undefined
      }
    >
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        optionFilterProp="label"
      >
        {activityDomains.map((activityDomain) => {
          return (
            <Option
              value={activityDomain._id}
              key={activityDomain._id}
              label={activityDomain.translatedName}
            >
              {activityDomain.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const [statusState, setStatusState] = useState<string>(
    form.getFieldValue('status') || undefined,
  );

  useEffect(() => {
    if (form.getFieldValue('status')) {
      setStatusState(form.getFieldValue('status'));
    }
  }, [form.getFieldValue('status')]);

  const renderStatusesFormItem = () => (
    <FormItem
      label="Statut"
      name="status"
      rules={userRole !== 'admin' ? [rules.required()] : undefined}
    >
      <Select
        // mode="multiple"
        allowClear
        style={{ width: '100%' }}
        optionFilterProp="label"
        value={statusState}
        onChange={(value) => setStatusState(value)}
      >
        {statuses.map((status) => {
          return (
            <Option
              value={status._id}
              key={status._id}
              label={status.translatedName}
            >
              {status.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  useEffect(() => {
    if (form.getFieldValue('targetPerson')) {
      setTargetPersonState(form.getFieldValue('targetPerson'));
    }
  }, [form.getFieldValue('targetPerson')]);

  const [nationalityState, setNationalityState] = useState<string>(
    form.getFieldValue('nationality') || undefined,
  );

  const renderNationalitiesFormItem = () => (
    <FormItem
      label="Nationalité"
      name="nationality"
      rules={userRole !== 'admin' ? [rules.required()] : undefined}
    >
      <Select
        // mode="multiple"
        allowClear
        style={{ width: '100%' }}
        optionFilterProp="label"
        value={nationalityState}
        onChange={(value) => setNationalityState(value)}
      >
        {nationalities.map((nationality) => {
          return (
            <Option
              value={nationality._id}
              key={nationality._id}
              label={nationality.translatedName}
            >
              {nationality.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const [targetPersonState, setTargetPersonState] = useState<string[]>(
    form.getFieldValue('targetPerson') || [],
  );

  useEffect(() => {
    setTargetPersonState(form.getFieldValue('targetPerson') || []);
  }, [form.getFieldValue('targetPerson')]);

  const renderTargetPersonsFormItem = () => (
    <FormItem
      label="Soutien orienté vers"
      name="targetPerson"
      rules={
        userRole !== 'admin' ? [rules.required({ type: 'array' })] : undefined
      }
    >
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        optionFilterProp="label"
        value={targetPersonState}
        onChange={(value) => setTargetPersonState(value)}
      >
        {targetPersons.map((targetPerson) => {
          return (
            <Option
              value={targetPerson._id}
              key={targetPerson._id}
              label={targetPerson.translatedName}
            >
              {targetPerson.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const renderTargetPopulationsFormItem = () => (
    <FormItem
      label="Bénéficiaires cibles"
      name="targetPopulations"
      rules={
        userRole !== 'admin' ? [rules.required({ type: 'array' })] : undefined
      }
    >
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        optionFilterProp="label"
      >
        {targetPopulations.map((targetPopulation) => {
          return (
            <Option
              value={targetPopulation._id}
              key={targetPopulation._id}
              label={targetPopulation.translatedName}
            >
              {targetPopulation.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const renderActivityZoneFormItem = () => (
    <FormItem label="Zones d'intervention" name="activityZones">
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        optionFilterProp="label"
      >
        {classification.activityZones.map((activityZone) => {
          return (
            <Option
              value={activityZone._id}
              key={activityZone._id}
              label={activityZone.translatedName}
            >
              {activityZone.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const renderDonationTypeFormItem = () => (
    <FormItem
      label="Type de mécénat"
      name="donationTypes"
      rules={
        userRole !== 'admin' ? [rules.required({ type: 'array' })] : undefined
      }
    >
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        optionFilterProp="label"
      >
        {donationTypes.map((donationType) => {
          return (
            <Option
              value={donationType._id}
              key={donationType._id}
              label={donationType.translatedName}
            >
              {donationType.translatedName}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );

  const todayDate = () => {
    const date = new Date();
    return date.toDateString();
  };

  const renderLastCallForTenderValidation = () => {
    const date = dateOfLastCallForTenderValidation
      ? new Date(dateOfLastCallForTenderValidation)
      : undefined;
    return (
      <div>
        <p>Valider appels d&apos;offres</p>
        <Select
          defaultValue={dateOfLastCallForTenderValidation ? 'Oui' : 'Non'}
          style={{ width: '100%' }}
          optionFilterProp="label"
          onChange={(value) =>
            value === 'Oui' ? setIsValid(true) : setIsValid(false)
          }
        >
          <Option value="Oui" label="Oui">
            Oui
          </Option>
          <Option value="Non" label="Non">
            Non
          </Option>
        </Select>
        {isValid && (
          <FormItem
            label=""
            name="lastCallForTenderValidation"
            // style={{ marginBottom: 0 }}
            className={clsx(
              dateOfLastCallForTenderValidation
                ? classes.validateCallForTender
                : null,
            )}
          >
            <Radio.Group>
              <Radio.Button
                onClick={() => setDateOfLastCallForTenderValidation(new Date())}
                value={todayDate()}
              >
                Valider
              </Radio.Button>
            </Radio.Group>
          </FormItem>
        )}
        {dateOfLastCallForTenderValidation && (
          <div style={{ marginLeft: '9.5vw', marginBottom: '1.5vw' }}>
            Dernière validation:{' '}
            {new Intl.DateTimeFormat('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(date)}
          </div>
        )}
      </div>
    );
  };

  const handleChange = () => {
    initSearchFundedNgo();
  };

  const initSearchFundedNgo = () => {
    dispatch(searchNgosAndGetSuggestions({ name: '' }));
  };

  const handleSearch = (value: string) => {
    dispatch(searchNgosAndGetSuggestions({ name: value }));
  };

  const handleRemoveUnverifiedFundedNgo = (ngoId: string) => {
    form.setFieldsValue({
      unverifiedFundedNgos: form
        .getFieldValue('unverifiedFundedNgos')
        .filter((id: string) => id !== ngoId),
    });
  };

  const renderFundedNgosFormItem = () => (
    <>
      <FormItem label="Porteurs de projets soutenus" name="fundedNgos">
        <Select
          allowClear
          style={{ width: '100%' }}
          mode="multiple"
          onChange={handleChange}
          onSearch={handleSearch}
          onFocus={initSearchFundedNgo}
          placeholder={'Rechercher une ONG'}
          filterOption={(input, option) => {
            const label = (option?.label as string) || '';

            return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          options={optionsFundedNgos}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  padding: 8,
                }}
              >
                <a
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setAddNgoModalOpen(true);
                  }}
                >
                  {"Une organisation n'apparait pas ? Ajoutez-la "}
                  <PlusOutlined />
                </a>
              </div>
            </div>
          )}
        />
      </FormItem>
    </>
  );

  const renderNonRelevantMessage = () => (
    <Text className={classes.nonRelevantMessage}>
      {'Merci de noter cet organisme comme «Non pertinent»'}
    </Text>
  );

  const france = places
    .filter((place) => place.departments && place.departments.length)
    .sort((a, b) =>
      a.name.includes('DROM') || a.name.includes("Collectivités d'Outre-Mer")
        ? 1
        : a.name.localeCompare(b.name),
    ) as Place[];

  const countries = places
    .filter((place) => place.countries && place.countries.length)
    .sort((a, b) => a.name.localeCompare(b.name)) as Place[];

  function renderFrance(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    return isFranceComplete && <Tag className={classes.tag}>France</Tag>;
  }

  function renderDepartments(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return (
      results.length > 0 &&
      results.map((department, index) => (
        <Tag key={index} className={classes.tag}>
          {department}
        </Tag>
      ))
    );
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return;
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return (
      results.length > 0 &&
      results.map((currentCountry, index) => (
        <Tag key={index} className={classes.tag}>
          {currentCountry}
        </Tag>
      ))
    );
  }

  function renderLieuxInterventionFormItem() {
    return (
      <FormItem
        label="Lieu(x) d’intervention"
        name="places"
        rules={
          userRole !== 'admin' &&
          !form.getFieldValue('city') &&
          !form.getFieldValue('zipcode') &&
          !form.getFieldValue('roadAndNumber') &&
          !form.getFieldValue('uniqueZipcode')
            ? [
                {
                  required: true,
                  type: 'array',
                  validator: placesValidator,
                },
              ]
            : undefined
        }
      >
        <div>
          <div style={{ marginBottom: '20px' }}>
            {!!form.getFieldValue('roadAndNumber') &&
              !!form.getFieldValue('city') &&
              !!form.getFieldValue('zipcode') && (
                <div>
                  <Tag
                    key={'uniqueAddressWithInformations'}
                    className={classes.tag}
                  >
                    {form.getFieldValue('roadAndNumber')}
                    {', '}
                    {form.getFieldValue('city')}
                    {', '}
                    {form.getFieldValue('zipcode')}
                  </Tag>
                </div>
              )}
            {!!form.getFieldValue('uniqueZipcode') && (
              <div>
                <Tag key={'uniqueZipcode'} className={classes.tag}>
                  {form.getFieldValue('uniqueZipcode')}
                  {', '}
                  {form.getFieldValue('city')}
                </Tag>
              </div>
            )}
            {!!form.getFieldValue('places')?.length &&
              (renderFrance(form.getFieldValue('places')) ||
                renderDepartments(form.getFieldValue('places')))}
            {!!form.getFieldValue('places')?.length &&
              renderCountries(form.getFieldValue('places'))}
          </div>
          <Button
            onClick={() => setShowPlacesModal(true)}
            className={classes.button}
          >
            Choisir les lieu(x) d’intervention
          </Button>
        </div>
      </FormItem>
    );
  }

  function renderHeadquartersFormItem() {
    return (
      <>
        <FormItem
          label="Code postal du siège social :"
          name="headquarterZipcode"
          wrapperCol={{ span: 2 }}
          rules={[
            {
              pattern: new RegExp(/^[0-9]{4,5}$/),
              message: 'Un code postal complet est requis.',
            },
            {
              required: userRole === 'admin' ? false : true,
              message: 'Un code postal valide est requis.',
            },
          ]}
        >
          <Input
            allowClear
            placeholder={'00000'}
            onChange={(event) => {
              updateCitiesState(event);
            }}
          />
        </FormItem>
        {citiesState.length > 0 && (
          <>
            <Text style={{ fontSize: '0.875vw' }}>Choisissez la ville :</Text>
            {Array.from(new Set(citiesState)).map((city: string, index) => (
              <Tag
                key={index}
                onClick={() => {
                  setCityState(city);
                  form.setFieldsValue({ headquarterCity: city });
                }}
                className={classes.tagCities}
                style={
                  city === cityState
                    ? { backgroundColor: '#1890ff', color: 'white' }
                    : {}
                }
              >
                {city}
              </Tag>
            ))}
            <FormItem
              label="Ville du siège social : "
              name="headquarterCity"
              wrapperCol={{ span: 4 }}
              key={1}
              className={classes.headquarterCityFormItem}
              rules={[
                {
                  required: true,
                  message: 'Cliquez sur une ville proposée.',
                },
              ]}
            >
              <Input allowClear value={cityState} disabled placeholder={''} />
            </FormItem>
          </>
        )}
        <FormItem
          label="Numéro et adresse du siège social"
          name="headquarterAddress"
          wrapperCol={{ span: 8 }}
        >
          <Input allowClear placeholder={''} />
        </FormItem>
      </>
    );
  }

  return (
    <div className={classes.container}>
      <Row>
        <Col span={5} />
        <Col span={14}>
          <Form
            {...layout}
            initialValues={{ ...donor }}
            form={form}
            onValuesChange={onFormValuesChange}
            layout={'vertical'}
          >
            {userRole === 'admin' && (
              <>
                <Divider style={{ fontSize: '1vw' }} orientation={'left'}>
                  Champs Admin
                </Divider>
                {uploadFiles}
                {renderUrlFormItem({
                  label: 'Autre url',
                  name: 'otherUrl',
                })}
                {renderUrlFormItem({
                  label: 'Wachete url',
                  name: 'wacheteUrl',
                })}
                {renderUrlFormItem({
                  label: 'Wachete url 2',
                  name: 'wacheteUrl2',
                })}
                {renderUrlFormItem({
                  label: 'Wachete url 3',
                  name: 'wacheteUrl3',
                })}
                {renderLastCallForTenderValidation()}
                {renderLinkedinUrlFormItem({
                  label: 'Linkedin entreprise',
                  name: 'companyLinkedinUrl',
                })}
                <FormItem
                  label="Porteurs de projets soutenus non vérifiés"
                  name="unverifiedFundedNgos"
                >
                  {form
                    .getFieldValue('unverifiedFundedNgos')
                    ?.map((ngoId: string) => (
                      <Tag
                        key={ngoId}
                        className={classes.tag}
                        closable
                        onClose={() => handleRemoveUnverifiedFundedNgo(ngoId)}
                      >
                        {ngos?.find((ngo) => ngo._id === ngoId)?.name}
                      </Tag>
                    ))}
                </FormItem>
                <FormItem
                  label="Commentaires"
                  name="comments"
                  normalize={normalizeNullToUndefined}
                >
                  <TextArea autoSize={{ minRows: 3, maxRows: 10 }} />
                </FormItem>

                <Divider style={{ fontSize: '1vw' }} orientation={'left'}>
                  Champs Non-Admin
                </Divider>
              </>
            )}
            <Form.Item
              name="name"
              label="Nom"
              rules={[rules.required()]}
              normalize={normalizeNullToUndefined}
            >
              <Input />
            </Form.Item>

            {renderStatusesFormItem()}
            {statusState ===
              classification.statuses.find(
                (status) => status.name === 'shelteredFoundation',
              )?._id && (
              <FormItem label="Abritée par" name="hostedBy">
                <Input />
              </FormItem>
            )}
            {renderNationalitiesFormItem()}
            {renderTargetPersonsFormItem()}

            <FormItem
              label="Année de création"
              name="creationYear"
              normalize={(value) => (value === '' ? null : value)}
              rules={userRole !== 'admin' ? [rules.creationYear()] : undefined}
            >
              <InputNumber />
            </FormItem>

            <FormItem
              label="Description politique mécénale"
              name="description"
              rules={
                userRole !== 'admin'
                  ? [rules.required(), rules.min(200), rules.max(2000)]
                  : undefined
              }
              normalize={normalizeNullToUndefined}
            >
              <TextArea
                placeholder="Veuillez décrire votre organisation et ses activités"
                autoSize={{ minRows: 4, maxRows: 10 }}
                minLength={userRole !== 'admin' ? 200 : 0}
                maxLength={2000}
                showCount={true}
              />
            </FormItem>

            {renderDonationTypeFormItem()}
            <FormItem label="Mode opératoire" name="modusOperandi">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="label"
              >
                {Object.values(ModusOperandi).map((modus) => {
                  return (
                    <Option value={modus} key={modus} label={modus}>
                      {modus}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>

            {renderUrlFormItem({
              label: 'Url du site web',
              name: 'homepageUrl',
            })}

            {renderLinkedinUrlFormItem({
              label: 'URL de la page Linkedin',
              name: 'linkedinUrl',
            })}
            {uploadLogo}

            {renderTagsFormItem()}
            {renderActivityDomainsFormItem()}
            {renderLieuxInterventionFormItem()}
            {renderTargetPopulationsFormItem()}

            {renderFundedNgosFormItem()}
            {renderHeadquartersFormItem()}
            <FormItem
              label="Adresse e-mail"
              name="contactEmail"
              rules={
                userRole === 'admin'
                  ? [rules.email()]
                  : [rules.required(), rules.email()]
              }
            >
              <Input />
            </FormItem>
            <FormItem
              label="Numéro de téléphone"
              name="contactPhone"
              rules={[rules.phone()]}
              normalize={(value) => (value === '' ? null : value)}
            >
              <Input type="tel" />
            </FormItem>

            {nationality === 'other' && (
              <Row style={{ marginBottom: '24px' }}>
                <Col span={5} />
                <Col span={16}>{renderNonRelevantMessage()}</Col>
              </Row>
            )}

            {renderActivityZoneFormItem()}
          </Form>
        </Col>
      </Row>
      {renderAddNgoModal()}
      <TagsSelectorModal
        activityDomains={activityDomains}
        tags={tags}
        isVisible={showTagsModal}
        requestClose={() => setShowTagsModal(false)}
        value={form.getFieldValue('tags')}
        onChange={(value) => {
          setSelectedTags(value ?? []);
          form.setFieldsValue({ tags: value });
        }}
      />
      <PlacesSelectorModal
        form={form}
        uniqueZipcode={form.getFieldValue('uniqueZipcode')}
        zipcode={form.getFieldValue('zipcode')}
        roadAndNumber={form.getFieldValue('roadAndNumber')}
        city={form.getFieldValue('city')}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
        value={form.getFieldValue('places')}
        onChange={(value) => {
          form.setFieldsValue({ places: value });
        }}
        communes={communes}
      />
    </div>
  );
};

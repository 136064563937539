import React from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from '../constants/theme';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    border: '1px solid',
    borderColor: theme.palette.divider,
    padding: theme.spacing(2),
  },
});

export const Box: React.VFC<Props> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={[classes.container, className].join(' ')}>{children}</div>
  );
};

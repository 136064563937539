import { AsyncThunkAction, AsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { useContext } from 'react';

import { CaptchaModalContext } from '../context/CaptchaModal';
import { useAppDispatch } from './store';

export const useCaptchaDispatch = () => {
  const requestCaptcha = useContext(CaptchaModalContext);
  const dispatch = useAppDispatch();

  return async (
    thunk: AsyncThunk<any, any, any>,
    action: AsyncThunkAction<any, any, any>,
  ) => {
    const resultAction = await dispatch(action);

    if (
      thunk.rejected.match(resultAction) &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resultAction.error.message === 'captchaValidationNeeded'
    ) {
      try {
        if (requestCaptcha) {
          await requestCaptcha();
          return dispatch(action);
        }
      } catch (error) {
        message.error(
          'La vérification captcha à échoué. Vous devez faire la vérification pour afficher les données',
        );
      }
    } else {
      return resultAction;
    }
  };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Divider,
  Popover,
  Typography,
  Modal,
} from 'antd';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';
import { NewFinancingNeedEditForm } from '../containers/NewFinancingNeedEditForm';
import { NewNgoEditForm } from '../containers/NewNgoEditForm';
import { NgoEditForm } from '../containers/NgoEditForm';
const { Text } = Typography;
import { useAppSelector, useAppDispatch } from '../hooks/store';
import { CloseOutlined } from '@ant-design/icons';
import financingNeed, {
  FinancingNeed,
  getFinancingNeed,
  getFinancingNeeds,
  Offer,
  removeFinancingNeed,
  saveFinancingNeed,
  updateFinancingNeed,
} from 'src/slices/financingNeed';
import { FinancingNeedEditForm } from 'src/containers/FinancingNeedEditForm';
import { LoggedIntercom } from '../utils/loggedIntercom';

const useStyles = createUseStyles({
  container: {
    padding: '42px',
  },
  view: {
    height: '100vh',
    width: '100vw',
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  deleteButton: {
    // margin: '0 0 0 1.87%',
    color: 'red',
    textAlign: 'center',
  },
  cancelButton: {
    color: 'red',
    marginRight: '32px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
interface Props {
  setIsNavBarHidden: (isNavBarHidden: boolean) => void;
}

export const EditFinancingNeed: React.VFC<Props> = ({
  setIsNavBarHidden,
}: Props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { financingNeeds } = useAppSelector(
    ({ financingNeed: { financingNeeds } }) => ({
      financingNeeds,
    }),
  );

  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    if (user?.role === 'admin') {
      setIsNavBarHidden(false);
    } else {
      setIsNavBarHidden(true);
    }
  }, [user]);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const displayedFinancingNeed = useMemo<FinancingNeed>(
    () => financingNeeds.byId[id],
    [financingNeeds.byId[id]],
  );

  useEffect(() => {
    dispatch(getFinancingNeed(id));
  }, []);

  useEffect(() => {
    if (displayedFinancingNeed) {
      form.setFieldsValue({
        ...displayedFinancingNeed,
      });
    }
  }, [displayedFinancingNeed]);

  const handleSave = async (values: any) => {
    dispatch(
      updateFinancingNeed({
        id: id,
        updateFinancingNeedDto: values,
      }),
    );
    history.goBack();
  };

  const renderActionButtons = () => (
    <div className={classes.buttonContainer}>
      <Button
        type="dashed"
        onClick={() => history.goBack()}
        danger
        icon={<CloseOutlined />}
        className={classes.cancelButton}
      >
        Annuler
      </Button>
      <Button
        type="primary"
        onClick={async () => {
          try {
            const values = await form.validateFields();
            values.city = form.getFieldValue('city');
            values.uniqueZipcode = form.getFieldValue('uniqueZipcode');
            handleSave(values);
          } catch (error) {}
        }}
      >
        Sauvegarder
      </Button>
    </div>
  );

  function renderDeleteButton() {
    return (
      <>
        {(displayedFinancingNeed.offers &&
          displayedFinancingNeed.offers.find(
            (o) => o.offer != Offer.visibility,
          ) && <></>) || (
          <Button
            className={classes.deleteButton}
            type="primary"
            danger
            onClick={() => setShowConfirmDelete(true)}
            size="small"
          >
            Supprimer
          </Button>
        )}
      </>
    );
  }

  /* Remove project */
  const handleRemoveFinancingNeed = () => {
    dispatch(
      removeFinancingNeed({ financingNeedId: displayedFinancingNeed._id }),
    );
    history.push('/projects');
  };

  const renderConfirmDelete = () => (
    <Modal
      onOk={handleRemoveFinancingNeed}
      okText={'Supprimer définitivement'}
      cancelText={'Annuler'}
      visible={showConfirmDelete}
      onCancel={() => setShowConfirmDelete(false)}
      okButtonProps={{
        danger: true,
      }}
    >
      <Text>{'Ètes-vous sûrs de vouloir supprimer ce projet ?'}</Text>
    </Modal>
  );

  const onSave = (values: any) => {
    dispatch(
      updateFinancingNeed({
        id: id,
        updateFinancingNeedDto: values,
      }),
    );
    LoggedIntercom(
      user?.firstName + ' ' + user?.lastName,
      user?.email,
      user?._id,
      'createdFinancingNeed-completed',
    );
  };

  async function submitForm() {
    const fieldNames = [
      'name',
      'description',
      'targetPopulations',
      'tags',
      'places',
      'roadAndNumber',
      'uniqueZipcode',
      'zipcode',
      'city',
      'websiteUrl',
      'logoUrl',
    ];
    const allFieldValues = fieldNames.reduce((acc: any, fieldName: string) => {
      acc[fieldName] = form.getFieldValue(fieldName);
      return acc;
    }, {});
    onSave(allFieldValues);
    await dispatch(getFinancingNeeds(user?.ngoId));
    setIsNavBarHidden(false);
    history.push(`/projects/${displayedFinancingNeed._id}`);
  }

  return (
    <div className={classes.view}>
      {user?.role === 'admin' && (
        <div className={classes.container}>
          {renderActionButtons()}
          <FinancingNeedEditForm form={form} isNewFinancingNeed={false} />
          {renderActionButtons()}
          {renderDeleteButton()}
          {renderConfirmDelete()}
        </div>
      )}
      {user?.role !== 'admin' && (
        <div className={classes.view}>
          <NewFinancingNeedEditForm
            form={form}
            submitForm={submitForm}
            isNewFinancingNeed={false}
            onCancel={() => {
              setIsNavBarHidden(false);
              history.goBack();
            }}
          />
        </div>
      )}
    </div>
  );
};

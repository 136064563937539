import React, { useState } from 'react';
import { Divider } from 'antd';
import { GoogleLogin } from '@react-oauth/google';
import { createUseStyles } from 'react-jss';
import { SignUpForm } from '../containers/SignUpForm';

import { useAppDispatch } from '../hooks/store';
import { signInUpGoogle, signUp } from '../slices/auth';
import { Box } from '../components/Box';
import { SignUpFirstStep } from 'src/containers/SignUpFirstStep';

const useStyles = createUseStyles({
  box: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '550px',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const SignUpContributor: React.VFC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [error, setError] = useState<string | undefined>(undefined);
  const [organisationTypeValue, setOrganisationTypeValue] = useState<
    'MECENE' | 'PROJECT_HOLDER' | undefined
  >(undefined);

  const onFinish = async (values: any) => {
    const {
      email,
      password,
      firstName,
      lastName,
      organisationName,
      organisationType,
    } = values;
    if (error) {
      setError(undefined);
    }

    const resultAction = await dispatch(
      signUp({
        email,
        password,
        firstName,
        lastName,
        organisationName,
        organisationType,
        isContributor: true,
      }),
    );

    if (signUp.rejected.match(resultAction)) {
      setError(resultAction.error.message);
    }
  };

  const responseGoogle = async (response: any) => {
    if (error) {
      setError(undefined);
    }

    if (response.credential) {
      const resultAction = await dispatch(
        signInUpGoogle({
          googleAccessToken: response.credential,
          isContributor: true,
        }),
      );
      if (signInUpGoogle.rejected.match(resultAction)) {
        setError(resultAction.error.message);
      }
    }
  };

  return (
    <Box className={classes.box}>
      {organisationTypeValue === undefined ? (
        <SignUpFirstStep setOrganisationTypeValue={setOrganisationTypeValue} />
      ) : (
        <>
          <SignUpForm
            organisationTypeValue={organisationTypeValue}
            setOrganisationTypeValue={setOrganisationTypeValue}
            onFinish={onFinish}
            error={error}
            setError={setError}
            dispatch={dispatch}
          />
          <Divider />

          <GoogleLogin
            onSuccess={(credentialResponse) => {
              responseGoogle(credentialResponse);
              console.log(credentialResponse);
            }}
            onError={() => {
              responseGoogle({ error: 'error' });
              console.log('Login Failed');
            }}
          />
        </>
      )}
    </Box>
  );
};

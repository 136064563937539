import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Offer } from '../../slices/financingNeed';
import { Payment, Pricing } from './Payment';

export const PaymentPage: React.VFC = () => {
  const { projectId, pricing, type, offer, isMonthly } = useParams<{
    projectId: string;
    pricing: Pricing;
    type: 'donor' | 'ngo';
    offer: string;
    isMonthly: 'true' | 'false';
  }>();

  const promise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
      'cannot retrieve REACT_APP_STRIPE_PUBLIC_KEY',
  );
  let offerType = Offer.visibility;
  switch (offer) {
    case Offer.visibility.toString():
      offerType = Offer.visibility;
      break;
    case Offer.complete.toString():
      offerType = Offer.complete;
      break;
    default:
      break;
  }

  return (
    <Elements stripe={promise}>
      <Payment
        projectId={projectId}
        pricing={pricing}
        type={type}
        offer={offerType}
        isMonthly={isMonthly}
      />
    </Elements>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { getDealFlowColumn } from './dealFlowColumn';
import { DealFlowNote } from './dealFlowNote';

export type AddRemoveDealFlowCardDto = {
  notes: string[];
};

export type DealFlowCard = {
  _id: string;
  name: string;
  dealFlow: string;
  donor: string;
  ngo: string;
  column: string;
  notes: string[];
};

export type FullDealFlowCard = {
  _id: string;
  name: string;
  dealFlow: string;
  donor: string;
  ngo: string;
  column: string;
  notes: DealFlowNote[];
};

export type createDealFlowCardDto = {
  name?: string;
  dealFlow: string;
  donor?: string;
  ngo?: string;
  column: string;
  financingNeed: string;
};

export type updateDealFlowCardDto = {
  _id?: string;
  name?: string;
  dealFlow?: string;
  donor?: string;
  column?: string;
  notes?: string[];
  add?: AddRemoveDealFlowCardDto;
  remove?: AddRemoveDealFlowCardDto;
};

interface DealFlowCardState {
  cards: Record<string, DealFlowCard>;
  saved: boolean;
  loading: boolean;
  updating: boolean;
  error: any;
}

const initialState: DealFlowCardState = {
  cards: {},
  saved: false,
  loading: false,
  updating: false,
  error: null,
};

export const createDealFlowCard = createAsyncThunk(
  'createDealFlowCard',
  async (payload: createDealFlowCardDto, { dispatch }) => {
    const response = await apiRequest<DealFlowCard>(
      'POST',
      '/dealFlow/card',
      undefined,
      payload,
    );
    if (response.column) dispatch(getDealFlowColumn(response.column));
    return response;
  },
);

export const deleteDealFlowCard = createAsyncThunk(
  'deleteDealFlowCard',
  async (
    payload: {
      body: {
        columnId: string;
        projectId?: string;
        callForTenderId?: string;
        donorId?: string;
        ngoId?: string;
      };
      cardId: string;
    },
    { dispatch },
  ) => {
    const response = await apiRequest<DealFlowCard>(
      'DELETE',
      `/dealFlow/card/${payload.cardId}`,
      undefined,
      payload.body,
    );
    dispatch(getDealFlowColumn(payload.body.columnId));
    return response;
  },
);

export const updateDealFlowCard = createAsyncThunk(
  'updateDealFlowCard',
  async (
    payload: {
      body: updateDealFlowCardDto;
      cardId: string;
      dealFlowId: string;
    },
    // { dispatch },
  ) => {
    return await apiRequest<DealFlowCard>(
      'PATCH',
      `/dealFlow/card/${payload.cardId}`,
      undefined,
      payload.body,
    );
  },
);

const updateDealFlowCardLocallyAction = (
  state: DealFlowCardState,
  action: {
    type: string;
    payload: { cardId: string; body: updateDealFlowCardDto };
  },
) => {
  if (!state.cards) return state;
  const { add, remove, ...update } = action.payload.body;
  console.log(add, remove, update);
  // const card = state.cards[action.payload.cardId];
  // if (!card) return state;
  // add?.notes && card.notes.concat(add.notes);
  // remove?.notes && card.notes.splice(0, remove.notes.length, ...remove.notes);
  return {
    ...state,
    // ...update,
  };
};

export const getDealFlowCards = createAsyncThunk(
  'getDealFlowCards',
  async (columnId: string | undefined) => {
    return await apiRequest<DealFlowCard[]>(
      'GET',
      `/dealFlow/column/${columnId}/cards`,
    );
  },
);

export const getDealFlowCard = createAsyncThunk(
  'getDealFlowCard',
  async (cardId: string | undefined) => {
    return await apiRequest<DealFlowNote>('GET', `/dealFlow/card/${cardId}`);
  },
);

const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    updateDealFlowCardLocally: updateDealFlowCardLocallyAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDealFlowCard.pending, (state: DealFlowCardState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(
        createDealFlowCard.fulfilled,
        (state: DealFlowCardState, { payload }) => {
          state.cards[payload._id] = payload;
          state.loading = false;
        },
      )
      .addCase(
        createDealFlowCard.rejected,
        (state: DealFlowCardState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(deleteDealFlowCard.pending, (state: DealFlowCardState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(deleteDealFlowCard.fulfilled, (state: DealFlowCardState) => {
        state.loading = false;
      })
      .addCase(
        deleteDealFlowCard.rejected,
        (state: DealFlowCardState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(updateDealFlowCard.pending, (state: DealFlowCardState) => {
        state.updating = true;
      })
      .addCase(
        updateDealFlowCard.fulfilled,
        (state: DealFlowCardState, { payload }) => {
          state.cards[payload._id] = payload;
          state.saved = true;
          state.updating = false;
        },
      )
      .addCase(
        updateDealFlowCard.rejected,
        (state: DealFlowCardState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(getDealFlowCards.pending, (state: DealFlowCardState) => {
        state.updating = true;
      })
      .addCase(
        getDealFlowCards.fulfilled,
        (state: DealFlowCardState, { payload }) => {
          const cards = payload.reduce(
            (
              accumulator: Record<string, DealFlowCard>,
              current: DealFlowCard,
            ) => ({ ...accumulator, [current._id]: current }),
            {},
          );
          Object.assign(state.cards, cards);
          state.loading = false;
        },
      )
      .addCase(
        getDealFlowCards.rejected,
        (state: DealFlowCardState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(getDealFlowCard.pending, (state: DealFlowCardState) => {
        state.loading = true;
      })
      .addCase(
        getDealFlowCard.fulfilled,
        (state: DealFlowCardState, { payload }) => {
          Object.assign(state.cards, { [payload._id]: payload });
          state.loading = false;
        },
      )
      .addCase(
        getDealFlowCard.rejected,
        (state: DealFlowCardState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      );
  },
});

export const { updateDealFlowCardLocally } = cardSlice.actions;

export default cardSlice.reducer;

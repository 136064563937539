import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { getDealFlow } from './dealFlow';
import { DealFlowCard } from './dealFlowCard';

export type AddRemoveDealFlowColumnDto = {
  cards: string[];
};

export type DealFlowColumn = {
  _id: string;
  name: string;
  position: number;
  dealFlow: string;
  cards: string[];
};

export type FullDealFlowColumn = {
  _id: string;
  name: string;
  position: number;
  dealFlow: string;
  cards: DealFlowCard[];
};

export type createDealFlowColumnDto = {
  name?: string;
  position?: number;
  dealFlow: string;
};

export type updateDealFlowColumnDto = {
  _id?: string;
  name?: string;
  position?: number;
  dealFlow?: string;
  cards?: string[];
  add?: AddRemoveDealFlowColumnDto;
  remove?: AddRemoveDealFlowColumnDto;
};

interface DealFlowColumnState {
  columns: Record<string, DealFlowColumn>;
  saved: boolean;
  loading: boolean;
  updating: boolean;
  error: any;
}

const initialState: DealFlowColumnState = {
  columns: {},
  saved: false,
  updating: true,
  loading: false,
  error: null,
};

export const createDealFlowColumn = createAsyncThunk(
  'createDealFlowColumn',
  async (payload: createDealFlowColumnDto, { dispatch }) => {
    const response = await apiRequest<{ status: boolean }>(
      'POST',
      '/dealFlow/column',
      undefined,
      payload,
    );
    if (response) dispatch(getDealFlow(payload.dealFlow));
  },
);

export const updateDealFlowColumn = createAsyncThunk(
  'updateDealFlowColumn',
  async (
    payload: {
      body: updateDealFlowColumnDto;
      columnId: string;
      dealFlowId: string;
    },
    // { dispatch },
  ) => {
    /*const response = */ await apiRequest<{ status: boolean }>(
      'PATCH',
      `/dealFlow/column/${payload.columnId}`,
      undefined,
      payload.body,
    );
    // if (response) dispatch(getDealFlow(payload.dealFlowId));
  },
);

const addColumnCardAction = (
  state: DealFlowColumnState,
  action: {
    type: string;
    payload: { columnId: string; cardId: string; index: number };
  },
) => ({
  ...state,
  columns: {
    ...state.columns,
    [action.payload.columnId]: {
      ...state.columns[action.payload.columnId],
      cards: [
        ...state.columns[action.payload.columnId].cards.slice(
          0,
          action.payload.index,
        ),
        action.payload.cardId,
        ...state.columns[action.payload.columnId].cards.slice(
          action.payload.index,
        ),
      ],
    },
  },
});

const removeColumnCardAction = (
  state: DealFlowColumnState,
  action: {
    type: string;
    payload: { columnId: string; index: number };
  },
) => ({
  ...state,
  columns: {
    ...state.columns,
    [action.payload.columnId]: {
      ...state.columns[action.payload.columnId],
      cards: [
        ...state.columns[action.payload.columnId].cards.slice(
          0,
          action.payload.index,
        ),
        ...state.columns[action.payload.columnId].cards.slice(
          action.payload.index + 1,
        ),
      ],
    },
  },
});

const updateDealFlowColumnLocallyAction = (
  state: DealFlowColumnState,
  action: {
    type: string;
    payload: { columnId: string; body: updateDealFlowColumnDto };
  },
) => {
  if (!state.columns) return state;
  const { add, remove, ...update } = action.payload.body;
  const { columnId } = action.payload;
  console.log(add, remove, update);
  // const columns = state.columns.copy();
  // const column = state.columns[action.payload.columnId];
  // const cards = column.cards.slice();
  // if (!column) return state;
  // add?.cards && cards.concat(add.cards);
  // remove?.cards && cards.splice(0, remove.cards.length, ...remove.cards);
  // column.cards = cards;
  // columns[action.payload.columnId] = column;
  const cards = state.columns[columnId].cards.slice();
  add && cards.push(add?.cards[0]);
  remove && cards.splice(0, 1, remove.cards[0]);
  // .filter((card) => !remove?.cards.includes(card));
  return {
    ...state,
    columns: {
      ...state.columns,
      [action.payload.columnId]: {
        ...state.columns[action.payload.columnId],
        cards: cards,
        // state.columns[action.payload.columnId].cards
        //   .filter((card) => !remove?.cards.includes(card))
        //   .concat(...(add?.cards || [])),
        // ...update,
      },
    },
  };
};

export const getDealFlowColumns = createAsyncThunk(
  'getDealFlowColumns',
  async (dealFlowId: string | undefined) => {
    return await apiRequest<DealFlowColumn[]>(
      'GET',
      `/dealFlow/${dealFlowId}/columns`,
    );
  },
);

export const getDealFlowColumn = createAsyncThunk(
  'getDealFlowColumn',
  async (columnId: string | undefined) => {
    return await apiRequest<DealFlowColumn>(
      'GET',
      `/dealFlow/column/${columnId}`,
    );
  },
);

const columnSlice = createSlice({
  name: 'column',
  initialState,
  reducers: {
    updateDealFlowColumnLocally: updateDealFlowColumnLocallyAction,
    addColumnCard: addColumnCardAction,
    removeColumnCard: removeColumnCardAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDealFlowColumn.pending, (state: DealFlowColumnState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(
        createDealFlowColumn.fulfilled,
        (state: DealFlowColumnState, {}) => {
          state.saved = true;
          state.loading = false;
        },
      )
      .addCase(
        createDealFlowColumn.rejected,
        (state: DealFlowColumnState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(updateDealFlowColumn.pending, (state: DealFlowColumnState) => {
        state.updating = true;
        state.saved = false;
      })
      .addCase(
        updateDealFlowColumn.fulfilled,
        (state: DealFlowColumnState, {}) => {
          state.saved = true;
          state.updating = false;
        },
      )
      .addCase(
        updateDealFlowColumn.rejected,
        (state: DealFlowColumnState, { payload }) => {
          state.saved = false;
          state.updating = false;
          state.error = payload;
        },
      )
      .addCase(getDealFlowColumns.pending, (state: DealFlowColumnState) => {
        state.updating = true;
        state.saved = false;
      })
      .addCase(
        getDealFlowColumns.fulfilled,
        (state: DealFlowColumnState, { payload }) => {
          const columns = payload.reduce(
            (
              accumulator: Record<string, DealFlowColumn>,
              current: DealFlowColumn,
            ) => ({ ...accumulator, [current._id]: current }),
            {},
          );
          Object.assign(state.columns, columns);
          state.updating = false;
        },
      )
      .addCase(
        getDealFlowColumns.rejected,
        (state: DealFlowColumnState, { payload }) => {
          state.saved = false;
          state.updating = false;
          state.error = payload;
        },
      )
      .addCase(getDealFlowColumn.pending, (state: DealFlowColumnState) => {
        state.loading = true;
      })
      .addCase(
        getDealFlowColumn.fulfilled,
        (state: DealFlowColumnState, { payload }) => {
          state.columns[payload._id] = payload;
          state.loading = false;
        },
      )
      .addCase(
        getDealFlowColumn.rejected,
        (state: DealFlowColumnState, { payload }) => {
          state.saved = false;
          state.loading = false;
          state.error = payload;
        },
      );
  },
});

export const { updateDealFlowColumnLocally, addColumnCard, removeColumnCard } =
  columnSlice.actions;

export default columnSlice.reducer;

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Tag,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FormItem } from '../components/FormItem';
import { PlacesSelectorModal } from '../components/PlacesSelectorModal';
import { TagsSelectorModal } from '../components/TagsSelectorModal';
import { IUploadProps, Upload } from '../components/Upload';

import { useAppSelector, useAppDispatch } from '../hooks/store';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { placesValidator, tagsValidator } from '../helpers/validators';
import { useFormRules } from '../hooks/useFormRules';
import { useSortedClassification } from '../hooks/useSortedClassification';
import { Place } from '../slices/classification';
import { getCommunes } from '../slices/communes';
import { getNgo } from '../slices/ngo';
import { FinancingNeed } from 'src/slices/financingNeed';
import { FormInstance } from 'antd/es/form/Form';

const { Link } = Typography;
const { Option } = Select;

const TAGS_MAX_QUANTITY = 12;

const layout = {
  labelCol: { span: 8 },
};

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '3vh',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    '& .ant-form-item, .ant-form-item-label > label,\
      .ant-input, .ant-input-number': {
      fontSize: '0.875vw',
    },
    '& .ant-col': {
      maxWidth: '100%',
    },
  },
  textArea: {
    height: 200,
    marginBottom: 50,
  },
  tag: {
    fontSize: '14px',
    margin: '2px',
    padding: '2px 7px',
  },
  button: {
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  trashIcon: {
    color: '#002766',
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  inputNumber: {
    '& .ant-input-number-handler-wrap': {
      fontSize: '0.875vw',
      width: '0',
    },
    width: '10vw',
  },
  tagsFormItemsError: {
    '& .ant-btn': { color: 'red' },
    '& .ant-form-item-label > label': { color: 'red' },
  },
});

const normalizeEmptyStringToNull = (value: unknown) =>
  value === '' ? null : value;

interface IFinancingNeedEditForm {
  form: FormInstance<FinancingNeed>;
  isNewFinancingNeed: boolean;
  ngoId?: string;
}

export const FinancingNeedEditForm: React.VFC<IFinancingNeedEditForm> = ({
  form,
  isNewFinancingNeed,
  ngoId,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const rules = useFormRules();
  const classification = useSortedClassification();
  const { ngo, user, userRole, communes } = useAppSelector(
    ({ ngo: { ngo }, auth: { user }, communes: { communes } }) => ({
      ngo,
      user,
      userRole: user?.role,
      communes,
    }),
  );

  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string | undefined | null>(undefined);

  useEffect(() => {
    if (ngoId) {
      dispatch(getNgo(ngoId));
      return;
    }
    if (user?.ngoId && !ngo) {
      dispatch(getNgo(user?.ngoId));
    }
  }, [user?.ngoId, ngoId]);

  useEffect(() => {
    dispatch(getCommunes());
  }, []);

  useEffect(() => {
    form.setFieldsValue({ logoUrl });
  }, [logoUrl]);

  useEffect(() => {
    if (form.getFieldValue('logoUrl')) {
      setLogoUrl(form.getFieldValue('logoUrl'));
    }
  }, [form.getFieldValue('logoUrl')]);

  useEffect(() => {
    if (form.getFieldValue('tags')?.length > 0 && classification.tags?.length) {
      form.setFieldsValue({
        activityDomains: form
          .getFieldValue('tags')
          .reduce((previousValue: string[], tagId: string) => {
            const activityDomains = classification.tags.find(
              (t) => t._id === tagId,
            )?.associatedDomains;
            if (activityDomains) {
              return previousValue.concat(activityDomains);
            }
            return previousValue;
          }, [])
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index,
          ),
      });
    } else {
      form.setFieldsValue({
        activityDomains: [],
      });
    }
  }, [form.getFieldValue('tags')]);

  if (!classification || !ngo) return <FullscreenSpin />;

  const { tags, places, activityDomains, targetPopulations } = classification;

  /* Logo */
  const renderLogo = () => {
    return (
      logoUrl && (
        <div>
          <Row align="middle">
            <Col xs={8} />
            <Col xs={5} xxl={3}>
              <div
                style={{ display: 'flex', float: 'left', marginTop: '10px' }}
              >
                <Image
                  src={logoUrl}
                  width="90px"
                  height="90px"
                  fallback="error"
                  alt="Logo"
                />
              </div>
            </Col>
            <Col xs={9} xxl={10}>
              <Link onClick={() => setLogoUrl(null)} style={{ float: 'left' }}>
                <DeleteOutlined className={classes.trashIcon} />
              </Link>
            </Col>
          </Row>
        </div>
      )
    );
  };

  const onSuccessLogoUpload = (info: any) => {
    if (info.file.type === 'image/png' || info.file.type === 'image/jpeg') {
      message.success('Logo ajouté avec succès !');
      setLogoUrl(info.file.response);
    } else {
      message.error('Seul les formats jpeg et png sont acceptés');
    }
  };

  const uploadLogoProps: IUploadProps = {
    name: 'file',
    endpoint: '/file/upload',
    onSuccess: onSuccessLogoUpload,
    text: 'Importer un logo',
    children: <Button icon={<UploadOutlined />}>Importer un logo</Button>,
    uploadProps: {
      showUploadList: false,
    },
  };

  const renderUploadLogo = () => {
    return (
      <div style={{ marginBottom: '25px' }}>
        <Row align="middle" style={{ paddingBottom: '8px' }}>
          <Text
            style={{
              textAlign: 'left',
              width: '100%',
              marginRight: '8px',
              fontSize: '0.875vw',
              marginBottom: '8000px !important',
            }}
          >
            Logo :
          </Text>
        </Row>
        <Row align="middle" style={{ paddingBottom: '8px' }}>
          <Upload {...uploadLogoProps} />
        </Row>
        {renderLogo()}
      </div>
    );
  };

  const france = places
    .filter((place) => place.departments && place.departments.length)
    .sort((a, b) =>
      a.name.includes('DROM') || a.name.includes("Collectivités d'Outre-Mer")
        ? 1
        : a.name.localeCompare(b.name),
    ) as Place[];

  const countries = places
    .filter((place) => place.countries && place.countries.length)
    .sort((a, b) => a.name.localeCompare(b.name)) as Place[];

  function renderFrance(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    return isFranceComplete && <Tag className={classes.tag}>France</Tag>;
  }

  function renderDepartments(depIds: string[]) {
    if (!depIds || depIds.length === 0) return;
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return (
      results.length > 0 &&
      results.map((department, index) => (
        <Tag key={index} className={classes.tag}>
          {department}
        </Tag>
      ))
    );
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return;
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return (
      results.length > 0 &&
      results.map((currentCountry, index) => (
        <Tag key={index} className={classes.tag}>
          {currentCountry}
        </Tag>
      ))
    );
    return false;
  }

  function getTags() {
    if (!isNewFinancingNeed) {
      return form.getFieldValue('tags');
    }
    if (ngo && ngo.tags && ngo.tags.length <= 12) {
      return ngo.tags;
    }
    return [];
  }

  return (
    <div className={classes.container}>
      <Row>
        <Col span={6} />
        <Col span={12}>
          <Form
            {...layout}
            form={form}
            initialValues={{ adminNgos: [ngo._id] }}
            layout={'vertical'}
          >
            <Form.Item name="logoUrl" noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              label="Nom de votre projet"
              name="name"
              rules={[rules.required()]}
            >
              <Input placeholder="Tout commence avec un nom..." />
            </Form.Item>
            {renderUploadLogo()}
            <Form.Item
              label="Description"
              name="description"
              // rules={[rules.required(), rules.min(200), rules.max(2000)]}
              rules={
                user?.role !== 'admin'
                  ? [rules.required(), rules.min(200), rules.max(2000)]
                  : undefined
              }
            >
              <TextArea
                placeholder="Veuillez décrire en détail votre projet"
                autoSize={{ minRows: 4, maxRows: 10 }}
                minLength={200}
                maxLength={2000}
                showCount={true}
              />
            </Form.Item>
            <Form.Item
              label="Mots clés"
              name="tags"
              rules={
                user?.role !== 'admin'
                  ? [
                      {
                        required: true,
                        type: 'array',
                        validator: tagsValidator,
                      },
                    ]
                  : undefined
              }
            >
              <div>
                {!!form.getFieldValue('tags')?.length && (
                  <div style={{ marginBottom: '20px' }}>
                    {form.getFieldValue('tags')?.map((tagId: string) => (
                      <Tag key={tagId} className={classes.tag}>
                        {tags.find((tag) => tag._id === tagId)?.translatedName}
                      </Tag>
                    ))}
                  </div>
                )}
                <Button
                  onClick={() => setShowTagsModal(true)}
                  className={classes.button}
                >
                  {`Choisir les mots clés (maximum ${TAGS_MAX_QUANTITY})`}
                </Button>
              </div>
            </Form.Item>
            <FormItem
              label="Domaines d'intervention"
              name="activityDomains"
              hidden
              rules={
                userRole !== 'admin'
                  ? [rules.required({ type: 'array' })]
                  : undefined
              }
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="label"
              >
                {activityDomains.map((activityDomain) => {
                  return (
                    <Option
                      value={activityDomain._id}
                      key={activityDomain._id}
                      label={activityDomain.translatedName}
                    >
                      {activityDomain.translatedName}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
            <FormItem
              label="Bénéficiaires cibles"
              name="targetPopulations"
              rules={
                user?.role !== 'admin'
                  ? [rules.required({ type: 'array' })]
                  : undefined
              }
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="label"
              >
                {targetPopulations.map((targetPopulation) => {
                  return (
                    <Option
                      value={targetPopulation._id}
                      key={targetPopulation._id}
                      label={targetPopulation.translatedName}
                    >
                      {targetPopulation.translatedName}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
            {/*<FormItem label="Zones d'intervention" name="activityZones">*/}
            {/*  <Select*/}
            {/*    mode="multiple"*/}
            {/*    allowClear*/}
            {/*    style={{ width: '100%' }}*/}
            {/*    optionFilterProp="label"*/}
            {/*  >*/}
            {/*    {activityZones.map((activityZone) => {*/}
            {/*      return (*/}
            {/*        <Option*/}
            {/*          value={activityZone._id}*/}
            {/*          key={activityZone._id}*/}
            {/*          label={activityZone.translatedName}*/}
            {/*        >*/}
            {/*          {activityZone.translatedName}*/}
            {/*        </Option>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*  </Select>*/}
            {/*</FormItem>*/}
            <FormItem
              label="Lieu(x) d’intervention"
              name="places"
              rules={
                userRole !== 'admin' &&
                !form.getFieldValue('city') &&
                !form.getFieldValue('zipcode') &&
                !form.getFieldValue('roadAndNumber') &&
                !form.getFieldValue('uniqueZipcode')
                  ? [
                      {
                        required: true,
                        type: 'array',
                        validator: placesValidator,
                      },
                    ]
                  : undefined
              }
            >
              <div>
                <div style={{ marginBottom: '20px' }}>
                  {!!form.getFieldValue('roadAndNumber') &&
                    !!form.getFieldValue('city') &&
                    !!form.getFieldValue('zipcode') && (
                      <div>
                        <Tag
                          key={'uniqueAddressWithInformations'}
                          className={classes.tag}
                        >
                          {form.getFieldValue('roadAndNumber')}
                          {', '}
                          {form.getFieldValue('city')}
                          {', '}
                          {form.getFieldValue('zipcode')}
                        </Tag>
                      </div>
                    )}
                  {!!form.getFieldValue('uniqueZipcode') && (
                    <div>
                      <Tag key={'uniqueZipcode'} className={classes.tag}>
                        {form.getFieldValue('uniqueZipcode')}
                        {', '}
                        {form.getFieldValue('city')}
                      </Tag>
                    </div>
                  )}
                  {!!form.getFieldValue('places')?.length &&
                    (renderFrance(form.getFieldValue('places')) ||
                      renderDepartments(form.getFieldValue('places')))}
                  {!!form.getFieldValue('places')?.length &&
                    renderCountries(form.getFieldValue('places'))}
                </div>
                <Button
                  onClick={() => setShowPlacesModal(true)}
                  className={classes.button}
                >
                  Choisir les lieu(x) d’intervention
                </Button>
              </div>
            </FormItem>
            <FormItem
              label="Url du site web dédié"
              name="websiteUrl"
              rules={[rules.url()]}
              normalize={normalizeEmptyStringToNull}
            >
              <Input type="url" placeholder={'https://'} />
            </FormItem>
          </Form>
        </Col>
      </Row>
      <TagsSelectorModal
        activityDomains={activityDomains}
        tags={tags}
        isVisible={showTagsModal}
        requestClose={() => setShowTagsModal(false)}
        value={getTags()}
        onChange={(value) => form.setFieldsValue({ tags: value })}
        tagsMaxQuantity={TAGS_MAX_QUANTITY}
      />
      <PlacesSelectorModal
        form={form}
        uniqueZipcode={form.getFieldValue('uniqueZipcode')}
        roadAndNumber={form.getFieldValue('roadAndNumber')}
        city={form.getFieldValue('city')}
        zipcode={form.getFieldValue('zipcode')}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
        value={form.getFieldValue('places')}
        onChange={(value) => {
          form.setFieldsValue({ places: value });
        }}
        placesMaxQuantity={12}
        communes={communes}
      />
    </div>
  );
};

import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Table, Popconfirm } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  Contributor,
  deleteContributor,
  fetchAllContributors,
  removeContributor,
} from 'src/slices/contributor';
import { contributorsSelector } from 'src/store';

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '1vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  name: {
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '12.5vw',
    fontSize: '0.875vw',
  },
  column: {
    color: 'black',
    fontWeight: 'bold',
    cursor: 'pointer',
    maxWidth: '18.75vw',
  },
  button: {
    display: 'block',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
});

export const Contributors: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(({ contributor: { loading } }) => ({
    loading,
  }));

  const contributors = useAppSelector(contributorsSelector.selectAll);

  useEffect(() => {
    dispatch(fetchAllContributors());
  }, []);

  const deleteContributorbyId = (contributorId: string) => {
    dispatch(deleteContributor(contributorId));
    dispatch(removeContributor(contributorId));
    dispatch(fetchAllContributors());
  };

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'firstName',
      key: '_id',
      render: function renderName(firstName: string, record: Contributor) {
        return (
          <div className={classes.name}>
            {`${firstName ?? ''} ${record.lastName ?? ''}`}
          </div>
        );
      },
    },
    {
      title: 'À qualifier',
      dataIndex: 'rawData',
      sorter: (a: Contributor, b: Contributor) =>
        (a.rawData || 0) - (b.rawData || 0),
    },
    {
      title: 'À valider',
      dataIndex: 'toValidate',
      sorter: (a: Contributor, b: Contributor) =>
        (a.toValidate || 0) - (b.toValidate || 0),
    },
    {
      title: 'Validés',
      dataIndex: 'published',
      sorter: (a: Contributor, b: Contributor) =>
        (a.published || 0) - (b.published || 0),
    },
    {
      title: 'Assos référencées',
      dataIndex: 'referencedNgos',
      sorter: (a: Contributor, b: Contributor) =>
        (a.referencedNgos || 0) - (b.referencedNgos || 0),
    },
    {
      title: 'Suppression',
      key: 'deleteNgo',
      width: '10%',
      render: function renderDeleteNgo(record: Contributor) {
        return (
          <div className={classes.column}>
            <Popconfirm
              title="Êtes-vous sûr de vouloir supprimer ce contribueur ?"
              placement="left"
              onConfirm={() => {
                deleteContributorbyId(record._id);
              }}
              okText="Oui"
              cancelText="Non"
            >
              <Button type="primary" className={classes.button}>
                Supprimer
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      className={classes.table}
      dataSource={contributors}
      columns={columns}
      loading={loading || !contributors}
    />
  );
};

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  fetchUsersCount,
  fetchUsersNgoCount,
  fetchUsersNgoIdCount,
  fetchUsersDonorCount,
  fetchUsersDonorIdCount,
} from '../slices/auth';
import { fetchDonorsCount } from '../slices/donor';
import {
  fetchNgosCount,
  fetchNgosWithTagsWithoutAdminCount,
} from '../slices/ngo';
import {
  fetchCallForTendersCount,
  fetchUniqueAdminDonorsCount,
  fetchCallForTenderPaidCount,
  fetchUniqueAdminDonorsPaidCount,
} from '../slices/callForTender';
import {
  fetchFinancingNeedsCount,
  fetchUniqueAdminNgosCount,
  fetchUniqueAdminNgosPaidCount,
  fetchFinancingNeedPaidCount,
} from 'src/slices/financingNeed';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AnimatedNumber from 'animated-number-react';
import { createUseStyles } from 'react-jss';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useParams } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

const useStyles = createUseStyles({
  title: {
    fontSize: '2rem',
    color: '#261baa',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '1rem',
  },
  doughnut: {
    maxWidth: '300px',
    maxHeight: '300px',
  },
  stats: {
    width: '100%',
    margin: '1rem auto',
    padding: '1rem',
    backgroundColor: 'white',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '20px',
  },
  containerBlue1: {
    height: '200px',
    backgroundColor: '#261baa',
    color: '#f8edd3',
    fontSize: '1rem',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerBlue2: {
    height: '200px',
    backgroundColor: '#5062f0',
    color: '#f8edd3',
    fontSize: '1rem',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerBlue3: {
    height: '200px',
    backgroundColor: '#6a9bf7',
    color: '#f8edd3',
    fontSize: '1rem',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const Statistics: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { organisationType } = useParams<{ organisationType: string }>();
  const formatValue = (value: number) => value.toFixed(0);
  const { userNgoCount } = useAppSelector((state) => state.auth);
  const { userNgoIdCount } = useAppSelector((state) => state.auth);
  const { userDonorIdCount } = useAppSelector((state) => state.auth);
  const { userDonorCount } = useAppSelector((state) => state.auth);
  const { donorCount } = useAppSelector((state) => state.donor);
  const { ngoCount } = useAppSelector((state) => state.ngo);
  const { ngoWithTagsWithoutAdminCount } = useAppSelector((state) => state.ngo);
  const { callForTenderCount } = useAppSelector((state) => state.callForTender);
  const { financingNeedCount } = useAppSelector((state) => state.financingNeed);
  const { uniqueAdminDonorsCount } = useAppSelector(
    (state) => state.callForTender,
  );
  const { uniqueAdminNgosCount } = useAppSelector(
    (state) => state.financingNeed,
  );
  const { uniqueAdminNgosPaidCount } = useAppSelector(
    (state) => state.financingNeed,
  );
  const { uniqueAdminDonorsPaidCount } = useAppSelector(
    (state) => state.callForTender,
  );
  const { callForTenderPaidCount } = useAppSelector(
    (state) => state.callForTender,
  );
  const { financingNeedPaidCount } = useAppSelector(
    (state) => state.financingNeed,
  );

  useEffect(() => {
    dispatch(fetchUsersCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUsersNgoCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUsersNgoIdCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUsersDonorIdCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUsersDonorCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDonorsCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNgosCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNgosWithTagsWithoutAdminCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCallForTendersCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFinancingNeedsCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUniqueAdminDonorsCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUniqueAdminNgosCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUniqueAdminNgosPaidCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUniqueAdminDonorsPaidCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCallForTenderPaidCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFinancingNeedPaidCount());
  }, [dispatch]);

  return (
    <>
      <div className={classes.stats}>
        {organisationType === 'ngo' ? (
          <>
            <div className={classes.title}>Porteurs de projet :</div>
            <div className={classes.title}></div>
            <div className={classes.containerBlue1}>
              1_1_PP_Inscrits :
              <AnimatedNumber value={userNgoCount} formatValue={formatValue} />
            </div>
            <div className={classes.containerBlue1}>
              1_2_PP_Form_PP_complete :
              <AnimatedNumber
                value={userNgoIdCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue1}>
              1_2X_PP_Form_PP_non_complete :
              <AnimatedNumber
                value={
                  userNgoCount && userNgoIdCount
                    ? userNgoCount - userNgoIdCount
                    : 0
                }
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue1}>
              1_3_PP_Form_projet_complete :
              <AnimatedNumber
                value={uniqueAdminNgosCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue1}>
              1_3X_PP_Form_projet_non_complete :
              <AnimatedNumber
                value={
                  userNgoIdCount && uniqueAdminNgosCount
                    ? userNgoIdCount - uniqueAdminNgosCount
                    : 0
                }
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue1}>
              1_4_PP_Offre_Nationale_Souscrite :
              <AnimatedNumber
                value={uniqueAdminNgosPaidCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue1}>
              1_4X_PP_Offre_Nationale_Non_Souscrite :
              <AnimatedNumber
                value={
                  uniqueAdminNgosCount && uniqueAdminNgosPaidCount
                    ? uniqueAdminNgosCount - uniqueAdminNgosPaidCount
                    : 0
                }
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue1}>
              Porteurs de projet :
              <AnimatedNumber value={ngoCount} formatValue={formatValue} />
            </div>
            <div className={classes.containerBlue1}>
              Projets :
              <AnimatedNumber
                value={financingNeedCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue1}>
              Projets avec abonnement :
              <AnimatedNumber
                value={financingNeedPaidCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue1}>
              PP avec tags et sans admin :
              <AnimatedNumber
                value={ngoWithTagsWithoutAdminCount}
                formatValue={formatValue}
              />
            </div>
          </>
        ) : (
          <>
            <div className={classes.title}>Mécènes :</div>
            <div className={classes.title}></div>
            <div className={classes.containerBlue2}>
              1_1_M_Inscrits:
              <AnimatedNumber
                value={userDonorCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue2}>
              1_2_M_Form_M_complete :
              <AnimatedNumber
                value={userDonorIdCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue2}>
              1_2X_M_Form_M_non_complete :
              <AnimatedNumber
                value={
                  userDonorCount && userDonorIdCount
                    ? userDonorCount - userDonorIdCount
                    : 0
                }
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue2}>
              1_3_M_Form_programme_complete :
              <AnimatedNumber
                value={uniqueAdminDonorsCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue2}>
              1_3X_M_Form_programme_non_complete :
              <AnimatedNumber
                value={
                  userDonorIdCount && uniqueAdminDonorsCount
                    ? userDonorIdCount - uniqueAdminDonorsCount
                    : 0
                }
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue2}>
              1_4_M_Offre_Nationale_Souscrite :
              <AnimatedNumber
                value={uniqueAdminDonorsPaidCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue2}>
              1_4X_M_Offre_Nationale_Non_Souscrite :
              <AnimatedNumber
                value={
                  uniqueAdminDonorsCount && uniqueAdminDonorsPaidCount
                    ? uniqueAdminDonorsCount - uniqueAdminDonorsPaidCount
                    : 0
                }
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue2}>
              Mécènes :
              <AnimatedNumber value={donorCount} formatValue={formatValue} />
            </div>
            <div className={classes.containerBlue2}>
              Programmes de soutien :
              <AnimatedNumber
                value={callForTenderCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue2}>
              Mécènes avec programme :
              <AnimatedNumber
                value={uniqueAdminDonorsCount}
                formatValue={formatValue}
              />
            </div>
            <div className={classes.containerBlue2}>
              Programmes avec abonnement :
              <AnimatedNumber
                value={callForTenderPaidCount}
                formatValue={formatValue}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

import { Tag, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import { ClassificationElement } from '../slices/classification';
import { PopoverTagList } from './PopoverTagList';

interface Props {
  elementIds: string[];
  maxElements?: number;
  collection: ClassificationElement[];
  color: string;
  collectionName: string;
  highlightedIds?: string[];
  styles?: Record<string, unknown>;
}

const useStyles = createUseStyles({
  tag: {
    '&.ant-tag': {
      margin: '0.093vw 0 0.093vw',
      cursor: 'default',
      border: 'none',
      // padding: '0.1875vw 0.5vw',
      fontSize: '0.75vw',
      lineHeight: '1vw',
      whiteSpace: 'nowrap',
      marginLeft: '0.25vw',
      marginRight: '0.25vw',
    },
  },

  popoverTags: {
    display: 'flex',
    flexDirection: 'column',
    overFlowY: 'scroll',
  },
  otherTags: {
    cursor: 'pointer',
    margin: '0.093vw 0.25vw',
    fontSize: '0.875vw',
  },
});

export const TagListForDonorAndNgoPages: React.VFC<Props> = ({
  elementIds,
  maxElements = elementIds.length,
  collection,
  color,
  collectionName,
  styles,
  highlightedIds,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const elements = useMemo<ClassificationElement[]>(
    () =>
      elementIds?.reduce(
        (elementList: ClassificationElement[], elementId: string) => {
          const element = collection.find(
            (element: ClassificationElement) => element._id === elementId,
          );
          if (element) {
            elementList.push(element);
          }
          return elementList;
        },
        [],
      ),
    [elementIds, collection, collectionName],
  );

  const renderTag = (
    tag: ClassificationElement,
    index: number,
    arr: string | any[],
  ) => (
    <Tag
      className={classes.tag}
      style={
        highlightedIds && highlightedIds.includes(tag._id)
          ? { backgroundColor: 'rgba(0,189,156,0.66)' }
          : undefined
      }
      color={color}
      key={tag._id}
    >
      {t(`${collectionName}:${tag.name}`) +
        (arr.length - 1 !== index ? ',' : '')}
    </Tag>
  );

  if (!collection) return null;

  return (
    <div className="tag-list" style={styles}>
      {elements
        ?.filter((_element, index) => index < maxElements)
        .map((element, index: number, arr: string | any[]) =>
          renderTag(element, index, arr),
        )}
      {elements?.length > maxElements && (
        <PopoverTagList
          elementIds={elementIds}
          collection={collection}
          collectionName={collectionName}
        >
          <Typography.Text className={classes.otherTags}>{`(+${
            elements.length - maxElements
          })`}</Typography.Text>
        </PopoverTagList>
      )}
    </div>
  );
};

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SignIn } from '../pages/SignIn';
import { SignUp } from '../pages/SignUp';
import { SignUpContributor } from '../pages/SignUpContributor';
import { LostPassword } from '../pages/LostPassword';
// import { LandingSwitch } from './LandingSwitch';

export const GuestSwitch: React.VFC = () => {
  return (
    <Switch>
      {/*<Route*/}
      {/*  exact*/}
      {/*  path={['/', '/price', '/donors', '/our-project', '/contact', '/donors']}*/}
      {/*  component={LandingSwitch}*/}
      {/*/>*/}
      <Route path={['/', '/signIn']} exact component={SignIn} />
      <Route path="/signUp" component={SignUp} />
      <Route path="/signUpContributor" component={SignUpContributor} />
      <Route path="/lostPassword" component={LostPassword} />
      <Redirect to="/" />
    </Switch>
  );
};

import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
// import { getUsers } from 'src/slices/auth';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { getCallForTender } from 'src/slices/callForTender';
import { getClassification } from '../slices/classification';
import { getFundedNgosFromDonorTenderId } from '../slices/ngo';
import CallForTenderLeftColumn from './callForTenderPages/LeftColumn';
import CallForTenderRightColumn from './callForTenderPages/RightColumn';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: '10.49% 0% 9.34% 0%',
    background:
      'radial-gradient(ellipse at top right, #d2dcfd 0%, #f0f2ff 20%, transparent 50%),\
      radial-gradient(ellipse at top left, #fdfefe 0%, #f4f7ff 20%, transparent 50%),\
      radial-gradient(ellipse at bottom left, #e5ecfe, #f6f9fe 30%, transparent 50%),\
      radial-gradient(ellipse at bottom right, #f9fdfc, #f0f2ff 20%, transparent 50%)',
  },
});
interface Props {
  setIsNavBarHidden: (isNavBarHidden: boolean) => void;
}

export const CallForTender: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { callForTenderId } = useParams<{ callForTenderId: string }>();

  // const { ngos } = useAppSelector(({ ngo: { ngos } }) => ({
  //   ngos,
  // }));

  useEffect(() => {
    dispatch(getFundedNgosFromDonorTenderId(callForTenderId));
  }, []);

  const { callForTender, user, callForTenderLoading } = useAppSelector(
    ({ callForTender, auth }) => ({
      callForTender: callForTender.callForTenders.byId[callForTenderId],
      user: auth.user,
      callForTenderLoading: callForTender.loading,
    }),
  );

  const { classification } = useAppSelector(
    ({ classification: { classification } }) => ({
      classification,
    }),
  );

  useEffect(() => {
    setIsNavBarHidden(false);
  }, []);

  useEffect(() => {
    dispatch(getCallForTender(callForTenderId));
  }, []);

  useEffect(() => {
    dispatch(getClassification());
  }, []);

  if (!callForTender) return <FullscreenSpin />;

  if (!classification || !callForTender || !user || callForTenderLoading)
    return <FullscreenSpin />;

  return (
    <div className={classes.container}>
      <CallForTenderLeftColumn
        callForTenderId={callForTenderId}
        callForTender={callForTender}
        classification={classification}
      />
      <CallForTenderRightColumn
        callForTender={callForTender}
        classification={classification}
        // ngos={ngos ?? []}
      />
    </div>
  );
};

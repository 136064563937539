import { Typography, List, Avatar, Button, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FullscreenSpin } from 'src/components/FullscreenSpin';
import {
  MailOutlined,
  EnvironmentOutlined,
  ClockCircleOutlined,
  PhoneOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import Tag from 'antd/es/tag';
import { Donor, reportDonorProblem } from 'src/slices/donor';
import { Classification, Place } from 'src/slices/classification';
import { OddImages } from '../../components/OddImages';
import { PlacesModal } from '../../components/PlacesModal';
import { theme } from '../../constants/theme';
import { NgoName } from '../../slices/ngo';
import { TagListForDonorAndNgoPages } from 'src/components/TagListForNgoAndDonorPages';
import { useAppDispatch } from 'src/hooks/store';

const { Text } = Typography;

const { TextArea } = Input;

const useStyles = createUseStyles({
  subtitle: {
    color: theme.palette.secondary,
    fontWeight: 'bold',
    fontSize: 'calc(5px + 0.6vw)',
    lineHeight: 1,
  },
  text: {
    fontSize: 'calc(5px + 0.4vw)',
    marginBottom: '0.7vw',
    marginTop: '0.2vw',
  },
  rightColumn: {
    width: '26.5vw',
    boxSizing: 'border-box',
    margin: '0 0 0 2.84%',
    '& .ant-list': {
      fontSize: '0.875vw',
    },
    '& .ant-list-item-meta-title': {
      marginBottom: '0px',
    },
    '& .ant-list-item-meta-title, .ant-list-item-meta-description': {
      lineHeight: 1,
      fontSize: '0.875vw',
    },
    '& .ant-list-item': {
      marginBottom: '2vh !important',
    },
    minHeight: '100vh',
    height: '100%',
  },
  rightColumnElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '9.59% 8.94% 3.5%',
    marginBottom: '3.3vh',
    background: 'white',
    borderRadius: '0.3125vw',
  },
  rightSecondColumnElement: {
    padding: '7.59% 8.94% 7.59%',
    marginBottom: '3.3vh',
    background: 'white',
    borderRadius: '0.3125vw',
  },
  rightColumnLastElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '9.59% 8.94% 6.59%',
    background: 'white',
    borderRadius: '0.3125vw',
    wordBreak: 'break-word',
    '& .ant-list-item-meta-content': {
      marginTop: 'auto',
      marginBottom: 'auto',
      '& .ant-list-item-meta-description': {
        color: 'black',
        fontSize: 'calc(5px + 0.4vw)',
      },
    },
    '& .ant-list-item-meta-avatar': {
      marginRight: '2.87%',
    },
    '& .ant-avatar': {
      height: 'auto',
      width: 'auto',
      lineHeight: 1,
      fontSize: '1.125vw',
    },
  },
  link: {
    color: '#5050F9 !important',
  },
  avatar: {
    '&.ant-avatar': {
      backgroundColor: 'transparent',
    },
  },
  informationsIcon: {
    color: theme.palette.secondary,
    fontSize: '1.0625vw',
  },
  problemButton: {
    margin: '4vw 0 0 0',
    padding: '0.25vw 0.9375vw',
    fontSize: '0.75vw',
    height: 'fit-content',
    borderColor: 'green',
    ['@media screen and (max-width: 445px)']: {
      whiteSpace: 'unset',
    },
  },
  problemButtonPosition: {
    position: 'relative',
    marginTop: '-4.5vw',
    marginBottom: '1.5vw',
    marginLeft: '4.5vw',
  },
  tag: {
    '& .ant-tag': {
      margin: '0px 6px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      lineHeight: 1.5,
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: 'calc(5px + 0.4vw)',
    },
  },
  descriptionText: {
    fontSize: 'calc(5px + 0.4vw)',
    lineHeight: 1.3,
  },
  ngos: {
    wordBreak: 'break-all',
    lineHeight: 1.5,
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    '& .ant-tag': {
      margin: '0px 2px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-word',
    },
    fontSize: 'calc(5px + 0.4vw)',
    textDecoration: 'underline',
  },
  ngosUnclickable: {
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    '& .ant-tag': {
      margin: '0px 2px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-word',
    },
    fontSize: 'calc(5px + 0.4vw)',
  },
  ngosContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    wordBreak: 'break-all',
    lineHeight: 1.5,
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    '& .ant-tag': {
      margin: '0px 2px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-all',
    },
    fontSize: 'calc(5px + 0.4vw)',
  },
});

interface Props {
  displayedDonor: Donor;
  classification: Classification;
  ngos: NgoName[] | null;
}

const DonorPageRightColumn: React.VFC<Props> = ({
  displayedDonor,
  classification,
  ngos,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [visible, setVisible] = React.useState(false);
  const [editedProblem, setEditedProblem] = React.useState<string>('');

  if (!classification || !displayedDonor) return <FullscreenSpin />;

  const onEditProblem = (value: string) => {
    setEditedProblem(value);
  };

  const onSubmitProblem = () => {
    if (editedProblem) {
      dispatch(
        reportDonorProblem({
          _id: displayedDonor._id,
          name: displayedDonor.name,
          problemDescription: editedProblem,
        }),
      );
      setVisible(false);
    }
    setEditedProblem('');
  };

  const [showPlacesModal, setShowPlacesModal] = useState(false);

  const {
    activityDomains,
    // activityZones,
    sustainableDevelopmentGoals,
    statuses,
    targetPersons,
    donationTypes,
    targetPopulations,
    places,
  } = classification;

  const [countries, setCountries] = useState<Place[]>([]);
  const [france, setFrance] = useState<Place[]>([]);

  useEffect(() => {
    if (places) {
      setCountries(
        places
          .filter((place) => place.countries && place.countries.length)
          .sort((a, b) => a.name.localeCompare(b.name)) as Place[],
      );
      setFrance(
        places
          .filter((place) => place.departments && place.departments.length)
          .sort((a, b) =>
            a.name.includes('DROM') ||
            a.name.includes("Collectivités d'Outre-Mer")
              ? 1
              : a.name.localeCompare(b.name),
          ) as Place[],
      );
    }
  }, [places]);

  function renderFrance(depIds: string[]): string | undefined {
    if (!depIds || depIds.length === 0) return undefined;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    if (isFranceComplete) {
      return 'France';
    }
    return undefined;
  }

  function renderDepartments(depIds: string[]): string[] {
    if (!depIds || depIds.length === 0) return [];
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return results.length > 0 ? results : [];
    // return results.length > 0
    //   ? results.map((department, index) => (
    //       <Tag key={index} className={classes.tag}>
    //         {department}
    //         {', '}
    //       </Tag>
    //     ))
    //   : [];
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return [];
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return results.length > 0 ? results : [];
    // return results.length > 0
    //   ? results.map((currentCountry, index) => (
    //       <Tag key={index} className={classes.tag}>
    //         {currentCountry},{' '}
    //       </Tag>
    //     ))
    //   : [];
  }

  function renderPlaces(placeIds: string[]): JSX.Element[] {
    if (!placeIds || placeIds.length === 0) return [];
    let results = [] as string[];
    if (renderFrance(placeIds)) {
      const isFrance = renderFrance(placeIds);
      if (isFrance) {
        results = results.concat([isFrance]);
      }
    } else {
      if (renderDepartments(placeIds)) {
        results = results.concat(renderDepartments(placeIds));
      }
    }
    if (renderCountries(placeIds)) {
      results = results.concat(renderCountries(placeIds));
    }
    return results.length > 0
      ? results.map((department, index) =>
          index !== results.length - 1 ? (
            <Tag key={index} className={classes.tag}>
              {department}
              {', '}
            </Tag>
          ) : (
            <Tag key={index} className={classes.tag}>
              {department}
            </Tag>
          ),
        )
      : [];
  }

  const renderSubTitle = (text: string) => (
    <Text className={classes.subtitle}>{text}</Text>
  );

  const displayFundedNgos = () => {
    if (displayedDonor.fundedNgos?.length === 0) {
      return false;
    }
    const map: any[] = [];
    displayedDonor.fundedNgos?.map((ngoId) => {
      const ngo = ngos?.find((ngoName) => ngoName._id === ngoId);
      ngo ?? map.push(ngo);
    });
    return map?.length !== displayedDonor.fundedNgos?.length;
  };

  const donorInformations = [
    {
      icon: <MailOutlined className={classes.informationsIcon} />,
      description: (
        <div className={classes.descriptionText}>
          {displayedDonor.contactEmail}
        </div>
      ),
      valid: displayedDonor.contactEmail,
    },
    {
      icon: <PhoneOutlined className={classes.informationsIcon} />,
      description: (
        <div className={classes.descriptionText}>
          {displayedDonor.contactPhone}
        </div>
      ),
      valid: displayedDonor.contactPhone,
    },
    {
      icon: <EnvironmentOutlined className={classes.informationsIcon} />,
      description: `${displayedDonor.headquarterAddress}, ${displayedDonor.headquarterZipcode} ${displayedDonor.headquarterCity}`,
      valid:
        displayedDonor.headquarterAddress &&
        displayedDonor.headquarterZipcode &&
        displayedDonor.headquarterCity,
    },
    {
      icon: <ClockCircleOutlined className={classes.informationsIcon} />,
      description: (
        <div className={classes.descriptionText}>
          {displayedDonor.creationYear + ' (Année de création)'}
        </div>
      ),
      valid: displayedDonor.creationYear,
    },
  ];

  const donorCharacteristics = [
    {
      title: <Text className={classes.subtitle}>Statut</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={displayedDonor?.status ? [displayedDonor.status] : []}
          collection={statuses}
          color="default"
          collectionName="statuses"
        />
      ),
      valid: displayedDonor.status,
    },
    {
      title: <Text className={classes.subtitle}>Soutien orienté vers</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={
            displayedDonor?.targetPerson?.length === 2
              ? [displayedDonor.targetPerson[0], displayedDonor.targetPerson[1]]
              : displayedDonor?.targetPerson?.length === 1
              ? [displayedDonor.targetPerson[0]]
              : []
          }
          collection={targetPersons}
          color="default"
          collectionName="targetPersons"
        />
      ),
      valid: displayedDonor.targetPerson,
    },
    {
      title: <Text className={classes.subtitle}>Mode opératoire</Text>,
      description:
        displayedDonor.modusOperandi &&
        displayedDonor.modusOperandi.map((modus) => (
          <div className={classes.tag} key={modus}>
            <Tag>{modus}</Tag>
          </div>
        )),
      valid:
        displayedDonor.modusOperandi && displayedDonor.modusOperandi.length > 0,
    },
    {
      title: <Text className={classes.subtitle}>Abritée par</Text>,
      description: (
        <Text className={classes.descriptionText}>
          {displayedDonor.hostedBy}
        </Text>
      ),
      valid: !!displayedDonor.hostedBy,
    },
    {
      title: <Text className={classes.subtitle}>Type de mécénat</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={displayedDonor.donationTypes ?? []}
          collection={donationTypes}
          color="default"
          collectionName="donationTypes"
        />
      ),
      valid: displayedDonor.donationTypes,
    },
    {
      title: (
        <Text className={classes.subtitle}>Domaines d&apos;intervention</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={displayedDonor.activityDomains ?? []}
          collection={activityDomains}
          color="default"
          collectionName="activityDomains"
        />
      ),
      valid: displayedDonor.activityDomains,
    },
    {
      title: <Text className={classes.subtitle}>Bénéficiaires cibles</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={displayedDonor.targetPopulations ?? []}
          collection={targetPopulations}
          color="default"
          collectionName="targetPopulations"
        />
      ),
      valid: displayedDonor.targetPopulations,
    },
    {
      title: (
        <Text className={classes.subtitle}>Zones d&apos;intervention</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={displayedDonor.activityZones ?? []}
          collection={classification.activityZones}
          color="default"
          collectionName="activityZones"
        />
      ),
      valid: displayedDonor.activityZones,
    },
    {
      title: (
        <Text className={classes.subtitle}>
          Objectifs du développement durable
        </Text>
      ),
      description: (
        <OddImages
          sustainableDevelopmentGoals={sustainableDevelopmentGoals}
          oddIds={displayedDonor?.sustainableDevelopmentGoals || []}
        />
      ),
      valid: displayedDonor.sustainableDevelopmentGoals,
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <>
          {renderPlaces(displayedDonor.places ?? []).slice(0, 10)}
          {renderPlaces(displayedDonor.places ?? []).length > 10 && (
            <Button
              size={'small'}
              type="link"
              onClick={() => setShowPlacesModal(true)}
            >
              Voir Tout
            </Button>
          )}
        </>
      ),
      valid: displayedDonor.places || [],
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={
            displayedDonor.roadAndNumber &&
            displayedDonor.city &&
            displayedDonor.zipcode
              ? [displayedDonor.roadAndNumber]
              : []
          }
          collection={
            displayedDonor.roadAndNumber &&
            displayedDonor.city &&
            displayedDonor.zipcode
              ? [
                  {
                    _id: displayedDonor.roadAndNumber,
                    name: `${displayedDonor.roadAndNumber}, ${displayedDonor.city}, ${displayedDonor.zipcode}`,
                  },
                ]
              : []
          }
          color="default"
          collectionName="uniqueCustomAddress"
        />
      ),
      valid:
        displayedDonor.roadAndNumber &&
        displayedDonor.city &&
        displayedDonor.zipcode,
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={
            displayedDonor.uniqueZipcode ? [displayedDonor.uniqueZipcode] : []
          }
          collection={
            displayedDonor.uniqueZipcode
              ? [
                  {
                    _id: displayedDonor.uniqueZipcode,
                    name: `${displayedDonor.uniqueZipcode}, ${displayedDonor.city}`,
                  },
                ]
              : []
          }
          color="default"
          collectionName="uniqueZipcode"
        />
      ),
      valid: displayedDonor.uniqueZipcode ? [displayedDonor.uniqueZipcode] : [],
    },
  ];

  const donorInformationsFiltered = donorInformations.filter(
    (item) => item.valid,
  );

  const renderReportProblem = (
    onChange: (value: string) => void,
    onSubmit: () => void,
    value: string,
  ) => (
    <div>
      <Button
        className={classes.problemButton}
        type="dashed"
        onClick={() => setVisible(true)}
        icon={<ExclamationCircleFilled style={{ color: 'green' }} />}
      >
        Signaler une anomalie avec cette fiche
      </Button>

      <Modal
        visible={visible}
        title="Signalement d'une fiche mécène"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisible(false);
              setEditedProblem('');
            }}
          >
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={() => onSubmit()}>
            Valider
          </Button>,
        ]}
      >
        <TextArea
          placeholder="Merci de préciser le sujet avec la fiche de ce mécène"
          rows={4}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
      </Modal>
    </div>
  );

  return (
    <div className={classes.rightColumn}>
      <div className={classes.rightColumnElement}>
        <div className={classes.problemButtonPosition}>
          {renderReportProblem(onEditProblem, onSubmitProblem, editedProblem)}
        </div>
        <List
          grid={{ gutter: 0, column: 1 }}
          dataSource={donorCharacteristics}
          className={classes.tag}
          split={false}
          renderItem={(item) =>
            (Array.isArray(item.valid)
              ? item.valid?.length !== 0
              : !!item.valid) && (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )
          }
        />
      </div>
      {displayFundedNgos() && (
        <div className={classes.rightSecondColumnElement}>
          {renderSubTitle('Organisations soutenues')}
          <br />
          <div className={classes.ngosContainer}>
            {displayedDonor.fundedNgos?.map(
              (ngoId, index: number, arr: string | any[]) => {
                const ngo = ngos?.find((ngoName) => ngoName._id === ngoId);
                if (ngo?.name) {
                  return (
                    <div key={ngo?._id}>
                      {
                        <Tag key={ngoId}>
                          <div className={classes.ngosUnclickable}>
                            {ngo?.name + (arr.length - 1 !== index ? ',' : '')}
                          </div>
                        </Tag>
                      }
                    </div>
                  );
                }
              },
            )}
          </div>
        </div>
      )}
      {!!donorInformationsFiltered.length && (
        <div className={classes.rightColumnLastElement}>
          <List
            grid={{ gutter: 0, column: 1 }}
            dataSource={donorInformationsFiltered}
            split={false}
            renderItem={(item) => (
              <List.Item style={{ marginBottom: '4.22%' }}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      icon={item.icon}
                      className={classes.avatar}
                      shape="square"
                    />
                  }
                  description={item.description}
                />
              </List.Item>
            )}
          />
          {/*{renderTextSection(*/}
          {/*  'Moyenne des dons',*/}
          {/*  displayedDonor.averageDonation*/}
          {/*    ? `${displayedDonor.averageDonation}€`*/}
          {/*    : undefined,*/}
          {/*)}*/}
          {/*{renderTextSection(*/}
          {/*  "Budget de l'année en cours",*/}
          {/*  displayedDonor.currentYearBudget*/}
          {/*    ? `${displayedDonor.currentYearBudget}€`*/}
          {/*    : undefined,*/}
          {/*)}*/}
        </div>
      )}
      <PlacesModal
        ngoPlaces={
          displayedDonor.places && displayedDonor.places.length > 0
            ? displayedDonor.places
            : []
        }
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
      />
    </div>
  );
};

export default DonorPageRightColumn;

import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Divider, message } from 'antd';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../hooks/store';
import { CloseOutlined } from '@ant-design/icons';
import { theme } from 'src/constants/theme';
import { createDonor, getDonor } from 'src/slices/donor';
import { DonorEditForm } from 'src/containers/DonorEditForm';

const useStyles = createUseStyles({
  container: {
    backgroundColor: 'white',
    padding: '16px',
    paddingBottom: '12%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  cancelButton: {
    color: 'red',
  },
  saveButton: {
    backgroundColor: theme.palette.success,
    borderColor: theme.palette.success,
    '&:hover': {
      backgroundColor: theme.palette.green[4],
      borderColor: theme.palette.green[4],
    },
  },
});

export const CreateDonor: React.VFC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { donorId } = useParams<{ donorId: string }>();
  const donor = useAppSelector((state) => state.donor.donors.byId[donorId]);
  const history = useHistory();
  const classes = useStyles();
  const [callForTenders, setCallForTenders] = useState<string[] | undefined>([
    '',
  ]);

  useEffect(() => {
    if (donor) {
      dispatch(getDonor(donorId));
      setCallForTenders(callForTenders);
    }
  }, []);

  const handleSave = async (values: any) => {
    const result = await dispatch(
      createDonor({
        ...values,
        publicationStatus: '1', // TODO: change with the number that Laurent wants
      }),
    );
    if (result.type.includes('fulfilled')) {
      message.success('Mécène ajouté avec succès !');
    }
    history.goBack();
  };

  const renderActionButtons = () => (
    <Row align="top" style={{ margin: '30px' }} justify="space-between">
      <Col span={20}>
        <Button
          type="dashed"
          onClick={() => history.goBack()}
          danger
          icon={<CloseOutlined />}
          className={classes.cancelButton}
        >
          Annuler
        </Button>
      </Col>
      <Col span={4}>
        <Button
          type="primary"
          onClick={async () => {
            const values = await form.validateFields();

            handleSave(values);
          }}
          className={classes.saveButton}
        >
          Sauvegarder
        </Button>
      </Col>
    </Row>
  );

  return (
    <div className={classes.container}>
      {renderActionButtons()}
      <Divider>Nouveau mécène</Divider>
      <DonorEditForm form={form} />
      <div className={classes.buttonContainer}></div>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Button, Form, Row, Col, Divider } from 'antd';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../hooks/store';
import { CloseOutlined } from '@ant-design/icons';
import { theme } from 'src/constants/theme';
import { FullscreenSpin } from 'src/components/FullscreenSpin';
import { UserEditForm } from 'src/containers/UserEditForm';
import { fetchCurrentUser, findUserById, updateUser } from 'src/slices/auth';

const useStyles = createUseStyles({
  container: {
    backgroundColor: 'white',
    padding: '16px',
    paddingBottom: '12%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  cancelButton: {
    color: 'red',
  },
  saveButton: {
    backgroundColor: theme.palette.success,
    borderColor: theme.palette.success,
    '&:hover': {
      backgroundColor: theme.palette.green[4],
      borderColor: theme.palette.green[4],
    },
  },
});

export const EditAdminUser: React.VFC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();
  const classes = useStyles();
  const { user, users } = useAppSelector(({ auth: { user, users } }) => ({
    user,
    users,
  }));

  useEffect(() => {
    dispatch(findUserById({ userId }));
  }, [userId]);

  useEffect(() => {
    form.setFieldsValue({
      maxNumberOfProjects: users[userId]?.maxNumberOfProjects,
    });
  }, [users]);

  const handleSave = async (values: any) => {
    dispatch(updateUser({ id: userId, updateUserDto: values }));
    history.goBack();
  };

  const renderActionButtons = () => (
    <Row align="top" style={{ margin: '30px' }} justify="space-between">
      <Col span={20}>
        <Button
          type="dashed"
          onClick={() => history.goBack()}
          danger
          icon={<CloseOutlined />}
          className={classes.cancelButton}
        >
          Annuler
        </Button>
      </Col>
      <Col span={4}>
        <Button
          type="primary"
          onClick={async () => {
            const values = await form.validateFields();

            handleSave(values);
          }}
          className={classes.saveButton}
        >
          Sauvegarder
        </Button>
      </Col>
    </Row>
  );

  return (
    <div className={classes.container}>
      {renderActionButtons()}
      <Divider>Editer l&apos;utilisateur</Divider>
      <UserEditForm form={form} />
      <div className={classes.buttonContainer}></div>
    </div>
  );
};

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { ClassificationsFilter, Donor, DONORS_MAX_RESULTS } from './donor';

export const NGOS_MAX_RESULTS = 10;

type personnel = {
  lastName: string;
  firstName: string;
  email: string;
};

type SearchResults = {
  items: Ngo[];
  totalItems: number;
};

export type Ngo = {
  _id: string;
  followedDonors?: string[];
  name: string;
  object?: string;
  description?: string;
  nbEmployees?: number;
  nbVolunteers?: number;
  admins?: string[]; // [MongodbId]
  members?: string[]; // [MongodbId]
  tags?: string[]; // [MongodbId]
  places?: string[];
  city?: string;
  roadAndNumber?: string;
  zipcode?: string;
  uniqueZipcode?: string;
  sustainableDevelopmentGoals?: string[]; //[MongodbId]
  activityDomains?: string[]; //[MongodbId]
  targetPopulations?: string[]; // [MongodbId]
  donors?: string[]; // [MongodbId]
  unverifiedDonors?: string[]; // [MongodbId]
  bannerImageUrl?: string;
  personals?: personnel[];
  logoUrl?: string;
  siret?: string;
  headquarterAddress?: string;
  headquarterZipcode?: string;
  headquarterCity?: string;
  websiteUrl?: string;
  creationYear?: number;
  isPublicInterestNgo?: boolean;
  numberOfBeneficiaries?: string;
  activityZone?: string[]; // [MongodbId]
  contactMail?: string;
  phoneNumber?: string;
  linkedinUrl?: string;
  instagramUrl?: string;
  facebookUrl?: string;
  twitterUrl?: string;
  status?: string;
  rnaNumber?: string;
  countRef?: number;
  published?: boolean;
};

type AdminSearchNgo = {
  _id?: string;
  name?: string;
  object?: string;
  description?: string;
  nbEmployees?: number;
  nbVolunteers?: number;
  admins?: string[]; // [MongodbId]
  members?: string[]; // [MongodbId]
  tags?: string[]; // [MongodbId]
  places?: string[];
  city?: string;
  roadAndNumber?: string;
  zipcode?: string;
  uniqueZipcode?: string;
  sustainableDevelopmentGoals?: string[]; //[MongodbId]
  activityDomains?: string[]; //[MongodbId]
  targetPopulations?: string[]; // [MongodbId]
  donors?: string[]; // [MongodbId]
  unverifiedDonors?: string[]; // [MongodbId]
  bannerImageUrl?: string;
  personals?: personnel[];
  logoUrl?: string;
  siret?: string;
  headquarterAddress?: string;
  websiteUrl?: string;
  creationYear?: number;
  isPublicInterestNgo?: boolean;
  numberOfBeneficiaries?: string;
  activityZone?: string[]; // [MongodbId]
  contactMail?: string;
  phoneNumber?: string;
  linkedinUrl?: string;
  instagramUrl?: string;
  facebookUrl?: string;
  twitterUrl?: string;
  status?: string;
  department?: string[];
  region?: string[];
  rnaNumber?: string;
  published?: boolean;
};

export type NgoName = {
  _id: string;
  name: string;
  description?: string;
  published?: boolean;
};

type SearchParameters = {
  name?: string;
  offset: number;
  limit?: number;
  tags?: string[];
  statuses?: string[];
  activityDomains?: string[];
  activityZones?: string[];
  sustainableDevelopmentGoals?: string[];
  targetPopulations?: string[];
  donationTypes?: string[];
  source?: string;
  department?: string[];
  region?: string[];
};

export interface SaveContributorNgoDto {
  name: string;

  idRna: string;

  siret: string;

  headquarterAddress?: string;

  websiteUrl?: string;

  creationYear?: number;

  isPublicInterestNgo: boolean;

  object?: string;
}

export type LoadingStatus = 'idle' | 'pending' | 'succeeded' | 'failed';

interface NgoState {
  followedDonors: Donor[];
  followedDonorsLoading: LoadingStatus;
  saved: boolean;
  loading: boolean;
  error: any;
  ngo: Ngo | null;
  ngoCount: number | null;
  loadingNgosCount: LoadingStatus;
  ngoWithTagsWithoutAdminCount: number | null;
  loadingNgosWithTagsWithoutAdminCount: LoadingStatus;
  event: NgoEvent | null;
  ngos: NgoName[] | null;
  userDoesntHaveNgo: boolean;
  searchNgos: NgoName[] | null;
  searchResult: {
    resultsNgos?: Ngo[];
    resultIds?: string[] | null;
    resultsCount: number;
  } | null;
  offset: number;
  duplicateNgos: Ngo[][] | null;
  suggestionsNgos: NgoName[] | null;
  suggestionsLoading: boolean;
  classificationsFilter?: ClassificationsFilter;
}

enum NgoAction {
  ADD_FAVORITE_DONOR = 'addFavoriteDonor',
  REMOVE_FAVORITE_DONOR = 'removeFavoriteDonor',
  GET_FAVORITE_DONORS = 'getFavoriteDonors',
  GET_DONORS = 'getDonors',
  SEARCH_DONORS = 'searchDonors',
  GET_DISPLAYED_DONOR = 'getDonor',
}

interface NgoEvent {
  action: NgoAction;
  message?: string;
  error?: any;
}

const initialState: NgoState = {
  followedDonors: [],
  followedDonorsLoading: 'idle',
  saved: false,
  loading: false,
  error: null,
  ngo: null,
  event: null,
  ngos: null,
  loadingNgosCount: 'idle',
  ngoCount: 0,
  loadingNgosWithTagsWithoutAdminCount: 'idle',
  ngoWithTagsWithoutAdminCount: 0,
  userDoesntHaveNgo: false,
  suggestionsNgos: null,
  suggestionsLoading: false,
  searchNgos: null,
  searchResult: null,
  offset: 0,
  duplicateNgos: null,
  classificationsFilter: undefined,
};

export const fetchNgosCount = createAsyncThunk(
  'fetchNgosCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>('GET', '/ngo/count');
      dispatch(ngoSlice.actions.setNgosCount(Number(result)));
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchNgosWithTagsWithoutAdminCount = createAsyncThunk(
  'fetchNgosWithTagsWithoutAdminCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>(
        'GET',
        '/ngo/ngosWithTagsWithoutAdminCount',
      );
      dispatch(
        ngoSlice.actions.setNgosWithTagsWithoutAdminCount(Number(result)),
      );
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const saveNgo = createAsyncThunk('saveNgo', async (payload: Ngo) => {
  payload['activityDomains'] = payload['activityDomains'] || [];
  payload['tags'] = payload['tags'] || [];
  payload['targetPopulations'] = payload['targetPopulations'] || [];
  return await apiRequest<Ngo>('POST', '/ngo', undefined, payload);
});

const getParameter = (field: string, parameters: string[]) => {
  return parameters.reduce((object, item, index) => {
    return {
      ...object,
      [`${field}[${index}]`]: item,
    };
  }, {});
};

const getAllParameters = (payload: AdminSearchNgo) => {
  return {
    ...getParameter('tags', payload.tags ?? []),
    ...getParameter('activityDomains', payload.activityDomains ?? []),
    ...getParameter('targetPopulations', payload.targetPopulations ?? []),
    ...getParameter(
      'sustainableDevelopmentGoals',
      payload.sustainableDevelopmentGoals ?? [],
    ),
    ...getParameter('department', payload.department ?? []),
    ...getParameter('region', payload.region ?? []),
  };
};

export const saveContributorNgo = createAsyncThunk(
  'saveNgoRna',
  async (payload: SaveContributorNgoDto, { dispatch }) => {
    const body = { ...payload, status: 'Association' };
    const result = await apiRequest<Ngo>(
      'POST',
      '/ngo/simple',
      undefined,
      body,
    );
    if (result) {
      dispatch(getNgos());
    }
    return result;
  },
);

export const mergeNgo = createAsyncThunk(
  'mergeNgo',
  async ({
    index,
    id,
    payload,
  }: {
    index: number;
    id: string;
    payload: any;
  }) => {
    await apiRequest('POST', `/ngo/${id}/merge`, undefined, payload);

    return index;
  },
);

export const useMergeNgo = createAsyncThunk(
  'useMergeNgo',
  async (
    { index, selectedId }: { index: number; selectedId: string },
    { getState },
  ) => {
    const {
      ngo: { duplicateNgos },
    }: any = getState();

    await apiRequest('POST', '/ngo/useMerge', undefined, {
      selectedId,
      mergeIds: duplicateNgos[index].map((ngo: Ngo) => ngo._id),
    });

    return index;
  },
);

export const updateNgo = createAsyncThunk(
  'updateNgo',
  async ({ id, ...rest }: any) => {
    rest['activityDomains'] = rest['activityDomains'] || [];
    rest['tags'] = rest['tags'] || [];
    rest['uniqueZipcode'] = rest['uniqueZipcode'] || '';
    rest['city'] = rest['city'] || '';
    rest['roadAndNumber'] = rest['roadAndNumber'] || '';
    rest['zipcode'] = rest['zipcode'] || '';
    rest['targetPopulations'] = rest['targetPopulations'] || [];
    rest['activityZone'] = rest['activityZone'] || [];
    rest['unverifiedDonors'] = rest['unverifiedDonors'] || [];
    delete rest['donors'];

    return await apiRequest<Ngo>('PATCH', `/ngo/one/${id}`, undefined, rest);
  },
);

export const updateNgoAdmins = createAsyncThunk(
  'updateNgoAdmins',
  async ({ id, ...rest }: any) => {
    rest['adminsToAdd'] = rest['adminsToAdd'] || [];
    rest['adminsToRemove'] = rest['adminsToRemove'] || [];
    return await apiRequest<Ngo>(
      'PATCH',
      `/ngo/updateAdmins/${id}`,
      undefined,
      rest,
    );
  },
);

export const updateNgoPublished = createAsyncThunk(
  'updateNgoPublished',
  async (payload: { ngoIds: string[]; published: boolean }) => {
    const { ngoIds, published } = payload;
    return await apiRequest<Ngo[]>(
      'PATCH',
      `/ngo/updatePublished/${ngoIds.at(0)}`,
      undefined,
      {
        published: published,
      },
    );
  },
);

export const ignoreDuplicateNgo = createAsyncThunk(
  'ignoreDuplicateNgo',
  async ({ index, ignoreId }: { index: number; ignoreId: string }) => {
    await apiRequest('PATCH', `/ngo/${ignoreId}`, undefined, {
      ignoreDuplicate: true,
    });

    return { index, ignoreId };
  },
);

export const getNgosWithAlgorithm = createAsyncThunk(
  'getDonorsWithAlgorithm',
  async (payload: {
    callForTenderId: string;
    algorithm: string;
    searchParameters: SearchParameters;
  }) => {
    const params = {
      limit: DONORS_MAX_RESULTS,
      offset: payload.searchParameters.offset,
      name: payload.searchParameters.name || '',
      ...getAllParameters(payload.searchParameters),
    };

    return {
      ...(await apiRequest<SearchResults>(
        'GET',
        `/call-for-tenders/${payload.callForTenderId}/search-with-algorithm/${payload.algorithm}`, // TODO: change route
        params,
      )),
      offset: payload.searchParameters.offset,
    };
  },
);

export const searchNgosWithAlgorithmAndGetSuggestions = createAsyncThunk(
  'searchNgosWithAlgorithmAndGetSuggestions',
  async (payload: {
    callForTenderId: string;
    algorithm: string;
    searchParameters: SearchParameters;
  }) => {
    const params = {
      limit: DONORS_MAX_RESULTS,
      name: payload.searchParameters.name || '',
      ...getAllParameters(payload.searchParameters),
    };
    return await apiRequest<NgoName[]>(
      'GET',
      `/call-for-tenders/${payload.callForTenderId}/search-with-algorithm/${payload.algorithm}/suggestions`, // TODO :
      // change route
      params,
    );
  },
);

export const getNgo = createAsyncThunk(
  'getNgo',
  async (ngoId: string | undefined) => {
    return await apiRequest<Ngo>('GET', `/ngo/one/${ngoId}`);
  },
);

export const searchNgosAndGetSuggestions = createAsyncThunk(
  'searchNgosAndGetSuggestions',
  async (payload: { name: string }) => {
    return await apiRequest<Ngo[]>('GET', '/ngo/suggestions', payload);
  },
);

export const getNgos = createAsyncThunk('getNgos', async () => {
  return await apiRequest<Ngo[]>('GET', `/ngo`);
});

export const searchNgos = createAsyncThunk(
  'searchNgos',
  async (payload: SearchParameters) => {
    const params: any = {
      name: payload.name,
      limit: payload.limit,
      offset: payload.offset,
    };
    return {
      ...(await apiRequest<SearchResults>('GET', '/ngo/search', params)),
      offset: payload.offset,
    };
  },
);

export const searchNgosByCallForTender = createAsyncThunk(
  'searchNgosByCallForTender',
  async ({
    id,
    searchParameters,
  }: {
    id: string;
    searchParameters: SearchParameters;
  }) => {
    const params: any = {
      name: searchParameters.name,
      limit: DONORS_MAX_RESULTS,
      offset: searchParameters.offset,
      ...getAllParameters(searchParameters),
    };
    return {
      ...(await apiRequest<SearchResults>(
        'GET',
        `/ngo/search-by-call-for-tender/${id}`,
        params,
      )),
      offset: searchParameters.offset,
    };
  },
);

export const getNgosList = createAsyncThunk(
  'getNgosList',
  async (payload: { limit: number; offset: number }) => {
    try {
      return {
        ...(await apiRequest<SearchResults>('GET', `/ngo/list`, payload)),
        offset: payload.offset,
      };
    } catch (error) {
      return null;
    }
  },
);

export const getFundedNgosFromDonorId = createAsyncThunk(
  'getFundedNgosFromDonorId',
  async (donorId: string) => {
    return await apiRequest<NgoName[]>('GET', `/donor/${donorId}/fundedNgos`);
  },
);

export const getFundedNgosFromDonorTenderId = createAsyncThunk(
  'getFundedNgosFromDonorTenderId',
  async (tenderId: string) => {
    return await apiRequest<NgoName[]>(
      'GET',
      `/call-for-tenders/${tenderId}/fundedNgos`,
    );
  },
);

export const getDuplicateNgos = createAsyncThunk(
  'getDuplicateNgos',
  async () => {
    return await apiRequest<Ngo[][]>(
      'GET',
      '/ngo/duplicates',
      undefined,
      undefined,
      300000,
    );
  },
);

export const deleteNgo = createAsyncThunk(
  'deleteNgo',
  async (ngoId: string, { dispatch }) => {
    const result = await apiRequest<{ status: boolean }>(
      'DELETE',
      `/ngo/${ngoId}`,
    );
    if (result) dispatch(getNgo(ngoId));
    return result;
  },
);

export const reportNGOProblem = createAsyncThunk(
  'ReportNGOProblem',
  async (payload: {
    _id: string;
    name: string;
    problemDescription: string;
  }) => {
    await apiRequest('POST', `/ngo/${payload._id}/problem`, undefined, payload);
    return payload;
  },
);

export const getFollowedDonors = createAsyncThunk(
  'getFollowedDonors',
  async () => {
    return await apiRequest<Donor[]>('GET', '/ngo/get-followed-donors');
  },
);

export const addFollowedDonor = createAsyncThunk(
  'addFollowedDonor',
  async (payload: { donorId: string }) => {
    return await apiRequest<Donor>(
      'POST',
      `/ngo/add-followed-donor/${payload.donorId}`,
    );
  },
);

export const removeFollowedDonor = createAsyncThunk(
  'removeFollowedDonor',
  async (payload: { donorId: string }) => {
    return await apiRequest<Donor>(
      'DELETE',
      `/ngo/remove-followed-donor/${payload.donorId}`,
    );
  },
);

const pendingReducer = (state: NgoState) => {
  state.loading = true;
  state.error = null;
};

const fulfilledReducer = (state: NgoState) => {
  state.loading = false;
};

const rejectedReducer = (
  state: NgoState,
  { error }: { error: SerializedError },
) => {
  state.loading = false;
  state.error = error.message || 'error';
};

function getNgosIds(list: Ngo[]): string[] {
  return list.map((ngo) => ngo._id);
}

const receiveNgosReducer = (state: NgoState, { payload }: { payload: any }) => {
  state.loading = false;
  if (payload) {
    state.offset = payload.offset;
    state.searchResult = {
      resultsCount: payload.totalItems,
      resultsNgos: payload.items,
    };
  }
};

const ngoSlice = createSlice({
  name: 'ngo',
  initialState,
  reducers: {
    resetSearchNgos(state: NgoState) {
      state.searchNgos = null;
    },
    resetNgoSuggestions(state: NgoState) {
      state.ngos = null;
    },
    resetNgoEvent(state: NgoState) {
      state.event = null;
    },
    setNgosCount: (state, action: PayloadAction<number>) => {
      state.ngoCount = action.payload;
    },
    setNgosWithTagsWithoutAdminCount: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.ngoWithTagsWithoutAdminCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reportNGOProblem.pending, pendingReducer)
      .addCase(reportNGOProblem.rejected, rejectedReducer)
      .addCase(reportNGOProblem.fulfilled, (state: NgoState) => {
        state.loading = false;
      })
      .addCase(saveNgo.pending, (state: NgoState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(saveNgo.fulfilled, (state: NgoState, { payload }) => {
        state.saved = true;
        state.loading = false;
        state.ngo = payload;
      })
      .addCase(saveNgo.rejected, (state: NgoState, { payload }) => {
        state.saved = false;
        state.loading = false;
        state.error = payload;
      })
      .addCase(saveContributorNgo.pending, (state: NgoState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(saveContributorNgo.fulfilled, (state: NgoState, { payload }) => {
        state.saved = true;
        state.loading = false;
        state.ngo = payload;
      })
      .addCase(saveContributorNgo.rejected, (state: NgoState, { payload }) => {
        state.saved = false;
        state.loading = false;
        state.error = payload;
      })
      .addCase(getNgo.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(getNgo.fulfilled, (state: NgoState, { payload }) => {
        state.loading = false;
        state.ngo = payload;
      })
      .addCase(getNgo.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getFundedNgosFromDonorId.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(
        getFundedNgosFromDonorId.fulfilled,
        (state: NgoState, { payload }) => {
          state.loading = false;
          state.ngos = payload;
        },
      )
      .addCase(
        getFundedNgosFromDonorId.rejected,
        (state: NgoState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(getFundedNgosFromDonorTenderId.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(
        getFundedNgosFromDonorTenderId.fulfilled,
        (state: NgoState, { payload }) => {
          state.loading = false;
          state.ngos = payload;
        },
      )
      .addCase(
        getFundedNgosFromDonorTenderId.rejected,
        (state: NgoState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(updateNgo.pending, (state: NgoState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNgo.fulfilled, (state: NgoState, { payload }) => {
        state.loading = false;
        state.ngo = payload;
      })
      .addCase(updateNgo.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updateNgoAdmins.pending, (state: NgoState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNgoAdmins.fulfilled, (state: NgoState, { payload }) => {
        state.loading = false;
        state.ngo = payload;
      })
      .addCase(updateNgoAdmins.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updateNgoPublished.pending, (state: NgoState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(updateNgoPublished.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.saved = false;
        state.error = payload;
      })
      .addCase(updateNgoPublished.fulfilled, (state: NgoState, { payload }) => {
        state.loading = false;
        state.saved = true;
        if (payload && state.searchNgos) {
          state.searchNgos.forEach((ngo) => {
            payload.forEach((updatedNgo) => {
              if (ngo._id === updatedNgo._id) {
                ngo.published = updatedNgo.published;
              }
            });
          });
        }
      })
      .addCase(getNgos.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(getNgos.fulfilled, (state: NgoState, { payload }) => {
        state.loading = false;
        state.ngos = payload;
      })
      .addCase(getNgos.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getNgosList.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(getNgosList.fulfilled, receiveNgosReducer)
      .addCase(getNgosList.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(searchNgos.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(searchNgos.fulfilled, receiveNgosReducer)
      .addCase(searchNgos.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(searchNgosByCallForTender.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(
        searchNgosByCallForTender.fulfilled,
        (state: NgoState, { payload }) => {
          if (payload) {
            const ngosIds = getNgosIds(payload.items);
            state.loading = false;
            state.offset = payload.offset;
            state.searchResult = {
              resultsCount: payload.totalItems,
              resultIds: ngosIds,
              resultsNgos: payload.items,
            };
          }
        },
      )
      .addCase(
        searchNgosByCallForTender.rejected,
        (state: NgoState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )

      .addCase(searchNgosAndGetSuggestions.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(
        searchNgosAndGetSuggestions.fulfilled,
        (state: NgoState, { payload }) => {
          state.loading = false;
          state.searchNgos = payload;
        },
      )
      .addCase(
        searchNgosAndGetSuggestions.rejected,
        (state: NgoState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(getDuplicateNgos.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(getDuplicateNgos.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.duplicateNgos = payload;
      })
      .addCase(getDuplicateNgos.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(deleteNgo.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(deleteNgo.fulfilled, (state: NgoState) => {
        state.loading = false;
      })
      .addCase(deleteNgo.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(ignoreDuplicateNgo.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(ignoreDuplicateNgo.fulfilled, (state: NgoState, { payload }) => {
        if (state.duplicateNgos) {
          if (state.duplicateNgos[payload.index].length <= 2) {
            state.duplicateNgos.splice(payload.index, 1);
          } else {
            const index = state.duplicateNgos[payload.index].findIndex(
              (ngo) => ngo._id === payload.ignoreId,
            );

            if (index > -1) {
              state.duplicateNgos[payload.index].splice(index, 1);
            }
          }
        }
        state.loading = false;
      })
      .addCase(ignoreDuplicateNgo.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(useMergeNgo.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(useMergeNgo.fulfilled, (state: NgoState, { payload }) => {
        state.duplicateNgos?.splice(payload, 1);
        state.loading = false;
      })
      .addCase(useMergeNgo.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(mergeNgo.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(mergeNgo.fulfilled, (state: NgoState, { payload }) => {
        state.duplicateNgos?.splice(payload, 1);
        state.loading = false;
      })
      .addCase(mergeNgo.rejected, (state: NgoState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getNgosWithAlgorithm.pending, (state: NgoState) => {
        state.loading = true;
      })
      .addCase(
        getNgosWithAlgorithm.fulfilled,
        (state: NgoState, { payload }) => {
          if (payload) {
            const ngosIds = getNgosIds(payload.items);
            state.loading = false;
            state.offset = payload.offset;
            state.searchResult = {
              resultsCount: payload.totalItems,
              resultIds: ngosIds,
              resultsNgos: payload.items,
            };
          }
        },
      )
      .addCase(
        getNgosWithAlgorithm.rejected,
        (state: NgoState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(
        searchNgosWithAlgorithmAndGetSuggestions.rejected,
        (state: NgoState) => {
          state.loading = true;
          state.suggestionsLoading = false;
          state.error = 'error';
        },
      )
      .addCase(
        searchNgosWithAlgorithmAndGetSuggestions.pending,
        (state: NgoState) => {
          state.suggestionsLoading = true;
        },
      )
      .addCase(
        searchNgosWithAlgorithmAndGetSuggestions.fulfilled,
        (state: NgoState, { payload }) => {
          if (payload) {
            state.loading = false;
            state.suggestionsLoading = false;
            state.suggestionsNgos = payload;
          }
        },
      )
      .addCase(fetchNgosCount.pending, pendingReducer)
      .addCase(fetchNgosCount.rejected, rejectedReducer)
      .addCase(fetchNgosCount.fulfilled, fulfilledReducer)
      .addCase(fetchNgosWithTagsWithoutAdminCount.pending, pendingReducer)
      .addCase(fetchNgosWithTagsWithoutAdminCount.rejected, rejectedReducer)
      .addCase(fetchNgosWithTagsWithoutAdminCount.fulfilled, fulfilledReducer)
      .addCase(getFollowedDonors.pending, (state: NgoState) => {
        state.followedDonorsLoading = 'pending';
      })
      .addCase(getFollowedDonors.rejected, (state: NgoState) => {
        state.followedDonorsLoading = 'failed';
      })
      .addCase(getFollowedDonors.fulfilled, (state: NgoState, { payload }) => {
        state.followedDonorsLoading = 'succeeded';
        state.followedDonors = payload;
      })
      .addCase(addFollowedDonor.fulfilled, (state: NgoState, { payload }) => {
        state.followedDonors.push(payload);
        if (state.ngo?.followedDonors) {
          state.ngo?.followedDonors.push(payload._id);
        } else {
          if (state.ngo) state.ngo.followedDonors = [payload._id];
        }
      })
      .addCase(
        removeFollowedDonor.fulfilled,
        (state: NgoState, { payload }) => {
          state.followedDonors = state.followedDonors.filter(
            (donor) => donor._id !== payload._id,
          );
          if (state.ngo?.followedDonors) {
            state.ngo.followedDonors = state.ngo?.followedDonors.filter(
              (donorId) => donorId !== payload._id,
            );
          }
        },
      );
  },
});

export const { resetSearchNgos, resetNgoSuggestions, resetNgoEvent } =
  ngoSlice.actions;
export default ngoSlice.reducer;

import { FilterOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Badge,
  Button,
  DatePicker,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  Select,
} from 'antd';
import { SelectValue } from 'antd/lib/select';
import moment from 'moment';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { Contributor } from 'src/slices/contributor';
import {
  ClassificationsFilter,
  DonorName,
  ModusOperandi,
} from 'src/slices/donor';
import { FinancingNeed } from 'src/slices/financingNeed';
import {
  Classification,
  ClassificationElement,
} from '../slices/classification';
// Components never must use `useSelector` or `useDispatch`. This functions must be implemented in containers or
// pages

const { Search } = Input;
const { Option } = Select;

// export type SearchFiltersParameters = {
//   name?: string;
//   tags?: string[];
//   statuses?: string[];
//   activityDomains?: string[];
//   activityZones?: string[];
//   sustainableDevelopmentGoals?: string[];
//   targetPopulations?: string[];
//   donationTypes?: string[];
//   source?: string;
// };

export enum SortOrder {
  ASC,
  DESC,
  NAME,
}

export type SearchFiltersAdminParameters = {
  name?: string;
  tags?: string[];
  statuses?: string[];
  targetPersons?: string[];
  activityDomains?: string[];
  activityZones?: string[];
  sustainableDevelopmentGoals?: string[];
  targetPopulations?: string[];
  donationTypes?: string[];
  source?: string;
  hasLogo?: boolean;
  nationality?: string;
  publicationStatus?: string;
  hasHomepageUrl?: boolean;
  hasLinkedinUrl?: boolean;
  hasCompanyLinkedinUrl?: boolean;
  isHostedBy?: boolean;
  hasDescription?: boolean;
  usesCallsToTender?: boolean;
  hasComments?: boolean;
  author?: string;
  callForTendersCountStart?: number;
  callForTendersCountEnd?: number;
  callForTendersCount?: number;
  fundedNgosCount?: number;
  fundedNgosCountStart?: number;
  fundedNgosCountEnd?: number;
  unverifiedFundedNgosCount?: number;
  unverifiedFundedNgosCountStart?: number;
  unverifiedFundedNgosCountEnd?: number;
  unverifiedAndVerifiedFundedNgosCount?: number;
  unverifiedAndVerifiedFundedNgosCountStart?: number;
  unverifiedAndVerifiedFundedNgosCountEnd?: number;
  createdAtStart?: string;
  createdAtEnd?: string;
  updatedAtStart?: string;
  updatedAtEnd?: string;
  sort?: SortOrder;
  published?: boolean;
  modusOperandi?: string[];
};

interface Props {
  onSearch: (searchFiltersParameters: SearchFiltersAdminParameters) => void;
  classification: Classification;
  suggestions: DonorName[] | null;
  searchParameters: SearchFiltersAdminParameters;
  onChangeName?: (value: string) => void;
  suggestionsLoading: boolean;
  setSearchFilters: React.Dispatch<
    React.SetStateAction<SearchFiltersAdminParameters>
  >;
  classificationsFilter?: ClassificationsFilter;
  isFromProject: boolean;
  financingNeed?: FinancingNeed;
  contributors?: Contributor[];
}

const useStyles = createUseStyles({
  container: {
    margin: '0 0 0 auto',
    paddingRight: '3.45%',
    paddingTop: '3.45%',
  },
  grid: {
    display: 'grid',
    width: '28vw',
    gridTemplateColumns: '1fr',
    gridGap: '0.005vw',
    backgroundColor: 'white',
  },
  gridCell: {
    width: '93%',
    display: 'block',
    objectFit: 'cover',
    margin: '0.7vw 1vw 0.7vw 1vw',
    '& .ant-select .ant-select-selector .ant-select-single': {
      width: '93%',
      height: '100%',
      background: '#f9f9f9',
      border: 'none',
      padding: '2.35%',
      '& .ant-select-selection-item': {
        background: '#e0f5ff',
      },
    },
  },
  searchBar: {
    width: '28vw',
    display: 'flex',
    '& .ant-select .ant-select-selector': {
      height: '2.5vw',
      webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      borderRadius: '0.25vw',
    },
    '& .ant-badge': {
      marginLeft: '3.35%',
      width: '11.16%',
      webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      borderRadius: '0.25vw',
      '& .ant-btn': {
        borderRadius: '0.25vw',
        width: '100%',
        height: '2.5vw',
        padding: '8% 20%',
      },
    },
    '& .ant-input-affix-wrapper': {
      height: '2.5vw',
      padding: '1.14% 3.13%',
      borderRadius: '0.25vw',
      '& .ant-input': {
        fontSize: '0.875vw',
      },
    },
    '& .anticon-search': {
      fontSize: '1.75vw',
      color: '#ccc9e6',
    },
    '& .ant-input-group-addon': {
      verticalAlign: 'top',
      backgroundColor: 'transparent',
      fontSize: '0.875vw',
      lineHeight: 1,
      '& .ant-btn': {
        borderLeft: 'none',
        height: '2.5vw',
        width: '2.5vw',
        borderTopRightRadius: '0.25vw !important',
        borderBottomRightRadius: '0.25vw !important',
      },
    },
    '& .ant-btn': {
      lineHeight: '1',
    },
  },
  filters: {
    width: '28vw',
    maxWidth: '50%',
  },
  // totalActivatedFilters: {
  //   '&.ant-badge-count': {
  //     background: theme.palette.primary,
  //   },
  // },
});

const { SubMenu } = Menu;

export const DatabaseAdminSearchBar: React.VFC<Props> = ({
  onSearch,
  classification,
  suggestions,
  searchParameters,
  onChangeName,
  setSearchFilters,
  classificationsFilter,
  isFromProject,
  financingNeed,
  contributors,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState({ visible: false, openKeys: [''] });

  const getFieldOptions = (
    field:
      | 'tags'
      | 'statuses'
      | 'targetPersons'
      | 'activityDomains'
      | 'activityZones'
      | 'sustainableDevelopmentGoals'
      | 'targetPopulations'
      | 'donationTypes',
  ) =>
    classification
      ? (classificationsFilter
          ? classification[field].filter((entry: ClassificationElement) =>
              classificationsFilter[field]?.includes(entry._id),
            )
          : classification[field]
        ).map((entry: ClassificationElement) => (
          <Option
            value={entry._id}
            key={entry._id}
            label={t(`${field}:${entry.name}`)}
          >
            {t(`${field}:${entry.name}`)}
          </Option>
        ))
      : [];

  const getDonationTypesOptions = () =>
    classification
      ? classification['donationTypes'].map((entry: ClassificationElement) => (
          <Option
            value={entry._id}
            key={entry._id}
            label={t(`${'donationTypes'}:${entry.name}`)}
          >
            {t(`${'donationTypes'}:${entry.name}`)}
          </Option>
        ))
      : [];

  const getContributorsOptions = () =>
    contributors
      ? contributors.map((entry: Contributor) => (
          <Option value={entry._id} key={entry._id} label={entry.lastName}>
            {entry.firstName} {entry.lastName}
          </Option>
        ))
      : [];

  const getTagsOptions = () =>
    classification
      ? (classificationsFilter
          ? classification['tags'].filter((entry: ClassificationElement) =>
              classificationsFilter['tags'].includes(entry._id),
            )
          : classification['tags']
        )
          .filter((elem: ClassificationElement) =>
            financingNeed?.tags.includes(elem._id),
          )
          .map((entry: ClassificationElement) => (
            <Option
              value={entry._id}
              key={entry._id}
              label={t(`${'tags'}:${entry.name}`)}
            >
              {t(`${'tags'}:${entry.name}`)}
            </Option>
          ))
      : [];

  const tagOptions = !isFromProject
    ? useMemo(
        () => getFieldOptions('tags'),
        [classification, classificationsFilter],
      )
    : useMemo(() => getTagsOptions(), [classification, classificationsFilter]);

  const statusOptions = useMemo(
    () => getFieldOptions('statuses'),
    [classification, classificationsFilter],
  );

  const targetPersonOptions = useMemo(
    () => getFieldOptions('targetPersons'),
    [classification, classificationsFilter],
  );

  const activityDomainsOptions = useMemo(
    () => getFieldOptions('activityDomains'),
    [classification, classificationsFilter],
  );

  const activityZonesOptions = useMemo(
    () => getFieldOptions('activityZones'),
    [classification],
  );

  const targetPopulationsOptions = useMemo(
    () => getFieldOptions('targetPopulations'),
    [classification, classificationsFilter],
  );

  const sustainableDevelopmentGoalsOptions = useMemo(
    () => getFieldOptions('sustainableDevelopmentGoals'),
    [classification, classificationsFilter],
  );

  const donationTypesOptions = useMemo(
    () => getDonationTypesOptions(),
    [classification],
  );

  const contributorsOptions = useMemo(
    () => getContributorsOptions(),
    [contributors],
  );

  const menu = useMemo<any[]>(() => {
    return suggestions
      ? suggestions.map((value) => {
          return {
            value: value.name,
          };
        })
      : [];
  }, [suggestions]);

  const onChangeField = (field: string, value: string | SelectValue) => {
    if (field === 'published') {
      const publishedBoolean = value == 'true' ? true : false;
      onSearch({ ...searchParameters, [field]: publishedBoolean });
    } else {
      onSearch({ ...searchParameters, [field]: value });
    }
  };

  const handleReset = () => {
    const resetValues = {
      name: '',
      tags: [],
      statuses: [],
      targetPersons: [],
      activityDomains: [],
      activityZones: [],
      sustainableDevelopmentGoals: [],
      targetPopulations: [],
      donationTypes: [],
      source: undefined,
    };
    onChangeName && onChangeName('');
    setSearchFilters(resetValues);
    onSearch(resetValues);
  };

  const handleValueBoolParameters = (value: boolean | undefined) => {
    if (value === true) {
      return 'true';
    } else if (value === false) {
      return 'false';
    } else {
      return undefined;
    }
  };

  const handleValueDateParameters = (value: any) => {
    const newDate = value ? value.toDate() : undefined;
    return newDate ? newDate.toISOString() : undefined;
  };

  const stringToMoment = (value: string | undefined) => {
    return value ? moment(value) : undefined;
  };

  const handleMenuClick = (e: any) => {
    if (e.key === '4') {
      setMenuOpen({ visible: menuOpen.visible, openKeys: ['s'] });
    }
  };

  // const clearIcon = () => <Button style={{ textAlign: 'left' }}></Button>;

  const renderFilters = () => (
    <Menu onClick={handleMenuClick}>
      {/* <div className={classes.grid}> */}
      <SubMenu title="Filtrer par appels d'offres">
        <div className={classes.gridCell}>
          {"Nombre minimal d'appel d'offre: "}
          <InputNumber
            value={searchParameters.callForTendersCountStart}
            onChange={(value) =>
              onChangeField('callForTendersCountStart', value || undefined)
            }
            placeholder="0"
          />
        </div>
        <Menu.Divider />
        <div className={classes.gridCell}>
          {"Nombre maximal d'appel d'offre: "}
          <InputNumber
            value={searchParameters.callForTendersCountEnd}
            onChange={(value) =>
              onChangeField('callForTendersCountEnd', value || undefined)
            }
            placeholder="0"
          />
        </div>
        <Menu.Divider />
        <div className={classes.gridCell}>
          {"Nombre d'appel d'offre: "}
          <InputNumber
            value={searchParameters.callForTendersCount}
            onChange={(value) =>
              onChangeField('callForTendersCount', value || undefined)
            }
            placeholder="0"
          />
        </div>
      </SubMenu>
      <SubMenu title="Filtrer par associations verifiées soutenues">
        <div className={classes.gridCell}>
          {"Nombre minimal d'associations verifiées soutenues: "}
          <InputNumber
            value={searchParameters.fundedNgosCountStart}
            onChange={(value) =>
              onChangeField('fundedNgosCountStart', value || undefined)
            }
            placeholder="0"
          />
        </div>
        <Menu.Divider />
        <div className={classes.gridCell}>
          {"Nombre maximal d'associations verifiées soutenues: "}
          <InputNumber
            value={searchParameters.fundedNgosCountEnd}
            onChange={(value) =>
              onChangeField('fundedNgosCountEnd', value || undefined)
            }
            placeholder="0"
          />
        </div>
        <Menu.Divider />
        <div className={classes.gridCell}>
          {"Nombre d'associations verifiées soutenues: "}
          <InputNumber
            value={searchParameters.fundedNgosCount}
            onChange={(value) =>
              onChangeField('fundedNgosCount', value || undefined)
            }
            placeholder="0"
          />
        </div>
      </SubMenu>
      <SubMenu title="Filtrer par organisations soutenues">
        <div className={classes.gridCell}>
          {"Nombre minimal d'organisations soutenues: "}
          <InputNumber
            onChange={(value) =>
              onChangeField(
                'unverifiedFundedNgosCountStart',
                value || undefined,
              )
            }
            placeholder="0"
            value={searchParameters.unverifiedFundedNgosCountStart}
          />
        </div>
        <Menu.Divider />
        <div className={classes.gridCell}>
          {"Nombre maximal d'organisations soutenues: "}
          <InputNumber
            value={searchParameters.unverifiedFundedNgosCountEnd}
            onChange={(value) =>
              onChangeField('unverifiedFundedNgosCountEnd', value || undefined)
            }
            placeholder="0"
          />
        </div>
        <Menu.Divider />
        <div className={classes.gridCell}>
          {"Nombre d'organisations soutenues: "}
          <InputNumber
            value={searchParameters.unverifiedFundedNgosCount}
            onChange={(value) =>
              onChangeField('unverifiedFundedNgosCount', value || undefined)
            }
            placeholder="0"
          />
        </div>
      </SubMenu>
      <SubMenu title="Filtrer par associations verifiées et non verifiées soutenues">
        <div className={classes.gridCell}>
          {"Nombre cumulé minimal d'organisations soutenues: "}
          <InputNumber
            value={searchParameters.unverifiedAndVerifiedFundedNgosCountStart}
            onChange={(value) =>
              onChangeField(
                'unverifiedAndVerifiedFundedNgosCountStart',
                value || undefined,
              )
            }
            placeholder="0"
          />
        </div>
        <Menu.Divider />
        <div className={classes.gridCell}>
          {"Nombre cumulé maximal d'organisations soutenues: "}
          <InputNumber
            value={searchParameters.unverifiedFundedNgosCountEnd}
            onChange={(value) =>
              onChangeField(
                'unverifiedAndVerifiedFundedNgosCountEnd',
                value || undefined,
              )
            }
            placeholder="0"
          />
        </div>
        <Menu.Divider />
        <div className={classes.gridCell}>
          {"Nombre cumulé d'organisations soutenues: "}
          <InputNumber
            value={searchParameters.unverifiedFundedNgosCount}
            onChange={(value) =>
              onChangeField(
                'unverifiedAndVerifiedFundedNgosCount',
                value || undefined,
              )
            }
            placeholder="0"
          />
        </div>
      </SubMenu>
      <SubMenu title="Filtrer par date de création">
        <div className={classes.gridCell}>
          {'Date de création minimale: '}
          <DatePicker
            value={stringToMoment(searchParameters.createdAtStart)}
            onChange={(value) =>
              onChangeField('createdAtStart', handleValueDateParameters(value))
            }
            placeholder={'Entrez une date'}
          />
        </div>
        <Menu.Divider />
        <div className={classes.gridCell}>
          {'Date de création maximale: '}
          <DatePicker
            value={stringToMoment(searchParameters.createdAtEnd)}
            onChange={(value) =>
              onChangeField('createdAtEnd', handleValueDateParameters(value))
            }
            placeholder={'Entrez une date'}
          />
        </div>
      </SubMenu>
      <SubMenu title="Filtrer par date de dernière modification">
        <div className={classes.gridCell}>
          {'Date de dernière modification minimale: '}
          <DatePicker
            value={stringToMoment(searchParameters.updatedAtStart)}
            onChange={(value) =>
              onChangeField('updatedAtStart', handleValueDateParameters(value))
            }
            placeholder={'Entrez une date'}
          />
        </div>
        <Menu.Divider />
        <div className={classes.gridCell}>
          {'Date de dernière modification maximale: '}
          <DatePicker
            value={stringToMoment(searchParameters.updatedAtEnd)}
            onChange={(value) =>
              onChangeField('updatedAtEnd', handleValueDateParameters(value))
            }
            placeholder={'Entrez une date'}
          />
        </div>
      </SubMenu>
      <SubMenu title="Filtrer par informations générales">
        <div className={classes.grid}>
          <div className={classes.gridCell}>
            <Select
              placeholder={'Filtrer par nationalité'}
              onChange={(value) => onChangeField('nationality', value)}
              style={{ width: '100%' }}
              value={searchParameters.nationality}
              optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              <Option value="france">Française</Option>
              <Option value="other">Autre</Option>
            </Select>
          </div>
          <div className={classes.gridCell}>
            <Select
              placeholder={'Filtrer par état'}
              onChange={(value) => onChangeField('publicationStatus', value)}
              style={{ width: '100%' }}
              value={searchParameters.publicationStatus}
              // optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              {Array.from({ length: 10 }, (_, i) => i + 1).map((i) => (
                <Option key={i} value={`${i}`}>
                  {i}
                </Option>
              ))}
            </Select>
          </div>
          <div className={classes.gridCell}>
            <Select
              placeholder={'Est abrité par un organisme ?'}
              onChange={(value) => onChangeField('isHostedBy', value)}
              style={{ width: '100%' }}
              value={handleValueBoolParameters(searchParameters.isHostedBy)}
              optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              <Option value="true">Oui (Abrité par un organisme)</Option>
              <Option value="false">Non (Abrité par un organisme)</Option>
            </Select>
          </div>
          <div className={classes.gridCell}>
            <Select
              placeholder={"Fonctionne-t-il par appel d'offre ?"}
              onChange={(value) => onChangeField('usesCallsToTender', value)}
              style={{ width: '100%' }}
              value={handleValueBoolParameters(
                searchParameters.usesCallsToTender,
              )}
              optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              <Option value="true">
                Oui (Fonctionne par appel d&apos;offre)
              </Option>
              <Option value="false">
                Non (Fonctionne par appel d&apos;offre)
              </Option>
            </Select>
          </div>
          <div className={classes.gridCell}>
            <Select
              onChange={(value) => onChangeField('author', value)}
              style={{ width: '100%' }}
              placeholder={'Assigné à'}
              value={searchParameters.author}
              optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              {contributorsOptions}
            </Select>
          </div>
        </div>
      </SubMenu>
      <SubMenu title="Filtrer par renseignements sur fiche mécènes">
        <div className={classes.grid}>
          <div className={classes.gridCell}>
            <Select
              placeholder={'Filtrer par présence de logo'}
              onChange={(value) => onChangeField('hasLogo', value)}
              style={{ width: '84.2%' }}
              value={handleValueBoolParameters(searchParameters.hasLogo)}
              optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              <Option value="true">Oui (présence de logo)</Option>
              <Option value="false">Non (présence de logo)</Option>
            </Select>
          </div>
          <div className={classes.gridCell}>
            <Select
              placeholder={'Filtrer par présence de lien Url'}
              onChange={(value) => onChangeField('hasHomepageUrl', value)}
              style={{ width: '84.2%' }}
              value={handleValueBoolParameters(searchParameters.hasHomepageUrl)}
              optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              <Option value="true">Oui (présence de lien Url)</Option>
              <Option value="false">Non (présence de lien Url)</Option>
            </Select>
          </div>
          <div className={classes.gridCell}>
            <Select
              placeholder={'Filtrer par présence de lien Linkedin'}
              onChange={(value) => onChangeField('hasLinkedinUrl', value)}
              style={{ width: '84.2%' }}
              value={handleValueBoolParameters(searchParameters.hasLinkedinUrl)}
              optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              <Option value="true">Oui (Présence de lien Linkedin)</Option>
              <Option value="false">Non (Présence de lien Linkedin)</Option>
            </Select>
          </div>
          <div className={classes.gridCell}>
            <Select
              placeholder={
                "Filtrer par présence de lien Linkedin de l'entreprise"
              }
              onChange={(value) =>
                onChangeField('hasCompanyLinkedinUrl', value)
              }
              style={{ width: '84.2%' }}
              value={handleValueBoolParameters(
                searchParameters.hasCompanyLinkedinUrl,
              )}
              optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              <Option value="true">
                Oui (Présence de lien Linkedin de l&apos;entreprise)
              </Option>
              <Option value="false">
                Non (Présence de lien Linkedin de l&apos;entreprise)
              </Option>
            </Select>
          </div>
          <div className={classes.gridCell}>
            <Select
              placeholder={'Filtrer par présence de description'}
              onChange={(value) => onChangeField('hasDescription', value)}
              style={{ width: '84.2%' }}
              value={handleValueBoolParameters(searchParameters.hasDescription)}
              optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              <Option value="true">Oui (Présence de description)</Option>
              <Option value="false">Non (Présence de description)</Option>
            </Select>
          </div>
          <div className={classes.gridCell}>
            <Select
              placeholder={'Filtrer par présence de commentaires'}
              onChange={(value) => onChangeField('hasComments', value)}
              style={{ width: '84.2%' }}
              value={handleValueBoolParameters(searchParameters.hasComments)}
              optionFilterProp="label"
              allowClear
              showArrow={false}
            >
              <Option value="true">Oui (Présence de commentaires)</Option>
              <Option value="false">Non (Présence de commentaires)</Option>
            </Select>
          </div>
        </div>
      </SubMenu>
      <SubMenu title="Filtrer par caractéristiques">
        <div className={classes.grid}>
          <div className={classes.gridCell}>
            <Select
              placeholder={'Filtrer par mots clés'}
              mode="tags"
              onChange={(value) => onChangeField('tags', value)}
              style={{ width: '100%' }}
              value={searchParameters.tags}
              optionFilterProp="label"
            >
              {tagOptions}
            </Select>
          </div>
          <Menu.Divider />
          <div className={classes.gridCell}>
            <Select
              mode="tags"
              onChange={(value) => onChangeField('statuses', value)}
              style={{ width: '100%' }}
              placeholder={'Filtrer par statut'}
              value={searchParameters.statuses}
              optionFilterProp="label"
            >
              {statusOptions}
            </Select>
          </div>
          <Menu.Divider />
          <div className={classes.gridCell}>
            <Select
              mode="tags"
              onChange={(value) => onChangeField('targetPersons', value)}
              style={{ width: '100%' }}
              placeholder={'Filtrer par soutien orienté vers'}
              value={searchParameters.targetPersons}
              optionFilterProp="label"
            >
              {targetPersonOptions}
            </Select>
          </div>
          <Menu.Divider />
          <div className={classes.gridCell}>
            <Select
              mode="tags"
              onChange={(value) => onChangeField('activityDomains', value)}
              style={{ width: '100%' }}
              placeholder={"Filtrer par domaine d'activité"}
              value={searchParameters.activityDomains}
              optionFilterProp="label"
            >
              {activityDomainsOptions}
            </Select>
          </div>
          <Menu.Divider />
          <div className={classes.gridCell}>
            <Select
              mode="tags"
              onChange={(value) => onChangeField('activityZones', value)}
              style={{ width: '100%' }}
              placeholder={"Filtrer par zone d'activité"}
              value={searchParameters.activityZones}
              optionFilterProp="label"
            >
              {activityZonesOptions}
            </Select>
          </div>
          <Menu.Divider />
          <div className={classes.gridCell}>
            <Select
              mode="tags"
              onChange={(value) =>
                onChangeField('sustainableDevelopmentGoals', value)
              }
              style={{ width: '100%' }}
              placeholder={'Filtrer par objectif de développement durable'}
              value={searchParameters.sustainableDevelopmentGoals}
              optionFilterProp="label"
            >
              {sustainableDevelopmentGoalsOptions}
            </Select>
          </div>
          <Menu.Divider />
          <div className={classes.gridCell}>
            <Select
              mode="tags"
              onChange={(value) => onChangeField('targetPopulations', value)}
              style={{ width: '100%' }}
              placeholder={'Filtrer par public cible'}
              value={searchParameters.targetPopulations}
              optionFilterProp="label"
            >
              {targetPopulationsOptions}
            </Select>
          </div>
          <Menu.Divider />
          <div className={classes.gridCell}>
            <Select
              mode="tags"
              onChange={(value) => onChangeField('donationTypes', value)}
              style={{ width: '100%' }}
              placeholder={'Filtrer par type de mécénat'}
              value={searchParameters.donationTypes}
              optionFilterProp="label"
            >
              {donationTypesOptions}
            </Select>
          </div>
          <Menu.Divider />
          <div className={classes.gridCell}>
            <Select
              mode="tags"
              onChange={(value) => onChangeField('modusOperandi', value)}
              style={{ width: '100%' }}
              placeholder={'Filtrer par mode opératoire'}
              value={searchParameters.modusOperandi}
              optionFilterProp="label"
            >
              {Object.values(ModusOperandi).map((modusOperandi, index) => (
                <Option key={index} value={modusOperandi} label={modusOperandi}>
                  {modusOperandi}
                </Option>
              ))}
            </Select>
          </div>
          <Menu.Divider />
          <div className={classes.gridCell}>
            <Select
              onChange={(value) => onChangeField('published', value)}
              style={{ width: '100%' }}
              placeholder={'Filtrer par état de publication'}
              value={handleValueBoolParameters(searchParameters.published)}
              showArrow={false}
              allowClear
            >
              <Option value="true">Publié</Option>
              <Option value="false">Non publié</Option>
            </Select>
          </div>
        </div>
      </SubMenu>
      <div className={classes.gridCell}>
        <a style={{ color: 'red' }} onClick={() => handleReset()}>
          Supprimer tous les filtres
        </a>
      </div>
      {/* </div> */}
    </Menu>
  );

  const renderSearchBar = () => (
    <div className={classes.searchBar}>
      <AutoComplete
        options={menu}
        style={{ width: '85.49%' }}
        onSelect={(value: any) => onChangeField('name', value)}
        value={searchParameters.name}
        onSearch={(value) => onChangeField('name', value)}
      >
        <Search
          allowClear
          onChange={(e) => onChangeName && onChangeName(e.target.value)}
          onSearch={(value) => onChangeField('name', value)}
        />
      </AutoComplete>
      <Badge
        style={{
          background: theme.palette.orange[0],
          height: '1.25vw',
          minWidth: '1.25vw',
          fontFamily: "'Baloo 2'",
          fontSize: '1.25vw',
          lineHeight: '1',
          padding: '0',
          color: theme.palette.common.white,
          borderColor: theme.palette.orange[0],
        }}
      >
        <Button
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
        >
          <FilterOutlined style={{ color: '#ccc9e6', fontSize: '1.75vw' }} />
        </Button>
      </Badge>
    </div>
  );

  const ref = useRef(null);

  // useEffect(() => {
  //   document.addEventListener('click', close);
  //   return () => document.removeEventListener('click', close);
  // }, []);

  // function close(e: React.FormEvent<HTMLInputElement>) {
  //   console.dir(e.target, ref.current);
  //   setDropdownOpen(e && e.target === ref.current);
  // }

  return (
    <div ref={ref} className={classes.container}>
      <Dropdown visible={dropdownOpen} overlay={renderFilters()}>
        {renderSearchBar()}
      </Dropdown>
    </div>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';

export type ClassificationElement = {
  _id: string;
  name: string;
};

export type Tag = {
  associatedDomains: string[];
  associatedSDGs: string[];
};

export interface Place {
  name: string;
  countries?: ClassificationElement[];
  departments?: ClassificationElement[];
}

export interface SustainableDevelopmentGoal extends ClassificationElement {
  number: string;
}

export type Classification = {
  activityDomains: ClassificationElement[];
  activityZones: ClassificationElement[];
  donationTypes: ClassificationElement[];
  statuses: ClassificationElement[];
  nationalities: ClassificationElement[];
  targetPersons: ClassificationElement[];
  sustainableDevelopmentGoals: SustainableDevelopmentGoal[];
  tags: (ClassificationElement & Tag)[];
  places: Place[];
  targetPopulations: ClassificationElement[];
};

interface ClassificationState {
  loading: boolean;
  error: any;
  classification: Classification | null;
}

const initialState: ClassificationState = {
  loading: false,
  error: null,
  classification: null,
};

export const getClassification = createAsyncThunk(
  'classification',
  async () => {
    try {
      return await apiRequest<Classification>('GET', '/classification');
    } catch (error) {
      return null;
    }
  },
);

const classificationSlice = createSlice({
  name: 'classification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClassification.pending, (state: ClassificationState) => {
        state.loading = true;
      })
      .addCase(
        getClassification.fulfilled,
        (state: ClassificationState, { payload }) => {
          state.loading = false;
          state.classification = payload;
        },
      )
      .addCase(
        getClassification.rejected,
        (state: ClassificationState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      );
  },
});

export default classificationSlice.reducer;

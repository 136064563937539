import React from 'react';
import { createUseStyles } from 'react-jss';
import offerIcon from '../../assets/icons/offerIcon.svg';

const useStyles = createUseStyles({
  offerArgBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '11px',
  },
  offerArgText: {
    fontFamily: "'Baloo 2'",
    fontWeight: '400',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    color: '#292929',
  },
});

interface Props {
  value: string;
}

export const Benefit = ({ value }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.offerArgBox}>
      <img
        src={offerIcon}
        style={{ width: '20px', height: '20px', alignSelf: 'center' }}
      />
      <div className={classes.offerArgText}>{value}</div>
    </div>
  );
};

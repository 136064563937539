import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Form, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { DonorFirstStepsForm } from '../containers/DonorFirstStepsForm';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { fetchCurrentUser, updateUserDonorId } from '../slices/auth';
import { Donor, createDonor } from '../slices/donor';
import { LoggedIntercom } from '../utils/loggedIntercom';

const { Title } = Typography;

const useStyles = createUseStyles({
  title: {
    fontSize: '2vw !important',
  },
  view: {
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  table: {
    maxWidth: '100%',
    margin: '1vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  button: {
    display: 'block',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  column: {
    color: 'black',
    fontWeight: 'bold',
    cursor: 'pointer',
    maxWidth: '18.75vw',
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  donorEditForm: {},
  select: {
    display: 'flex',
    margin: '1.25vw auto',
    width: '15.625vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        height: '2vw !important',
        lineHeight: '2vw !important',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
  cancelButton: {
    color: 'red',
    marginRight: '32px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props {
  setIsNavBarHidden?: (isHidden: boolean) => void;
}

export const DonorFirstSteps = ({ setIsNavBarHidden }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [editForm, setEditForm] = React.useState(true);
  const [donorForm] = Form.useForm();

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  useEffect(() => {
    fetchCurrentUser();
  }, [user]);

  useEffect(() => {
    if (user) {
      donorForm.setFieldsValue({ name: user.organisationName });
    }
  }, [user]);

  const onSubmitDonorForm = (values: any) => {
    return dispatch(
      createDonor({
        ...values,
        homepageUrl: values.homepageUrl || '',
        publicationStatus: '1',
        published: true,
      }),
    );
  };

  const updateDonorIdAndSendEmail = (donorId: string) => {
    if (user) {
      // dispatch(
      //   sendWelcomeEmail({
      //     donorId: donorId,
      //     userId: user._id,
      //     organizationType: 'DONOR',
      //   }),
      // );
      dispatch(
        updateUserDonorId({
          updateUserDto: { donorId: donorId, hasValidSubscription: true },
          id: user._id,
        }),
      );
    }
  };

  if (!user) {
    return <FullscreenSpin />;
  }

  async function submitForm() {
    const fieldNames = [
      'name',
      'status',
      'creationYear',
      'headquarterZipcode',
      'headquarterCity',
      'headquarterAddress',
      'description',
      'donationTypes',
      'modusOperandi',
      'tags',
      'places',
      'roadAndNumber',
      'uniqueZipcode',
      'zipcode',
      'city',
      'activityZones',
      'targetPopulations',
      'nationality',
      'targetPerson',
      'fundedNgos',
      'contactEmail',
      'contactPhone',
      'homepageUrl',
      'logoUrl',
      'linkedinUrl',
    ];

    const allFieldValues = fieldNames.reduce((acc: any, fieldName: string) => {
      acc[fieldName] = donorForm.getFieldValue(fieldName);
      return acc;
    }, {});

    const donorPost = await onSubmitDonorForm(allFieldValues);
    LoggedIntercom(
      user?.firstName + ' ' + user?.lastName,
      user?.email,
      user?._id,
      'donorFirstSteps-completed',
    );
    window.dataLayer.push({
      event: 'donorFirstSteps_completed',
    });
    setEditForm(false);
    if (donorPost.payload) {
      const donorPayload = donorPost.payload as Donor;
      await updateDonorIdAndSendEmail(donorPayload._id);
    }
    const payload = donorPost.payload as Donor;
    history.push(`/donor/${payload._id}`);
  }

  if (setIsNavBarHidden && editForm) {
    setIsNavBarHidden(true);
  } else if (setIsNavBarHidden && !editForm) {
    setIsNavBarHidden(false);
  }

  //handleClosePrompt
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);
  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    const handler = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handler);
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [isFirstRender]);

  return (
    <div className={classes.view}>
      {editForm ? (
        <div className={classes.donorEditForm}>
          <DonorFirstStepsForm
            form={donorForm}
            setEditForm={setEditForm}
            submitForm={submitForm}
          />
        </div>
      ) : (
        <div className={classes.container}>
          <Title className={classes.title}>
            Bienvenue chez Sonar, veuillez créer votre organisation
          </Title>
          <Button
            type="primary"
            size={'large'}
            onClick={async () => {
              setEditForm(true);
            }}
            className={classes.button}
          >
            Créer mon organisation
          </Button>
        </div>
      )}
    </div>
  );
};

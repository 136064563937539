import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../hooks/store';
import { theme } from 'src/constants/theme';
import { saveCallForTender } from 'src/slices/callForTender';
import { getDonor } from 'src/slices/donor';
import { LoggedIntercom } from '../utils/loggedIntercom';
import { CallForTenderStepsForm } from 'src/containers/CallForTenderStepsForm';
import CallForTenderConfirmation from './donorPage/CallForTenderConfirmation';

const useStyles = createUseStyles({
  container: {},
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  saveButton: {
    backgroundColor: theme.palette.success,
    borderColor: theme.palette.success,
    '&:hover': {
      backgroundColor: theme.palette.green[4],
      borderColor: theme.palette.green[4],
    },
  },
  cancelButton: {
    color: 'red',
    marginRight: '32px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props {
  setIsNavBarHidden?: (isNavBarHidden: boolean) => void;
}

export const SaveTenderDonor: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { donorId } = useParams<{ donorId: string }>();
  const donor = useAppSelector((state) => state.donor.donors.byId[donorId]);
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  const classes = useStyles();
  const [callForTenders, setCallForTenders] = useState<string[] | undefined>([
    '',
  ]);
  const [editForm, setEditForm] = useState(true);

  useEffect(() => {
    if (setIsNavBarHidden) {
      setIsNavBarHidden(true);
    }
  }, []);

  useEffect(() => {
    if (donor) {
      dispatch(getDonor(donorId));
      setCallForTenders(callForTenders);
    }
  }, []);

  const handleSave = async (values: any) => {
    await dispatch(saveCallForTender({ donorId: donorId, ...values }));
    LoggedIntercom(
      user?.firstName + ' ' + user?.lastName,
      user?.email,
      user?._id,
      'createdCallForTender-completed',
    );
  };

  async function submitForm() {
    const fieldNames = [
      'name',
      'description',
      'donationTypes',
      'modusOperandi',
      'targetPopulations',
      'tags',
      'places',
      'roadAndNumber',
      'uniqueZipcode',
      'zipcode',
      'city',
      'openingDate',
      'closingDate',
      'url',
      'comments',
    ];

    const allFieldValues = fieldNames.reduce((acc: any, fieldName: string) => {
      acc[fieldName] = form.getFieldValue(fieldName);
      return acc;
    }, {});
    await handleSave(allFieldValues);
    if (donor && donor.callForTenders && donor.callForTenders.length < 2) {
      setEditForm(false);
      form.resetFields();
    } else {
      history.push(`donor/${donorId}`);
    }
    user?.role === 'admin' && history.push(`/donor/${donorId}`);
  }

  //handleClosePrompt
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);
  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    const handler = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handler);
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [isFirstRender]);

  return editForm ? (
    <div className={classes.container}>
      <CallForTenderStepsForm
        form={form}
        isNewCallForTender={true}
        donorId={donorId}
        submitForm={submitForm}
      />
    </div>
  ) : (
    <div className={classes.container}>
      <CallForTenderConfirmation setEditForm={setEditForm} />
    </div>
  );
};

import React, { useEffect, useMemo } from 'react';
import { Avatar, Comment, Typography } from 'antd';
import { DealFlowNote } from 'src/slices/dealFlowNote';
import { useAppSelector } from 'src/hooks/store';
import { useDispatch } from 'react-redux';
import { User, findUserById } from 'src/slices/auth';

interface Props {
  noteId: string;
}

export const KanbanNote: React.VFC<Props> = ({ noteId }) => {
  const dispatch = useDispatch();

  const { notes, users } = useAppSelector(
    ({ dealFlowNote: { notes }, auth: { users } }) => ({
      notes,
      users,
    }),
  );

  const note = useMemo<DealFlowNote>(() => notes && notes[noteId], [notes]);
  const author = useMemo<User | null>(() => {
    if (!users || !note || !note.author) return null;
    return users[note.author];
  }, [note, users]);

  useEffect(() => {
    if (note && (!users || !users[note.author]))
      dispatch(findUserById({ userId: note.author }));
  }, [note]);

  const findTimeInterval = (
    time: number,
  ): [
    number,
    'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years',
  ] => {
    const timeUnits = [31536000, 2628002, 604800, 86400, 3600, 60, 0];
    const timeUnitNames: (
      | 'seconds'
      | 'minutes'
      | 'hours'
      | 'days'
      | 'weeks'
      | 'months'
      | 'years'
    )[] = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];
    let index = timeUnits.findIndex((unit) => time >= unit);
    if (index < 0) index = 6;
    const timeInterval = Math.floor(
      time / (timeUnits[index] > 0 ? timeUnits[index] : 1),
    );
    return [timeInterval, timeUnitNames[index]];
  };

  const renderDate = () => {
    if (!note) return <></>;
    const date = new Date(note.createdAt).getTime();
    const now = new Date().getTime();
    const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'narrow' });
    const timeInterval = findTimeInterval((now - date) / 1000);
    const relativeTime = rtf1.format(-1 * timeInterval[0], timeInterval[1]);
    return <Typography>{relativeTime}</Typography>;
  };

  if (!note) return <></>;
  return (
    <Comment
      author={
        <Typography>{`${author?.firstName} ${author?.lastName}`}</Typography>
      }
      avatar={<Avatar src={author?.profilePictureUrl} />}
      content={<p>{note.content}</p>}
      datetime={renderDate()}
    />
  );
};
